import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";

import EditorContainer from "../../components/Editor/EditorContainer";
import Modal from "../../components/Modal/Modal";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import { routeCreators } from "../../lib/routes";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import * as LAYER_TYPES from "../../constants/layer-types";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import { PRODUCT_TYPE_IDS, productsByProductTypeId } from "../../data/products";
import * as designsData from "../../data/designs";

class AnnouncementCardEditorContainer extends Component {
  state = {
    itemForEditor: null,
    isItemDuplicationAlertVisible: false,
    toast: null,
    editorItemLoaded: false,
    editorItemLoading: true,
    isApprovalModalVisible: false,
    approvalModalItemId: null,
    approvalModalImage: null,
    renderItemLoading: false,
    renderItemLoaded: false,
  };

  componentWillMount() {
    this.handleSelectProductType(PRODUCT_TYPE_IDS.ANNOUNCEMENT);
  }

  closeEditor = (closeBehavior = {}) => {
    // TODO: extract this to ENV var
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  setUpEditorItem = async ({ product, productTypeId, designId, selectedVariationIndex }) => {
    const designDetail = await postsnapApi.designs.getDetails(designId);

    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    if (selectedVariationIndex !== undefined) {
      layers = layers.map(layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          return layer.setIn(
            ["config", "s3_key"],
            layer.getIn(["config", "s3_keys", selectedVariationIndex])
          );
        }

        if (layer.get("type") === LAYER_TYPES.TEXT) {
          return layer.setIn(
            ["config", "color"],
            layer.getIn(["config", "colors", selectedVariationIndex])
          );
        }

        return layer;
      });
    }

    let itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId,
      quantity: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      designOptions: designDetail.data.data.preview_s3_keys || [],
      postDate:
        productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD ? moment().add(1, "days") : moment(),
    });

    if (itemData.getIn(["pages", "envelope"])) {
      itemData = itemData.update("layers", layers =>
        layers.concat(
          fromJS([
            {
              config: {
                rect: {
                  x: 0.1,
                  y: 0.7,
                  width: 0.5,
                  height: 0.6,
                },
                font: "Anaheim Gothic",
                size: 10,
                color: "0,0,0",
              },
              id: "ENVELOPE_ADDRESS",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              zindex: null,
              type: "Layer::Address",
            },
            {
              config: {
                rect: {
                  x: 0.695,
                  y: 0.94,
                  width: 0.26,
                  height: 0.23,
                },
              },
              id: "ENVELOPE_STAMP",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              type: "Layer::Indicia",
            },
          ])
        )
      );
    }

    this.setState({
      itemForEditor: itemData,
      editorItemLoaded: true,
    });
  };

  handleSelectProductType = async productTypeId => {
    // this.setState({
    //   editorItemLoading: true,
    // });
    // Get the first product ID for this product type ID
    const firstProductForProductType = productsByProductTypeId.get(productTypeId).first();

    /**
     * Get the first design for that product (the first design is the _only_ design, except for greeting cards which
     * are handled in a separate method)
     */
    // console.log(productTypeId);
    // console.log(firstProductForProductType.get('id'));
    // console.log(designsData.designsByProductId.toJS());
    const firstDesignForProductId = designsData.designsByProductId
      .get(firstProductForProductType.get("id"))
      .first();
    this.setUpEditorItem({
      designId: firstDesignForProductId.get("id"),
      productTypeId: productTypeId,
      product: firstProductForProductType,
    });
  };

  handleAddToBasket = async item => {
    this.props.addBasketItem(item.toJS());
    this.props.goToBasket();
  };

  render() {
    if (this.state.editorItemLoading && !this.state.editorItemLoaded) {
      return (
        <FullScreenLoader
          key="loader"
          message="Loading your postcard..."
          isVisible={this.state.editorItemLoading && !this.state.editorItemLoaded}
        />
      );
    }
    return (
      <Modal
        key="editor-modal"
        isOpen={Boolean(this.state.itemForEditor)}
        onClose={this.closeEditor}
        hasHeader={false}
        animated={false}
      >
        <EditorContainer
          key="editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemForEditor}
          onClose={this.closeEditor}
          onSave={this.handleAddToBasket}
          saveButtonLabel="Add to Basket"
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementCardEditorContainer);
