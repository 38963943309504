import React, { Component } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Pluralize from "pluralize";
import TransitionGroup from "react-transition-group/TransitionGroup";
import CSSTransition from "react-transition-group/CSSTransition";

import MASKS from "../../constants/masks";
import MODELS from "../../constants/models";
import { routeCreators } from "../../lib/routes";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";
import { militaryStates } from "../../constants/geo";
import MainContent from "../../components/MainContent/MainContent";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Currency from "../../components/Formatters/Currency";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
import PayPalButton from "../../components/PayPalButton/PayPalButton";
//import { PayPalButton } from "react-paypal-button-v2";
import StripePaymentRequestButton from "../../components/StripePaymentRequestButton/StripePaymentRequestButton";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import ModalPage from "../../components/Modal/ModalPage";
import Icon from "../../components/Icon/Icon";
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import PhotoMagazinePreview from "../../components/PhotoMagazinePreview/PhotoMagazinePreview";
import Grid from "../../components/Grid/Grid";
import PhotoPrintPreview from "../../components/PhotoPrintPreview/PhotoPrintPreview";
import { Row, Col, Button as AntButton, Modal as AntModal, message, Progress, Collapse, Icon as AntIcon } from 'antd';

import './Basket.scss';

const { Panel } = Collapse;

const { confirm } = AntModal;

const PAYMENT_METHODS = {
  CREDIT_DEBIT_CARD: "CREDIT_DEBIT_CARD",
  PREPAY: "PREPAY",
};

const PAYMENT_MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  SAVED_CARD: "SAVED_CARD",
  STRIPE_CREDIT_CARD_FORM: "STRIPE_CREDIT_CARD_FORM",
  PREPAY_CREDIT_PAYMENT: "PREPAY_CREDIT_PAYMENT",
};

const BasketItem = ({
  item,
  showAddress,
  showShipping,
  canDelete,
  onClickPreview,
  onClickDeleteButton,
  onClickAddress,
  onClickIncreaseQty,
  onClickDecreaseQty,
}) => {
  const address = item.get("address") || item.get("addressBookEntry");
  const isCanvas = item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;

  const productSlug = isCanvas && productsByProductId.get(item.get("productId")).get("url_slug");

  const isPackMode = item.get("packMode");

  return (
    <tr>
      {canDelete && (
        <td className="pr-default">
          <AntButton ghost type="primary" shape="circle" icon="close" onClick={onClickDeleteButton} />
          {/* <Button icon="clear" circle size="small" priority="secondary" onClick={onClickDeleteButton} /> */}
        </td>
      )}
      <td
        width="40%"
        className="item-preview"
        style={item.get("isRendering") ? { opacity: 0.4 } : null}
      >
        <HtmlRenderer
          width={100}
          isInteractive={false}
          previewMode={true}
          onClick={onClickPreview}
          item={item.toJS()}
          page={item.getIn(["pages", "front"])}
          mask={isCanvas && MASKS[productSlug].canvasPreview}
        />
        {item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS && (
          <div className="qtyPicker" style={{margin: '7px auto', textAlign:'center', marginLeft: '-6px'}}>
            <AntButton.Group>
              <AntButton
                size="small"
                type={"primary"}
                ghost
                active={"true"}
                onClick={() => onClickDecreaseQty(item.get('id'))}
              >
                <AntIcon type="minus" />
              </AntButton>
              <AntButton
                size="small"
                onClick={() => onClickIncreaseQty(item.get('id'))}
                type={"primary"}
                ghost
              >
                <AntIcon type="plus" />
              </AntButton>
            </AntButton.Group>
          </div>
        )}
        <br />
      </td>
      <td style={{ paddingLeft: "5px" }}>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  width: "70%",
                  fontWeight: "400",
                  fontSize: "14px",
                  verticalAlign: "top",
                }}
              >
                {isPackMode ? (
                  `Pack of ${item.get("quantity")} cards`
                ) : (
                  `${item.get("description")} `
                )}
                {item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT && (
                  <span style={{ fontSize: "0.8em", color: "#aaa" }}>
                    <br />({item.get("product_options")})
                  </span>
                )}
              </td>
              <td className="text-right">
                {item.get("productTypeId") !== PRODUCT_TYPE_IDS.POSTCARD ? (
                  <div style={{fontSize: '12px'}}>
                    {isPackMode ? (
                      <React.Fragment>
                        <Currency
                          amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {item.get("quantity")} ×{" "}
                        <Currency amount={item.getIn(["pricingScheme", "cost"])} /><br/>={" "}
                        <Currency
                          amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])}
                        />
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <Currency amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])} />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {showAddress && (item.get("address") || item.get("addressBookEntry")) && (
                  <p style={{ fontSize: 11, whiteSpace: "pre-line" }} onClick={onClickAddress}>
                    {generateMultilineAddress(address.toJS()).join("\n")}
                  </p>
                )}
              </td>
            </tr>
            {showShipping && item.get("postageScheme") && (
              <tr>
                <td style={{ width: "70%", fontWeight: "400", fontSize: "12px" }}>Shipping</td>
                <td
                  className="text-right"
                  style={{ width: "70%", fontWeight: "400", fontSize: "12px" }}
                >
                  {item.getIn(["postageScheme", "cost"]) === 0 ? (
                    "Free"
                  ) : (
                    <Currency amount={item.getIn(["postageScheme", "cost"])} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
      </td>
    </tr>
  );
};

class Basket extends Component {
  static propTypes = {
    items: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    unapprovedItems: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    prepayBalance: PropTypes.object,
    isCreatingOrder: PropTypes.bool,
    isPayingForOrder: PropTypes.bool,
    canMakeStripePayment: PropTypes.bool,
    paymentRequest: PropTypes.any,
    onClickPreview: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onCreateOrder: PropTypes.func,
    onStripePaymentFormSubmit: PropTypes.func,
    onPayPalAuthorization: PropTypes.func,
    onShowAuthModal: PropTypes.func,
    onApplyPromoCode: PropTypes.func,
    onRemovePromoCode: PropTypes.func,
    onRetryRenders: PropTypes.func,
    showDuplicateAlertForItem: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    isDeletionModeActive: false,
    isPaymentModalVisible: false,
    paymentModalPage: PAYMENT_MODAL_PAGES.OVERVIEW,
    isApprovalModalVisible: false,
    approvalMetaData: {
      canCompleteApproval: true,
    },
    isItemDuplicationAlertVisible: false,
    productNameForDuplicationItem: "",
    isApprovalModalProductFlipped: false,
    isCrossSellModalVisible: true,
    hasSeenCrossSellModal: false,
    promoCode: "",
    alert: null,
    modal: null,
    modalVisible: false,
    isLookingUpPromoCode: false,
    isAddingPromoCode: false,
  };

  componentDidMount() {
    this.checkMilitaryAddress();
  }

  componentDidUpdate(prevProps) {
    if (this.props.items.size === 0 && this.state.isDeletionModeActive) {
      this.setState({
        isDeletionModeActive: false,
      });
    }

    if (this.props.unapprovedItems.size === 0 && this.state.isApprovalModalVisible) {
      this.setState({
        isApprovalModalVisible: false,
      });
    }

    if (this.props.unapprovedItems.size > 0) {
      const itemToApprove = this.props.unapprovedItems.first();
      if (itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
        if (this.state.approvalMetaData.canCompleteApproval && !this.state.approvalMetaData.pageObject){
          this.setState({
            approvalMetaData: {
              canCompleteApproval: false,
              pageObject: null,
            },
          });
        }
      } else if (!this.state.approvalMetaData.canCompleteApproval)  {
        this.setState({
          approvalMetaData: {
            canCompleteApproval: true,
          },
        });
      }
    }

    if (this.props.showDuplicateAlertForItem) {
      let singularProductNameForItem = "this product";
      let multipleProductNameForItems = "these products";

      console.log(this.props.showDuplicateAlertForItem.get('quantity'));
      switch (this.props.showDuplicateAlertForItem.get("productTypeId")) {
        case PRODUCT_TYPE_IDS.POSTCARD:
        case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
        case PRODUCT_TYPE_IDS.GREETING_CARD:
          singularProductNameForItem = "this card";
          multipleProductNameForItems = "these cards";
          break;
        case PRODUCT_TYPE_IDS.CANVAS:
          singularProductNameForItem = "this canvas";
          multipleProductNameForItems = "these canvases";
          break;
        case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
          singularProductNameForItem = "this magazine";
          multipleProductNameForItems = "these magazines";
          break;
        case PRODUCT_TYPE_IDS.PHOTO_PRINT:
          singularProductNameForItem = "this print";
          multipleProductNameForItems = "these prints";
          break;
        default:
          break;
      }

      if (!this.state.isItemDuplicationAlertVisible){
        this.setState({
          isItemDuplicationAlertVisible: true,
          productNameForDuplicationItem: this.props.showDuplicateAlertForItem.get('quantity') === 1 ? singularProductNameForItem : multipleProductNameForItems,
        });
      }
    }
  }

  handleClickStripePaymentRequestButton = e => {
    if (this.props.unapprovedItems.size > 0) {
      e.preventDefault();
      this.openApprovalModal();
    }
  };

  createOrderAndShowPaymentOptions = async () => {
    const orderCreationResponse = await this.props.onCreateOrder();

    // console.log("Order Response Raw", orderCreationResponse);
    // console.log("Order Response", orderCreationResponse.statusText);
    if (orderCreationResponse && orderCreationResponse.status !== 200) {
      //console.log("Order Response Data", orderCreationResponse.data);
      let errorMessage;
      if (orderCreationResponse.data.errors){
        if (orderCreationResponse.data.errors.errors.base){
          errorMessage = orderCreationResponse.data.errors.base[0].message || orderCreationResponse.data.errors.base[0];
        } else{
          errorMessage = null;
        }
      } else {
        errorMessage = null;
      }
      
      this.setState({
        alert: {
          type: "error",
          title: "Error",
          text: errorMessage || "There was a problem creating your order.",
          showCancelButton: false,
          confirmButtonText: "OK",
          onConfirm: this.clearAlert,
        },
      });
    } else if (orderCreationResponse && orderCreationResponse.status == 200){
      const response = orderCreationResponse.data
      console.log(response);
      if (response.success){
        this.setState({
          isPaymentModalVisible: true,
        });
      } else {
        let errorMessage;
        if (response.errors){
          if (response.errors.promotion){
            errorMessage = response.errors.promotion[0]
          } else{
            errorMessage = response.errors.base[0].message ||
            response.errors.base[0];
          }
        }
        this.setState({
          alert: {
            type: "error",
            title: "There was a problem creating your order",
            text: errorMessage || "There was a problem creating your order.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      }
    } else {
      this.setState({
        isPaymentModalVisible: true,
      });
    }
  };

  checkMilitaryAddress = () => {
    let containsPFOAddress = this.props.items.some(item => {
      const address = item.get("address") || item.get("addressBookEntry");
      if (address) {
        let anyMilitaryStates = militaryStates.some(ms => ms.name === address.get("county"));
        return anyMilitaryStates;
      } else {
        return false;
      }
    });

    if (containsPFOAddress) {
      this.setState({
        alert: {
          type: "success",
          title: "",
          text:
            "<p>🇺🇸 We're so grateful for US service personnel worldwide.</p> <br/><p>Use code <strong style='color: #ffffff;margin: 3px;padding: 5px 10px;background: #7b7878;'><code>MILITARY50</code></strong> for <strong>50%</strong> off all postcards to US military personnel.</p>",
          showCancelButton: false,
          confirmButtonText: "OK 👌",
          onConfirm: this.clearAlert,
        },
      });
    }
  };

  toggleDeletionMode = () => {
    this.setState({
      isDeletionModeActive: !this.state.isDeletionModeActive,
    });
  };

  openApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: true,
    });
  };

  retryRenders = () => {
    console.log('here')
    this.props.onRetryRenders();
  };

  closeApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: false,
    });
  };

  closePaymentModal = () => {
    this.setState({
      isPaymentModalVisible: false,
      paymentModalPage: PAYMENT_MODAL_PAGES.OVERVIEW,
    });
  };

  selectPaymentMethod = paymentMethod => {
    switch (paymentMethod) {
      case PAYMENT_METHODS.CREDIT_DEBIT_CARD: {
        if (
          this.props.user.get("stripe_token") &&
          this.props.user.getIn(["stripe_card_details", "saved_card"])
        ) {
          this.showPaymentModalPage(PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM);
        } else {
          this.showPaymentModalPage(PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM);
        }
        break;
      }
      case PAYMENT_METHODS.PREPAY:
        this.showPaymentModalPage(PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT);
        break;
      // no default
    }
  };

  printsPanelRef = React.createRef()


  scrollToPrintsPanel = (e) => {
    setTimeout(() => {
      if(this.printsPanelRef.current){
        this.printsPanelRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
      }
    }, 450)
  }

  handleChangePromotionCodeInput = e => {
    this.setState({
      promoCode: e.target.value.toUpperCase(),
    });
  };

  handleClearPromoCode = () => {
    this.setState(
      {
        promoCode: "",
      },
      () => {
        this.props.onRemovePromoCode();
      }
    );
  };

  handleShowPromoCode = () => {
    this.setState(
      {
        isAddingPromoCode: true,
      },
      () => {
        setTimeout(() => {
          this.promoCodeInput.focus();
        }, 500);
      }
    );
  };

  handleHidePromoCode = () => {
    this.setState({
      isAddingPromoCode: false,
    });
  };

  handleSubmitPromoCodeForm = async e => {
    e.preventDefault();
    this.setState({
      isLookingUpPromoCode: true,
    });
    try {
      const response = await this.props.onApplyPromoCode(this.state.promoCode);
      if (!response.payload.data.success) {
        this.setState({
          alert: {
            type: "error",
            title: "Invalid Code",
            text: "It looks like this code doesn't exist, or has expired.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
          isLookingUpPromoCode: false,
        });
      } else {
        this.setState({
          isLookingUpPromoCode: false,
          isAddingPromoCode: false,
        }, () => {
          if (response.payload.data.data.message){
            message.success(response.payload.data.data.message);
          }
        });
      }
    } catch (err) {
      this.setState({
        isLookingUpPromoCode: false,
      });
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  toggleFlippedForApprovalModal = () => {
    this.setState({
      isApprovalModalProductFlipped: !this.state.isApprovalModalProductFlipped,
    });
  };

  handleApproveItem = item => {
    this.props.onApproveItem(item);
    this.setState({
      isApprovalModalProductFlipped: false,
      approvalMetaData: {
        canCompleteApproval: true,
      },
    });
  };

  handleApproveAllItems = () => {
    let approvalText = this.props.unapprovedItems.size > 1 ? `all the remaining ${this.props.unapprovedItems.size} items without checking each one?` : `this?`

    confirm({
      title: 'Approve all?',
      content: `Are you sure you want to approve ${approvalText} We do not check orders before printing and shipping.`,
      okText: 'Yes — Approve All',
      cancelText: 'No',
      onOk: () => {
        this.props.onApproveAllItems();
        message.success('All items approved');
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });

  };

  handleEditItem = item => {
    this.closeApprovalModal();
    // TODO, might need a different route to return us to the approval modal after saving?
    this.props.onClickPreview(item);
  };

  handleDeleteItem = item => {
    // this.setState({
    //   modalVisible: true,
    //   modal: {
    //     title: 'Delete?',
    //     content: 'Are you sure you want to delete this item from your order?',
    //     okText: 'Yes',
    //     cancelText: 'No',
    //     onOk: () => {
    //       console.log('Deleting item');
    //       this.props.onDeleteItem(item.get("id"));
    //       this.setState({
    //         modalVisible: false,
    //         modal: null,
    //       });
    //     },
    //     onCancel: () => {
    //       console.log('Cancel');
    //       this.setState({
    //         modalVisible: false,
    //         modal: null,
    //       });
    //     },
    //   },
    // });
    confirm({
      title: 'Delete?',
      content: 'Are you sure you want to delete this item from your order?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.props.onDeleteItem(item.get("id"));
        message.success('Item deleted');
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
    // this.setState({
    //   alert: {
    //     type: "warning",
    //     title: "Delete?",
    //     text: "Are you sure you wish to remove this item completely?",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "No",
    //     onConfirm: () => this.props.onDeleteItem(item.get("id")),
    //     onCancel: this.clearAlert,
    //     animation: false,
    //     customClass: "animated fadeIn",
    //   },
    // });
  };

  showPaymentModalPage = pageId => {
    this.setState({
      paymentModalPage: pageId,
    });
  };

  handleDuplicationAlertGoToBasket = () => {
    if(this.props.showDuplicateAlertForItem){
      this.props.onDismissDuplicateAlertItem(this.props.showDuplicateAlertForItem.get("id"));
    }
    this.setState({
      isItemDuplicationAlertVisible: false,
    });
  };

  handleDuplicationAlertDuplicate = () => {
    this.props.onClickDuplicate(this.props.showDuplicateAlertForItem);
    this.props.onDismissDuplicateAlertItem(this.props.showDuplicateAlertForItem.get("id"));

    this.setState({
      isItemDuplicationAlertVisible: false,
    });
  };

  handleCrossSellOk = () => {
    console.log("Cross sell OK");
    this.setState({
      isCrossSellModalVisible: false
    });
    this.props.onSetSeenCrossSellModal(true);
    this.props.goToRoute(routeCreators.magazineEditor());
  }

  handleCrossSellCancel = () => {
    console.log("Cross sell cancel");
    this.setState({
      isCrossSellModalVisible: false
    });
    this.props.onSetSeenCrossSellModal(true);
  }

  handleChangeMagazineApprovalPage = pageObject => {
    this.setState({
      approvalMetaData: {
        ...this.state.approvalMetaData,
        canCompleteApproval:
          this.state.canCompleteApproval === true ? true : pageObject.page === 23,
        pageObject: pageObject,
      },
    });
  };

  handleDeleteAll = () => {
    this.props.onDeleteAllItems();
    this.clearAlert();
  };

  handleShowDeleteAllAlert = () => {
    this.setState({
      alert: {
        type: "info",
        title: "Clear your bag?",
        text: "Are you sure you wish to remove all your items from your bag?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        onConfirm: this.handleDeleteAll,
        onCancel: this.clearAlert,
        animation: false,
        customClass: "animated fadeIn",
      },
    });
  };

  goToShop = () => {
    // TODO: extract this to ENV var
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  render() {
    if (this.props.orderSummary.get("items").size === 0) {
      return (
        <React.Fragment>
          <Header
            key="top-header"
            theme="grey"
            image="/images/logo.svg"
            title="Postsnap Postcard App"
            rightAction={<BasketButtonContainer />}
            leftAction={<AccountButtonContainer />}
            onClickTitleOrImage={this.goToShop}
          />
          <MainContent scrollable padded centeredVertically>
            <Icon name="cart" size="full-width" style={{ opacity: 0.2 }} />
            <p className="text-center muted">You have no items in your basket.</p>
            <Button block label="Start Shopping" theme="dark-blue" onClick={this.goToShop} />
          </MainContent>
        </React.Fragment>
      );
    }

    const areAnyItemsRendering = this.props.orderSummary
      .get("items")
      .some(i => i.get("isRendering"));

    const areAnyItemsRenderFailed = this.props.orderSummary
      .get("items")
      .some(i => i.get("renderFailed"));


    //debugger;

    const rendersInProgress = this.props.orderSummary.get("items").filter(i => i.get("isRendering")).count();
    //console.log("Renders is progress", rendersInProgress)
    //const rendersCompleted  = this.props.orderSummary.get("items").filter(i => i.get("render")).count();
    //console.log("rendersCompleted", rendersCompleted);
    const renderProgress =  1 - (rendersInProgress / this.props.orderSummary.get("items").count());
    const progressPercent = Math.ceil(renderProgress * 100);
    //console.log("Render progress", renderProgress);

    const anyUnapprovedItem = this.props.unapprovedItems.size > 0;

    let renderingLabel = "";

    if (areAnyItemsRendering) {
      const itemsRendering = this.props.orderSummary.get("items").filter(i => i.get("isRendering"));
      const renderingProducts = itemsRendering.map(item => {
        return item.get("description");
      });
      const uniqueRenderingProducts = [...new Set(renderingProducts)];
      const multipleProducts = uniqueRenderingProducts.length > 1;
      const isCanvasRendering = itemsRendering.some(
        rendering => rendering.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS
      );
      const preparingSingleLabel = isCanvasRendering
        ? "Preparing your canvas"
        : `Preparing your ${Pluralize(uniqueRenderingProducts[0], renderingProducts.size)}...`;
      renderingLabel = multipleProducts ? "Preparing your cards" : preparingSingleLabel;
    }

    const isInWrongRegionForPrepay =
      this.props.isSignedIn && this.props.prepayBalance.currency !== this.props.currency;
    const hasEnoughPrepayBalanceToPayForOrder =
      this.props.isSignedIn &&
      this.props.prepayBalance.amount >= this.props.orderSummary.get("total");

    let paymentModalProps = {};

    switch (this.state.paymentModalPage) {
      case PAYMENT_MODAL_PAGES.OVERVIEW:
        paymentModalProps = {
          title: "Payment Method",
        };
        break;
      case PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM:
        paymentModalProps = {
          title: "Card Payment",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.showPaymentModalPage(PAYMENT_MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      case PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT:
        paymentModalProps = {
          title: "Prepay Payment",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.showPaymentModalPage(PAYMENT_MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      // no default
    }

    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    const isFreeOrder = this.props.orderSummary.get("total") === 0;

    const paymentModal = (
      <Modal
        key="payment-modal"
        padded
        isOpen={this.state.isPaymentModalVisible}
        onClose={this.closePaymentModal}
        paged
        activePage={this.state.paymentModalPage}
        {...paymentModalProps}
      >
        <ModalPage pageId={PAYMENT_MODAL_PAGES.OVERVIEW} depth={1}>
          <MainContent scrollable={false} padded={false} centeredVertically>
            <div className="text-center muted">
              <p>
                Order total: <Currency amount={this.props.orderSummary.get("total")} />
              </p>

              {(this.props.isSignedIn && !isInWrongRegionForPrepay && !isFreeOrder) && (
                <p>
                  Your prepay balance: <Currency amount={this.props.prepayBalance.amount} />
                </p>
              )}

              <br />
            </div>
            <div style={{ width: "100%", maxWidth: 500, margin: "0 auto" }}>
              {isFreeOrder ? (
                <React.Fragment>
                  <p style={{textAlign:'center'}}>Your order is free, tap below to complete this order:</p>
                  <Button
                    block
                    label="Complete Order"
                    loadingLabel="Making Payment..."
                    loading={this.props.isPayingForOrder}
                    onClick={this.props.onPayForOrderWithPrepayBalance}
                  />
                </React.Fragment>
              )
              : (
                <React.Fragment>
                  <Button
                    block
                    label="Pay with Credit/Debit Card"
                    onClick={() => this.selectPaymentMethod(PAYMENT_METHODS.CREDIT_DEBIT_CARD)}
                  />
                  {hasEnoughPrepayBalanceToPayForOrder && (
                    <Button
                      block
                      label="Pay with Prepay Credit"
                      priority="secondary"
                      onClick={() => this.selectPaymentMethod(PAYMENT_METHODS.PREPAY)}
                      disabled={isInWrongRegionForPrepay}
                    />
                  )}
                  {!hasEnoughPrepayBalanceToPayForOrder && (
                    <Button block label="Buy More Prepay" link={routeCreators.prepay()} />
                  )}
                  {isInWrongRegionForPrepay && (
                    <p className="help-text text-center" style={{ marginTop: -16 }}>
                      You can only use prepay credit in the region
                      <br />
                      your account was created in ({this.props.prepayBalance.currency})
                    </p>
                  )}
                  <PayPalButton
                    amount={this.props.orderSummary.get("total")}
                    currency={this.props.currency}
                    onAuthorize={this.props.onPayPalAuthorization}
                    disabled={this.props.isPayingForOrder}
                  />
                </React.Fragment>
              )}
            </div>
          </MainContent>
        </ModalPage>
        <ModalPage pageId={PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM} depth={2}>
          <StripePaymentForm
            amount={this.props.orderSummary.get("total")}
            onSubmit={this.props.onStripePaymentFormSubmit}
            onPayWithSavedCard={this.props.onPayWithSavedCard}
            isPaying={this.props.isPayingForOrder}
            isPayingWithSavedCard={this.props.isPayingForOrderWithSavedCard}
            disabled={this.props.isPayingForOrderWithSavedCard}
            currency={this.props.currency}
          />
        </ModalPage>
        <ModalPage pageId={PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT} depth={2}>
          {this.props.isSignedIn && (
            <p>
              Credit remaining: <Currency amount={this.props.prepayBalance.amount} />
            </p>
          )}
          <p>
            Order total: <Currency amount={this.props.orderSummary.get("total")} />
          </p>
          <Button
            label="Pay with Prepay Credit"
            loadingLabel="Making Payment..."
            loading={this.props.isPayingForOrder}
            disabled={!hasEnoughPrepayBalanceToPayForOrder}
            onClick={this.props.onPayForOrderWithPrepayBalance}
          />
        </ModalPage>
      </Modal>
    );

    let approvalModalContent = null;

    let allowApproveAll = false;

    if (this.props.unapprovedItems.size > 0) {
      const itemToApprove = this.props.unapprovedItems.first();

      const isCanvas = itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
      const productSlug = isCanvas && productsByProductId.get(itemToApprove.get("productId")).get("url_slug");
      if(itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT){
        allowApproveAll = true;
      }


      switch (itemToApprove.get("productTypeId")) {
        case PRODUCT_TYPE_IDS.CANVAS:
          approvalModalContent = (
            <React.Fragment>
              <ThreeDimensionalViewer
                model={MODELS[productSlug] || MODELS.canvas}
                image={itemToApprove.get("preview_s3_key")}
              />
              <p className="help-text text-center" style={{ zIndex: 1 }}>
                This preview is for illustrative purposes only. The actual product may differ
                slightly from what is shown here.
              </p>
              <p className="help-text text-center" style={{ zIndex: 1 }}>
                Please tap below to confirm that you have triple-checked your product. All sales are
                final. Please note that we do not check orders before printing and shipping.
              </p>
            </React.Fragment>
          );
          break;
        case PRODUCT_TYPE_IDS.PHOTO_PRINT:
          approvalModalContent = (
            <React.Fragment>
              <div className="photo-print-previews-container" style={{height: '100%'}}>
                <PhotoPrintPreview item={itemToApprove} />
              </div>
              <br />
              <p className="help-text text-center">
                Please tap below to confirm that you have triple-checked your product. All sales are
                final. Please note that we do not check orders before printing and shipping.
              </p>
            </React.Fragment>
          );
          break;
        case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
          approvalModalContent = (
            <React.Fragment>
              <PhotoMagazinePreview
                item={itemToApprove}
                onChangePage={this.handleChangeMagazineApprovalPage}
              />
              <br />
              <p className="help-text text-center">
                Please swipe through the preview information until you reach the end of the
                magazine, after which you'll be able to approve your magazine. All sales are final.
                Please note that we do not check orders before printing and shipping.
              </p>
            </React.Fragment>
          );
          break;
        default:
          break;
      }
    }

    const approvalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isApprovalModalVisible}
        onClose={this.closeApprovalModal}
        title={this.props.unapprovedItems.size === 1 ? `Approve your item` : "Approve your items"}
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={this.closeApprovalModal}
          />
        }
      >
        {this.props.unapprovedItems.size > 0 && [
          <MainContent scrollable={false} centeredVertically padded key="main-preview">
            {this.props.unapprovedItems.size > 1 && (
              <p className="text-center">
                <span className="text-primary">{this.props.unapprovedItems.size}</span> items left
                to approve
              </p>
            )}
            {approvalModalContent}
          </MainContent>,
          <Footer padded key="footer">
            <Row type="flex" justify="space-around">
              <Col span={allowApproveAll ? 7 : 11}>
                <AntButton
                  type="default"
                  block
                  size="large"
                  onClick={() => this.handleDeleteItem(this.props.unapprovedItems.first())}
                >
                  Delete
                </AntButton>
              </Col>
              <Col span={allowApproveAll ? 7 : 11}>
                <AntButton
                  type="primary"
                  block
                  style={{backgroundColor: '#49bda1', borderColor: '#49bda1'}}
                  size="large"
                  onClick={() => this.handleEditItem(this.props.unapprovedItems.first())}
                >
                  Edit
                </AntButton>
              </Col>
              {allowApproveAll && (
                <Col span={9}>
                  <AntButton
                    type="primary"
                    block
                    size="large"
                    style={{backgroundColor: '#043253', borderColor: '#043253'}}
                    disabled={!this.state.approvalMetaData.canCompleteApproval}
                    onClick={() => this.handleApproveAllItems()}
                  >
                    Approve All
                  </AntButton>
                </Col>
              )}
            </Row>
            <Row type="flex" justify="space-around" style={{marginTop:'5px'}}>
              <Col span={allowApproveAll ? 24 : 23}>
                <AntButton
                  type="primary"
                  block
                  size="large"
                  disabled={!this.state.approvalMetaData.canCompleteApproval}
                  onClick={() => this.handleApproveItem(this.props.unapprovedItems.first().get("id"))}
                >
                  Approve
                </AntButton>
              </Col>
            </Row>
            
          </Footer>,
        ]}
      </Modal>
    );

    const promotionCodeText = (
      <table>
        <tbody>
          <tr>
            <td>
              <p onClick={this.handleShowPromoCode} className="promotion-code-text">
                Have a promotional code?
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const promotionCodeInput = (
      <form
        className="form promotion-code-input animated fadeIn"
        onSubmit={this.handleSubmitPromoCodeForm}
      >
        <div className="form-group">
          <p onClick={this.handleHidePromoCode} className="promotion-code-dismiss-text">
            No promo code
          </p>
          <label htmlFor="promocode" className="control-label">
            Promotion code
          </label>
          <div className="promotion-code-input__input-wrapper">
            <input
              type="text"
              value={this.state.promoCode}
              className="form-control"
              id="promocode"
              ref={input => (this.promoCodeInput = input)}
              onChange={this.handleChangePromotionCodeInput}
              placeholder="Enter your code..."
            />
            <Button
              label="Apply"
              priority={this.state.isLookingUpPromoCode ? "primary" : "secondary"}
              type="submit"
              theme="success"
              disabled={!this.state.promoCode}
              loadingLabel="Looking up code..."
              loading={this.state.isLookingUpPromoCode}
            />
          </div>
        </div>
      </form>
    );

    const promotionCodeInfo = this.props.orderSummary.getIn(["promotionInfo", "data"]) && (
      <table>
        <tbody>
          <tr>
            <td>
              <Button icon="clear" priority="tertiary" onClick={this.handleClearPromoCode} />
            </td>
            <td>
              {this.props.orderSummary.getIn(["promotionInfo", "data", "description"])}
              &nbsp; (
              <span>{this.props.orderSummary.getIn(["promotionInfo", "data", "code"])}</span>)
            </td>
            <td className="text-right">
              <Currency
                amount={-this.props.orderSummary.getIn(["promotionInfo", "totalDiscountForOrder"])}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
    // eslint-disable-next-line
    const debugPricingTable = (
      <table>
        <tbody>
          <tr>
            <td className="text-right">
              <code>subTotal</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("subTotal")} />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>totalShippingCost</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("totalShippingCost")} />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>discount</code>
            </td>
            <td>
              <code>
                <Currency
                  amount={this.props.orderSummary.getIn(["promotionInfo", "totalDiscountForOrder"])}
                />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>total</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("total")} />
              </code>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const allPhotoPrintItemIds = this.props.orderSummary
      .get("items")
      .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
      .map(item => item.get("id"));

    const photoPrintItemWithRealPostageScheme = this.props.orderSummary
      .get("items")
      .find(item => item.getIn(["postageScheme", "cost"]));

    const photoPrintItemAddress = photoPrintItemWithRealPostageScheme
      ? photoPrintItemWithRealPostageScheme.get("address") ||
        photoPrintItemWithRealPostageScheme.get("addressBookEntry")
      : null;

    const payButtonLabel = areAnyItemsRenderFailed ? "Try again" : this.props.canMakeStripePayment ? "Other Payment Options" : "Pay Now"

    const payButton = (
      <Button
        block
        disabled={areAnyItemsRendering}
        loadingProgress={renderProgress}
        label={payButtonLabel}
        loadingLabel={areAnyItemsRendering ? renderingLabel : "Creating Order..."}
        loading={areAnyItemsRendering || this.props.isCreatingOrder}
        onClick={
          areAnyItemsRenderFailed ? this.retryRenders : (this.props.isSignedIn ? this.createOrderAndShowPaymentOptions : this.props.onShowAuthModal)
        }
      />
    );

    const keepShoppingButton = (
      <Button block label="Keep Shopping" theme="dark-blue" onClick={this.goToShop} />
    );

    const printsShippingPanelStyle = {
      background: 'rgb(255, 255, 255)',
      borderRadius: 4,
      marginBottom: 24,
      border: '1px solid #ebebeb',
      overflow: 'hidden',
      boxShadow: '3px 3px 6px 2px #cccccc21',
    };

    
    const printsShippingCost = photoPrintItemWithRealPostageScheme && (
      <div>
        <AntIcon type="info-circle" style={{marginRight: '5px'}}/>
        <span>Prints Shipping — Standard: </span>
        <Currency
          amount={photoPrintItemWithRealPostageScheme.getIn([
            "postageScheme",
            "cost",
          ])}
        />
      </div>
    );    
    
    const anyItemsForCrossSell = this.props.orderSummary.get("items").filter(item => {
      return item.get("isRendering") === false && item.get("preview_s3_key") != null && item.get("productTypeId") !== PRODUCT_TYPE_IDS.GREETING_CARD
    });
    const noMagazines = this.props.orderSummary.get("items").every(item => item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_MAGAZINE );
    const itemForCrossSell = anyItemsForCrossSell.first();
    const shouldShouldShowCrossSellModal = false; //((this.state.isCrossSellModalVisible && !this.state.isItemDuplicationAlertVisible && this.props.hasSeenCrossSellModal === false && noMagazines) && itemForCrossSell);

    return [
      <AntModal
        key="basket-modal"
        visible={Boolean(this.state.modalVisible)}
        onOk={this.state.modal && this.state.modal.onOk}
        onCancel={this.state.modal && this.state.modal.onCancel}
        {...(this.state.modal || {})}
      >
        {this.state.modal && this.state.modal.content && (
          <p>{this.state.modal.content}</p>
        )}
      </AntModal>,
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="basket-alert"
      />,
      <AntModal
        visible={shouldShouldShowCrossSellModal} //&& this.props.hasSeenCrossSellModal === false
        title="Make this into a magazine?"
        onOk={this.handleCrossSellOk}
        onCancel={this.handleCrossSellCancel}
        centered
        footer={[
          <AntButton key="back" onClick={this.handleCrossSellCancel}>
            No thanks
          </AntButton>,
          <AntButton key="submit" type="primary" onClick={this.handleCrossSellOk}>
            Make Now
          </AntButton>,
        ]}
      >
        <div className="upsell--magazine-container">
          <p className="upsell--intro-text">This would look great in our of our glossy photo magazines:</p>
          <div className="upsell--magazine-cover" style={{ backgroundImage: `url(${itemForCrossSell ? generateS3AssetUrlFromKey(itemForCrossSell.get("preview_s3_key")) : ''})` }}>
            <div className="upsell--magazine-band">
              <p>Family Memories<br/>2019</p>
            </div>
            {/* <img src={`${process.env.PUBLIC_URL}/images/pagecurl.png`}/> */}
            <div className="pagecurl-overlay" style={{backgroundImage: `${process.env.PUBLIC_URL}/images/pagecurl.png`}}></div>
            {/* <div className="pagecurl"></div> */}
          </div>
          <p className="upsell--footer-text">
            Start making today and get 10% off with code:<br/><br/><span className="upsell--promo-code">PHOTOMAG10</span>
          </p>
        </div>
      </AntModal>,
      <AntModal
        key="duplication-alert-modal"
        visible={this.state.isItemDuplicationAlertVisible}
        onOk={this.handleDuplicationAlertDuplicate}
        onCancel={this.handleDuplicationAlertGoToBasket}
        cancelText="No"
        okText="Yes"
        title="Send to someone else?"
      >
        <p>{`Would you like to edit & send ${this.state.productNameForDuplicationItem} to someone else?`}</p>
      </AntModal>,
      // <SweetAlert
      //   key="duplication-alert"
      //   isOpen={this.state.isItemDuplicationAlertVisible}
      //   title="Send to someone else?"
      //   text={`Would you like to edit & send ${this.state.productNameForDuplicationItem} to someone else?`}
      //   confirmButtonText="No"
      //   cancelButtonText="Yes"
      //   onConfirm={this.handleDuplicationAlertGoToBasket}
      //   onCancel={this.handleDuplicationAlertDuplicate}
      //   animation={false}
      //   customClass="animated fadeIn"
      // />,
      approvalModal,
      paymentModal,
      <Header
        key="top-header"
        theme="grey"
        image="/images/logo.svg"
        title="Postsnap Postcard App"
        rightAction={<BasketButtonContainer />}
        leftAction={<AccountButtonContainer />}
        onClickTitleOrImage={this.goToShop}
      />,
      <Header
        key="header"
        leftAction={
          this.props.orderSummary.get("items").size > 0 && (
            <Button
              label={this.state.isDeletionModeActive ? "Done" : "Delete"}
              priority="tertiary"
              theme="muted"
              onClick={this.toggleDeletionMode}
            />
          )
        }
        rightAction={
          [this.state.isDeletionModeActive && (
            <Button
              label="Delete All"
              priority="secondary"
              onClick={this.handleShowDeleteAllAlert}
            />
          ),
          areAnyItemsRendering && (
            <div style={{paddingRight:'10px'}}>
              <Progress
                strokeColor={{
                  from: '#63C0AC',
                  to: '#64C2AB',
                }}
                type="circle"
                percent={progressPercent}
                width={40}
              />
            </div>
          )
          ]
        }
      />,
      <MainContent key="content" padded>
        
        <table>
          <TransitionGroup component="tbody">
            {this.props.orderSummary.get("items").map(item => (
              <CSSTransition
                mountOnEnter={true}
                unmountOnExit={
                  this.state.isDeletionModeActive && this.props.orderSummary.get("items").size > 1
                }
                enter={true}
                appear={true}
                exit={
                  this.state.isDeletionModeActive && this.props.orderSummary.get("items").size > 1
                }
                key={`cst-${item.get("id")}`}
                classNames={{
                  exit: "animated fadeOutRight",
                  enter: "animated fadeInLeft",
                  appear: "animated fadeIn",
                }}
                timeout={500}
              >
                <React.Fragment>
                  {item.get("id") === allPhotoPrintItemIds.first() && (
                    <React.Fragment>
                      <tr>
                        <td colSpan={this.state.isDeletionModeActive ? 3 : 2} style={{ paddingBottom: "7px" }}>
                          <Collapse
                          bordered={false}
                          onChange={(e) => this.scrollToPrintsPanel(e)}
                          expandIconPosition={'right'}
                          expandIcon={({ isActive }) => <AntIcon type="caret-down" rotate={isActive ? 180 : 0} />}
                          >
                          <Panel header={printsShippingCost} key="prints-shipping" style={printsShippingPanelStyle}>
                            <p style={{fontSize: '12px'}}>{`We will ship all your prints to the following address:`}</p>
                            {photoPrintItemAddress && (
                              <div ref={this.printsPanelRef}>
                                <p
                                style={{ fontSize: 11, whiteSpace: "pre-line" }}
                                onClick={() => this.props.onClickItemAddress(item)}
                              >
                                {generateMultilineAddress(photoPrintItemAddress.toJS()).join("\n")}
                              </p>
                              <AntButton
                                type="default"
                                size="small"
                                style={{backgroundColor: '#49bda1', borderColor: '#49bda1', color: 'white', fontSize: '13px'}}
                                onClick={() => this.props.onClickItemAddress(item)}
                              >
                                <AntIcon type="edit" /> Change Address
                              </AntButton>
                              </div>
                            )}
                          </Panel>
                        </Collapse>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                  <BasketItem
                    key={item.get("id")}
                    item={item}
                    showAddress={item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT}
                    showShipping={item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT}
                    onClickPreview={() => this.props.onClickPreview(item)}
                    onClickDeleteButton={() => this.handleDeleteItem(item)}
                    onClickAddress={() => this.props.onClickItemAddress(item)}
                    onClickIncreaseQty={(itemId) => this.props.onIncreaseQuantityForItem(itemId)}
                    onClickDecreaseQty={(itemId) => this.props.onDecreaseQuantityForItem(itemId)}
                    canDelete={this.state.isDeletionModeActive}
                  />
                </React.Fragment>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </table>
      </MainContent>,
      <FullScreenLoader
        key="loader"
        message="Checking out..."
        isVisible={this.props.isCreatingGuestOrder}
      />,
      <Footer padded key="promo-footer" topShadow style={{zIndex: 1}}>
        {this.state.isAddingPromoCode
          ? promotionCodeInfo || promotionCodeInput
          : promotionCodeInfo || promotionCodeText}
      </Footer>,
      <Footer padded key="total">
        <div className="text-center">
          <p style={{ fontWeight: "bold" }}>
            TOTAL: <Currency amount={this.props.orderSummary.get("total")} />
          </p>
        </div>
      </Footer>,
      <Footer padded key="footer">
        {this.props.unapprovedItems.size > 0 && (
          <React.Fragment>
            {keepShoppingButton}
            <Button
              block
              label={areAnyItemsRenderFailed ? "Try again" : "Checkout"}
              loadingLabel={renderingLabel}
              loading={areAnyItemsRendering}
              loadingProgress={renderProgress}
              disabled={areAnyItemsRendering}
              onClick={areAnyItemsRenderFailed ? this.retryRenders : this.openApprovalModal}
            />
          </React.Fragment>
        )}
        {this.props.unapprovedItems.size === 0 && (
          <React.Fragment>
            {this.props.canMakeStripePayment && !areAnyItemsRendering && (
              <StripePaymentRequestButton
                onClick={this.handleClickStripePaymentRequestButton}
                paymentRequest={this.props.paymentRequest}
                disabled={areAnyItemsRendering}
                preventPropagation={anyUnapprovedItem}
              />
            )}
            {this.props.canMakeStripePayment && (
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingRight: "0.25rem" }}>{keepShoppingButton}</div>
                </Grid.Column>
                <Grid.Column>
                  <div style={{ paddingLeft: "0.25rem" }}>{payButton}</div>
                </Grid.Column>
              </Grid.Row>
            )}
            {!this.props.canMakeStripePayment && (
              <React.Fragment>
                {keepShoppingButton}
                {payButton}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Footer>,
    ];
  }
}

export default Basket;
