import React, { Component } from "react";
import { fromJS, List } from "immutable";

import throttle from "lodash/throttle";
import * as LAYER_TYPES from "../../constants/layer-types";
import { pickImages } from "../../lib/file-uploader";
import { productsByProductId } from "../../data/products";

import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import Button from "../Button/Button";
import EditorCropperModal from "../Editor/EditorCropperModal";
import PhotoMagazineEditor from "./PhotoMagazineEditor";
import SweetAlert from "../SweetAlert/SweetAlert";
import EditorAddressInputModal from "../Editor/EditorAddressInputModal";

const DEBUG_UPLOADS = [
  {
    uuid: "171d3c8e-e89b-424b-9e7a-26c9baad5f23",
    name: "Screen Shot 2019-09-11 at 11.44.16.png",
    size: 1790676,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23/",
    cdnUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23//-/autorotate/yes//-/resize/400x/",
  },
  {
    uuid: "c55e5bd2-8647-45df-96bf-0624a3bbbe90",
    name: "Screen Shot 2019-09-11 at 11.43.49.png",
    size: 1788153,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90/",
    cdnUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90//-/autorotate/yes//-/resize/400x/",
  },
  {
    uuid: "59f08462-19f0-4717-bc43-80d17c599093",
    name: "Screen Shot 2019-09-11 at 11.39.33.png",
    size: 1850478,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093/",
    cdnUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093//-/autorotate/yes//-/resize/400x/",
  },
];

function getImagesMappedToItem(item, images) {
  return item.update("layers", layers => {
    return layers.map(layer => {
      const imageForPage = images.get(layer.get("page"));

      if (layer.get("type") === LAYER_TYPES.PHOTO) {
        return layer.setIn(["config", "layout", 0, "image"], imageForPage);
      }

      return layer;
    });
  });
}

class PhotoMagazineEditorContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      item: props.item,
      images: props.item
        .get("layers")
        .filter(layer => layer.get("type") === LAYER_TYPES.PHOTO)
        .map(layer => layer.getIn(["config", "layout", 0, "image"]))
        .filter(image => image),
      alert: null,
      pageNumberToCrop: null,
      isUploadModalVisible: false,
      isReplacingImage: false,
      replacingImageIndex: null,
      isAddressInputModalVisible: false,
      isCoverTextInputModalVisible: true,
      coverTextModalHasBeenAutoShown: false,
      isCancelConfirmationAlertVisible: false,
    };
  }

  componentDidMount() {
    if (this.state.images.size === 0) {
      this.showUploadModal();
    }
    //this.debugUpload().then(() => {
    //  //this.startCropping(0);
    //});
  }

  emitUpdate = () => {
    this.props.onUpdate &&
      this.props.onUpdate({
        item: this.state.item,
        images: this.state.images,
      });
  };

  pickImageFromUploadcare = async (source, amount) => {
    try {
      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };
  
      const images = await pickImages({
        amount: amount,
        source,
        onTotalProgressUpdate: throttle(progressHandler, 500),
      });
      //const generatedItems = await this.generateItem(images);
  
      if (amount === 1) {
        if (this.state.replacingImageIndex !== null){
          let image = images;
          let newImage = {
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            cropDate: null
          }
          this.setState(
            {
              images: this.state.images.setIn([this.state.replacingImageIndex], fromJS(newImage)),
            },
            this.emitUpdate
          );
        } else {
          this.addImages([images]); // TODO: Fix this hack
        }
      } else {
        this.addImages(images);
      }
  
      // this.setState({
      //   items: [...this.state.items, ...generatedItems],
      // });
      this.closeUploadModal();
    } catch (err) {
      console.log('Cancelling upload:', err);
    }
    
  };

  addImages = images => {
    this.setState(
      {
        images: this.state.images
          .concat(
            new List(
              fromJS(
                images.map(image => ({
                  src: {
                    lowResUrl: image.lowResUrl,
                    highResUrl: image.highResUrl,
                    uploadcareUuid: image.uuid,
                  },
                  cropData: null,
                }))
              )
            )
          )
          .slice(0, 23),
      },
      this.emitUpdate
    );
  };

  debugUpload = async () => {
    this.addImages([
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
      ...DEBUG_UPLOADS,
    ]);
    this.closeUploadModal();
  };

  showUploadModal = () => {
    this.setState({ isUploadModalVisible: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalVisible: false });
  };

  startCropping = pageNumber => {
    this.setState({
      pageNumberToCrop: pageNumber,
    });
  };

  closeCropper = () => {
    this.setState({
      pageNumberToCrop: null,
    });
  };

  handleSaveCrop = crop => {
    this.setState(
      {
        images: this.state.images.setIn([this.state.pageNumberToCrop, "cropData"], fromJS(crop)),
      },
      this.emitUpdate
    );
    this.closeCropper();
  };

  handleSwapImages = (sourcePageNumber, destinationPageNumber) => {
    const sourceImage = this.state.images.get(sourcePageNumber);
    const destinationImage = this.state.images.get(destinationPageNumber);
    this.setState(
      {
        images: this.state.images.withMutations(images => {
          images.set(sourcePageNumber, destinationImage);
          images.set(destinationPageNumber, sourceImage);
        }),
      },
      this.emitUpdate
    );
  };

  handleDeleteImage = pageNumber => {
    this.deleteImage(pageNumber);
    // this.setState({
    //   alert: {
    //     type: "warning",
    //     text: "Are you sure you want to delete this page?",
    //     confirmButtonText: "Delete",
    //     onConfirm: () => {
    //       this.setState({ alert: null });
    //       this.deleteImage(pageNumber);
    //     },
    //     cancelButtonText: "Cancel",
    //     onCancel: () => {
    //       this.setState({ alert: null });
    //     },
    //   },
    // });
  };

  deleteImage = pageNumber => {
    this.setState(
      {
        images: this.state.images.delete(pageNumber),
      },
      this.emitUpdate
    );
  };

  handleCoverTextModalAutoShown = () => {
    this.setState({
      coverTextModalHasBeenAutoShown: true,
    });
  };

  handleChangeCoverText = coverText => {
    this.setState({
      item: this.state.item.set("product_options", coverText),
    });
  };

  showAddressInputModal = () => {
    this.setState({ isAddressInputModalVisible: true });
  };

  closeAddressInputModal = () => {
    this.setState({ isAddressInputModalVisible: false });
  };

  handleSaveAddress = address => {
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          item.set("addressBookId", null);
          item.set("address", fromJS(address));
        }),
      },
      this.saveItem
    );
  };

  handleOwnAddressSave = address => {
    return this.props.onChangeOwnAddress(address);
  };

  handleSelectAddressBookEntry = addressBookEntryId => {
    //console.log("Saving address book entry ID:", addressBookEntryId);
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          item.set("addressBookId", addressBookEntryId);
          item.set("address", null);
        }),
      },
      this.saveItem
    );
  };

  handleClickSave = () => {
    this.showAddressInputModal();
  };

  handleReplaceImage = (index) => {
    this.setState({
      pageNumberToCrop: null,
      isUploadModalVisible: true,
      isReplacingImage: true,
      replacingImageIndex: index
    });
  }

  saveItem = () => {
    this.props.onSave(getImagesMappedToItem(this.state.item, this.state.images));
  };

  render() {
    const product = productsByProductId.get(this.state.item.get("productId"));
    const layersWithPhotos = this.state.images;

    const totalPagesRequired = product.get("pages");
    const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
    const pagesRemaing = this.state.isReplacingImage ? 1 : totalPagesRequired - pagesAdded;

    const alert = <SweetAlert isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />;

    const addressInputModal = (
      <EditorAddressInputModal
        isDoubleDirect={false}
        isOpen={this.state.isAddressInputModalVisible}
        mode={"new"}
        initialFormData={
          this.state.item && this.state.item.get("address")
            ? this.state.item.get("address").toJS()
            : null
        }
        onCancel={this.closeAddressInputModal}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
        onSaveOwnAddress={this.handleOwnAddressSave}
      />
    );
    
    const recentlyUploaded = JSON.parse(localStorage.getItem('recentlyUploadedFiles'));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;

    const uploadModal = this.state.item && (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title={`Upload ${pagesRemaing} Photos`}
      >
        <MainContent scrollable={false} padded centeredVertically>
          {process.env.NODE_ENV === "development" && (
            <Button
              theme="dark-blue"
              block
              label="DEBUG UPLOAD"
              icon="phone"
              onClick={this.debugUpload}
            />
          )}
          <Button
            block
            label="From Device"
            icon="phone"
            onClick={() => this.pickImageFromUploadcare("file", pagesRemaing)}
          />
          {anyRecentlyUploaded && (
            <Button
              block
              className="btn--recent"
              label="Recently Uploaded"
              icon="upload"
              onClick={() => this.pickImageFromUploadcare("favorites", pagesRemaing)}
            />
          )}
          <Button
            block
            icon="facebook"
            className="btn--facebook"
            label="Facebook"
            onClick={() => this.pickImageFromUploadcare("facebook", pagesRemaing)}
          />
          <Button
            block
            icon="instagram"
            className="btn--instagram"
            label="Instagram"
            onClick={() => this.pickImageFromUploadcare("instagram", pagesRemaing)}
          />
          <Button
            block
            icon="gdrive"
            className="btn--google"
            label="Google Drive"
            onClick={() => this.pickImageFromUploadcare("gdrive", pagesRemaing)}
          />
          <Button
            block
            icon="dropbox"
            className="btn--dropbox"
            label="Dropbox"
            onClick={() => this.pickImageFromUploadcare("dropbox", pagesRemaing)}
          />
          <Button
            block
            icon="flickr"
            className="btn--flickr"
            label="Flickr"
            onClick={() => this.pickImageFromUploadcare("flickr",pagesRemaing)}
          />
        </MainContent>
      </Modal>
    );

    const imageForCropper = this.state.images.get(this.state.pageNumberToCrop);
    const cropperModalProps = {};

    if (imageForCropper) {
      cropperModalProps.imgUrl = imageForCropper.getIn(["src", "lowResUrl"]);
      cropperModalProps.cropData = imageForCropper.get("cropData")
        ? imageForCropper.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        this.state.item.getIn(["productDimensions", "width"]) /
        this.state.item.getIn(["productDimensions", "height"]);
      if (this.state.pageNumberToCrop === 0) {
        cropperModalProps.renderPhotoMagazineCoverBand = true;
        cropperModalProps.photoMagazineCoverText = {
          firstLine: this.state.item.get("product_options").split("\n")[0],
          secondLine: this.state.item.get("product_options").split("\n")[1],
        };
      }
    }

    const cropperModal = (
      <EditorCropperModal
        isOpen={this.state.pageNumberToCrop !== null}
        {...cropperModalProps}
        onClose={this.closeCropper}
        onSave={this.handleSaveCrop}
        onHandleReplaceImage={() => this.handleReplaceImage(this.state.pageNumberToCrop)}
      />
    );

    const itemWithMappedImages = getImagesMappedToItem(this.state.item, this.state.images);

    return (
      <React.Fragment>
        {alert}
        {cropperModal}
        {uploadModal}
        {addressInputModal}
        <PhotoMagazineEditor
          coverTextModalOpen={false}
          onCoverTextModalAutoShown={this.handleCoverTextModalAutoShown}
          product={product}
          item={itemWithMappedImages}
          saveButtonText="Next"
          onStartUpload={this.showUploadModal}
          onClickPage={this.startCropping}
          onSwapImages={this.handleSwapImages}
          onDeleteImage={this.handleDeleteImage}
          onChangeCoverText={this.handleChangeCoverText}
          onClickSave={this.handleClickSave}
          onCancel={this.props.onCancel}
        />
      </React.Fragment>
    );
  }
}

export default PhotoMagazineEditorContainer;
