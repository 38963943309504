import React from "react";
import PropTypes from "prop-types";
import MailchimpSubscribeForm from "react-mailchimp-subscribe";

import Modal from "../Modal/Modal";

import './EmailCapturePopup.scss';

const mailchimpSubscribeFormProps = {
  url: `//postsnap.us8.list-manage.com/subscribe/post?u=${process.env.REACT_APP_SLACK_MAILCHIMP_USER_ID}&id=${process.env.REACT_APP_SLACK_MAILCHIMP_DISCOUNT_SUBSCRIBE_LIST_ID}`,
  messages: {
    inputPlaceholder: "enter email address...",
    btnLabel: "ACTIVATE",
    sending: "Subscribing",
    success: "Thanks, please check your inbox for your discount!",
    error: "We couldn't register that email address.",
  },
  styles: {
    sending: {
      fontSize: 14,
      color: "auto",
    },
    success: {
      fontSize: 14,
      color: "#5784b5",
    },
    error: {
      fontSize: 14,
      color: "#ff5380",
    },
  },
};

const EmailCapturePopup = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="email-capture-popup" padded>
      <h3 className="email-capture-popup__title">Do you want 25% off your first purchase?</h3>
      <MailchimpSubscribeForm {...mailchimpSubscribeFormProps} />
    </Modal>
  );
};

EmailCapturePopup.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

EmailCapturePopup.defaultProps = {};

export default EmailCapturePopup;
