import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BounceLoader, BarLoader } from "react-spinners";

import { PRIMARY_COLOR } from "../../constants/colors";

import './FullScreenLoader.scss';

const FullScreenLoader = ({ isVisible, message, loader }) => {
  const classes = classNames("fullscreen-loader", {
    "fullscreen-loader--is-visible": isVisible,
  });

  return (
    <div className={classes}>
      <div className="fullscreen-loader__spinner">
        {loader === "bar" ? (
          <BarLoader color={PRIMARY_COLOR} />
        ) : (
          <BounceLoader color={PRIMARY_COLOR} />
        )}
      </div>
      {message && <div className="fullscreen-loader__message">{message}</div>}
    </div>
  );
};

FullScreenLoader.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
};

FullScreenLoader.defaultProps = {};

export default FullScreenLoader;
