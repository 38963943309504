import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import isElementInViewport from "../../lib/is-element-in-viewport";

import './HorizontalScroller.scss';

class HorizontalScroller extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    center: PropTypes.bool
  };

  static defaultProps = {};

  componentDidMount() {
    this.scrollToActiveChild();
  }

  componentDidUpdate() {
    this.scrollToActiveChild();
  }

  scrollToActiveChild = () => {
    setTimeout(() => {
      try {
        const $node = ReactDOM.findDOMNode(this);
        const $activeChild = [...$node.children].find(c => c.className.includes("active"));

        if ($activeChild && !isElementInViewport($activeChild)) {
          const styles = window.getComputedStyle($node);
          const paddingLeft = parseInt(styles.paddingLeft, 10);
          $node.scrollLeft = $activeChild.offsetLeft - paddingLeft;
        }
      } catch (err) {}
    }, 0);
  };

  render() {
    const classes = classNames("horizontal-scroller", {
      "horizontal-scroller__center-content": this.props.center,
    });
  

    return <div className={classes}>{this.props.children}</div>;
  }
}

export default HorizontalScroller;
