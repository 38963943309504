import React from "react";
import PropTypes from "prop-types";
import * as CURRENCIES from "../../constants/currencies";

import { Select } from "antd";
const Option = Select.Option;

class CurrencySwitcher extends React.Component {
  componentDidUpdate() {
    // console.log("Setting basket cookie count to", this.props.totalItemQuantity);
    // document.cookie = `basketCount=${
    //   this.props.totalItemQuantity
    // }; domain=${window.location.hostname.replace(/^(app\.)/, "")}; path=/`;
  }

  render() {
    const currency = this.props.currency;
    return (
      <div className="currency-switcher" style={{ marginLeft: "15px" }}>
        <Select
          label="Currency"
          value={currency}
          defaultValue={currency}
          onChange={value => this.props.onChangeCurrency(value)}
        >
          {Object.keys(CURRENCIES).map(currency => (
            <Option value={currency}>
              <img
                src={`${process.env.PUBLIC_URL}/images/currency-flag-${currency.toLowerCase()}.png`}
                style={{ width: "18px", height: "18px", marginRight: "3px", marginBottom: "3px" }}
                alt={`${currency}-currency`}
              />{" "}
              {currency}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

CurrencySwitcher.propTypes = {
  currency: PropTypes.string,
  onChangeCurrency: PropTypes.func,
};

CurrencySwitcher.defaultProps = {};

export default CurrencySwitcher;
