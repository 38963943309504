import React from "react";
import PropTypes from "prop-types";

import MainContent from "../../components/MainContent/MainContent";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import Block from "../../components/Block/Block";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import CurrencySwitcherContainer from "../../components/CurrencySwitcher/CurrencySwitcherContainer";
import * as STORAGE_KEYS from "../../constants/storage-keys";

import { routeCreators } from "../../lib/routes";
import { NavLink } from "react-router-dom";

const Account = ({
  user,
  onClickEditAccount,
  onClickAddress,
  onClickChangePassword,
  onClickAddressBook,
  onClickSignOut,
}) => {
  const storedAppVersion = localStorage.getItem(STORAGE_KEYS.APP_VERSION);
  return (
    <MainContent scrollable padded={false}>
      <Header
        title="My Account"
        rightAction={
          <Button theme="default" priority="tertiary" label="Edit" onClick={onClickEditAccount} />
        }
        onClickTitleOrImage={() => (window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com")}
        leftAction={<CurrencySwitcherContainer />}
      />
      <div>
        <Block hasRightArrow>
          <NavLink exact to={routeCreators.prepay()}>
            Buy PrePay
          </NavLink>
        </Block>
      </div>
      <div>
        <Block hasRightArrow>
          <NavLink exact to={routeCreators.orderHistory()}>
            Order History
          </NavLink>
        </Block>
      </div>
      <Block>
        <strong>Name</strong>
        <br />
        {user.get("first_name")} {user.get("last_name")}
      </Block>
      <Block>
        <strong>Email</strong>
        <br />
        {user.get("email")}
      </Block>
      <Block hasRightArrow onClick={onClickAddress}>
        <strong>Address</strong>
        <br />
        {user.get("address") && (
          <p style={{ whiteSpace: "pre" }}>
            {generateMultilineAddress(user.get("address").toJS()).join("\n")}
          </p>
        )}
      </Block>
      <br />
      <div>
        <Block hasRightArrow onClick={onClickChangePassword}>
          <p>Change Password</p>
        </Block>
      </div>
      <div>
        <Block hasRightArrow onClick={onClickAddressBook}>
          <p>Address Book</p>
        </Block>
      </div>
      <div>
        <Block hasRightArrow onClick={onClickSignOut}>
          <p>Sign Out</p>
        </Block>
      </div>
      <div>
        <span style={{ textAlign: 'right',width: '100%', display: 'block', paddingRight: '10px'}}>
          v{storedAppVersion}
          {` - ${process.env.REACT_APP_SENTRY_RELEASE}`}
        </span>
      </div>
    </MainContent>
  );
};

Account.propTypes = {
  onClickEditAccount: PropTypes.func,
  onClickAddress: PropTypes.func,
  onClickChangePassword: PropTypes.func,
  onClickAddressBook: PropTypes.func,
  onClickSignOut: PropTypes.func,
};

Account.defaultProps = {};

export default Account;
