import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './MainContent.scss';

class MainContent extends Component {
  static propTypes = {
    scrollable: PropTypes.bool.isRequired,
    padded: PropTypes.bool.isRequired,
    centeredVertically: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    scrollable: true,
    padded: true,
  };

  render() {
    const classes = classNames(
      "main-content",
      {
        "main-content--scrollable": this.props.scrollable,
        "main-content--padded": this.props.padded,
        "main-content--centered-vertically": this.props.centeredVertically,
      },
      this.props.className
    );

    return (
      <main className={classes} id="maincontent">
        {this.props.children}
      </main>
    );
  }
}

export default MainContent;
