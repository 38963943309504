import React, { useEffect } from "react";

import loadFont from "../../lib/load-font";

import './PhotoMagazineCoverBand.scss';

const COVER_TEXT_FONT = "Poppins";

const PhotoMagazineCoverBand = ({ fontSize = 3, firstLine, secondLine }) => {
  useEffect(() => {
    loadFont(COVER_TEXT_FONT);
  }, []);

  if ((!firstLine || !firstLine.trim()) && (!secondLine || !secondLine.trim())) {
    return null;
  }

  return (
    <div className="photo-magazine-cover-band" style={{ fontFamily: COVER_TEXT_FONT, fontSize }}>
      <div className="photo-magazine-cover-band__text">
        <div>{firstLine}</div>
        {secondLine && <div>{secondLine}</div>}
      </div>
    </div>
  );
};

export default PhotoMagazineCoverBand;
