import React from "react";
import PropTypes from "prop-types";

import { PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO } from "../../constants/text-style-options";
import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererPlaceholderLayer extends React.Component {
  static propTypes = {
    message: PropTypes.string,
  };

  static defaultProps = {
    message: "Tap to add",
  };

  render() {
    const rectStyles = {
      fontSize:
        (this.props.canvasDimensions.height || 0) *
        PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO,
    };

    return (
      <HtmlRendererRect
        className="html-renderer-placeholder-layer"
        rect={this.props.config.rect}
        onClick={() => this.props.onClick(this.rect.getDimensions())}
        ref={el => (this.rect = el)}
        style={rectStyles}
      >
        <p>{this.props.message}</p>
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererPlaceholderLayer;
