import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { is } from "immutable";

import * as STORAGE_KEYS from "../../constants/storage-keys";
import { productsByProductTypeId, getProductforProductId } from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import { actions as editorActions, actions as basketActions, selectors as editorSelectors } from "../../store/ducks/editor";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import Editor from "./Editor";
import SweetAlert from "../SweetAlert/SweetAlert";

import { Modal as AntModal } from 'antd';

const { confirm } = AntModal;

class EditorContainer extends Component {
  static propTypes = {
    item: PropTypes.object,
    isSignedIn: PropTypes.bool,
    user: PropTypes.object,
    editorItem: PropTypes.object,
    showAuthModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
    setEditorItem: PropTypes.func.isRequired,
    clearEditorItem: PropTypes.func.isRequired,
    setS3KeyForGraphicLayer: PropTypes.func.isRequired,
    setTextContentForTextLayer: PropTypes.func.isRequired,
    setTextPositionForTextLayer: PropTypes.func.isRequired,
    setImageSrcForPhotoRegion: PropTypes.func.isRequired,
    setFilterForPhotoRegion: PropTypes.func.isRequired,
    setProductId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    saveButtonLabel: "Save",
  };

  state = {
    isDirtyWarningVisible: false,
  };

  componentDidMount() {
    if (this.props.item) {
      //console.log(this.props.item.toJS());
      this.setItemAsEditorItem(this.props.item);
      //console.log(this.props.editorItem);
    }

    if (this.props.isDuplicate) {
      console.log("This is a duplicate");
      this.resetForDuplicate();
    }

    const wipEditorItem = localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM)
      ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM))
      : null;

    if (wipEditorItem && `/app${wipEditorItem.source}` === window.location.pathname) {
      this.props.setEditorItem(wipEditorItem.item);
    }
    //
    //setTimeout(() => {
    //  this.props.setTextContentForTextLayer('EXTRA_TEXT_LAYER', '<p>I am the only one</p><br><p>Where are the others</p>');
    //}, 1000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.item || !this.props.item) {
      return this.props.clearEditorItem();
    }

    if (prevProps.item.get("id") !== this.props.item.get("id")) {
      this.setItemAsEditorItem(this.props.item);
    }
  }

  componentWillUnmount() {
    this.props.clearEditorItem();
  }

  setItemAsEditorItem = item => {
    this.props.setEditorItem(item);
    this.originalEditorItem = item;
  };

  reset = () => {
    const editorItem = this.props.editorItem;
    this.props.clearEditorItem();
    this.setItemAsEditorItem(editorItem);
  };

  resetForDuplicate = () => {
    const newItemWithoutAddress = {
      ...this.props.item.toJS(),
      addressBookEntry: null,
      addressBookId: null,
    };
    this.props.clearEditorItem();
    this.props.setEditorItem(newItemWithoutAddress);
  };

  goToFrontPage = () => {
    this.editor.goToProductFront();
  };

  handleSave = () => {
    this.props.onSave(this.props.editorItem.delete("addressBookEntry"));
  };

  handleClose = (productName) => {
    const isDirty = !is(this.originalEditorItem, this.props.editorItem);
    if (isDirty) {
      confirm({
        title: 'Are you sure?',
        content: `You have unsaved changes, are you sure you don't want this ${productName}?`,
        okText: `Stay`,
        cancelText: 'Leave',
        onOk: () => {
          this.dismissDirtyWarning();
        },
        onCancel: () => {
          this.discardChangesAndClose();
        },
      });
    } else {
      this.props.onClose();
    }
  };

  dismissDirtyWarning = () => {
    this.setState({ isDirtyWarningVisible: false });
  };

  discardChangesAndClose = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    this.dismissDirtyWarning();
    this.props.onClose();
  };

  render() {
    if (!this.props.editorItem) {
      return null;
    }

    let productName = getProductforProductId(this.props.editorItem.get("productId"));

    let products = productsByProductTypeId
      .get(this.props.editorItem.get("productTypeId"))
      .map(product =>
        product.set(
          "pricingScheme",
          getPricingSchemeForProductAndQuantity({
            productId: product.get("id"),
            quantity: this.props.editorItem.get("quantity"),
            currency: this.props.currency,
          })
        )
      );
    // if Greeting card, we dont treat portrait and landscape as the same type

    if (this.props.editorItem.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD) {
      products = [
        products.find(product => product.get("id") === this.props.editorItem.get("productId")),
      ];

      // because getProductforProductId returns undefined for Portrait/Landscape cards...
      productName = "greeting card";
    }

    return [
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isDirtyWarningVisible}
        text={`You have unsaved changes, are you sure you don't want this ${productName}?`}
        cancelButtonText="Exit & discard changes"
        confirmButtonText={`Continue my ${productName}`}
        onConfirm={this.dismissDirtyWarning}
        onCancel={this.discardChangesAndClose}
      />,
      <Editor
        key="editor"
        onRef={ref => (this.editor = ref)}
        ref={ref => (this.editor = ref)}
        item={this.props.editorItem}
        products={products}
        isSignedIn={this.props.isSignedIn}
        user={this.props.user}
        showAuthModal={this.props.showAuthModal}
        onClose={() => this.handleClose(productName)}
        onSave={this.handleSave}
        saveButtonLabel={this.props.saveButtonLabel}
        onTextContentChange={this.props.setTextContentForTextLayer}
        onTextPositionChange={this.props.setTextPositionForTextLayer}
        onChangeSrcForPhotoRegion={this.props.setImageSrcForPhotoRegion}
        onMoveImageInRegionInPhotoLayer={this.props.setImagePositionForPhotoRegion}
        onCropImageInRegionInPhotoLayer={this.props.setImageCropDataForPhotoRegion}
        onChangeFilterForPhotoRegion={this.props.setFilterForPhotoRegion}
        onChangeSelectedGraphic={this.props.setS3KeyForGraphicLayer}
        onChangeTextColor={this.props.setColorForTextLayer}
        onChangeBorderThickness={this.props.setBorderThicknessForPhotoLayer}
        onChangeBorderStyle={this.props.setBorderStyleForPhotoLayer}
        onChangeLayout={this.props.setLayoutForPhotoLayer}
        onChangeTextConfig={this.props.updateTextLayerConfig}
        onChangeSignature={this.props.setImageForSignatureLayer}
        onChangeAddress={this.props.setAddress}
        onChangeOwnAddress={this.props.setUserAddress}
        onChangeAddressBookId={this.props.setAddressBookId}
        onChangePostDate={this.props.setPostDate}
        onChangeProductId={this.props.setProductId}
        onDecreaseQuantity={this.props.decreaseQuantity}
        onIncreaseQuantity={this.props.increaseQuantity}
        onChangePackQuantity={this.props.setPackQuantity}
      />,
    ];
  }
}

const mapStateToProps = state => {
  const editorItem = editorSelectors.getItem(state);
  const user = authSelectors.getUser(state);

  return {
    editorItem,
    isSignedIn: Boolean(user),
    user: user,
    currency: basketSelectors.getCurrency(state),
  };
};

const mapDispatchToProps = dispatch => ({
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
  setEditorItem: item => dispatch(editorActions.setEditorItem(item)),
  clearEditorItem: () => dispatch(editorActions.clearEditorItem()),
  setProductId: productId => dispatch(editorActions.setProductId(productId)),
  setS3KeyForGraphicLayer: (layerId, s3Key) =>
    dispatch(editorActions.setS3KeyForGraphicLayer(layerId, s3Key)),
  setColorForTextLayer: (layerId, index) =>
    dispatch(editorActions.setColorForTextLayer(layerId, index)),
  setTextContentForTextLayer: (layerId, content) =>
    dispatch(editorActions.setTextContentForTextLayer(layerId, content)),
  setTextPositionForTextLayer: (layerId, position) =>
    dispatch(editorActions.setTextPositionForTextLayer(layerId, position)),
  setImageSrcForPhotoRegion: ({ layerId, regionIndex, src }) =>
    dispatch(
      editorActions.setImageSrcForPhotoRegion({
        layerId,
        regionIndex,
        src,
      })
    ),
  setImageCropDataForPhotoRegion: ({ layerId, regionIndex, cropData }) =>
    dispatch(
      editorActions.setImageCropDataForPhotoRegion({
        layerId,
        regionIndex,
        cropData,
      })
    ),
  setImagePositionForPhotoRegion: ({ layerId, regionIndex, position }) =>
    dispatch(
      editorActions.setImagePositionForPhotoRegion({
        layerId,
        regionIndex,
        position,
      })
    ),
  setFilterForPhotoRegion: ({ layerId, regionIndex, filter }) =>
    dispatch(
      editorActions.setFilterForPhotoRegion({
        layerId,
        regionIndex,
        filter,
      })
    ),
  setBorderThicknessForPhotoLayer: (layerId, thickness) =>
    dispatch(editorActions.setBorderThicknessForPhotoLayer(layerId, thickness)),
  setBorderStyleForPhotoLayer: (layerId, style) =>
    dispatch(editorActions.setBorderStyleForPhotoLayer(layerId, style)),
  setLayoutForPhotoLayer: (layerId, layout) =>
    dispatch(editorActions.setLayoutForPhotoLayer(layerId, layout)),
  updateTextLayerConfig: (layerId, style) =>
    dispatch(editorActions.updateTextLayerConfig(layerId, style)),
  setImageForSignatureLayer: (layerId, src) =>
    dispatch(editorActions.setImageForSignatureLayer(layerId, src)),
  setAddress: address => dispatch(editorActions.setAddress(address)),
  setAddressBookId: addressBookId => dispatch(editorActions.setAddressBookId(addressBookId)),
  setPostDate: date => dispatch(editorActions.setPostDate(date)),
  decreaseQuantity: () => dispatch(editorActions.decreaseQuantity()),
  increaseQuantity: () => dispatch(editorActions.increaseQuantity()),
  setPackQuantity: (packQty) => dispatch(editorActions.setPackQuantity(packQty)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EditorContainer);
