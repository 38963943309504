import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererSignatureLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    avatarWidth: null,
  };

  componentDidMount() {
    this.setState({
      avatarWidth: this.avatarContainer.offsetHeight,
    });
  }

  componentDidUpdate() {
    const newWidth = this.avatarContainer.offsetHeight;

    if (this.state.avatarWidth !== newWidth) {
      this.setState({
        avatarWidth: newWidth,
      });
    }
  }

  render() {
    const { config } = this.props;
    const avatarStyles = {
      width: this.state.avatarWidth,
    };
    const drawingStyles = {};

    if (config.avatar && config.avatar.url) {
      avatarStyles.backgroundImage = `url(${config.avatar.url})`;
    }

    if (config.drawing && config.drawing.image) {
      drawingStyles.backgroundImage = `url(${config.drawing.image})`;
    }

    return (
      <HtmlRendererRect
        className="html-renderer-signature-layer"
        rect={this.props.config.rect}
        onClick={this.props.onClick}
      >
        <div
          className="html-renderer-signature-layer__avatar"
          ref={el => (this.avatarContainer = el)}
          style={avatarStyles}
        >
          &nbsp;
        </div>
        <div className="html-renderer-signature-layer__drawing" style={drawingStyles} />
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererSignatureLayer;
