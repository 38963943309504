import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Pluralize from "pluralize";

import * as LAYER_TYPES from "../../constants/layer-types";
import * as PHOTO_FILTERS from "../../constants/photo-filters";
import FILL_OPTIONS from "../../constants/fill-options";
import LAYOUTS from "../../constants/layouts";
import PAGES_PER_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import MASKS from "../../constants/masks";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import { pickImages } from "../../lib/file-uploader";
import {
  getEarliestPostingDateForCurrentTime,
  getArrivalDatesForDestination,
} from "../../lib/postage-date-calculator";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import { PRODUCT_TYPE_IDS, PACK_STEPS, productsByProductId } from "../../data/products";
import { preloadImage } from "../../lib/image-preloader";

import MainContent from "../MainContent/MainContent";
import Footer from "../Footer/Footer";
import BottomNavBar from "../BottomNavbar/BottomNavbar";
import BottomNavbarItem from "../BottomNavbar/BottomNavbarItem";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import FilterPreview from "../FilterPreview/FilterPreview";
import Flippable from "../Flippable/Flippable";
import EditorImageOption from "./EditorImageOption";
import Slider from "../Slider/Slider";
import EditorFillOption from "./EditorFillOption";
import Header from "../Header/Header";
import Grid from "../Grid/Grid";
import Button from "../Button/Button";
import EditorCollageOption from "./EditorCollageOption";
import EditorSignatureInputModal from "./EditorSignatureInputModal";
import EditorAddressInputModal from "./EditorAddressInputModal";
import EditorCropperModal from "./EditorCropperModal";
import SweetAlert from "../SweetAlert/SweetAlert";
import EditorTextEditorModal from "./EditorTextEditorModal";
import Currency from "../Formatters/Currency";
import LocalUploadModal from "../Debug/LocalUploadModal";
import Modal from "../Modal/Modal";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Icon from "../Icon/Icon";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import CardOpener from "../CardOpener/CardOpener";
import EditorCanvasPackage from "./EditorCanvasPackage";

import { Select, Popover, Icon as AntIcon, Row, Col, message, Button as AntButton, notification } from 'antd';
import { getAppValueByKey } from "../../data/app-values";
import './Editor.scss';

const Option = Select.Option

const PLACEHOLDER_IMAGES = [
  "/images/placeholder-photo-900x400.jpeg",
  "/images/placeholder-photo-900x1600.jpeg",
  "/images/placeholder-photo-1000x500.jpeg",
  "/images/placeholder-photo-1200x800.jpeg",
  "/images/placeholder-photo-1800x1000.jpeg",
  "/images/debug-grid.jpg",
];

const TOOLS = {
  STYLE: {
    id: "STYLE",
    label: "Style",
    icon: "style",
  },
  FILTERS: {
    id: "FILTERS",
    label: "Effects",
    icon: "effects",
  },
  COLLAGE: {
    id: "COLLAGE",
    label: "Layouts",
    icon: "collage",
  },
  CROP: {
    id: "CROP",
    label: "Crop & Rotate",
    icon: "crop-rotate",
  },
  TEXT: {
    id: "TEXT",
    label: "Text",
    icon: "text",
  },
  BORDER: {
    id: "BORDER",
    label: "Borders",
    icon: "border",
  },
  DEBUG: {
    id: "DEBUG",
    label: "Debug",
    icon: "bug",
  },
};

const EDITOR_CONFIG_PER_PRODUCT_TYPE = {
  [PRODUCT_TYPE_IDS.POSTCARD]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.GREETING_CARD]: {
    tools: [TOOLS.STYLE, TOOLS.CROP, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.INVITATION]: {
    tools: [TOOLS.STYLE, TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.ANNOUNCEMENT]: {
    tools: [TOOLS.STYLE, TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.CANVAS]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].front]: "Canvas",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].rear]: "Canvas",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].envelope]: "Recipient",
    },
  },
};

const STEPS = {
  PRODUCT_FRONT: "PRODUCT_FRONT",
  PRODUCT_REAR: "PRODUCT_REAR",
  RECIPIENT_DETAILS: "RECIPIENT_DETAILS",
  SELECT_POST_DATE: "SELECT_POST_DATE",
};

const UPLOAD_MODES = {
  DEBUG: "DEBUG",
  UPLOADCARE: "UPLOADCARE",
};

class CustomDatepickerField extends Component {
  render() {
    let dateLabel;
    if (moment(this.props.value).isSame(moment(), "day")) {
      dateLabel = "Today";
    } else if (moment(this.props.value).isSame(moment(new Date()).add(1, "days"), "day")) {
      dateLabel = "Tomorrow";
    } else {
      dateLabel = moment(this.props.value).format("D MMM YYYY");
    }

    return (
      <Button
        theme="muted"
        priority="secondary"
        label={dateLabel}
        icon="arrow-dropdown"
        iconPosition="right"
        onClick={this.props.onClick}
      />
    );
  }
}

class Editor extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    saveButtonLabel: PropTypes.string,
    onTextContentChange: PropTypes.func,
    onTextPositionChange: PropTypes.func,
    onChangeSrcForPhotoRegion: PropTypes.func,
    onCropImageInRegionInPhotoLayer: PropTypes.func,
    onMoveImageInRegionInPhotoLayer: PropTypes.func,
    onChangeFilterForPhotoRegion: PropTypes.func,
    onChangeSelectedGraphic: PropTypes.func,
    onChangeBorderThickness: PropTypes.func,
    onChangeBorderStyle: PropTypes.func,
    onChangeTextConfig: PropTypes.func,
    onChangeLayout: PropTypes.func,
    onChangeSignature: PropTypes.func,
    onChangeAddress: PropTypes.func,
    onChangeAddressBookId: PropTypes.func,
    onChangePostDate: PropTypes.func,
    onChangeProductId: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    const availableTools = Editor.getAvailableToolsForPage(props.item, props.item.getIn(["pages", "front"]));
    this.defaultState = {
      alert: null,
      uploadMode:
        process.env.NODE_ENV === "development" ? UPLOAD_MODES.UPLOADCARE : UPLOAD_MODES.UPLOADCARE,
      isLoading: false,
      isUploadModalVisible: false,
      isTextEditorVisible: false,
      textEditorScalingDimensions: null,
      isSignatureEditorVisible: false,
      isAddressModalVisible: false,
      isInfoModalVisible: false,
      addressInputMode: "new",
      isCropperModalVisible: false,
      isPhotoMagazineEditorVisible: false,
      currentStep: STEPS.PRODUCT_FRONT,
      selectedLayerId: null,
      selectedRegionIndex: null,
      availableTools: availableTools,
      activeTool: availableTools[0].id,
      areTextOptionsVisible: true,
      isAdditionalEnveleopeRequired: false,
    };

    const photoLayersForPage = this.props.item
      .get("layers")
      .filter(
        l =>
          l.get("type") === LAYER_TYPES.PHOTO &&
          l.get("page") === this.props.item.getIn(["pages", "front"])
      );

    if (photoLayersForPage && photoLayersForPage.size === 1) {
      if (photoLayersForPage.first().getIn(["config", "layout"]).size === 1) {
        this.defaultState.selectedLayerId = photoLayersForPage.get("id");
        this.defaultState.selectedRegionIndex = 0;
      }
    }

    this.state = { ...this.defaultState };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.moveUpPostingDateIfNecessary(this.props.item.get("postDate"));

    message.config({
      top: 20,
    });

    if (this.props.item && this.props.item.get('packMode')){
      //message.info(`This card is being made as a pack of ${this.props.item.get('quantity')} — you can change your pack size below`, 4);
      //message.info(`This card is being made as a pack of ${this.props.item.get('quantity')} — you can change your pack size below`, 4);

      message.open({
        content: `This card is being made as a pack of ${this.props.item.get('quantity')} — you can change your pack size below`,
        duration: 2.5,
        icon: <AntIcon type="info-circle"/>
      })
    }
    this.props.onRef(undefined);
    if (this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS) {
      preloadImage(`${process.env.PUBLIC_URL}/images/cardboard.jpg`);
    }
  }

  componentDidUpdate() {
     this.moveUpPostingDateIfNecessary(this.props.item.get("postDate"));
  }

  static getDerivedStateFromProps(props, state) {
    switch (state.currentStep) {
      case STEPS.PRODUCT_FRONT:
        return {
          availableTools: Editor.getAvailableToolsForPage(props.item, props.item.getIn(["pages", "front"])),
        };
      case STEPS.PRODUCT_REAR:
        return {
          availableTools: Editor.getAvailableToolsForPage(props.item, props.item.getIn(["pages", "rear"])),
        };
      default:
        return null;
    }
  }

  goToProductFront = () => {
    this.setCurrentStep(STEPS.PRODUCT_FRONT);
  };

  moveUpPostingDateIfNecessary = date => {
    if (this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD) {
      const earliestPostingDate = getEarliestPostingDateForCurrentTime();
      const selectedDate = moment(date);
      const isSelectedDateBeforeEarliestPostingDate =
        selectedDate.diff(earliestPostingDate, "days") < 0;

      if (isSelectedDateBeforeEarliestPostingDate) {
        this.props.onChangePostDate(earliestPostingDate.format());
      }
    }
  };

  handleSelectRegionInPhotoLayer = (layerId, regionIndex) => {
    this.setState({
      selectedLayerId: layerId,
      selectedRegionIndex: regionIndex,
    });

    const photoLayer = this.props.item.get("layers").find(l => l.get("id") === layerId);
    const regionInPhoto = photoLayer.getIn(["config", "layout", regionIndex]);
    if (!regionInPhoto.get("image")) {
      this.showUploadModal();
    } else {
      switch (this.state.activeTool) {
        case TOOLS.STYLE.id:
        case TOOLS.COLLAGE.id: {
          this.showUploadModal();
          break;
        }
        case TOOLS.CROP.id:
          this.setState({
            isCropperModalVisible: true,
          });
          break;
        // no default
      }
    }
  };

  showUploadModal = () => {
    this.setState({
      isUploadModalVisible: true,
    });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalVisible: false });
  };

  pickImageFromUploadcare = async source => {
    try {
      const result = await pickImages({ source });
      this.props.onChangeSrcForPhotoRegion({
        layerId: this.state.selectedLayerId,
        regionIndex: this.state.selectedRegionIndex,
        src: {
          lowResUrl: result.lowResUrl,
          highResUrl: result.highResUrl,
          uploadcareUuid: result.uuid,
        },
      });
      this.closeUploadModal();
    } catch (err) {
      console.log("Error while uploading:", err);
    }
  };

  handleSelectDebugUploadImage = imgUrl => {
    const layer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);

    switch (layer.get("type")) {
      case LAYER_TYPES.PHOTO:
        this.props.onChangeSrcForPhotoRegion({
          layerId: this.state.selectedLayerId,
          regionIndex: this.state.selectedRegionIndex,
          src: {
            lowResUrl: imgUrl,
            highResUrl: imgUrl,
          },
        });
        break;
      // no default
    }

    this.closeUploadModal();
  };

  handleClickTextLayer = (layerId, dimensionsInPixels) => {
    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      const layer = this.props.item.get("layers").find(l => l.get("id") === layerId);

      this.setState(
        {
          selectedLayerId: layerId,
          selectedRegionIndex: null,
          activeTool: !this.state.activeTool ? TOOLS.TEXT.id : this.state.activeTool,
          textEditorScalingDimensions: null,
        },
        () => {
          if (layer.getIn(["config", "text"]) === layer.getIn(["config", "originalText"])) {
            //this.openTextEditorForLayerId(layerId);
          }
        }
      );
    } else {
      this.setState({
        textEditorScalingDimensions: dimensionsInPixels,
      });
      this.openTextEditorForLayerId(layerId);
    }
  };

  handleDeselectTextLayer = () => {
    this.setState({
      selectedLayerId: null,
      selectedRegionIndex: null,
      activeTool: this.state.activeTool === TOOLS.TEXT.id ? null : this.state.activeTool,
    });
  };

  openTextEditorForLayerId = layerId => {
    this.setState({
      activeTool: TOOLS.TEXT.id,
      selectedLayerId: layerId,
      selectedRegionIndex: null,
      isTextEditorVisible: true,
    });

    this.textEditor.focus();
  };

  toggleTextOptions = () => {
    this.setState({
      areTextOptionsVisible: !this.state.areTextOptionsVisible,
    });
  };

  handleClickSignatureLayer = layerId => {
    this.setState({
      selectedLayerId: layerId,
      selectedRegionIndex: null,
      isSignatureEditorVisible: true,
    });
  };

  handleClearLayerSelection = () => {
    this.setState({
      selectedLayerId: null,
      selectedRegionIndex: null,
    });
  };

  setFilterForPhotoRegion = filter => {
    this.props.onChangeFilterForPhotoRegion({
      layerId: this.state.selectedLayerId,
      regionIndex: this.state.selectedRegionIndex,
      filter,
    });
  };

  activateTool = toolId => {
    const currentTool = this.state.activeTool;
    this.setState(
      {
        activeTool: toolId,
      },
      () => {
        switch (toolId) {
          case TOOLS.TEXT.id: {
            const activePageIndex =
              this.state.currentStep === STEPS.PRODUCT_FRONT
                ? this.props.item.getIn(["pages", "front"])
                : this.props.item.getIn(["pages", "rear"]);

            const photoLayerForPage = this.props.item
              .get("layers")
              .find(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.PHOTO);
            const textLayersForPage = this.props.item
              .get("layers")
              .filter(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.TEXT);
            const extraTextLayer = this.props.item
              .get("layers")
              .find(l => l.get("id") === "EXTRA_TEXT_LAYER");
            const extraTextLayerIsEmpty = !extraTextLayer.getIn(["config", "text"]);
            const notEveryRegionHasPhoto = !photoLayerForPage
              .getIn(["config", "layout"])
              .every(region => region.get("image"));
            const missingPhotoCount =
              photoLayerForPage.getIn(["config", "layout"]).count() -
              photoLayerForPage.getIn(["config", "layout"]).count(region => region.get("image"));
            const extraTextLayerIsOnlyTextLayer = extraTextLayer && textLayersForPage.size === 1;

            if (extraTextLayerIsOnlyTextLayer && extraTextLayerIsEmpty && notEveryRegionHasPhoto) {
              return this.setState({
                activeTool: currentTool,
                alert: {
                  type: "info",
                  text:
                    missingPhotoCount === 1
                      ? "Please add your photo before adding text"
                      : `Please add the remaining ${missingPhotoCount} photos before adding text.`,
                  showCancelButton: false,
                  onConfirm: this.clearAlert,
                },
              });
            }

            if (Editor.doesPageContainLayerType(this.props.item, activePageIndex, LAYER_TYPES.TEXT)) {
              const firstTextLayer = this.props.item
                .get("layers")
                .find(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.TEXT);
              this.openTextEditorForLayerId(firstTextLayer.get("id"));
            }
            break;
          }
          case TOOLS.BORDER.id: {
            const activePageIndex =
              this.state.currentStep === STEPS.PRODUCT_FRONT
                ? this.props.item.getIn(["pages", "front"])
                : this.props.item.getIn(["pages", "rear"]);
            const firstPhotoLayer = this.props.item
              .get("layers")
              .find(l => l.get("page") === activePageIndex);

            if (firstPhotoLayer && !firstPhotoLayer.getIn(["config", "border", "style"])) {
              this.setState({
                selectedLayerId: firstPhotoLayer.get("id"),
              });
              this.props.onChangeBorderStyle(firstPhotoLayer.get("id"), {
                type: "color",
                color: "rgb(255,255,255)",
              });
            }
            break;
          }
          case TOOLS.COLLAGE.id: {
            this.setState({
              selectedLayerId: null,
              selectedRegionIndex: null,
            });
            break;
          }
          case TOOLS.CROP.id: {
            const allPhotoLayersForPage = this.props.item
              .get("layers")
              .filter(
                l =>
                  l.get("type") === LAYER_TYPES.PHOTO &&
                  l.get("page") === this.props.item.getIn(["pages", "front"])
              );

            // If there's only one photo layer with one image region, select that region to automatically start the cropper
            if (allPhotoLayersForPage.size === 1) {
              if (allPhotoLayersForPage.first().getIn(["config", "layout"]).size === 1) {
                this.handleSelectRegionInPhotoLayer(allPhotoLayersForPage.first().get("id"), 0);
              }
            }
            break;
          }
          // no default
        }
      }
    );
  };

  static doesPageContainLayerType = (item, pageIndex, layerType) => {
    return Boolean(
      item.get("layers").find(layer => {
        const isLayerForCurrentPage = layer.get("page") === pageIndex;
        const isMatchingLayerType = layer.get("type") === layerType;
        return isLayerForCurrentPage && isMatchingLayerType;
      })
    );
  };

  static getAvailableToolsForPage = (item, pageIndex) => {
    const configEntry = EDITOR_CONFIG_PER_PRODUCT_TYPE[item.get("productTypeId")];

    if (!configEntry) {
      return [];
    }

    const allToolsForProductType = configEntry.tools;

    return allToolsForProductType.filter(tool => {
      switch (tool.id) {
        case TOOLS.STYLE.id:
          return (
            Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.GRAPHIC) &&
            item.get("designOptions").size > 1
          );
        case TOOLS.FILTERS.id:
        case TOOLS.BORDER.id:
        case TOOLS.COLLAGE.id:
        case TOOLS.CROP.id:
          return Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.PHOTO);
        case TOOLS.TEXT.id:
          return (
            Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.TEXT) ||
            (Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.PHOTO) &&
              !Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.TEXT))
          );
        case TOOLS.DEBUG.id:
          return true;
        default:
          return false;
      }
    });
  };

  handleSave = () => {
    if (this.props.item.get("address") || this.props.item.get("addressBookId")) {
      this.props.onSave();
    } else {
      this.setState({
        alert: {
          type: "info",
          showCancelButton: false,
          confirmButtonText: "OK",
          title: "Recipient Details",
          text: "Please add an address",
          onConfirm: this.clearAlert,
        },
      });
    }
  };

  saveTextEditorConfig = config => {
    this.props.onChangeTextConfig(this.state.selectedLayerId, config);
    this.closeTextEditor();
  };

  closeTextEditor = () => {
    console.log("closing");
    this.setState({
      isTextEditorVisible: false,
    });
  };

  saveSignature = signature => {
    this.props.onChangeSignature(this.state.selectedLayerId, signature);
    this.closeSignatureEditor();
  };

  closeSignatureEditor = () => {
    this.setState({
      selectedLayerId: null,
      isSignatureEditorVisible: false,
    });
  };

  clearSelection = () => {
    this.frontRenderer.clearSelection();
    this.backRenderer.clearSelection();
  };

  handleCropperSave = cropData => {
    this.props.onCropImageInRegionInPhotoLayer({
      layerId: this.state.selectedLayerId,
      regionIndex: this.state.selectedRegionIndex,
      cropData,
    });
    this.setState({
      isCropperModalVisible: false,
    });
  };

  handleReplaceImage = () => {
    this.setState({
      isCropperModalVisible: false,
      isUploadModalVisible: true,
    });
  }

  handleChangeLayout = (layerId, layout) => {
    this.setState(
      {
        selectedLayerId: null,
        selectedRegionIndex: null,
      },
      () => {
        this.props.onChangeLayout(layerId, layout);
      }
    );
  };

  handleClickAddressLayer = () => {
    if (this.props.item.get("address")) {
      this.setState({
        alert: {
          text: "Do you wish to edit this address or add a new one?",
          confirmButtonText: "Edit Existing",
          onConfirm: () =>
            this.setState({ addressInputMode: "edit", alert: null }, this.showAddressModal),
          cancelButtonText: "Add New",
          onCancel: () =>
            this.setState({ addressInputMode: "new", alert: null }, this.showAddressModal),
        },
      });
    } else {
      this.showAddressModal();
    }
  };

  handleAddressSave = address => {
    this.props.onChangeAddress(address);
    this.closeAddressModal();
  };

  handleOwnAddressSave = address => {
    return this.props.onChangeOwnAddress(address);
  };

  handleSelectAddressBookEntry = (entryId, isDoubleDirect) => {
    this.props.onChangeAddressBookId(entryId);
    this.setState({
      isAdditionalEnvelopeRequired: isDoubleDirect,
    });
    this.closeAddressModal();
  };

  showAddressModal = () => {
    this.setState({
      isAddressModalVisible: true,
    });
  };

  closeAddressModal = () => {
    this.setState({
      isAddressModalVisible: false,
    });
  };

  showInfoModal = () => {
    this.setState({
      isInfoModalVisible: true,
    });
  };

  closeInfoModal = () => {
    this.setState({
      isInfoModalVisible: false,
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  setCurrentStep = newStep => {
    const currentStep = this.state.currentStep;

    switch (newStep) {
      case STEPS.PRODUCT_REAR: {
        if (currentStep === STEPS.PRODUCT_FRONT) {
          const allPhotoLayersForPage = this.props.item
            .get("layers")
            .filter(
              l =>
                l.get("type") === LAYER_TYPES.PHOTO &&
                l.get("page") === this.props.item.getIn(["pages", "front"])
            );
          const allRegionsInPhotoLayers = allPhotoLayersForPage.flatMap(photoLayer =>
            photoLayer.getIn(["config", "layout"])
          );
          const missingPhotoCount =
            allRegionsInPhotoLayers.count() -
            allRegionsInPhotoLayers.count(region => region.get("image"));

          if (!allRegionsInPhotoLayers.every(region => region.get("image"))) {
            message.warning(missingPhotoCount === 1
              ? "Please add a photo before proceeding"
              : ` Please add all ${Pluralize("photo", missingPhotoCount)} before proceeding`);
            return null;
            // return this.setState({
            //   alert: {
            //     type: "info",
            //     showCancelButton: false,
            //     confirmButtonText: "OK",
            //     title: missingPhotoCount === 1 ? "Photo required" : "Photos required",
            //     text:
            //       missingPhotoCount === 1
            //         ? "Please add a photo before proceeding"
            //         : ` Please add all ${Pluralize("photo", missingPhotoCount)} before proceeding`,
            //     onConfirm: this.clearAlert,
            //   },
            // });
          }
        }
        break;
      }
      case STEPS.RECIPIENT_DETAILS: {
        if (currentStep === STEPS.PRODUCT_REAR) {
          const textLayers = this.props.item
            .get("layers")
            .filter(
              l => {
                return (l.get("type") === LAYER_TYPES.TEXT)
              }
          );
          
          const everyTextLayerBlank = textLayers.every((layer) => {
            return (layer.getIn(["config", "text"]) === "" || layer.getIn(["config", "text"]) === layer.getIn(["config", "placeholderText"]))
          });

          if (everyTextLayerBlank){
            const btn = (
              <AntButton type="primary" size="small" onClick={() => this.setState({currentStep: newStep}, () => {notification.close("check")})}>
                Continue
              </AntButton>
            );
            notification.open({
              message: "One second...",
              description: "Sure you're all done? Be sure to check you're happy with all sides of your design. Anything you see here will be printed",
              duration: 0,
              key: "check",
              btn,
              placement: 'topRight',
              icon: <AntIcon type="info-circle" style={{ color: '#F75E67' }} />,
              onClose: () => {console.log("Close")}
            });
            return null;
          }
        }
        break;
      }

      case STEPS.SELECT_POST_DATE: {
        if (currentStep === STEPS.RECIPIENT_DETAILS) {
          const address = this.props.item.get("address") || this.props.item.get("addressBookId");
          if (!address) {
            return this.setState({
              alert: {
                type: "info",
                showCancelButton: false,
                confirmButtonText: "OK",
                title: "Recipient Details",
                text: "Please add an address",
                onConfirm: this.clearAlert,
              },
            });
          }
        }
        break;
      }
      // no default
    }
    
    const updatedState = {
      currentStep: newStep,
      selectedLayerId: null,
      selectedRegionIndex: null,
    };

    // Make sure to clear canvas selection when transitioning between front/back
    if (
      this.state.currentStep === STEPS.PRODUCT_FRONT ||
      this.state.currentStep === STEPS.PRODUCT_REAR
    ) {
      this.clearSelection();
    }

    if (newStep === STEPS.PRODUCT_FRONT || newStep === STEPS.PRODUCT_REAR) {
      const activePageIndex =
        newStep === STEPS.PRODUCT_FRONT
          ? this.props.item.getIn(["pages", "front"])
          : this.props.item.getIn(["pages", "rear"]);
      updatedState.availableTools = Editor.getAvailableToolsForPage(this.props.item, activePageIndex);
    }

    this.setState(updatedState);
  };

  getHeaderPropsForStep = step => {
    const headerProps = {
      title: "",
      leftAction: null,
      rightAction: null,
    };

    const configEntry = EDITOR_CONFIG_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")];
    const isCanvas = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    switch (step) {
      case STEPS.PRODUCT_FRONT: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].front;
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? configEntry.pageTitles[pageNumber]
            : "Card Front";
        headerProps.leftAction = (
          <Button theme="muted" priority="tertiary" label="Cancel" onClick={this.props.onClose} />
        );
        headerProps.rightAction = (
          <Button
            theme="default"
            priority="tertiary"
            label="Next"
            onClick={() =>
              this.setCurrentStep(isCanvas ? STEPS.RECIPIENT_DETAILS : STEPS.PRODUCT_REAR)
            }
          />
        );
        break;
      }
      case STEPS.PRODUCT_REAR: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].rear;
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? configEntry.pageTitles[pageNumber]
            : "Card Back";
        headerProps.leftAction = (
          <Button
            theme="muted"
            priority="tertiary"
            label="Front"
            onClick={() => this.setCurrentStep(STEPS.PRODUCT_FRONT)}
          />
        );

        switch (this.props.item.get("productTypeId")) {
          case PRODUCT_TYPE_IDS.POSTCARD: {
            headerProps.rightAction = (
              <Button
                theme="default"
                priority="tertiary"
                label={this.props.saveButtonLabel}
                onClick={this.handleSave}
              />
            );
            break;
          }
          // For any non-postcard product, the next step after the product rear step is the envelope step
          default: {
            headerProps.rightAction = (
              <Button
                theme="default"
                priority="tertiary"
                label="Recipient"
                onClick={() => this.setCurrentStep(STEPS.RECIPIENT_DETAILS)}
              />
            );
            break;
          }
        }
        break;
      }
      case STEPS.RECIPIENT_DETAILS: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].envelope;
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? configEntry.pageTitles[pageNumber]
            : "Recipient Details";
        headerProps.leftAction = (
          <Button
            theme="muted"
            priority="tertiary"
            label="Back"
            onClick={() => this.setCurrentStep(isCanvas ? STEPS.PRODUCT_FRONT : STEPS.PRODUCT_REAR)}
          />
        );

        if (isGreetingCard) {
          headerProps.rightAction = (
            <Button
              theme="default"
              priority="tertiary"
              label="Post Date"
              onClick={() => this.setCurrentStep(STEPS.SELECT_POST_DATE)}
            />
          );
        } else {
          headerProps.rightAction = (
            <Button
              theme="default"
              priority="tertiary"
              label={this.props.saveButtonLabel}
              onClick={this.handleSave}
            />
          );
        }
        break;
      }
      case STEPS.SELECT_POST_DATE: {
        headerProps.title = "Postage Date";
        headerProps.leftAction = (
          <Button
            theme="muted"
            priority="tertiary"
            label="Back"
            onClick={() => this.setCurrentStep(STEPS.RECIPIENT_DETAILS)}
          />
        );
        headerProps.rightAction = (
          <Button
            theme="default"
            priority="tertiary"
            label={this.props.saveButtonLabel}
            onClick={this.handleSave}
          />
        );
        break;
      }
      // no default
    }

    return headerProps;
  };

  filterDate = date => {
    date = moment(date);
    const isWeekDay = date.isoWeekday() < 6;

    return isWeekDay;
  };

  handleDatePickerChange = date => {
    const FUTURE_DATE_WARNING_DAYS = 60;
    // If selected date is more than x days in the future, show a courtesy warning
    if (
      Math.abs(
        moment()
          .startOf("day")
          .diff(moment(date).startOf("day"), "days")
      ) > FUTURE_DATE_WARNING_DAYS
    ) {
      this.setState({
        alert: {
          type: "warning",
          showCancelButton: false,
          confirmButtonText: "Yes",
          title: "Future Date",
          text: `${moment(date).format(
            "D MMM YYYY"
          )} is quite far in the future, are you sure you want this card to be posted on this date?`,
          onConfirm: this.clearAlert,
        },
      });
    }
    this.props.onChangePostDate(date.format());
  };

  handleChangeSelectedVariant = async variantIndex => {
    this.setState({
      isLoading: true,
    });

    const promises = this.props.item
      .get("layers")
      .map(layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          const imgUrl = generateS3AssetUrlFromKey(
            layer.getIn(["config", "s3_keys", variantIndex])
          );
          return preloadImage(imgUrl);
        }

        return null;
      })
      .filter(p => p);

    await Promise.all(promises);

    this.props.item.get("layers").forEach(layer => {
      if (layer.get("type") === LAYER_TYPES.TEXT && layer.getIn(["config", "colors"])) {
        this.props.onChangeTextConfig(layer.get("id"), {
          color: layer.getIn(["config", "colors", variantIndex]),
        });
      }

      if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
        this.props.onChangeSelectedGraphic(
          layer.get("id"),
          layer.getIn(["config", "s3_keys", variantIndex])
        );
      }
    });

    this.setState({
      isLoading: false,
    });
  };

  renderFrontOrRearStep = () => {
    const activePageIndex =
      this.state.currentStep === STEPS.PRODUCT_FRONT
        ? this.props.item.getIn(["pages", "front"])
        : this.props.item.getIn(["pages", "rear"]);
    const activeLayer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);
    let helpText;
    let cropText;
    let footerContent;

    const toolNavbarItems = this.state.availableTools.map(tool => (
      <BottomNavbarItem
        key={tool.id}
        active={this.state.activeTool === tool.id}
        label={tool.label}
        onClick={() => this.activateTool(tool.id)}
      >
        <Icon name={tool.icon} />
      </BottomNavbarItem>
    ));

    let activeToolMarkup;

    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      switch (this.state.activeTool) {
        case TOOLS.STYLE.id: {
          const graphicLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.GRAPHIC && l.get("page") === activePageIndex);

          // const textLayerForPage = this.props.item
          //   .get('layers')
          //   .find(l => l.get('type') === LAYER_TYPES.TEXT && l.get('page') === activePageIndex);

          const graphicOptions = this.props.item.get("designOptions").map((key, index) => {
            return (
              <EditorImageOption
                key={key}
                src={generateS3AssetUrlFromKey(key)}
                active={
                  graphicLayerForPage.getIn(["config", "s3_key"]) ===
                  graphicLayerForPage.getIn(["config", "s3_keys", index])
                }
                onClick={() => this.handleChangeSelectedVariant(index)}
              />
            );
          });

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="graphic-options" center={true}>{graphicOptions}</HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.FILTERS.id: {
          const imageForFilterOptions =
            activeLayer &&
            activeLayer.get("type") === LAYER_TYPES.PHOTO &&
            activeLayer.getIn(["config", "layout", this.state.selectedRegionIndex, "image"]);

          if (!imageForFilterOptions) {
            helpText = "Please select an image to apply effects.";
            break;
          }

          const filterOptions = Object.keys(PHOTO_FILTERS).map(filter => (
            <FilterPreview
              key={filter}
              filterId={filter}
              imageSrc={imageForFilterOptions && imageForFilterOptions.getIn(["src", "lowResUrl"])}
              onClick={this.setFilterForPhotoRegion}
            />
          ));

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="effect-options">
                <FilterPreview
                  key="no-filter"
                  filterId={null}
                  imageSrc={
                    imageForFilterOptions && imageForFilterOptions.getIn(["src", "lowResUrl"])
                  }
                  onClick={this.setFilterForPhotoRegion}
                />
                {filterOptions}
              </HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.BORDER.id: {
          const photoLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.PHOTO && l.get("page") === activePageIndex);

          const borderConfig = photoLayerForPage.getIn(["config", "border"]);

          activeToolMarkup = [
            photoLayerForPage.getIn(["config", "border", "style"]) && (
              <div
                className="editor__tool editor__tool--padded-for-slider"
                key="border-thickness-slider"
              >
                <Slider
                  min={0.05}
                  max={0.2}
                  step={0.005}
                  value={photoLayerForPage.getIn(["config", "border", "thickness"])}
                  onChange={val =>
                    this.props.onChangeBorderThickness(photoLayerForPage.get("id"), val)
                  }
                />
              </div>
            ),
            <div className="editor__tool" key="border-options">
              <HorizontalScroller>
                <EditorFillOption
                  type="image"
                  src={`${process.env.PUBLIC_URL}/images/no-option.svg`}
                  onClick={() => this.props.onChangeBorderStyle(photoLayerForPage.get("id"), null)}
                  active={borderConfig.get("style") === null}
                />
                {FILL_OPTIONS.map((option, index) => (
                  <EditorFillOption
                    key={index}
                    type={option.type}
                    color={option.color}
                    src={option.src}
                    active={
                      borderConfig &&
                      borderConfig.get("style") &&
                      (isEqual(borderConfig.get("style").toJS(), {
                        type: "color",
                        color: option.color,
                      }) ||
                        isEqual(borderConfig.get("style").toJS(), {
                          type: "image",
                          src: option.src,
                        }))
                    }
                    onClick={() =>
                      this.props.onChangeBorderStyle(photoLayerForPage.get("id"), option)
                    }
                  />
                ))}
              </HorizontalScroller>
            </div>,
          ];
          break;
        }
        case TOOLS.COLLAGE.id: {
          const photoLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.PHOTO && l.get("page") === activePageIndex);

          const photoCount = photoLayerForPage
            .getIn(["config", "layout"])
            .count(region => region.get("image"));
          const currentLayout = photoLayerForPage.getIn(["config", "layout"]);
          const onlyLayoutProperties = currentLayout.map(region => ({
            xOffset: region.get("xOffset"),
            yOffset: region.get("yOffset"),
            width: region.get("width"),
            height: region.get("height"),
          }));

          activeToolMarkup = (
            <div className="editor__tool" key="border-options">
              <HorizontalScroller>
                {LAYOUTS.map((layout, index) => (
                  <EditorCollageOption
                    key={index}
                    ratio={
                      this.props.item.getIn(["productDimensions", "width"]) /
                      this.props.item.getIn(["productDimensions", "height"])
                    }
                    layout={layout}
                    active={isEqual(layout, onlyLayoutProperties.toJS())}
                    onClick={() => this.handleChangeLayout(photoLayerForPage.get("id"), layout)}
                  />
                ))}
              </HorizontalScroller>
            </div>
          );

          helpText =
            photoCount > 0
              ? photoCount === 1
                ? "Tap your photo to replace it"
                : "Tap a photo to replace it"
              : "Tap the camera icon to add a photo";
          break;
        }
        case TOOLS.TEXT.id: {
          const isActiveLayerTextLayer =
            activeLayer && activeLayer.get("type") === LAYER_TYPES.TEXT;
          if (isActiveLayerTextLayer) {
            activeToolMarkup = null;
          }
          break;
        }
        case TOOLS.CROP.id: {
          const allPhotoLayersForPage = this.props.item
            .get("layers")
            .filter(
              l =>
                l.get("type") === LAYER_TYPES.PHOTO &&
                l.get("page") === this.props.item.getIn(["pages", "front"])
            );
          const allRegionsInPhotoLayers = allPhotoLayersForPage.flatMap(photoLayer =>
            photoLayer.getIn(["config", "layout"])
          );
          const populatedPhotoCount = allRegionsInPhotoLayers.count(region => region.get("image"));

          if (populatedPhotoCount === 0) {
            cropText = "Tap the camera icon to add a photo";
          } else {
            cropText =
              populatedPhotoCount === 1
                ? "Tap your photo to crop and zoom to fit"
                : "Tap a photo to crop and zoom to fit";
          }

          activeToolMarkup = (
            <div className="editor__tool editor__tool--padded" key="active-tool">
              <small>{cropText}</small>
            </div>
          );
          break;
        }
        case TOOLS.DEBUG.id: {
          activeToolMarkup = (
            <div className="editor__tool editor__tool--padded" key="active-tool">
              <small>Nothing here yet.</small>
            </div>
          );
        }
        // no default
      }
    }

    const rendererProps = {
      debug: false,
      item: this.props.item.toJS(),
      currentEditorStep: this.state.currentStep,
      activeTool: this.state.activeTool,
      selectedLayerId: this.state.selectedLayerId,
      selectedRegionIndex: this.state.selectedRegionIndex,
      onTextContentChange: this.props.onTextContentChange,
      onTextPositionChange: this.props.onTextPositionChange,
      onSelectRegionInPhotoLayer: this.handleSelectRegionInPhotoLayer,
      onMoveImageInRegionInPhotoLayer: this.props.onMoveImageInRegionInPhotoLayer,
      onClearLayerSelection: this.handleClearLayerSelection,
      onClickTextLayer: this.handleClickTextLayer,
      onDeselectTextLayer: this.handleDeselectTextLayer,
      onDeleteTextLayerContents: id => this.props.onChangeTextConfig(id, { text: "" }),
      onStartEditingTextLayer: this.openTextEditorForLayerId,
      onClickSignatureLayer: this.handleClickSignatureLayer,
      onClickAddressLayer: this.handleClickAddressLayer,
    };

    const isCanvas = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;

    const dimensions = this.props.item.get("productDimensions").toJS();

    const productRatio =
      (dimensions.width + dimensions.bleed.left + dimensions.bleed.right) /
      (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom);

    const productSlug =
      isCanvas && productsByProductId.get(this.props.item.get("productId")).get("url_slug");

    const isPackMode = this.props.item.get("packMode");

    const whatArePacksContent = (
      <div>
        <p>Packs are exact duplicates of the same card (the one you're making now) sent to a single recipient, usually back to yourself.</p>
        <p>For each pack quantity (8, 16 etc), you will receive the same number of additional blank envelopes for you to personalise each card and send on.</p>
        <p>The more you buy, the better value the packs. Happy PostSnapping.</p>
      </div>
    );

    const packOptions = PACK_STEPS.slice(1,-1).map(packSize => {
      const price = getPricingSchemeForProductAndQuantity({
        productId: this.props.item.get("productId"),
        quantity: packSize,
        currency: 'GBP',
      })
      return { packSize: packSize, price: price.get('cost')*packSize };
    });

    const frontRenderer = (
      <HtmlRenderer
        page={this.props.item.getIn(["pages", "front"])}
        ref={e => (this.frontRenderer = e)}
        onSelectTextPlaceholderLayer={this.openTextEditorForLayerId}
        mask={isCanvas && MASKS[productSlug].canvasEditor}
        {...rendererProps}
      />
    );

    const backRenderer = (
      <HtmlRenderer
        page={this.props.item.getIn(["pages", "rear"])}
        ref={e => (this.backRenderer = e)}
        {...rendererProps}
      />
    );

    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      footerContent = <BottomNavBar>{toolNavbarItems}</BottomNavBar>;
    } else {
      footerContent = (
        <div>
          <table>
            <tbody>
              {this.props.products.map(product => (
                <tr
                  key={product.get("id")}
                  className={classNames("editor__product-size-option", {
                    "editor__product-size-option--selected":
                      this.props.item.get("productId") === product.get("id"),
                  })}
                  htmlFor={`product-option-${product.get("id")}`}
                  onClick={() => this.props.onChangeProductId(product.get("id"))}
                >
                  <td className="editor__product-size-option__preview">
                    <HtmlRenderer
                      page={this.props.item.getIn(["pages", "front"])}
                      item={this.props.item.toJS()}
                      width={[5, 28, 76, 199].includes(product.get("id")) ? 85 : [201, 199].includes(product.get("id")) ? 45 : 120 }
                      isInteractive={false}
                      onClick={() => this.props.onChangeProductId(product.get("id"))}
                    />
                  </td>
                  <td className="editor__product-size-option__info">
                    <div className="editor__product-size-option-title">{product.get("name")}{isPackMode ? ' Pack': ''}</div>
                    <div className="editor__product-size-option-subtitle">
                      {product.get("width")}mm × {product.get("height")}mm
                    </div>
                  </td>
                  <td className="text-right">
                    {!isPackMode && (
                      <div className="editor__product-size-option__price">
                      <Currency
                        amount={product.getIn(["pricingScheme", "cost"])}
                      />

                    </div>
                    )}
                    {this.props.products.size > 1 && (
                      <div className="pretty p-default p-round">
                        <input
                          type="radio"
                          checked={this.props.item.get("productId") === product.get("id")}
                          id={`product-option-${product.get("id")}`}
                          readOnly
                        />
                        <div className="state">
                          <label></label>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {(this.props.item.get("productTypeId") !==
                PRODUCT_TYPE_IDS.POSTCARD && !isPackMode) && (
                <tr>
                  <td colSpan={3}>
                    <div className="editor__quantity-picker">
                      <Grid.Row alignVertically>
                        <Grid.Column className="text-right pr-default">
                          <div className="editor__quantity-picker-amount">
                            Quantity: {this.props.item.get("quantity")}
                          </div>
                        </Grid.Column>
                        <Grid.Column className="text-right" size="0 auto">
                          <div className="editor__quantity-picker-controls">
                          <AntButton.Group>
                            <AntButton
                              size="default"
                              type={"primary"}
                              ghost
                              active={"true"}
                              onClick={this.props.onDecreaseQuantity}
                            >
                              <AntIcon type="minus" />
                            </AntButton>
                            <AntButton
                              size="default"
                              onClick={this.props.onIncreaseQuantity}
                              type={"primary"}
                              ghost
                            >
                              <AntIcon type="plus" />
                            </AntButton>
                          </AntButton.Group>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }

    let cropperRatio = 1;

    if (activeLayer && this.state.selectedLayerId && this.state.selectedRegionIndex !== null) {
      if (activeLayer.get("type") === LAYER_TYPES.PHOTO) {
        const activeRenderer =
          this.state.currentStep === STEPS.PRODUCT_FRONT ? this.frontRenderer : this.backRenderer;
        if (!activeRenderer) {
          return;
        }

        const realDimensions = activeRenderer.layerRefs[activeLayer.get("id")].getDimensions();

        const photoLayer = this.props.item
          .get("layers")
          .find(l => l.get("id") === this.state.selectedLayerId);
        const regionInPhoto = photoLayer.getIn([
          "config",
          "layout",
          this.state.selectedRegionIndex,
        ]);

        // console.log(regionInPhoto.get('width'));
        // console.log(regionInPhoto.get('height'));

        cropperRatio =
          (realDimensions.width * regionInPhoto.get("width")) /
          (realDimensions.height * regionInPhoto.get("height"));
      }
    }

    const productDimensions = this.props.item.get("productDimensions");
    const imageForCropper =
      activeLayer &&
      activeLayer.getIn(["config", "layout", this.state.selectedRegionIndex, "image"]);

    let signatureRatio = null;

    if (this.state.isSignatureEditorVisible && activeLayer) {
      // We subtract a square (dimensions: rectHeight x rectHeight) from the rect dimensions to get the signature ratio
      const signatureDimensions = {
        width: productDimensions.get("width") * activeLayer.getIn(["config", "rect", "width"]),
        height: productDimensions.get("height") * activeLayer.getIn(["config", "rect", "height"]),
      };

      signatureRatio = this.state.isSignatureEditorVisible
        ? (signatureDimensions.width - signatureDimensions.height) / signatureDimensions.height
        : null;
    }

    let uploadModal = null;

    const recentlyUploaded = JSON.parse(localStorage.getItem('recentlyUploadedFiles'));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;


    switch (this.state.uploadMode) {
      case UPLOAD_MODES.DEBUG:
        uploadModal = (
          <LocalUploadModal
            key="debug-upload-modal"
            isOpen={this.state.isUploadModalVisible}
            onClose={this.closeUploadModal}
            images={PLACEHOLDER_IMAGES}
            onSelectImage={this.handleSelectDebugUploadImage}
          />
        );
        break;
      case UPLOAD_MODES.UPLOADCARE:
        uploadModal = (
          <Modal
            key="uploadcare-selection-modal"
            isOpen={this.state.isUploadModalVisible}
            onClose={this.closeUploadModal}
            title="Upload Photo"
          >
            <MainContent scrollable={false} padded centeredVertically>
              <Button
                block
                label="From Device"
                icon="phone"
                onClick={() => this.pickImageFromUploadcare("file")}
              />
              {anyRecentlyUploaded && (
                <Button
                  block
                  className="btn--recent"
                  label="Recently Uploaded"
                  icon="upload"
                  onClick={() => this.pickImageFromUploadcare("favorites")}
                />
              )}
              <Button
                block
                icon="facebook"
                className="btn--facebook"
                label="Facebook"
                onClick={() => this.pickImageFromUploadcare("facebook")}
              />
              <Button
                block
                icon="instagram"
                className="btn--instagram"
                label="Instagram"
                onClick={() => this.pickImageFromUploadcare("instagram")}
              />
              <Button
                block
                icon="gdrive"
                className="btn--google"
                label="Google Drive"
                onClick={() => this.pickImageFromUploadcare("gdrive")}
              />
              <Button
                block
                icon="dropbox"
                className="btn--dropbox"
                label="Dropbox"
                onClick={() => this.pickImageFromUploadcare("dropbox")}
              />
              <Button
                block
                icon="flickr"
                className="btn--flickr"
                label="Flickr"
                onClick={() => this.pickImageFromUploadcare("flickr")}
              />
            </MainContent>
          </Modal>
        );
        break;
      // no default
    }

    let infoContent = getAppValueByKey("CANVAS_INFO")
      ? getAppValueByKey("CANVAS_INFO").get("value")
      : "";
    const canvasImage = imageForCropper
      ? imageForCropper.getIn(["src", "lowResUrl"])
      : "https://unsplash.it/560/280?image=1040";
    const infoModal = (
      <Modal
        key="info-modal"
        isOpen={this.state.isInfoModalVisible}
        onClose={this.closeInfoModal}
        title="Information"
      >
        <MainContent scrollable padded>
          <div
            className="canvas-preview black"
            style={{ display: "none", backgroundImage: `url(${canvasImage})` }}
          >
            <div className="top" style={{ backgroundImage: `url(${canvasImage})` }}></div>
            <div className="right" style={{ backgroundImage: `url(${canvasImage})` }}></div>
          </div>
          <div
            style={{ display: "block" }}
            key="help-text"
            dangerouslySetInnerHTML={{ __html: infoContent }}
          />
        </MainContent>
      </Modal>
    );

    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    return [
      <EditorSignatureInputModal
        key="signature-input-modal"
        ratio={signatureRatio}
        signatureData={
          this.state.isSignatureEditorVisible && activeLayer.getIn(["config", "data"])
            ? activeLayer.getIn(["config", "data"]).toJS()
            : []
        }
        config={activeLayer && activeLayer.get("config")}
        isOpen={this.state.isSignatureEditorVisible}
        onSave={this.saveSignature}
        onCancel={this.closeSignatureEditor}
      />,
      <EditorCropperModal
        key="cropper-modal"
        onHandleReplaceImage={this.handleReplaceImage}
        isOpen={this.state.isCropperModalVisible}
        imgUrl={imageForCropper && imageForCropper.getIn(["src", "lowResUrl"])}
        cropData={
          imageForCropper && imageForCropper.get("cropData")
            ? imageForCropper.get("cropData").toJS()
            : null
        }
        ratio={cropperRatio}
        onClose={() => this.setState({ isCropperModalVisible: false })}
        onSave={this.handleCropperSave}
      />,
      uploadModal,
      infoModal,
      <MainContent scrollable={false} key="main">
        {isGreetingCard ? (
          <CardOpener
            isOpen={this.state.currentStep && this.state.currentStep === STEPS.PRODUCT_REAR}
            ratio={productRatio}
            front={frontRenderer}
            inside={backRenderer}
          />
        ) : (
          <Flippable
            isFlipped={this.state.currentStep === STEPS.PRODUCT_REAR}
            ratio={productRatio}
            front={frontRenderer}
            back={backRenderer}
          />
        )}
        {isCanvas && (
          <div key="info-button-container" className="editor__info-button-container">
            <Button theme="aqua" priority="secondary" label="Info" onClick={this.showInfoModal} />
          </div>
        )}
      </MainContent>,
      helpText && (
        <div
          key="help-text"
          className="editor__help-text"
          dangerouslySetInnerHTML={{ __html: helpText }}
        />
      ),
      isPackMode && (
        <div class="editor__pack-mode-container">
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              <Select
              label='Pack Size'
              autoFocus={true}
              dropdownStyle={{fontSize: '13px', textAlign:'left', margin: '0px 3px'}}
              value={this.props.item.get("quantity")}
              defaultValue={this.props.item.get("quantity")}
              onChange={(value) => this.props.onChangePackQuantity(value)}>
                {packOptions.map(({packSize, price}, i) => 
                  (
                    <Option value={packSize}>
                      {`Pack of ${packSize} — `}
                      <Currency
                        amount={price}
                      />{" "}&nbsp;
                    </Option>
                  )
                )}
              </Select>
              &nbsp;
              <Popover content={whatArePacksContent} title="What are packs?" trigger="hover">
                <AntIcon
                  style={{fontSize: '18px', marginLeft: '6px'}}
                  type="info-circle"
                />
              </Popover>
            </Col>
          </Row>          
        </div>
      ),
      activeToolMarkup,
      <Footer key="footer">{footerContent}</Footer>,
    ];
  };

  renderRecipientDetailsStep = () => {
    const rendererProps = {
      debug: false,
      item: this.props.item.toJS(),
      activeTool: this.state.activeTool,
      selectedLayerId: this.state.selectedLayerId,
      selectedRegionIndex: this.state.selectedRegionIndex,
      onClearLayerSelection: this.handleClearLayerSelection,
      onSelectTextPlaceholderLayer: this.openAdvancedTextEditorForLayerId,
      onClickAddressLayer: this.showAddressModal,
    };

    //const image = preloadImage("/images/cardboard.jpg");

    const isCanvas = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
    const isPortraitProduct =
      this.props.item.getIn(["productDimensions", "width"]) <
      this.props.item.getIn(["productDimensions", "height"]);

    const address = this.props.item.get("address") || this.props.item.get("addressBookEntry");

    const isPackMode = this.props.item.get("packMode");
    const packQty = this.props.item.get('quantity');

    return [
      <MainContent scrollable={false} key="main">
        {isCanvas ? (
          <EditorCanvasPackage
            address={address && address.toJS()}
            onClickAddress={this.showAddressModal}
            aspectRatio={
              this.props.item.getIn(["productDimensions", "width"]) /
              this.props.item.getIn(["productDimensions", "height"])
            }
          />
        ) : (
          <React.Fragment>
            <div style={{ position: "relative", width: "100%" }}>
              {this.state.isAdditionalEnvelopeRequired && <div className="editor__extra-envelope" />}
              <HtmlRenderer
                page="ENVELOPE"
                isPortraitProduct={isPortraitProduct}
                {...rendererProps}
              />
            </div>
            {isPackMode && (
              <div className="editor__pack-details">
                {`This is where we will send all cards in your greeting card pack. Your shipment will include ${packQty} additional blank envelopes.`}
              </div>
            )}
          </React.Fragment>
        )}
      </MainContent>,
    ];
  };

  renderSelectPostDateStep = () => {
    const selectedDate = moment(this.props.item.get("postDate"));
    const destination =
      (this.props.item.get("address") && this.props.item.getIn(["address", "country"])) ||
      (this.props.item.get("addressBookEntry") &&
        this.props.item.getIn(["addressBookEntry", "country"]));
    const arrivalEstimates = getArrivalDatesForDestination(
      this.props.item.get("postDate"),
      destination
    );

    const isPackMode = this.props.item.get("packMode");

    return [
      <MainContent scrollable={false} key="main">
        <div style={{ maxWidth: "80%", margin: "0 auto", textAlign: "center" }}>
          <p className="muted">
            {isPackMode ? (
              `When should we post your cards?`
            ) : (
              `When should we post your card?`
            )}
          </p>
          <DatePicker
            onChange={this.handleDatePickerChange}
            withPortal
            minDate={getEarliestPostingDateForCurrentTime()}
            filterDate={this.filterDate}
            selected={selectedDate}
            locale="en-custom"
            customInput={<CustomDatepickerField />}
          />
          <br />
          <p className="muted">Estimated arrival between:</p>
          <div className="editor__arrival-estimation">
            <div className="date">
              <div className="date__month">
                {arrivalEstimates.earliestArrivalDate.format("MMM")}
              </div>
              <div className="date__day">{arrivalEstimates.earliestArrivalDate.format("D")}</div>
              <div className="date__day-of-week">
                {arrivalEstimates.earliestArrivalDate.format("dddd")}
              </div>
            </div>
            <div className="editor__arrival-estimation-divider">—</div>
            <div className="date">
              <div className="date__month">{arrivalEstimates.latestArrivalDate.format("MMM")}</div>
              <div className="date__day">{arrivalEstimates.latestArrivalDate.format("D")}</div>
              <div className="date__day-of-week">
                {arrivalEstimates.latestArrivalDate.format("dddd")}
              </div>
            </div>
          </div>
        </div>
      </MainContent>,
    ];
  };

  render() {
    let contentAfterHeader = [];
    let headerProps = this.getHeaderPropsForStep(this.state.currentStep);

    switch (this.state.currentStep) {
      case STEPS.PRODUCT_FRONT:
      case STEPS.PRODUCT_REAR:
        contentAfterHeader = this.renderFrontOrRearStep();
        break;
      case STEPS.RECIPIENT_DETAILS:
        contentAfterHeader = this.renderRecipientDetailsStep();
        break;
      case STEPS.SELECT_POST_DATE:
        contentAfterHeader = this.renderSelectPostDateStep();
        break;
      // no default
    }

    const activeLayer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);

    const activeRenderer =
      this.state.currentStep === STEPS.PRODUCT_FRONT ? this.frontRenderer : this.backRenderer;

    return [
      <SweetAlert key="alert" isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />,
      <Header key="header" {...headerProps} />,
      ...contentAfterHeader,
      <FullScreenLoader
        key="loader"
        isVisible={this.state.isLoading}
        message="Loading design..."
      />,
      <EditorTextEditorModal
        key="text-editor-modal"
        ref={el => (this.textEditor = el)}
        isOpen={this.state.isTextEditorVisible}
        scaleToDimensions={this.state.textEditorScalingDimensions}
        canvasDimensions={(activeRenderer && activeRenderer.getDimensions()) || {}}
        config={activeLayer && activeLayer.get("config").toJS()}
        onClose={this.closeTextEditor}
        onSave={this.saveTextEditorConfig}
      />,
      <EditorAddressInputModal
        key="address-input-modal"
        isDoubleDirect={
          this.props.item.get("productTypeId") !== PRODUCT_TYPE_IDS.POSTCARD &&
          this.props.item.get("productTypeId") !== PRODUCT_TYPE_IDS.CANVAS
        }
        isPackMode={this.props.item.get("packMode")}
        isOpen={this.state.isAddressModalVisible}
        mode={this.state.addressInputMode}
        initialFormData={
          this.props.item.get("address") ? this.props.item.get("address").toJS() : null
        }
        onCancel={this.closeAddressModal}
        onSaveNewAddress={this.handleAddressSave}
        onSaveOwnAddress={this.handleOwnAddressSave}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
      />,
    ];
  }
}
export default Editor;
