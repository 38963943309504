import { provincesByCountry, militaryStates } from "../constants/geo";

export default function generateMultilineAddress(address) {
  let addressLines;

  switch (address.country) {
    case "United Kingdom":
      addressLines = [
        [address.title, address.first_name || address.name, address.last_name]
          .filter(e => e)
          .join(" "),
        address.line1,
        address.line2,
        address.city,
        address.county,
        address.postcode,
        address.country,
      ];
      break;
    case "United States": {
      const shortStateCode = militaryStates
        .concat(provincesByCountry.US)
        .find(s => s.name === address.county);
      addressLines = [
        [address.title, address.first_name || address.name, address.last_name]
          .filter(e => e)
          .join(" "),
        address.line1,
        address.line2,
        `${address.city} ${shortStateCode ? shortStateCode.short : address.county} ${
          address.postcode
        }`,
        address.country,
      ];
      break;
    }
    default:
      addressLines = [
        [address.title, address.first_name || address.name, address.last_name]
          .filter(e => e)
          .join(" "),
        address.line1,
        address.line2,
        `${address.postcode} ${address.city}`,
        address.county,
        address.country,
      ];
      break;
  }

  return addressLines.filter(l => l);
}
