import { fromJS, Map } from "immutable";

const PRODUCTS_API_DATA = fromJS(require("../api-data/products"));
const PRODUCT_TYPE_AND_PRODUCT_IDS = require("../api-data/product-type-and-product-ids");

export const PACK_STEPS = [1, 8, 16, 24, 32, 40, 80];

export const productsByProductTypeId = PRODUCTS_API_DATA.flatMap(productType =>
  productType.get("products")
).groupBy(p => p.get("product_type_id"));

export const productsByProductId = new Map(
  PRODUCTS_API_DATA.flatMap(productType => productType.get("products")).map(v => [v.get("id"), v])
);

// values here must match the product type names!
export const productTypeAppKeys = {
  POSTCARD: "Postcards",
  ANNOUNCEMENT: "Announcement cards",
  GREETING_CARD: "Greeting cards",
  INVITATION: "Invitations",
  CANVAS: "Canvas",
  PHOTO_PRINT: "Photo Prints",
  PHOTO_MAGAZINE: "Photo Magazine",
};

export const PRODUCT_TYPE_URL_SLUGS = {
  POSTCARD: "postcards",
  ANNOUNCEMENT: "announcement-cards",
  GREETING_CARD: "greeting-cards",
  CANVAS: "canvas-prints",
  PHOTO_PRINT: "prints",
  PHOTO_MAGAZINE: "magazine",
};

export const PRODUCT_TYPE_IDS = Object.keys(productTypeAppKeys).reduce((map, key) => {
  const appKey = productTypeAppKeys[key];
  map[key] = PRODUCT_TYPE_AND_PRODUCT_IDS.productTypes[appKey];
  return map;
}, {});

export const PRODUCT_TYPE_IDS_BY_SLUG = {
  [PRODUCT_TYPE_URL_SLUGS.POSTCARD]: PRODUCT_TYPE_IDS.POSTCARD,
  [PRODUCT_TYPE_URL_SLUGS.ANNOUNCEMENT]: PRODUCT_TYPE_IDS.ANNOUNCEMENT,
  [PRODUCT_TYPE_URL_SLUGS.GREETING_CARD]: PRODUCT_TYPE_IDS.GREETING_CARD,
  [PRODUCT_TYPE_URL_SLUGS.INVITATION]: PRODUCT_TYPE_IDS.INVITATION,
  [PRODUCT_TYPE_URL_SLUGS.CANVAS]: PRODUCT_TYPE_IDS.CANVAS,
  [PRODUCT_TYPE_URL_SLUGS.PHOTO_PRINT]: PRODUCT_TYPE_IDS.PHOTO_PRINT,
  [PRODUCT_TYPE_URL_SLUGS.PHOTO_MAGAZINE]: PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
};

export function getProductforProductId(productId) {
  return Object.keys(PRODUCT_TYPE_AND_PRODUCT_IDS.products).find(
    key => PRODUCT_TYPE_AND_PRODUCT_IDS.products[key] === productId
  );
}

export function isPortraitProduct(productId) {
  return productsByProductId.getIn([productId, "isPortrait"]);
}

export function getNextIncreasedQuantityForProductType(productTypeId, quantity) {
  console.log(productTypeId, quantity);

  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return Math.min(25, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return Math.min(10, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
    case PRODUCT_TYPE_IDS.INVITATION: {
      if (quantity === 120) {
        return 120;
      }
      return PACK_STEPS[PACK_STEPS.indexOf(quantity) + 1];
    }
    // no default
  }
}

export function getNextDecreasedQuantityForProductType(productTypeId, quantity) {
  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
    case PRODUCT_TYPE_IDS.INVITATION: {
      return PACK_STEPS[PACK_STEPS.indexOf(quantity) - 1];
    }
    // no default
  }
}
