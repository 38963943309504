export default [
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 1,
      height: 1,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.5,
      height: 1,
    },
    {
      xOffset: 0.5,
      yOffset: 0,
      width: 0.5,
      height: 1,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 1,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 1,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.5,
      height: 1,
    },
    {
      xOffset: 0.5,
      yOffset: 0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0,
      width: 0.5,
      height: 1,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 1,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 1,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.34,
      height: 1,
    },
    {
      xOffset: 0.34,
      yOffset: 0,
      width: 0.33,
      height: 1,
    },
    {
      xOffset: 0.67,
      yOffset: 0,
      width: 0.33,
      height: 1,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 1.0,
      height: 0.333,
    },
    {
      xOffset: 0.0,
      yOffset: 0.333,
      width: 1,
      height: 0.333,
    },
    {
      xOffset: 0.0,
      yOffset: 0.666,
      width: 1.0,
      height: 0.334,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0.0,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.5,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
    {
      xOffset: 0.0,
      yOffset: 0.5,
      width: 0.5,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.66,
      height: 0.5,
    },
    {
      xOffset: 0.66,
      yOffset: 0,
      width: 0.34,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 0.34,
      height: 0.5,
    },
    {
      xOffset: 0.34,
      yOffset: 0.5,
      width: 0.66,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0,
      yOffset: 0,
      width: 0.34,
      height: 0.5,
    },
    {
      xOffset: 0.34,
      yOffset: 0,
      width: 0.66,
      height: 0.5,
    },
    {
      xOffset: 0,
      yOffset: 0.5,
      width: 0.66,
      height: 0.5,
    },
    {
      xOffset: 0.66,
      yOffset: 0.5,
      width: 0.34,
      height: 0.5,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 1.0,
      height: 0.33,
    },
    {
      xOffset: 0.0,
      yOffset: 0.33,
      width: 0.5,
      height: 0.34,
    },
    {
      xOffset: 0.5,
      yOffset: 0.33,
      width: 0.5,
      height: 0.34,
    },
    {
      xOffset: 0.0,
      yOffset: 0.67,
      width: 1.0,
      height: 0.33,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.25,
      height: 0.33,
    },
    {
      xOffset: 0.0,
      yOffset: 0.33,
      width: 0.25,
      height: 0.34,
    },
    {
      xOffset: 0.0,
      yOffset: 0.67,
      width: 0.25,
      height: 0.33,
    },
    {
      xOffset: 0.25,
      yOffset: 0.0,
      width: 0.75,
      height: 1.0,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.75,
      height: 1.0,
    },
    {
      xOffset: 0.75,
      yOffset: 0.0,
      width: 0.25,
      height: 0.33,
    },
    {
      xOffset: 0.75,
      yOffset: 0.33,
      width: 0.25,
      height: 0.34,
    },
    {
      xOffset: 0.75,
      yOffset: 0.67,
      width: 0.25,
      height: 0.33,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.33,
      height: 0.66,
    },
    {
      xOffset: 0.33,
      yOffset: 0.0,
      width: 0.34,
      height: 0.66,
    },
    {
      xOffset: 0.67,
      yOffset: 0.0,
      width: 0.33,
      height: 0.66,
    },
    {
      xOffset: 0.0,
      yOffset: 0.66,
      width: 1.0,
      height: 0.34,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 1.0,
      height: 0.34,
    },
    {
      xOffset: 0.0,
      yOffset: 0.34,
      width: 0.33,
      height: 0.66,
    },
    {
      xOffset: 0.33,
      yOffset: 0.34,
      width: 0.34,
      height: 0.66,
    },
    {
      xOffset: 0.67,
      yOffset: 0.34,
      width: 0.33,
      height: 0.66,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.33,
      height: 0.45,
    },
    {
      xOffset: 0.33,
      yOffset: 0.0,
      width: 0.34,
      height: 0.45,
    },
    {
      xOffset: 0.67,
      yOffset: 0.0,
      width: 0.33,
      height: 0.45,
    },
    {
      xOffset: 0.0,
      yOffset: 0.45,
      width: 0.5,
      height: 0.55,
    },
    {
      xOffset: 0.5,
      yOffset: 0.45,
      width: 0.5,
      height: 0.55,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.5,
      height: 0.55,
    },
    {
      xOffset: 0.5,
      yOffset: 0.0,
      width: 0.5,
      height: 0.55,
    },
    {
      xOffset: 0.0,
      yOffset: 0.55,
      width: 0.33,
      height: 0.45,
    },
    {
      xOffset: 0.33,
      yOffset: 0.55,
      width: 0.34,
      height: 0.45,
    },
    {
      xOffset: 0.67,
      yOffset: 0.55,
      width: 0.33,
      height: 0.45,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.7,
      height: 0.3,
    },
    {
      xOffset: 0.7,
      yOffset: 0.0,
      width: 0.3,
      height: 0.7,
    },
    {
      xOffset: 0.0,
      yOffset: 0.3,
      width: 0.3,
      height: 0.7,
    },
    {
      xOffset: 0.3,
      yOffset: 0.3,
      width: 0.4,
      height: 0.4,
    },
    {
      xOffset: 0.3,
      yOffset: 0.7,
      width: 0.7,
      height: 0.3,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.33,
      height: 0.67,
    },
    {
      xOffset: 0.33,
      yOffset: 0.0,
      width: 0.34,
      height: 0.33,
    },
    {
      xOffset: 0.67,
      yOffset: 0.0,
      width: 0.33,
      height: 0.33,
    },
    {
      xOffset: 0.0,
      yOffset: 0.67,
      width: 0.33,
      height: 0.33,
    },
    {
      xOffset: 0.33,
      yOffset: 0.33,
      width: 0.67,
      height: 0.67,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.67,
      height: 0.67,
    },
    {
      xOffset: 0.67,
      yOffset: 0.0,
      width: 0.33,
      height: 0.33,
    },
    {
      xOffset: 0.0,
      yOffset: 0.67,
      width: 0.33,
      height: 0.33,
    },
    {
      xOffset: 0.33,
      yOffset: 0.67,
      width: 0.34,
      height: 0.33,
    },
    {
      xOffset: 0.67,
      yOffset: 0.33,
      width: 0.33,
      height: 0.67,
    },
  ],
  [
    {
      xOffset: 0.0,
      yOffset: 0.0,
      width: 0.33,
      height: 0.5,
    },
    {
      xOffset: 0.33,
      yOffset: 0.0,
      width: 0.33,
      height: 0.5,
    },
    {
      xOffset: 0.66,
      yOffset: 0.0,
      width: 0.34,
      height: 0.5,
    },
    {
      xOffset: 0.0,
      yOffset: 0.5,
      width: 0.34,
      height: 0.5,
    },
    {
      xOffset: 0.33,
      yOffset: 0.5,
      width: 0.33,
      height: 0.5,
    },
    {
      xOffset: 0.66,
      yOffset: 0.5,
      width: 0.34,
      height: 0.5,
    },
  ],
];
