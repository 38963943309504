import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";

import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as designsData from "../../data/designs";
import { productsByProductId } from "../../data/products";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";

import PhotoMagazineEditorContainer from "../PhotoMagazineEditor/PhotoMagazineEditorContainer";
import Modal from "../Modal/Modal";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import SweetAlert from "../SweetAlert/SweetAlert";

import { Modal as AntModal } from 'antd';

const { confirm } = AntModal;

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;

  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

function getImagesMappedToItem(item, images) {
  return item.update("layers", layers => {
    return layers.map(layer => {
      const imageForPage = images.get(layer.get("page"));

      if (layer.get("type") === LAYER_TYPES.PHOTO) {
        return layer.setIn(["config", "layout", 0, "image"], imageForPage);
      }

      return layer;
    });
  });
}

class PhotoMagazinePreBagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      item: null,
      pageNumberToCrop: null,
      isUploadModalVisible: false,
      isCoverTextInputModalVisible: true,
      coverTextModalHasBeenAutoShown: false,
      isCancelConfirmationAlertVisible: false,
    };

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag) {
      this.state.item = fromJS(existingPrebag.data.item);
    }
  }

  componentDidMount() {
    if (!this.state.item) {
      this.generateItem();
    }
  }

  generateItem = async () => {
    const { productTypeId, productId } = this.props;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId)
      .sort((a, b) => a.page - b.page)
      .reverse();

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: "",
      postDate: moment(),
      duplicateAlertShown: false,
    });

    return this.setState({
      item: itemData,
    });
  };

  handleEditorUpdate = ({ item, images }) => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          item: getImagesMappedToItem(item, images),
        },
      })
    );
  };

  handleEditorSave = item => {
    this.setState(
      {
        item,
      },
      this.finalizePreBag
    );
  };

  finalizePreBag = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    this.props.addItemToBasket(this.state.item.toJS());
    this.props.navigateToBasket();
  };

  showCancelConfirmationAlert = () => {
    if (this.photoMagazineEditor.state.images.size > 0) {
      confirm({
        title: 'Are you sure?',
        content: 'You have unsaved changes, are you sure you want to go back?',
        okText: 'Stay',
        cancelText: 'Exit & discard changes',
        onOk: () => {
          this.closeCancelConfirmationAlert();
        },
        onCancel: () => {
          this.navigateBack();
        },
      });
      //this.setState({ isCancelConfirmationAlertVisible: true });
    } else {
      this.navigateBack();
    }
  };

  closeCancelConfirmationAlert = () => {
    this.setState({ isCancelConfirmationAlertVisible: false });
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  render() {
    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`You have unsaved changes, are you sure you want to go back?`}
        cancelButtonText="Exit & discard changes"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    return (
      <Modal key="photomagazine-prebag-modal" isOpen={true} hasHeader={false} animated={false}>
        <React.Fragment>
          {cancelConfirmationAlert}
          {this.state.item && (
            <PhotoMagazineEditorContainer
              ref={el => (this.photoMagazineEditor = el)}
              item={this.state.item}
              saveButtonText="Next"
              onCancel={this.showCancelConfirmationAlert}
              onUpdate={this.handleEditorUpdate}
              onSave={this.handleEditorSave}
              setGlobalProgressBarPercentage={this.props.setGlobalProgressBarPercentage}
              onChangeOwnAddress={this.props.setUserAddress}
            />
          )}
        </React.Fragment>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(PhotoMagazinePreBagContainer);
