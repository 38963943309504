import React from "react";
import PropTypes from "prop-types";

const S3Image = ({ storageKey, alt, className }) => (
  <img
    className={className}
    src={`${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${storageKey}`}
    alt={alt}
  />
);

S3Image.propTypes = {
  storageKey: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

S3Image.defaultProps = {
  alt: "",
};

export default S3Image;
