import React from "react";
import { connect } from "react-redux";

import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";

import OrderHistory from "./OrderHistory";

class OrderHistoryContainer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    props.fetchOrderHistory();
  }

  render() {
    return (
      <OrderHistory
        reference={this.props.match.params.reference}
        orderHistory={this.props.orderHistory}
      />
    );
  }
}

const mapStateToProps = state => ({
  orderHistory: authSelectors.getOrderHistory(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrderHistory: () => dispatch(authActions.fetchOrderHistory()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryContainer);
