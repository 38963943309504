import WebFont from "webfontloader";

const loadedFonts = [];

export default function loadFont(fontName) {
  // console.log('Loading font:', fontName);
  if (loadedFonts.includes(fontName)) {
    return Promise.resolve();
  }

  return new Promise(function(resolve, reject) {
    WebFont.load({
      custom: {
        families: [fontName],
      },
      active: () => {
        loadedFonts.push(fontName);
        resolve();
      },
      fontinactive: () => {
        console.warn("Could not load font:", fontName);
        reject();
      },
      inactive: () => {
        console.warn("Could not load font:", fontName);
        reject();
      },
    });
  });
}
