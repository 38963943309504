import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererIndiciaLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <HtmlRendererRect
        className="html-renderer-indicia-layer"
        rect={this.props.config.rect}
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/images/postsnap-postage-paid.png')`,
        }}
      ></HtmlRendererRect>
    );
  }
}

export default HtmlRendererIndiciaLayer;
