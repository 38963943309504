import PropTypes from "prop-types";
import { connect } from "react-redux";
import currencyFormatter from "currency-formatter";

import { selectors as basketSelectors } from "../../store/ducks/basket";

const Currency = ({ amount, currency }) => {
  return currencyFormatter.format(amount, { code: currency });
};

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Currency.defaultProps = {
  amount: 0,
};

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

export default connect(mapStateToProps)(Currency);
