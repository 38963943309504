import React from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import isEqual from "lodash/isEqual";

import 'sweetalert2/src/sweetalert2.scss'

import { PRIMARY_COLOR } from "../../constants/colors";

class SweetAlert extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(["warning", "error", "success", "info"]),
    text: PropTypes.string,
    html: PropTypes.string,
    title: PropTypes.string,
    allowEscapeKey: PropTypes.bool,
    allowOutsideClick: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    confirmButtonText: PropTypes.string,
    confirmButtonColor: PropTypes.string,
    cancelButtonText: PropTypes.string,
    animation: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    customClass: PropTypes.string,
  };

  static defaultProps = {
    allowEscapeKey: true,
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: "OK",
    confirmButtonColor: PRIMARY_COLOR,
    cancelButtonText: "Cancel",
    animation: true,
    title: "",
    html: null,
    customClass: null,
    onConfirm: () => {},
    onCancel: () => {},
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.renderAlert(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.isOpen && this.props.isOpen) ||
      (this.props.isOpen && !isEqual(prevProps, this.props))
    ) {
      this.renderAlert(this.props);
    }
  }

  renderAlert = props => {
    const options = {
      type: props.type,
      text: props.text,
      title: props.title,
      html: props.html ? props.html : props.text,
      allowEscapeKey: props.allowEscapeKey,
      allowOutsideClick: props.allowOutsideClick,
      showCancelButton: props.showCancelButton,
      showConfirmButton: props.showConfirmButton,
      confirmButtonText: props.confirmButtonText,
      confirmButtonColor: props.confirmButtonColor,
      cancelButtonText: props.cancelButtonText,
      animation: props.animation,
      customClass: props.customClass,
    };

    if (props.isOpen) {
      swal(options).then(props.onConfirm, props.onCancel);
    }
  };

  render() {
    return null;
  }
}

export default SweetAlert;
