import { connect } from "react-redux";

import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as basketActions } from "../../store/ducks/basket";
import CurrencySwitcher from "./CurrencySwitcher";

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  onChangeCurrency: currency => dispatch(basketActions.setCurrency(currency)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencySwitcher);
