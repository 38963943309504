import React from "react";

import MOCK_POSTCARD from "../../data/mock-postcard";
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";
import MainContent from "../../components/MainContent/MainContent";

const RENDER_SIZES_IN_VIEWPORT_PERCENTAGES = [100, 80, 60, 30, 20];

const RENDER_SIZES_IN_PIXELS = [1800, 1200, 600, 400, 200];

const RendererDebug = () => {
  // eslint-disable-next-line
  const rendersWithViewportPercentages = RENDER_SIZES_IN_VIEWPORT_PERCENTAGES.map(width => (
    <div>
      <HtmlRenderer
        key={width}
        page={0}
        item={MOCK_POSTCARD}
        width={(document.documentElement.offsetWidth - 60) * (width / 100)}
      />
      <br />
    </div>
  ));

  const rendersWithFixedWidths = RENDER_SIZES_IN_PIXELS.map(width => (
    <div>
      <HtmlRenderer key={width} page={0} item={MOCK_POSTCARD} width={width} />
      <br />
    </div>
  ));

  return (
    <MainContent scrollable padded>
      {rendersWithFixedWidths}
    </MainContent>
  );
};

RendererDebug.propTypes = {};

RendererDebug.defaultProps = {};

export default RendererDebug;
