import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";

import TruncatedText from "../Formatters/TruncatedText";
import Block from "../Block/Block";
import Grid from "../Grid/Grid";

import './AddressBook.scss';

const AddressBook = ({ entries, onClickEntry }) => {
  const sortedEntriesByFirstCharacter = entries
    .filter(entry => !entry.get("main"))
    .sortBy(entry =>
      entry
        .get("first_name")
        .charAt(0)
        .toUpperCase()
    )
    .groupBy(entry =>
      entry
        .get("first_name")
        .charAt(0)
        .toUpperCase()
    )
    .map(entries => entries.sortBy(entry => entry.get("first_name")))
    .toJS();

  const entrySections = Object.keys(sortedEntriesByFirstCharacter).map(character => {
    const entriesForCharacter = sortedEntriesByFirstCharacter[character];
    return [
      <div key="ab-char" className="address-book__character">
        {character}
      </div>,
      <div key="ab-entries">
        {entriesForCharacter.map(entry => (
          <Block key={entry.id} hasRightArrow onClick={() => onClickEntry(fromJS(entry))}>
            <Grid.Row>
              <Grid.Column>
                <TruncatedText width="35vw">
                  {entry.first_name} {entry.last_name}
                </TruncatedText>
              </Grid.Column>
              <Grid.Column>
                <TruncatedText width="40vw">
                  {entry.line1} {entry.line2}
                </TruncatedText>
              </Grid.Column>
            </Grid.Row>
          </Block>
        ))}
      </div>,
    ];
  });

  return <div className="address-book">{entrySections}</div>;
};

AddressBook.propTypes = {
  // entries: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number,
  //   first_name: PropTypes.string,
  //   last_name: PropTypes.string,
  //   line1: PropTypes.string,
  //   line2: PropTypes.string,
  // })),
  entries: PropTypes.object,
  onClickEntry: PropTypes.func,
};

AddressBook.defaultProps = {
  onClickEntry: () => {},
};

export default AddressBook;
