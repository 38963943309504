export default function stringToPatternOrFill(string) {
  if (string.includes("rgb(")) {
    return {
      type: "color",
      color: string,
    };
  } else {
    return {
      type: "image",
      src: string,
    };
  }
}
