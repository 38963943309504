const urlsAlreadyPreloaded = [];

export function preloadImage(url) {
  if (urlsAlreadyPreloaded.includes(url)) {
    //console.log("Image already preloaded, resolving right away");
    return Promise.resolve();
  }

  console.log("Preloading image:", url);
  return new Promise(function(resolve, reject) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      urlsAlreadyPreloaded.push(url);
      resolve();
    };
    img.onerror = reject;
  });
}
