import { fromJS } from "immutable";

const allPricingSchemes = fromJS(require("../api-data/pricing-schemes"));
const pricingSchemesByProductId = allPricingSchemes.groupBy(s => s.get("product_id"));

export function getPricingSchemeForProductAndQuantity({ productId, quantity, currency = "GBP" }) {
  const applicablePricingScheme = pricingSchemesByProductId.get(productId).find(scheme => {
    return (
      quantity >= (scheme.get("min_qty") || 0) && quantity <= (scheme.get("max_qty") || Infinity)
    );
  });

  if (applicablePricingScheme) {
    return applicablePricingScheme.withMutations(scheme => {
      scheme.set("cost", scheme.getIn(["cost", currency]));
    });
  }
}
