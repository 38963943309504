import * as STORAGE_KEYS from "../constants/storage-keys";

const storedDebugState = localStorage.getItem(STORAGE_KEYS.DEBUG_STATE)
  ? JSON.parse(localStorage.getItem(STORAGE_KEYS.DEBUG_STATE))
  : {};

export default function getStoreDebugStateOrDefaultState(stateSectionName, defaultState) {
  if (storedDebugState[stateSectionName]) {
    console.warn(`Using stored debug state for state.${stateSectionName}`);
  }
  return storedDebugState[stateSectionName] || defaultState;
}
