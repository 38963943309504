import React, { Component } from "react";
import PropTypes from "prop-types";

class AppValue extends Component {
  static propTypes = {
    title: PropTypes.string,
    htmlContent: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    if (this.props.title !== "About Us") {
      const doc = this.iframeElement.contentWindow.document;
      doc.open();
      doc.write(this.props.htmlContent);
      doc.close();
    }
    if (window.Trustpilot) {
      window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
    }
  }

  render() {
    const trustpilotUrl = "https://www.trustpilot.com/review/postsnap.com";

    const trustpilotCarousel = () => ({
      __html: `
         <div
         class='trustpilot-widget'
         data-locale='en-GB'
         data-template-id='54ad5defc6454f065c28af8b'
         data-businessunit-id='5af1b82a4bc191000118efad'
         data-style-height='240px'
         data-style-width='100%'
         data-theme="light"
         data-stars="4,5"
         data-schema-type="Organization">
           <a href=${trustpilotUrl} target='_blank'>Trustpilot</a>
         </div>
       `,
    });

    return (
      <div style={{ height: "100%", overflow: "scroll" }}>
        {this.props.title === "About Us" ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.props.htmlContent }} />
            <div
              style={{ marginTop: "20px" }}
              className="trustpilot-carousel"
              dangerouslySetInnerHTML={trustpilotCarousel()}
            />
          </div>
        ) : (
          <iframe
            ref={el => (this.iframeElement = el)}
            className="app-value-frame"
            src="about:blank"
            title={this.props.title}
          />
        )}
      </div>
    );
  }
}

export default AppValue;
