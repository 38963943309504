import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { actions as authActions } from "../../store/ducks/auth";
import ResetPassword from "./ResetPassword";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

class ResetPasswordContainer extends React.Component {
  state = {
    alert: null,
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  clearAlertAndRedirect = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        window.location.href = window.location.origin;
        //this.props.goToShop();
      }
    );
  };

  handleFormSubmit = newPassword => {
    this.props
      .resetPassword({
        password: newPassword,
        token: queryString.parse(window.location.search).token,
      })
      .then(result => {
        if (result.payload.data.success) {
          this.setState({
            alert: {
              type: "success",
              title: "Password reset",
              text: "Your password has been reset, and you can now log in.",
              showCancelButton: false,
              onConfirm: this.clearAlertAndRedirect, //window.location.href = window.location.origin;
              callback: () => {
                window.location.href = window.location.origin;
              },
            },
          });

          // this.props.showAlert({
          //   type: 'success',
          //   title: 'Password reset',
          //   text: 'Your password has been reset, and you can now log in.',
          //   callback: () => {
          //     window.location.href = window.location.origin;
          //   }
          // });
        } else {
          const errors = result.payload.data.errors;
          let title;
          let text;

          if (errors.reset_password_token) {
            title = "Invalid token";
            text =
              "This token is not valid. Please request another password reset to receive a token.";
          }

          this.setState({
            alert: {
              type: "error",
              title: title,
              text: text,
              showCancelButton: false,
              onConfirm: this.clearAlert,
            },
          });
        }
      });
  };

  render() {
    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <ResetPassword onSubmit={this.handleFormSubmit} />,
    ];
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: ({ password, token }) => dispatch(authActions.resetPassword({ password, token })),
});

export default connect(
  null,
  mapDispatchToProps
)(ResetPasswordContainer);
