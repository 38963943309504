import React from "react";
import PropTypes from "prop-types";

const SplitButton = ({ children }) => {
  return <div className="split-button">{children}</div>;
};

SplitButton.propTypes = {
  children: PropTypes.node.isRequired,
};

SplitButton.defaultProps = {};

export default SplitButton;
