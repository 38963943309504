import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";
import classNames from "classnames";
import queryString from "query-string";

import EditorContainer from "../../components/Editor/EditorContainer";
import Modal from "../../components/Modal/Modal";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import { routeCreators } from "../../lib/routes";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import * as LAYER_TYPES from "../../constants/layer-types";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import { PRODUCT_TYPE_IDS, productsByProductTypeId } from "../../data/products";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import * as designsData from "../../data/designs";
import { greetingCardCategories, greetingCardDesignsByCategoriesMap } from "../../data/designs";
import RatingStars from "../../components/RatingStars/RatingStars";
import Button from "../../components/Button/Button";
import sortBy from "lodash/sortBy";
import ModalPage from "../../components/Modal/ModalPage";
import Block from "../../components/Block/Block";
import Grid from "../../components/Grid/Grid";
import MainContent from "../../components/MainContent/MainContent";
import Footer from "../../components/Footer/Footer";

import "./GreetingCardEditorContainer.scss";

const GREETING_CARD_SELECTION_MODAL_PAGES = {
  CATEGORIES: "CATEGORIES",
  LIST: "LIST",
};

const orderedGreetingCardCategories = greetingCardCategories.filter(c => c.get('published')).sortBy(c => c.get("display_order"));

const DEFAULT_GREETING_CARD_CATEGORY =
  process.env.REACT_APP_DEFAULT_GREETING_CARD_CATEGORY || "Best Sellers";

const GreetingCardDesign = ({ image, rating, availableColors, onClick, noStars, noSwatches }) => (
  <div className="greeting-card-design" onClick={onClick}>
    <img className="greeting-card-design__preview" src={image} alt="" />
    {!noSwatches && (
      <div className="greeting-card-design__colors">
        {availableColors.map((color, index) => {
          if (color === "rgb(255,255,255)") {
            return (
              <div
                key={`${color}-${index}`}
                className="greeting-card-design__color greeting-card-design__color__with-border"
                style={{ backgroundColor: color }}
              />
            );
          } else {
            return (
              <div
                key={`${color}-${index}`}
                className="greeting-card-design__color"
                style={{ backgroundColor: color }}
              />
            );
          }
        })}
      </div>
    )}
    {!noStars && (
      <div className="greeting-card-design__rating">
        <RatingStars rating={!rating ? 4 : rating} />
      </div>
    )}
  </div>
);

class GreetingCardEditorContainer extends Component {
  state = {
    itemForEditor: null,
    isItemDuplicationAlertVisible: false,
    toast: null,
    editorItemLoaded: false,
    editorItemLoading: false,
    isApprovalModalVisible: false,
    approvalModalItemId: null,
    approvalModalImage: null,
    renderItemLoading: false,
    renderItemLoaded: false,
    isGreetingCardDesignSelectionModalVisible: false,
    designToPreview: null,
    selectedVariationIndex: 0,
    greetingCardDesignSelectionModalPage: GREETING_CARD_SELECTION_MODAL_PAGES.CATEGORIES,
    greetingCardCategoryId: greetingCardCategories
      .find(c => c.get("name") === DEFAULT_GREETING_CARD_CATEGORY)
      .get("id"),
    isLoading: false,
    closeURL: null,
  };

  componentWillMount() {
    if (this.props.designSlug) {
      const design = designsData.getDesignForDesignUrlSlug(this.props.designSlug);
      this.handleSelectGreetingCardDesign(design, 0);
      this.setState({
        ...this.defaultState,
        closeURL: document.referrer || "https://www.postsnap.com/greeting-cards",
      });
    } else {
      this.showGreetingCardDesignSelectionModal();
    }
  }

  showGreetingCardDesignSelectionModal = () => {
    this.setState({
      isGreetingCardDesignSelectionModalVisible: true,
    });
  };

  hideGreetingCardDesignSelectionModal = () => {
    this.setState(
      {
        isGreetingCardDesignSelectionModalVisible: false,
      },
      () => {
        this.closeEditor();
      }
    );
  };

  handlePreviewGreetingCardDesign = design => {
    this.setState({
      designToPreview: design,
      selectedVariationIndex: 0,
    });
  };

  showDesignSelectionModalPage = pageId => {
    this.setState({
      greetingCardDesignSelectionModalPage: pageId,
    });
  };

  selectGreetingCardCategory = id => {
    this.setState({
      greetingCardCategoryId: id,
      greetingCardDesignSelectionModalPage: GREETING_CARD_SELECTION_MODAL_PAGES.LIST,
    });
  };

  selectVariationIndex = index => {
    this.setState({
      selectedVariationIndex: index,
    });
  };

  setLoading = value => {
    this.setState({ isLoading: value });
  };

  customisePreviewedGreetingCard = () => {
    this.props.goToDesign(this.state.designToPreview.get("slug"));
  };

  closeEditor = (closeBehavior = {}) => {
    // TODO: extract this to ENV var
    console.log("Going back to", this.state.closeURL);
    if (this.state.closeURL) {
      window.location = this.state.closeURL;
    } else {
      window.history.back();
      //window.location = "https://www.post-snap.com/";
    }
  };

  handleSelectGreetingCardDesign = (design, selectedVariationIndex) => {
    const greetingCardProduct = productsByProductTypeId
      .get(PRODUCT_TYPE_IDS.GREETING_CARD)
      .find(p => p.get("id") === design.get("product_id"));
    this.setState({
      editorItemLoading: true,
    });

    this.setUpEditorItem({
      designId: design.get("id"),
      productTypeId: PRODUCT_TYPE_IDS.GREETING_CARD,
      product: greetingCardProduct,
      selectedVariationIndex,
    });
  };

  setUpEditorItem = async ({ product, productTypeId, designId, selectedVariationIndex }) => {
    const designDetail = await postsnapApi.designs.getDetails(designId);

    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    if (selectedVariationIndex !== undefined) {
      layers = layers.map(layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          return layer.setIn(
            ["config", "s3_key"],
            layer.getIn(["config", "s3_keys", selectedVariationIndex])
          );
        }

        if (layer.get("type") === LAYER_TYPES.TEXT) {
          return layer.setIn(
            ["config", "color"],
            layer.getIn(["config", "colors", selectedVariationIndex])
          );
        }

        return layer;
      });
    }

    const parsedQueryString = queryString.parse(window.location.search);

    let itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId,
      packMode: parsedQueryString.packMode === "true" || false,
      quantity: parseInt(parsedQueryString.qty) || 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      designOptions: designDetail.data.data.preview_s3_keys || [],
      postDate:
        productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD ? moment().add(1, "days") : moment(),
    });
    console.log('Pack Mode', itemData.get("packMode"));
    if (itemData.getIn(["pages", "envelope"])) {
      itemData = itemData.update("layers", layers =>
        layers.concat(
          fromJS([
            {
              config: {
                rect: {
                  x: 0.1,
                  y: 0.7,
                  width: 0.5,
                  height: 0.6,
                },
                font: "Anaheim Gothic",
                size: 10,
                color: "0,0,0",
              },
              id: "ENVELOPE_ADDRESS",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              zindex: null,
              type: "Layer::Address",
            },
            {
              config: {
                rect: {
                  x: 0.695,
                  y: 0.94,
                  width: 0.26,
                  height: 0.23,
                },
              },
              id: "ENVELOPE_STAMP",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              type: "Layer::Indicia",
            },
          ])
        )
      );
    }

    this.setState({
      itemForEditor: itemData,
      editorItemLoaded: true,
    });
  };

  handleSelectProductType = async productTypeId => {
    // this.setState({
    //   editorItemLoading: true,
    // });
    // Get the first product ID for this product type ID
    const firstProductForProductType = productsByProductTypeId.get(productTypeId).first();

    /**
     * Get the first design for that product (the first design is the _only_ design, except for greeting cards which
     * are handled in a separate method)
     */
    const firstDesignForProductId = designsData.designsByProductId
      .get(firstProductForProductType.get("id"))
      .first();
    this.setUpEditorItem({
      designId: firstDesignForProductId.get("id"),
      productTypeId: productTypeId,
      product: firstProductForProductType,
    });
  };

  handleAddToBasket = async item => {
    this.props.addBasketItem(item.toJS());
    this.props.goToBasket();
  };

  render() {
    let greetingCardSelectionModalProps = {};

    switch (this.state.greetingCardDesignSelectionModalPage) {
      case GREETING_CARD_SELECTION_MODAL_PAGES.CATEGORIES:
        greetingCardSelectionModalProps = {
          title: "Card Categories",
          padded: false,
        };
        break;
      case GREETING_CARD_SELECTION_MODAL_PAGES.LIST:
        greetingCardSelectionModalProps = {
          title: greetingCardCategories
            .find(c => c.get("id") === this.state.greetingCardCategoryId)
            .get("name"),
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() =>
                this.showDesignSelectionModalPage(GREETING_CARD_SELECTION_MODAL_PAGES.CATEGORIES)
              }
            />
          ),
          padded: true,
        };
        break;
      // no default
    }

    const greetingCardDesigns =
      greetingCardDesignsByCategoriesMap[this.state.greetingCardCategoryId];
    const sortedCardDesigns = sortBy(greetingCardDesigns, design => design.get("display_order"));

    const greetingCardDesignSelectionModal = (
      <Modal
        className="shop__design-selection-modal"
        isOpen={this.state.isGreetingCardDesignSelectionModalVisible}
        onClose={this.hideGreetingCardDesignSelectionModal}
        key="greeting-card-design-selection-modal"
        animated={false}
        paged
        activePage={this.state.greetingCardDesignSelectionModalPage}
        {...greetingCardSelectionModalProps}
      >
        <ModalPage pageId={GREETING_CARD_SELECTION_MODAL_PAGES.CATEGORIES} depth={1}>
          {orderedGreetingCardCategories.map(c => (
            <Block key={c.get("id")} onClick={() => this.selectGreetingCardCategory(c.get("id"))}>
              <Grid.Row>
                <Grid.Column size="1">
                  <span style={{ color: c.get("colour_string") }}>{c.get("name")}</span>
                </Grid.Column>
              </Grid.Row>
            </Block>
          ))}
        </ModalPage>
        <ModalPage pageId={GREETING_CARD_SELECTION_MODAL_PAGES.LIST} depth={2}>
          {sortedCardDesigns.map(design => (
            <GreetingCardDesign
              key={design.get("id")}
              image={generateS3AssetUrlFromKey(design.get("preview_s3_key"))}
              rating={design.getIn(["average_rating", "average"])}
              availableColors={design.get("variant_colours").map(c => c.get("rgb"))}
              onClick={() => this.handlePreviewGreetingCardDesign(design)}
            />
          ))}
        </ModalPage>
      </Modal>
    );

    const designPreviewModal = (
      <Modal
        className="shop__design-preview-modal"
        key="design-preview-modal"
        isOpen={this.state.designToPreview != null}
        onClose={() => this.setState({ designToPreview: null })}
        padded
      >
        {this.state.designToPreview && [
          <MainContent centeredVertically key="main-content">
            <img
              src={generateS3AssetUrlFromKey(
                this.state.designToPreview.getIn([
                  "large_variant_s3_keys",
                  this.state.selectedVariationIndex,
                ])
              )}
              alt=""
              style={{ alignSelf: "center", maxHeight: "75%" }}
              onClick={this.customisePreviewedGreetingCard}
            />
            <br />
            <div className="text-center" style={{ height: "45px" }}>
              {this.state.designToPreview.get("variant_colours").size > 1 &&
                this.state.designToPreview.get("variant_colours").map((color, index) => {
                  const classes = classNames("shop__design-preview-modal-color", {
                    "shop__design-preview-modal-color--active":
                      this.state.selectedVariationIndex === index,
                  });
                  return (
                    <div
                      key={color}
                      className={classes}
                      style={{ backgroundColor: color.get("rgb") }}
                      onClick={() => this.selectVariationIndex(index)}
                    />
                  );
                })}
            </div>
          </MainContent>,
          <Footer padded key="footer">
            <Button block label="Customise design" onClick={this.customisePreviewedGreetingCard} />
          </Footer>,
        ]}
      </Modal>
    );
    if (this.state.editorItemLoading && !this.state.editorItemLoaded) {
      return (
        <FullScreenLoader
          key="loader"
          message="Loading your card..."
          isVisible={this.state.editorItemLoading && !this.state.editorItemLoaded}
        />
      );
    }
    return [
      /* eslint-disable jsx-a11y/img-redundant-alt, jsx-a11y/alt-text*/
      greetingCardDesignSelectionModal,
      designPreviewModal,
      <Modal
        key="gc-editor-modal"
        isOpen={Boolean(this.state.itemForEditor)}
        onClose={this.closeEditor}
        hasHeader={false}
        animated={false}
      >
        <EditorContainer
          key="gc-editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemForEditor}
          onClose={this.closeEditor}
          onSave={this.handleAddToBasket}
          saveButtonLabel="Add to Basket"
        />
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  goToDesign: slug => dispatch(push(`/create/greeting-cards/${slug}`)),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GreetingCardEditorContainer);
