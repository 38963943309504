import React from "react";
import "offline-js/offline";
import "offline-js/themes/offline-language-english.css";
import "offline-js/themes/offline-theme-default.css";
//import 'offline-js/themes/offline-theme-chrome.css'

import './OfflineChecker.scss';

class OfflineChecker extends React.Component {
  componentDidMount() {
    // Use this url to check if internet connection is on (maybe create a specific endpoint for this)
    // const offlineCheckUrl = '/favicon.ico';
    window.Offline.options = {
      checkOnLoad: true,
      interceptRequests: true,
      reconnect: {
        initialDelay: 3,
      },
      requests: true,
    };
  }

  render() {
    return false;
  }
}

export default OfflineChecker;
