import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";

import './EditorCollageOption.scss';

const COLLAGE_LAYOUT_PREVIEW_HEIGHT = 40;

function getRectangleForRegion(region, regionIndex) {
  return (
    <rect
      key={regionIndex}
      x={`${region.xOffset * 100}%`}
      y={`${region.yOffset * 100}%`}
      width={`${region.width * 100}%`}
      height={`${region.height * 100}%`}
      rx={2}
      ry={2}
    />
  );
}

class EditorCollageOption extends React.Component {
  static propTypes = {
    ratio: PropTypes.number.isRequired,
    layout: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
  };

  static defaultProps = {
    ratio: 1,
    active: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: COLLAGE_LAYOUT_PREVIEW_HEIGHT * props.ratio,
      height: COLLAGE_LAYOUT_PREVIEW_HEIGHT,
    };
  }

  componentDidMount() {
    if (this.props.stretchToFit) {
      const parentWidth = ReactDOM.findDOMNode(this).parentNode.offsetWidth;
      const parentHeight = ReactDOM.findDOMNode(this).parentNode.offsetHeight;
      const newDimensions = {
        width: parentWidth,
        height: parentWidth / this.props.ratio,
      };

      if (newDimensions.height > parentHeight) {
        newDimensions.height = parentHeight;
        newDimensions.width = newDimensions.height * this.props.ratio;
      }

      this.setState(newDimensions);
    }
  }

  render() {
    const { layout, active, onClick } = this.props;
    const classes = classNames("editor__collage-option", {
      "editor__collage-option--active": active,
    });

    const buttonStyles = {
      ...this.state,
    };

    return (
      <button className={classes} onClick={onClick} style={buttonStyles}>
        <svg
          width={this.state.width}
          height={this.state.height}
          viewBox={`0 0 ${this.state.width} ${this.state.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {layout.map((region, regionIndex) => getRectangleForRegion(region, regionIndex))}
        </svg>
      </button>
    );
  }
}

export default EditorCollageOption;
