import React, { Component } from "react";
import PropTypes from "prop-types";
import chunk from "lodash/chunk";

import Grid from "../Grid/Grid";

class LocalUploadGrid extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.string),
    onSelectImage: PropTypes.func,
  };

  static defaultProps = {};

  render() {
    const imageChunks = chunk(this.props.images, 2);

    return imageChunks.map((images, index) => (
      <Grid.Row key={index}>
        {images.map(imageUrl => (
          <Grid.Column key={imageUrl}>
            <div style={{ textAlign: "center" }}>
              <img src={imageUrl} alt="" onClick={() => this.props.onSelectImage(imageUrl)} />
              <p>
                <small>{imageUrl}</small>
              </p>
            </div>
          </Grid.Column>
        ))}
      </Grid.Row>
    ));
  }
}

export default LocalUploadGrid;
