import React from "react";
import PropTypes from "prop-types";

import Grid from "../Grid/Grid";
import Button from "../Button/Button";

import './CookieWarning.scss';

const CookieWarning = ({ onDismiss }) => {
  if (navigator.userAgent === "ReactSnap") {
    console.log("React snap in cookie warning");
    return null;
  }
  return (
    <div className="animated fadeInUp" style={{ zIndex: 500 }} key="cookie-warning-container">
      <div className="cookie-warning" key="cookie-warning">
        <Grid.Row>
          <Grid.Column size="1" className="pr-default">
            <p>
              We use browser cookies to help you get the best out of our website. By using our
              website, you agree to the use of cookies.
              <br />
            </p>
          </Grid.Column>
          <Grid.Column size="0 auto">
            <Button label="Got it!" theme="muted" priority="secondary" onClick={onDismiss} />
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
};

CookieWarning.propTypes = {
  onDismiss: PropTypes.func,
};

CookieWarning.defaultProps = {};

export default CookieWarning;
