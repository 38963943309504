import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable";

//import * as smartyStreetsApi from '../../lib/apis/smartystreets';
import { countriesByCurrency } from "../../constants/geo";
//import { provincesByCountry, countriesByCurrency, militaryStates } from '../../constants/geo';
import { selectors as addressBookSelectors } from "../../store/ducks/address-book";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import AddressForm from "../Form/AddressForm";
import ModalPage from "../Modal/ModalPage";
import AddressBook from "../AddressBook/AddressBook";
import SplitButton from "../Button/SplitButton";
//import UKPostcodeLookUpAddressForm from "../Form/UKPostcodeLookUpAddressForm";
import SweetAlert from "../SweetAlert/SweetAlert";

//import { Button as AntButton } from 'antd';

const DEFAULT_COUNTRY = "United Kingdom";

const MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  ADDRESS_FORM: "ADDRESS_FORM",
  ADDRESS_BOOK: "ADDRESS_BOOK",
  ADD_OWN_ADDRESS: "ADD_OWN_ADDRESS",
};

const ACTIONS_AFTER_SIGNING_IN = {
  SEND_BACK_TO_ME: "SEND_BACK_TO_ME",
  OPEN_ADDRESS_BOOK: "OPEN_ADDRESS_BOOK",
};

class EditorAddressInputModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    onSaveNewAddress: PropTypes.func.isRequired,
    onSelectAddressBookEntry: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showAuthModal: PropTypes.func.isRequired,
    initialFormData: PropTypes.object,
    isDoubleDirect: PropTypes.bool.isRequired,
    isPackMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.defaultFormData = {
      country: countriesByCurrency[props.currency] || DEFAULT_COUNTRY,
    };

    this.state = {
      newAddressFormData: props.initialFormData || this.defaultFormData,
      ownAddressFormData: this.defaultFormData,
      activePage: MODAL_PAGES.OVERVIEW,
      loadingSendBackToMe: false,
      sendBackToMeIsDoubleDirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Reset the form when the modal appears, with either the initial form data provided in props, or the default form
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        newAddressFormData:
          this.props.mode === "edit" ? this.props.initialFormData : this.defaultFormData,
        activePage: this.props.mode === "edit" ? MODAL_PAGES.ADDRESS_FORM : MODAL_PAGES.OVERVIEW,
      });
    }

    if (!this.props.isSignedIn && this.props.isSignedIn && this.actionAfterSigningIn) {
      switch (this.actionAfterSigningIn) {
        case ACTIONS_AFTER_SIGNING_IN.SEND_BACK_TO_ME:
          if (this.props.user.get("address")) {
            this.handleSelectAddressBookEntry(this.props.user.get("address"));
          } else {
            this.setState({
              activePage: MODAL_PAGES.ADD_OWN_ADDRESS,
            });
          }
          break;
        // no default
        case ACTIONS_AFTER_SIGNING_IN.OPEN_ADDRESS_BOOK:
          this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
          break;
        // no default
      }

      this.actionAfterSigningIn = null;
    }
  }

  handleNewAddressFormChange = form => {
    this.setState({
      newAddressFormData: {
        ...this.state.newAddressFormData,
        ...form.formData,
      },
    });
  };

  handleNewAddressFormSubmit = async () => {
    const address = this.state.newAddressFormData;

    const existingAddressBookEntryForAddress = this.props.addressBookEntries.find(entry => {
      const PROPERTIES_TO_MATCH = [
        "county",
        "city",
        "postcode",
        "country",
        "first_name",
        "line1",
        "line2",
      ];

      return PROPERTIES_TO_MATCH.every(property => entry.get(property) === address[property]);
    });

    if (existingAddressBookEntryForAddress) {
      return this.handleSelectAddressBookEntry(existingAddressBookEntryForAddress);
    }

    if (address.country === "United States") {
      //const matchingState = militaryStates.concat(provincesByCountry['US']).find(p => p.name === address.county);

      //const stateCode = matchingState && matchingState.short;

      // Skip buggy address verification for now
      this.props.onSaveNewAddress(address);
      this.setActivePage(MODAL_PAGES.OVERVIEW);
      // try {
      //   await smartyStreetsApi.verifyAddress({ street: address.line1, city: address.city, state: stateCode });
      //   this.props.onSaveNewAddress(address);
      //   this.setActivePage(MODAL_PAGES.OVERVIEW);
      // } catch (err) {
      //   this.setState({
      //     alert: {
      //       title: 'Verification Unsuccessful',
      //       text: "We can't find that address in the local postal service's database. Would you like to use this address anyway?",
      //       confirmButtonText: 'Yes',
      //       onConfirm: () => {
      //         this.props.onSaveNewAddress(address);
      //         this.setActivePage(MODAL_PAGES.OVERVIEW);
      //         this.setState({ alert: null });
      //       },
      //       cancelButtonText: 'No',
      //       onCancel: () => this.setState({ alert: null })
      //     }
      //   });
      // }
    } else {
      this.props.onSaveNewAddress(address);
      this.setActivePage(MODAL_PAGES.OVERVIEW);
    }
  };

  handleOwnAddressFormChange = form => {
    this.setState({
      ownAddressFormData: {
        ...this.state.ownAddressFormData,
        ...form.formData,
      },
    });
  };

  handleOwnAddressFormSubmit = async () => {
    const address = this.state.ownAddressFormData;
    this.props.onSaveOwnAddress(address).then(this.handleClickSendBackToMe);
  };

  setActivePage = pageId => {
    this.setState({ activePage: pageId });
  };

  handleSelectAddressBookEntry = (entry, isDoubleDirect) => {
    this.props.onSelectAddressBookEntry(entry.get("id"), isDoubleDirect);
    this.setActivePage(MODAL_PAGES.OVERVIEW);
    this.setState({ loadingSendBackToMe: false });
  };

  handleClickSendBackToMe = ownAddressResponse => {
    if (this.props.isSignedIn) {
      if (this.props.user.get("address") || ownAddressResponse) {
        this.setState({ loadingSendBackToMe: true }, () => {
          this.handleSelectAddressBookEntry(
            this.props.user.get("address") || fromJS(ownAddressResponse.payload.data.data),
            this.props.isDoubleDirect
          );
        });
      } else {
        this.setState({
          activePage: MODAL_PAGES.ADD_OWN_ADDRESS,
        });
      }
    } else {
      this.props.showAuthModal();
      this.actionAfterSigningIn = ACTIONS_AFTER_SIGNING_IN.SEND_BACK_TO_ME;
    }
  };

  handleClickAddressBook = () => {
    if (this.props.isSignedIn) {
      this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
    } else {
      this.props.showAuthModal();
      this.actionAfterSigningIn = ACTIONS_AFTER_SIGNING_IN.OPEN_ADDRESS_BOOK;
    }
  };

  handleClickOwnCountryTab = () => {
    this.setState({
      newAddressFormData: {
        ...this.state.newAddressFormData,
        country: this.defaultFormData.country,
      },
    });
  };

  handleClickWorldTab = () => {
    switch (this.state.newAddressFormData.country) {
      case "United States":
        this.setState({
          newAddressFormData: {
            ...this.state.newAddressFormData,
            country: "United Kingdom",
          },
        });
        break;
      default:
        this.setState({
          newAddressFormData: {
            ...this.state.newAddressFormData,
            country: "United States",
          },
        });
        break;
    }
  };

  render() {
    let headerProps = {};

    switch (this.state.activePage) {
      case MODAL_PAGES.OVERVIEW:
        headerProps = {
          title: "Recipient Address",
        };
        break;
      case MODAL_PAGES.ADDRESS_BOOK:
        headerProps = {
          title: "Choose Address",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      case MODAL_PAGES.ADDRESS_FORM:
        headerProps = {
          title: "New Address",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            <Button
              label={this.state.newAddressFormData.country === "United States" ? "Save" : "Save"}
              priority="tertiary"
              theme="default"
              onClick={() => this.newAddressForm && this.newAddressForm.submitForm()}
              disabled={
                this.state.newAddressFormData.country === "United Kingdom" &&
                !this.state.newAddressFormData.line1
              }
            />
          ),
        };
        break;
      case MODAL_PAGES.ADD_OWN_ADDRESS:
        headerProps = {
          title: "Add Your Address",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            <Button
              label={this.state.ownAddressFormData.country === "United States" ? "Save" : "Save"}
              priority="tertiary"
              theme="default"
              onClick={() => this.ownAddressForm && this.ownAddressForm.submitForm()}
              disabled={
                this.state.ownAddressFormData.country === "United Kingdom" &&
                !this.state.ownAddressFormData.line1
              }
            />
          ),
        };
        break;
      // no default
    }

    const isOnOwnCountry =
      this.state.newAddressFormData.country === countriesByCurrency[this.props.currency];
    let ownCountryLabel = this.defaultFormData.country;
    const shouldShowUKPostcodeLookupForm =
      isOnOwnCountry && this.state.newAddressFormData.country === "United Kingdom";

    switch (this.defaultFormData.country) {
      case "United Kingdom":
        ownCountryLabel = "UK";
        break;
      case "United States":
        ownCountryLabel = "USA";
        break;
      // no default
    }

    return [
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="address-alert"
      />,
      <Modal
        key="address-input-modal"
        isOpen={this.props.isOpen}
        onClose={this.props.onCancel}
        padded
        paged
        activePage={this.state.activePage}
        {...headerProps}
      >
        <ModalPage pageId={MODAL_PAGES.OVERVIEW} depth={1}>
          {/* {this.props.isPackMode && (
            <React.Fragment>
              <AntButton
              type="primary"
              block
              loading={this.state.loadingSendBackToMe}
              onClick={() => this.handleClickSendBackToMe()}
            >
              Send Back To Me With Envelopes
            </AntButton>
              <br/>
            </React.Fragment>
          )}
          <AntButton
            type="primary"
            block
            onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
          >
            New Name & Address
          </AntButton>
          <br />
          <AntButton
            type="primary"
            block
            onClick={this.handleClickAddressBook}
          >
            PostSnap Address Book
          </AntButton>
          <br />
          {!this.props.isPackMode && (
            <AntButton
              type="primary"
              block
              loading={this.state.loadingSendBackToMe}
              onClick={() => this.handleClickSendBackToMe()}
            >
              {this.props.isDoubleDirect
                  ? "Send Back To Me With An Extra Envelope"
                  : "Send Back To Me"
              }
            </AntButton>
          )} */}
          {this.props.isPackMode && (
            <Button
              block
              priority="secondary"
              label="Send back to me with additional envelopes"
              loadingLabel="Fetching Your Address..."
              loading={this.state.loadingSendBackToMe}
              onClick={() => this.handleClickSendBackToMe()}
            />
          )}
          <Button
            block
            priority="secondary"
            label="New Name & Address"
            onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
          />
          <Button
            block
            priority="secondary"
            label="Postsnap Address Book"
            onClick={this.handleClickAddressBook}
          />
          {!this.props.isPackMode && (
            <Button
              block
              label={
                this.props.isDoubleDirect
                  ? "Send Back To Me With An Extra Envelope"
                  : "Send Back To Me"
              }
              priority="secondary"
              loadingLabel="Fetching Your Address..."
              loading={this.state.loadingSendBackToMe}
              onClick={() => this.handleClickSendBackToMe()}
            />
          )}
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_BOOK} depth={2}>
          <div style={{ margin: "0 -16px" }}>
            <AddressBook
              entries={this.props.addressBookEntries}
              onClickEntry={this.handleSelectAddressBookEntry}
            />
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_FORM} depth={2}>
          <div className="text-center">
            <SplitButton>
              <Button
                priority={isOnOwnCountry ? "primary" : "secondary"}
                label={ownCountryLabel}
                onClick={this.handleClickOwnCountryTab}
              />
              <Button
                priority={!isOnOwnCountry ? "primary" : "secondary"}
                label="World"
                onClick={this.handleClickWorldTab}
              />
            </SplitButton>
          </div>
          {shouldShowUKPostcodeLookupForm ? (
            // <UKPostcodeLookUpAddressForm
            //   ref={el => (this.newAddressForm = el)}
            //   formData={this.state.newAddressFormData}
            //   onChange={this.handleNewAddressFormChange}
            //   onSubmit={this.handleNewAddressFormSubmit}
            // />
            <AddressForm
              key="own-country-address-form"
              ref={el => (this.newAddressForm = el)}
              formData={this.state.newAddressFormData}
              onChange={this.handleNewAddressFormChange}
              onSubmit={this.handleNewAddressFormSubmit}
              showCountry={false}
            />
          ) : (
            <AddressForm
              key="other-country-address-form"
              ref={el => (this.newAddressForm = el)}
              formData={this.state.newAddressFormData}
              onChange={this.handleNewAddressFormChange}
              onSubmit={this.handleNewAddressFormSubmit}
              showCountry={true}
            />
          )}
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADD_OWN_ADDRESS} depth={2}>
          <div className="text-center">
            <SplitButton>
              <Button
                priority={isOnOwnCountry ? "primary" : "secondary"}
                label={ownCountryLabel}
                onClick={this.handleClickOwnCountryTab}
              />
              <Button
                priority={!isOnOwnCountry ? "primary" : "secondary"}
                label="World"
                onClick={this.handleClickWorldTab}
              />
            </SplitButton>
          </div>
          {shouldShowUKPostcodeLookupForm ? (
            <AddressForm
            key="own-country-address-form"
            ref={el => (this.ownAddressForm = el)}
            formData={this.state.ownAddressFormData}
            onChange={this.handleOwnAddressFormChange}
            onSubmit={this.handleOwnAddressFormSubmit}
            showCountry={false}
            />
            // <UKPostcodeLookUpAddressForm
            //   ref={el => (this.ownAddressForm = el)}
            //   formData={this.state.ownAddressFormData}
            //   onChange={this.handleOwnAddressFormChange}
            //   onSubmit={this.handleOwnAddressFormSubmit}
            // />
          ) : (
            <AddressForm
              ref={el => (this.ownAddressForm = el)}
              formData={this.state.ownAddressFormData}
              onChange={this.handleOwnAddressFormChange}
              onSubmit={this.handleOwnAddressFormSubmit}
            />
          )}
        </ModalPage>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  addressBookEntries: addressBookSelectors.getAllEntries(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAddressInputModal);
