import { types as userTypes } from "../ducks/auth";
import { types as editorTypes } from "../ducks/editor";

import * as STORAGE_KEYS from "../../constants/storage-keys";

export default store => next => action => {
  try {
    // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
    // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
    // to avoid the entire page breaking, without having to do a check at each usage of Storage.
    if (typeof localStorage === "object") {
      // Safari will throw a fit if we try to use localStorage.setItem in private browsing mode.
      try {
        localStorage.setItem("localStorage", 1);
        localStorage.removeItem("localStorage");
      } catch (e) {
        Storage.prototype._setItem = Storage.prototype.setItem;
        Storage.prototype.setItem = function() {};
        alert(
          'Your web browser does not appear to support storing data and settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some data may not save or some features may not work properly for you. Try again using "Normal Browsing Mode" if you experience any problems.'
        );
      }
    }

    const result = next(action);

    const { type, payload } = action;

    switch (type) {
      case userTypes.SIGN_IN:
      case userTypes.FETCH_USER_DATA_WITH_AUTH_TOKEN:
        if (payload && payload.data && payload.data.success) {
          localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, payload.data.data.auth_token);
          localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(payload.data.data.customer));
        }
        break;
      case userTypes.SIGN_OUT:
        localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
        localStorage.removeItem(STORAGE_KEYS.USER);
        localStorage.removeItem(STORAGE_KEYS.BASKET);
        break;
      // no default
    }

    // Save basket items to localStorage
    if (action.type.includes("POSTSNAP/BASKET/")) {
      const basketStateToSave = store.getState().basket;
      basketStateToSave.update("items", items => items.map(item => item.set("rendering", false)));
      localStorage.setItem(STORAGE_KEYS.BASKET, JSON.stringify(basketStateToSave.toJS()));
    }

    // Save WIP editor item to localStorage
    const shouldSave =
      action.type.includes("POSTSNAP/EDITOR/") && action.type !== "POSTSNAP/EDITOR/SET_EDITOR_ITEM";
    const shouldDelete = action.type === editorTypes.CLEAR_EDITOR_ITEM;
    if (shouldSave && !shouldDelete) {
      const state = store.getState();
      const editorSourcePage = state.router.location.pathname;
      const itemToSave = store
        .getState()
        .editor.get("item")
        .toJS();
      localStorage.setItem(
        STORAGE_KEYS.WIP_EDITOR_ITEM,
        JSON.stringify({ source: editorSourcePage, item: itemToSave })
      );
    }

    if (shouldDelete) {
      localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    }

    return result;
  } catch (err) {
    console.error(err);
  }
};
