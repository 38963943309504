import React from "react";

import './GlobalProgressBar.scss';

const GlobalProgressBar = ({ percentage }) => {
  const containerStyles = {
    display: percentage === 100 ? "none" : "block",
  };
  const trackStyles = {
    width: `${percentage}%`,
  };

  return (
    <div className="global-progress-bar" style={containerStyles}>
      <div className="global-progress-bar__track" style={trackStyles} />
    </div>
  );
};

GlobalProgressBar.propTypes = {
  percentage: (props, propName) => {
    const value = props[propName];
    if (isNaN(value)) {
      return new Error(`${propName} in GlobalProgressBar should be a number.`);
    }

    if (value < 0 || value > 100) {
      return new Error(`${propName} in GlobalProgressBar should be a number between 0 and 100.`);
    }

    return null;
  },
};

GlobalProgressBar.defaultProps = {};

export default GlobalProgressBar;
