import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";

import Icon from "../Icon/Icon";

import './Button.scss';

const Button = ({
  label,
  loadingLabel,
  loading,
  loadingProgress,
  disabled,
  icon,
  iconPosition,
  theme,
  priority,
  block,
  className,
  type,
  link,
  onClick,
  buttonRef,
  circle,
  size,
  ...rest
}) => {
  const classes = classNames(
    "btn",
    {
      "btn--block": block,
      "btn--circle": circle,
      "btn--only-icon": !label && icon,
      [`btn--icon-position-${iconPosition}`]: icon && iconPosition,
    },
    `btn--${theme}`,
    `btn--${priority}`,
    `btn--size-${size}`,
    className
  );

  const labelText = loading ? loadingLabel || label : label;
  let buttonContents = [
    icon && (
      <div key="icon" className="btn__icon">
        <Icon name={icon} />
      </div>
    ),
    labelText && (
      <div key="label" className="btn__label">
        {labelText}
      </div>
    ),
  ];

  if (iconPosition === "right") {
    buttonContents.reverse();
  }

  if (link) {
    if (/^https?:\/\//.test(link)) {
      return (
        <a className={classes} href={link} target="_blank" rel="noopener noreferrer" {...rest}>
          {buttonContents}
        </a>
      );
    } else {
      return (
        <Link className={classes} to={link} {...rest}>
          {buttonContents}
        </Link>
      );
    }
  } else {
    return (
      <LaddaButton
        disabled={disabled}
        progress={loadingProgress}
        type={type}
        className={classes}
        data-style={EXPAND_RIGHT}
        loading={loading}
        onClick={onClick}
        ref={buttonRef}
        {...rest}
      >
        {buttonContents}
      </LaddaButton>
    );
  }
};

Button.propTypes = {
  /**
   * The text that appears in the button.
   */
  label: PropTypes.string,
  /**
   * The text that appears in the button when `loading` is truthy.
   */
  loadingLabel: PropTypes.string,
  /**
   * An internal link for the app. The button will be a react-router-dom `<Link />` instead of a `<button />`.
   */
  link: PropTypes.string,
  /**
   * Used to determine the icon's position relative to the button label.
   */
  iconPosition: PropTypes.oneOf(["left", "right"]),
  /**
   * Used to indicate a loading state for the button, will show a spinner and change the label to `loadingLabel`.
   */
  loading: PropTypes.bool,
  theme: PropTypes.oneOf(["default", "muted", "success", "aqua", "dark-blue"]).isRequired,
  priority: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
  type: PropTypes.oneOf(["submit", "button"]).isRequired,
  size: PropTypes.oneOf(["default", "small"]).isRequired,
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  theme: "default",
  size: "default",
  priority: "primary",
  block: false,
  type: "button",
  iconPosition: "left",
};

export default Button;
