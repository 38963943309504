import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './Grid.scss';

const Row = ({ children, alignVertically, alignHorizontally }) => {
  const classes = classNames("grid-row", {
    "grid-row--align-vertically": alignVertically,
    "grid-row--align-horizontally": alignHorizontally,
  });

  return <div className={classes}>{children}</div>;
};

Row.propTypes = {
  alignVertically: PropTypes.bool.isRequired,
  alignHorizontally: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  alignVertically: false,
  alignHorizontally: false,
};

const Column = ({ children, size, className, centerContent }) => {
  const classes = classNames(
    "grid-column",
    { "grid-column--centered-content": centerContent },
    className
  );

  return (
    <div className={classes} style={{ flex: size }}>
      {children}
    </div>
  );
};

Column.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Column.defaultProps = {
  size: 1,
};

export default {
  Row,
  Column,
};
