import React, { Component } from "react";
import Loader from "react-loader";

import { PRIMARY_COLOR } from "../../constants/colors";
import Block from "../../components/Block/Block";
import Currency from "../../components/Formatters/Currency";
import Date from "../../components/Formatters/Date";

import Header from "../../components/Header/Header";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import { routeCreators } from "../../lib/routes";

class TopUpHistory extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.props.fetchTopUpHistory();
  }

  render() {
    if (this.props.topUpHistory.get("loading")) {
      return <Loader color={PRIMARY_COLOR} />;
    }

    const topUpEntries = this.props.topUpHistory.get("data").map(topUp => (
      <Block key={topUp.get("id")}>
        <table>
          <tbody>
            <tr>
              <td>
                <Date date={topUp.get("created_at")} format="DD/MM/YYYY, HH:MM" />
              </td>
              <td className="text-right">
                Credit: <Currency amount={topUp.get("value")} />
              </td>
            </tr>
          </tbody>
        </table>
      </Block>
    ));

    return (
      <MainContent scrollable padded={false} key="main-content">
        <Header
          title="Transaction History"
          leftAction={
            <Button label="Back" link={routeCreators.prepay()} priority="tertiary" theme="muted" />
          }
        />
        {topUpEntries}
      </MainContent>
    );
  }
}

export default TopUpHistory;
