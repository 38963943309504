import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import './PayPalButton.scss';

class PayPalButton extends React.Component {
  static propTypes = {
    amount: PropTypes.number,
    onAuthorize: PropTypes.func,
    disabled: PropTypes.bool,
    description: PropTypes.string,
  };

  static defaultProps = {};

  componentDidMount() {
    const $node = ReactDOM.findDOMNode(this);
    const isSandbox = process.env.NODE_ENV !== "production";

    window.paypal.Button.render(
      {
        client: {
          [isSandbox ? "sandbox" : "production"]: isSandbox
            ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
            : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY,
        },
        style: {
          label: "pay",
          size: "responsive",
          shape: "rect",
          color: "blue",
        },
        env: isSandbox ? "sandbox" : "production",
        payment: (data, actions) => {
          return actions.payment.create({
            payment: {
              transactions: [
                {
                  amount: {
                    total: this.props.amount.toString(),
                    currency: this.props.currency,
                  },
                  description: this.props.description || "PostSnap",
                },
              ],
            },
            experience: {
              input_fields: {
                no_shipping: 1,
              },
            },
          });
        },
        commit: true,
        onAuthorize: (data, actions) => {
          console.log("Authorized PayPal payment");
          return actions.payment.execute().then(response => {
            this.props.onAuthorize(response);
          });
        },
      },
      $node
    );
  }

  render() {
    return (
      <div>
        {this.props.disabled === true ? (
          <div className="paypal-wait"> Please wait while we confirm your PayPal payment </div>
        ) : (
          <div key="paypal-mount" ref={this.payPalButtonMountPoint} />
        )}
      </div>
    );
  }
}

export default PayPalButton;
