import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './EditorFontOption.scss';

const EditorFontOption = ({ label, fontName, active, onClick }) => {
  const style = {
    fontFamily: fontName,
  };

  const classes = classNames("editor__font-option animated fadeIn", {
    "editor__font-option--active": active,
  });

  return (
    <button className={classes} style={style} onClick={onClick}>
      {label}
    </button>
  );
};

EditorFontOption.propTypes = {
  label: PropTypes.string.isRequired,
  fontName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

EditorFontOption.defaultProps = {};

export default EditorFontOption;
