import React, { useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import containsEmoji from "../../lib/contains-emoji";

import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Footer from "../Footer/Footer";
import Grid from "../Grid/Grid";
import * as LAYER_TYPES from "../../constants/layer-types";
import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { Popconfirm, Icon as AntIcon, Modal as AntModal, Form, Input } from 'antd';

import './PhotoMagazineEditor.scss';

const MAGAZINE_TOTAL_PAGES = 23;
const COVER_TEXT_LINE_MAX_LENGTH = 40;

const PhotoMagazineEditor = ({
  product,
  item,
  saveButtonText,
  coverTextModalOpen,
  onCoverTextModalAutoShown,
  onCancel,
  onStartUpload,
  onClickSave,
  onClickPage,
  onChangeCoverText,
  onSwapImages,
  onDeleteImage,
}) => {
  const [isCoverTextInputModalVisible, setIsCoverTextInputModalVisible] = useState(false);
  const [isEmojiWarningVisible, setIsEmojiWarningVisible] = useState(false);
  const [isDeleteConfirmationAlertVisible, setDeleteConfirmationAlertVisible] = useState(false);
  const [deleteConfirmationAlertVisibleForIndex, setDeleteConfirmationAlertVisibleForIndex] = useState(null);
  const [coverTextDraft, setCoverTextDraft] = useState({
    firstLine: item.get("product_options").split("\n")[0],
    secondLine: item.get("product_options").split("\n")[1],
  });

  const totalPagesRequired = product.get("pages") || MAGAZINE_TOTAL_PAGES;

  const layersWithPhotos =
    item &&
    item
      .get("layers")
      .filter(
        layer =>
          layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
      );

  const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
  const pagesRemaing = totalPagesRequired - pagesAdded;

  const doAllPagesHaveAPhoto = layersWithPhotos && pagesAdded === totalPagesRequired;

  const handleDragEnd = result => {
    onSwapImages(result.source.index, result.destination.index);
  };

  const handleClickDelete = (index) => {
    setDeleteConfirmationAlertVisible(!isDeleteConfirmationAlertVisible);
    setDeleteConfirmationAlertVisibleForIndex(index);
  };

  const handleClickDeleteCancel = (index) => {
    setDeleteConfirmationAlertVisible(false);
    setDeleteConfirmationAlertVisibleForIndex(null);
  }

  const handleClickDeleteConfirm = (page) => {
    setDeleteConfirmationAlertVisible(false);
    setDeleteConfirmationAlertVisibleForIndex(null);
    onDeleteImage(page)
  }

  const handleClickEditCoverText = () => {
    setCoverTextDraft({
      firstLine: item.get("product_options").split("\n")[0],
      secondLine: item.get("product_options").split("\n")[1],
    });
    setIsCoverTextInputModalVisible(true);
  };

  const handleCoverTextDraftChange = (property, value) => {
    if (containsEmoji(value)) {
      setIsEmojiWarningVisible(true);
    } else {
      setCoverTextDraft({
        ...coverTextDraft,
        [property]: value.slice(0, COVER_TEXT_LINE_MAX_LENGTH),
      });
    }
  };

  const handleCoverTextFormSubmit = e => {
    e.preventDefault();
    onChangeCoverText([coverTextDraft.firstLine, coverTextDraft.secondLine].join("\n"));
    setIsCoverTextInputModalVisible(false);
  };

  const hideCoverTextInputModal = () => {
    setIsCoverTextInputModalVisible(false);
    onCoverTextModalAutoShown();
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // style page row if dragging
    border: isDragging ? "1px solid #f8f8f8" : "none",
    boxShadow: isDragging ? "1px 1px 11px 0px #ccc" : "none",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  

  const coverTextModal = (
    <AntModal
      visible={isCoverTextInputModalVisible || coverTextModalOpen}
      title={<span style={{textAlign:'center', display:'block'}}>Add your cover text</span>}
      okText="Save"
      onCancel={hideCoverTextInputModal}
      onOk={handleCoverTextFormSubmit}
      okButtonProps={{style: {backgroundColor: '#49bda1', borderColor: '#49bda1'}}}
    > 
      <Form onSubmit={handleCoverTextFormSubmit} className="coverText-form">
        {isEmojiWarningVisible && (
          <div className="form-group field">
            <p className="help-text text-center">
              Sorry, we don't currently support <br /> the printing of Emoji 🙈
            </p>
            <br/>
          </div>
        )}
        <Form.Item>
          <Input
            placeholder="Place"
            value={coverTextDraft.firstLine}
            onChange={e => handleCoverTextDraftChange("firstLine", e.target.value)}
            style={{ textAlign: "center" }}
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder="Date"
            value={coverTextDraft.secondLine}
            onChange={e => handleCoverTextDraftChange("secondLine", e.target.value)}
            style={{ textAlign: "center" }}
            size="large"
          />
        </Form.Item>
      </Form>
    </AntModal>
  )

  // const coverTextInputModal = (
  //   <Modal
  //     padded
  //     minimal
  //     animated
  //     title="Add Cover Text"
  //     leftAction={<span></span>}
  //     rightAction={null}
  //     isOpen={isCoverTextInputModalVisible || coverTextModalOpen}
  //     onClose={hideCoverTextInputModal}
  //   >
  //     <form className="form" onSubmit={handleCoverTextFormSubmit}>
  //       {isEmojiWarningVisible && (
  //         <div className="form-group field">
  //           <p className="help-text text-center">
  //             Sorry, we don't currently support <br /> the printing of Emoji 🙈
  //           </p>
  //         </div>
  //       )}
  //       <div className="form-group field">
  //         <input
  //           type="text"
  //           className="form-control"
  //           placeholder={"Place"}
  //           style={{ textAlign: "center" }}
  //           value={coverTextDraft.firstLine}
  //           onChange={e => handleCoverTextDraftChange("firstLine", e.target.value)}
  //         />
  //       </div>
  //       <div className="form-group field">
  //         <input
  //           type="text"
  //           className="form-control"
  //           placeholder={"Date"}
  //           style={{ textAlign: "center" }}
  //           value={coverTextDraft.secondLine}
  //           onChange={e => handleCoverTextDraftChange("secondLine", e.target.value)}
  //         />
  //       </div>
  //       <Grid.Row>
  //         <Grid.Column>
  //           <div style={{ paddingRight: "0.5rem " }}>
  //             <Button block label="Cancel" theme="dark-blue" onClick={hideCoverTextInputModal} />
  //           </div>
  //         </Grid.Column>
  //         <Grid.Column>
  //           <div style={{ paddingLeft: "0.5rem " }}>
  //             <Button block label="Save" type="submit" theme="success" />
  //           </div>
  //         </Grid.Column>
  //       </Grid.Row>
  //     </form>
  //   </Modal>
  // );

  return (
    <React.Fragment>
      {coverTextModal}
      <Header
        title={product.get("name")}
        leftAction={<Button theme="muted" priority="tertiary" label="Cancel" onClick={onCancel} />}
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label={saveButtonText}
            onClick={onClickSave}
            disabled={!doAllPagesHaveAPhoto}
          />
        }
      />
      <p className="photo-magazine-prebag__help-text">
        Tap a photo to crop, zoom & rotate
        <br />
        Press and drag <Icon name="drag-handle" size="small" />
        to reorder your pages
      </p>
      <MainContent padded scrollable className="photo-magazine-prebag">
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="photo-magazine-prebag__pages-list">
            <Droppable droppableId="pages">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {layersWithPhotos &&
                    layersWithPhotos.map((layer, index) => (
                      <Draggable draggableId={layer.get("id")} index={index}>
                        {(provided, snapshot) => {
                          const isCoverPage = layer.get("page") === 0;
                          return (
                            <div
                              className="photo-magazine-prebag__page"
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="photo-magazine-prebag__page-number">
                                {isCoverPage ? "Cover" : layer.get("page")}
                              </div>
                              <div className="photo-magazine-prebag__preview">
                                <HtmlRenderer
                                  width={130}
                                  isInteractive={false}
                                  previewMode={true}
                                  item={item.toJS()}
                                  page={layer.get("page")}
                                  onClick={() => onClickPage(layer.get("page"))}
                                />
                              </div>
                              <div className="photo-magazine-prebag__actions">
                                {isCoverPage && (
                                  <Button
                                    label={
                                      !coverTextDraft.firstLine && !coverTextDraft.secondLine
                                        ? "Add text"
                                        : "Edit text"
                                    }
                                    theme="success"
                                    size="small"
                                    onClick={handleClickEditCoverText}
                                  />
                                )}
                                <Popconfirm
                                  style={{marginRight: '10px'}}
                                  visible={isDeleteConfirmationAlertVisible && deleteConfirmationAlertVisibleForIndex === index}
                                  title="Delete this page?"
                                  onConfirm={() => handleClickDeleteConfirm(layer.get("page"))}
                                  onCancel={() => handleClickDeleteCancel(index)}
                                  okText="Yes"
                                  cancelText="No"
                                  icon={<AntIcon type="exclamation-circle-o" style={{ color: '#F75E67' }}/>}
                                  placement="left"
                                >
                                  <Button
                                    className="photo-magazine-prebag__delete-button"
                                    icon="subtract"
                                    kind="primary-outline"
                                    size="small"
                                    onClick={() => handleClickDelete(index)}
                                  />
                                </Popconfirm>
                                <div
                                  className="photo-magazine-prebag__drag-handle"
                                  {...provided.dragHandleProps}
                                >
                                  <Icon name="drag-handle" size="small" />
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </MainContent>
      <Footer padded>
        {item && pagesAdded < totalPagesRequired ? (
          <div className="photo-magazine-prebag__page-indicator">
            {pagesAdded === 0 ? (
              <p>Add {pagesRemaing} pages to your photo magazine.</p>
            ) : (
              <p>
                You've added {pagesAdded} pages. Add {pagesRemaing} more to complete your photo
                magazine.
              </p>
            )}
          </div>
        ) : (
          <div className="photo-magazine-prebag__page-indicator complete">
            <p>You've added all {pagesAdded} pages. Tap Next to continue</p>
          </div>
        )}
        {item && !doAllPagesHaveAPhoto && (
          <Button
            block
            size="small"
            label="Add photos"
            onClick={onStartUpload}
            disabled={doAllPagesHaveAPhoto}
          />
        )}
      </Footer>
    </React.Fragment>
  );
};

PhotoMagazineEditor.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default PhotoMagazineEditor;
