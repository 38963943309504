import { fromJS, Map } from "immutable";

import { PRODUCT_TYPE_IDS, productsByProductTypeId } from "./products";

const DESIGNS_API_DATA = fromJS(require("../api-data/designs"));
const CACHED_DESIGNS_DATA = fromJS(require("../api-data/cached-designs"));

export const designsByProductId = DESIGNS_API_DATA.get("designs").groupBy(design =>
  design.get("product_id")
);

export const cachedDesigns = CACHED_DESIGNS_DATA.mapEntries(([key, value]) => {
  return [+key, value];
});

//console.log(productsByProductTypeId.toJS());
//console.log(PRODUCT_TYPE_IDS);
//const greetingCardProductIds = [productsByProductTypeId.get(PRODUCT_TYPE_IDS.GREETING_CARD).first().get('id')];

const greetingCardProductIds = productsByProductTypeId
  .get(PRODUCT_TYPE_IDS.GREETING_CARD)
  .map(item => item.get("id"));

// export const greetingCardDesignsByCategory = designsByProductId.get(greetingCardProductId)
//   .groupBy(design => design.get('category_id'));

export const greetingCardDesignsByCategory = greetingCardProductIds.flatMap(
  greetingCardProductId => {
    if (designsByProductId.get(greetingCardProductId)) {
      return designsByProductId
        .get(greetingCardProductId)
        .groupBy(design => design.get("category_id"));
    } else {
      return [];
    }
  }
);

export const greetingCardDesignsByCategoriesMap = new Map();

export const greetingCardDesignsByCategories = greetingCardProductIds.flatMap(
  greetingCardProductId => {
    if (designsByProductId.get(greetingCardProductId) !== undefined) {
      designsByProductId.get(greetingCardProductId).forEach(design => {
        design.get("category_ids").forEach(category_id => {
          if (!greetingCardDesignsByCategoriesMap[category_id]) {
            greetingCardDesignsByCategoriesMap[category_id] = [design];
          } else {
            greetingCardDesignsByCategoriesMap[category_id].push(design);
          }
        });
      });
    }
  }
);

export function getDesignForDesignId(designId) {
  return DESIGNS_API_DATA.get("designs").find(design => design.get("id") === designId);
}

export function getDesignForDesignUrlSlug(slug) {
  return DESIGNS_API_DATA.get("designs").find(design => design.get("slug") === slug);
}

export const greetingCardCategories = DESIGNS_API_DATA.get("categories");
