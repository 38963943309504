export const MIN_FONT_SIZE = 10;
export const MAX_FONT_SIZE = 60;
export const POSTCARD_DEFAULT_FONT_SIZE = 14;
export const GREETING_CARD_DEFAULT_FONT_SIZE = 20;

export const MIN_FONT_SIZE_IN_EM = 0.8;
export const MAX_FONT_SIZE_IN_EM = 5;
export const FONT_SIZE_STEP = 0.1;
/**
 * The next value for the canvas/font size ratio is a bit of a magic number, this was tweaked to make the font sizes in
 * the webapp match the ones in the iOS app.
 */
export const STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO = 0.025;

export const PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO = 0.04;

export const FONT_FAMILIES = [
  {
    name: "Open Sans",
    displayName: "Seatown",
  },
  {
    name: "Josefin Sans",
    displayName: "Hazelbury",
  },
  {
    name: "Charm",
    displayName: "Poundbury",
  },
  {
    name: "EB Garamond",
    displayName: "Gillingham",
  },
  {
    name: "IndieFlower",
    displayName: "Tuckton",
  },
  {
    name: "Gaegu",
    displayName: "Piddlehinton",
  },
  {
    name: "SwankyandMooMoo",
    displayName: "Portland",
  },
  {
    name: "Satisfy",
    displayName: "Swanage",
  },
  {
    name: "Caveat",
    displayName: "Overcombe",
  },
  {
    name: "Pacifico",
    displayName: "Brownsea Island",
  },
  {
    name: "Reenie Beanie",
    displayName: "Bryanston",
  },
  {
    name: "La Belle Aurore",
    displayName: "Chesil Beach",
  },
  {
    name: "Wren",
    displayName: "Lyme Regis",
  },
  {
    name: "Special Elite",
    displayName: "Blue Pool",
  },
  {
    name: "Meridian",
    displayName: "Canford Cliffs",
  },
  {
    name: "Coustard",
    displayName: "Middlemarsh",
  },
  {
    name: "Poppins",
    displayName: "Durdle Door",
  },
  {
    name: "Prata",
    displayName: "Kingston Lacey",
  },
  {
    name: "Anaheim Gothic",
    displayName: "Tarrant",
  },
  {
    name: "Helsinki",
    displayName: "Bournemouth",
  },
  {
    name: "Amaranth",
    displayName: "Sandbanks",
  },
  {
    name: "Antonio",
    displayName: "Studland",
  },
  {
    name: "BokkaSolidOT",
    displayName: "Lulworth Cove",
  },
  {
    name: "Just Another Hand",
    displayName: "Dorchester",
  },
  {
    name: "BokkaHalfShadowOT",
    displayName: "Shell Bay",
  },
];

export const DEFAULT_FONT = FONT_FAMILIES[0].name;

export const DEFAULT_FONT_COLOR = "rgb(0,0,0)";
