import React from "react";
import PropTypes from "prop-types";

import './TruncatedText.scss'

const TruncatedText = ({ width, children }) => {
  return (
    <span className="truncated-text" style={{ width }}>
      {children}
    </span>
  );
};

TruncatedText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

TruncatedText.defaultProps = {
  width: "50vw",
};

export default TruncatedText;
