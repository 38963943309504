import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { renderToString } from "react-dom/server";

import { preloadImage } from "../../lib/image-preloader";
import loadCrossOriginImage from "../../lib/load-cross-origin-image";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import HtmlRendererRect from "./HtmlRendererRect";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import Icon from "../Icon/Icon";

const CANVAS_PIXEL_OVERDRAW = 1;
const AMOUNT_OF_REPEATING_PATTERNS_IN_FULL_WIDTH = 10;

class HtmlRendererPhotoLayer extends React.Component {
  static propTypes = {
    config: PropTypes.object,
  };

  static defaultProps = {};

  canvas = null;

  state = {
    preloadedImageUrls: [],
    imageStylesByRegionIndex: {},
  };

  shouldComponentUpdate(nextProps, nextState) {
    const hasSameConfig = isEqual(nextProps.config, this.props.config);
    const hasSameCanvasDimensions = isEqual(
      nextProps.canvasDimensions,
      this.props.canvasDimensions
    );
    const hasSameState = isEqual(this.state, nextState);
    return !hasSameConfig || !hasSameCanvasDimensions || !hasSameState;
  }

  componentDidUpdate = async prevProps => {
    if (
      ![PRODUCT_TYPE_IDS.PHOTO_PRINT, PRODUCT_TYPE_IDS.PHOTO_MAGAZINE].includes(
        this.props.item.productTypeId
      )
    ) {
      if (!this.canvas && this.props.canvasDimensions.width) {
        this.setUpCanvas();
      }

      if (!isEqual(prevProps.config.border.style, this.props.config.border.style)) {
        await this.styleBorder(this.props.config);
        this.canvas.renderAll();
      }

      this.clipBorder(this.props.config.border.thickness);
      this.canvas.renderAll();
    }

    if (!this.props.screenshotMode) {
      this.preloadAllImagesForLayout(this.props.config.layout);
    }

    this.setImagesStylesForRegions(this.props.config.layout);
  };

  preloadHighResImg = async imgUrl => {
    try {
      await preloadImage(imgUrl);
      this.setState(prevState => {
        return {
          preloadedImageUrls: [...prevState.preloadedImageUrls, imgUrl],
        };
      });
    } catch (err) {
      console.log("Error while preloading image:", err);
    }
  };

  preloadAllImagesForLayout = debounce(
    layout => {
      layout
        .map(region => region.image && region.image.src.highResUrl)
        .filter(url => url && !this.state.preloadedImageUrls.includes(url))
        .forEach(this.preloadHighResImg);
    },
    200,
    { leading: true }
  );

  getDimensions = () => {
    return this.rect.getDimensions();
  };

  setImagesStylesForRegions = layout => {
    
    const updatedState = {};
    layout.forEach((region, regionIndex) => {
      if (region.image) {
        const imageStyles = {};
        const cropData = { ...region.image.cropData };
        const hasPreloadedHighRes = this.state.preloadedImageUrls.includes(
          region.image.src.highResUrl
        );

        if (!region.image.cropData) {
          const imageUrl =
            hasPreloadedHighRes || this.props.screenshotMode
              ? region.image.src.highResUrl
              : region.image.src.lowResUrl;
          imageStyles.backgroundImage = `url(${imageUrl})`;
        } else {
          //const hasPreloadedHighRes = this.state.preloadedImageUrls.includes(region.image.src.highResUrl);
          const rectDimensions = this.rect.getDimensions();
          const regionDimensions = {
            width: rectDimensions.width * region.width,
            height: rectDimensions.height * region.height,
          };

          if (!cropData) {
            return;
          }

          // Multiply the translate values by the ratio of the region width & preview width
          const regionVsPreviewRatio = regionDimensions.width / cropData.transform.containerWidth;
          const regionVsPreviewRatioHeight =
            regionDimensions.height / cropData.transform.containerHeight;

          //console.log("Ratio Width", regionVsPreviewRatio);
          //console.log("Ratio Height", regionVsPreviewRatioHeight);

          const multipliedTransform = {
            width: cropData.transform.width * regionVsPreviewRatio,
            height: cropData.transform.height * regionVsPreviewRatioHeight,
            translateX: cropData.transform.translateX * regionVsPreviewRatio,
            translateY: cropData.transform.translateY * regionVsPreviewRatioHeight,
          };

          //// TODO: multiply the translate values by the ratio?
          //if (hasPreloadedHighRes || this.props.screenshotMode) {
          //  //const [lowResDimensions, highResDimensions] = await Promise.all([
          //  //  getImageDimensions(region.image.src.lowResUrl),
          //  //  getImageDimensions(region.image.src.highResUrl),
          //  //]);
          //  //
          //  //const ratio = lowResDimensions.width / highResDimensions.width;
          //}
          //
          imageStyles.width = multipliedTransform.width;
          imageStyles.height = multipliedTransform.height;
          imageStyles.transform = `
            translateX(${multipliedTransform.translateX.toFixed(2)}px)
            translateY(${multipliedTransform.translateY.toFixed(2)}px)
            rotate(${cropData.rotate}deg)
          `;
          imageStyles.WebkitTransform = imageStyles.transform;
        }

        updatedState[regionIndex] = imageStyles;
      }
    });

    if (!isEqual(this.state.imageStylesByRegionIndex, updatedState)) {
      this.setState({
        imageStylesByRegionIndex: updatedState,
      });
    }
  };

  setUpCanvas = async () => {
    const width =
      Math.ceil(this.props.canvasDimensions.width * this.props.config.rect.width) +
      CANVAS_PIXEL_OVERDRAW;
    const height =
      Math.ceil(this.props.canvasDimensions.height * this.props.config.rect.height) +
      CANVAS_PIXEL_OVERDRAW;
    this.canvas = new fabric.Canvas(this.canvasContainer, {
      preserveObjectStacking: true,
      width,
      height,
      top: -CANVAS_PIXEL_OVERDRAW,
      left: -CANVAS_PIXEL_OVERDRAW,
    });

    const borderDimensions = {
      width,
      height,
    };

    this.border = new fabric.Rect({
      ...borderDimensions,
      selectable: false,
      evented: false,
      fill: "transparent",
    });

    this.canvas.add(this.border);

    if (this.props.config.border.style) {
      await this.styleBorder(this.props.config);
      this.clipBorder(this.props.config.border.thickness);
      this.canvas.renderAll();
    }
  };

  styleBorder = async config => {
    if (!config.border.style) {
      this.border.set({ opacity: 0 });
      return;
    } else {
      this.border.set({ opacity: 1 });
    }

    switch (config.border.style.type) {
      case "color":
        this.border.set({ fill: config.border.style.color });
        break;
      case "image": {
        const borderImg = await loadCrossOriginImage(`${config.border.style.src}?${+Date.now()}`);
        const img = new fabric.Image(borderImg);
        const imgWidth = Math.round(
          this.props.canvasDimensions.width /
            AMOUNT_OF_REPEATING_PATTERNS_IN_FULL_WIDTH /
            (window.devicePixelRatio || 1)
        );
        img.scaleToWidth(imgWidth);
        const patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(img);
        patternSourceCanvas.renderAll();

        this.props.debug && console.log("Creating border pattern and setting as fill for border");
        const pattern = new fabric.Pattern({
          source: function() {
            patternSourceCanvas.setDimensions({
              width: imgWidth,
              height: imgWidth,
            });

            return patternSourceCanvas.renderAll().getElement();
          },
          repeat: "repeat",
        });

        this.border.set({ fill: pattern });
        break;
      }
      // no default
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showCropAlert = () => {
    this.setState({
      alert: {
        type: "info",
        title: "Crop to move",
        showCancelButton: false,
        confirmButtonText: "OK",
        onConfirm: this.clearAlert,
        html:
          "<div class='crop-alert'>Use the Crop & Rotate " +
          renderToString(<Icon name="crop-rotate" />) +
          " tool below to move your photo to fit</div>",
      },
    });
  };

  handleDrag = debounce(image => {
    if (image) {
      this.showCropAlert();
    }
  }, 200);

  clipBorder = thickness => {
    const layerDimensions = {
      left: 0,
      top: 0,
      width: this.canvas.width,
      height: this.canvas.height,
    };

    const borderThickness = Math.round(layerDimensions.width * thickness);
    this.border.set({
      clipTo: ctx => {
        ctx.beginPath();
        // Top side
        ctx.rect(
          -layerDimensions.width / 2,
          -layerDimensions.height / 2 - CANVAS_PIXEL_OVERDRAW,
          layerDimensions.width,
          borderThickness
        );
        // Bottom side
        ctx.rect(
          -layerDimensions.width / 2,
          layerDimensions.height / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
          layerDimensions.width,
          borderThickness
        );
        // Left side
        ctx.rect(
          -layerDimensions.width / 2 - CANVAS_PIXEL_OVERDRAW,
          -layerDimensions.height / 2,
          borderThickness,
          layerDimensions.height
        );
        // Right side
        ctx.rect(
          layerDimensions.width / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
          -layerDimensions.height / 2,
          borderThickness,
          layerDimensions.height
        );
        ctx.closePath();
      },
    });
  };

  render() {
    const regions = this.props.config.layout.map((region, regionIndex) => {
      const styles = {
        left: `${region.xOffset * 100}%`,
        top: `${region.yOffset * 100}%`,
        width: `${region.width * 100}%`,
        height: `${region.height * 100}%`,
      };

      const classes = classNames("html-renderer-photo-layer__region", {
        "html-renderer-photo-layer__region--has-image": region.image,
        "animated fadeIn": !(this.props.screenShotMode || this.props.previewMode),
      });

      let imageStyles = {};
      let imageUrl;
      let hasPreloadedHighRes = false;

      if (region.image) {
        hasPreloadedHighRes = this.state.preloadedImageUrls.includes(region.image.src.highResUrl);
        imageUrl =
          hasPreloadedHighRes || this.props.screenshotMode
            ? region.image.src.highResUrl
            : region.image.src.lowResUrl;
        if (!region.image.cropData) {
          imageStyles.backgroundImage = `url(${imageUrl})`;
        } else if (this.state.imageStylesByRegionIndex[regionIndex]) {
          //console.log(`Image height for ${regionIndex} ${this.state.imageStylesByRegionIndex[regionIndex].height}`);
          imageStyles = this.state.imageStylesByRegionIndex[regionIndex];
        }
      }

      return (
        <div
          className={classes}
          style={styles}
          key={regionIndex}
          onClick={() => this.props.onClickRegion(regionIndex)}
          draggable={!!region.image}
          onDragEnd={() => this.handleDrag(region.image)}
          onTouchMove={() => this.handleDrag(region.image)}
        >
          {region.image && !region.image.cropData && (
            <div
              className="html-renderer-photo-layer__region__uncropped-image"
              style={imageStyles}
            />
          )}
          {region.image && region.image.cropData && (
            <img
              className="html-renderer-photo-layer__region__cropped-image animated fadeIn"
              src={imageUrl}
              style={imageStyles}
              alt=""
            />
          )}
          {!region.image && (
            <img
              className="html-renderer-photo-layer__region__placeholder-icon animated fadeIn"
              src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
              alt=""
            />
          )}
        </div>
      );
    });

    return (
      <HtmlRendererRect
        className="html-renderer-photo-layer"
        rect={this.props.config.rect}
        ref={el => (this.rect = el)}
      >
        <canvas
          className="html-renderer-photo-layer__border"
          ref={el => (this.canvasContainer = el)}
        />
        {regions}
        {this.props.effect && (
          <div
            className={`html-renderer-photo-layer-effect html-renderer-photo-layer-effect--${this.props.effect}`}
          />
        )}
        <SweetAlert
          isOpen={Boolean(this.state.alert)}
          {...(this.state.alert || {})}
          key="crop-alert"
        />
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererPhotoLayer;
