import { COUNTRIES } from "../constants/geo";

export const schema = {
  default: {
    type: "object",
    required: ["first_name", "country", "county", "postcode", "line1", "city"],
    properties: {
      first_name: { type: "string", title: "Name" },
      country: {
        type: "string",
        title: "Country",
        enum: COUNTRIES.map(c => c.name),
      },
      line1: { type: "string", title: "Street address" },
      line2: { type: "string", title: "Street 2" },
      city: { type: "string", title: "Town/City" },
      county: { type: "string", title: "County/State" },
      postcode: { type: "string", title: "Postcode/Zip" },
    },
  },
  "United Kingdom": {
    type: "object",
    required: ["first_name", "country", "postcode", "line1", "city"],
    properties: {
      first_name: { type: "string", title: "Name" },
      country: {
        type: "string",
        title: "Country",
        enum: COUNTRIES.map(c => c.name),
      },
      line1: { type: "string", title: "Line 1" },
      line2: { type: "string", title: "Line 2" },
      city: { type: "string", title: "Town/City" },
      county: { type: "string", title: "County" },
      postcode: { type: "string", title: "Postcode" },
    },
  },
  "United States": {
    type: "object",
    required: ["first_name", "country", "postcode", "line1", "city"],
    properties: {
      first_name: { type: "string", title: "Name" },
      country: {
        type: "string",
        title: "Country",
        enum: COUNTRIES.map(c => c.name),
      },
      line1: { type: "string", title: "Street address" },
      line2: { type: "string", title: "Street 2" },
      city: { type: "string", title: "City" },
      county: { type: "string", title: "State" },
      postcode: { type: "string", title: "Zip Code" },
    },
  },
  Australia: {
    type: "object",
    required: ["first_name", "country", "postcode", "line1", "city"],
    properties: {
      first_name: { type: "string", title: "Name" },
      country: {
        type: "string",
        title: "Country",
        enum: COUNTRIES.map(c => c.name),
      },
      line1: { type: "string", title: "Street address" },
      line2: { type: "string", title: "Street 2" },
      city: { type: "string", title: "Town/Suburb" },
      county: { type: "string", title: "State" },
      postcode: { type: "string", title: "Postcode" },
    },
  },
};
