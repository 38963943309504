export default function generateS3AssetUrlFromKey(key, thumbnail = false) {
  if (key && key.startsWith("/")) {
    return key;
  }

  if (thumbnail) {
    let regex = /.png/;
    key = key.replace(regex, `-thumbnail.png`);
  }

  return `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${key}`;
}
