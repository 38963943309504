import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";

class HtmlRendererGraphicLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    const s3Key =
      this.props.config.s3_key || (this.props.config.s3_keys && this.props.config.s3_keys[0]);

    //TODO: perhaps it's cleaner to use this.props.config.thumbnails[]

    // Only show the thumbnails in the editor
    const graphicSrc = generateS3AssetUrlFromKey(s3Key, !this.props.screenshotMode);

    return (
      <HtmlRendererRect
        className="html-renderer-graphic-layer"
        rect={this.props.config.rect}
        style={{ backgroundImage: `url(${graphicSrc})` }}
        onClick={this.props.onClick}
      ></HtmlRendererRect>
    );
  }
}

export default HtmlRendererGraphicLayer;
