import React, { Component } from "react";
import PropTypes from "prop-types";
import * as ImmutablePropTypes from "react-immutable-proptypes";

import { routeCreators } from "../../lib/routes";
import MainContent from "../../components/MainContent/MainContent";
import Currency from "../../components/Formatters/Currency";
import Block from "../../components/Block/Block";
import Modal from "../../components/Modal/Modal";
import PayPalButton from "../../components/PayPalButton/PayPalButton";
//import { PayPalButton } from "react-paypal-button-v2";
import Button from "../../components/Button/Button";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
import StripePaymentRequestButton from "../../components/StripePaymentRequestButton/StripePaymentRequestButton";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
// eslint-disable-next-line
import currencyFormatter from "currency-formatter";

import './PrePay.scss';

class PrePay extends Component {
  static propTypes = {
    balance: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    topupOptions: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    canMakeStripePaymentRequest: PropTypes.bool,
    paymentRequest: PropTypes.object,
    onPayPalAuthorization: PropTypes.func,
    onStripePaymentFormSubmit: PropTypes.func,
    onSwitchToCurrency: PropTypes.func,
    isPayingForTopup: PropTypes.bool,
  };

  static defaultProps = {};

  state = {
    isStripePaymentFormVisible: null,
    selectedTopupOption: null,
    alert: null,
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showSuccessConfirmation = () => {
    this.setState({
      alert: {
        type: "success",
        title: "Prepay purchased",
        text: "Your prepay payment was successful",
        showCancelButton: false,
        onConfirm: this.clearAlert,
      },
    });
  };

  showErrorAlert = err => {
    this.setState({
      alert: {
        type: "error",
        title: "Problem with payment",
        text:
          "There was a problem processing your payment, please contact us if the problem persists.",
        showCancelButton: false,
        onConfirm: this.clearAlert,
      },
    });
  };

  handleSelectTopupOption = topupOption => {
    this.props.onSelectTopupOption(topupOption);
  };

  openStripePaymentFormModal = selectedTopupOption => {
    this.setState({
      isStripePaymentFormVisible: true,
      selectedTopupOption: selectedTopupOption,
    });
  };

  closeStripePaymentFormModal = () => {
    this.setState({
      isStripePaymentFormVisible: false,
      selectedTopupOption: null,
    });
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    try {
      await this.props.onStripePaymentFormSubmit({ result, saveCardDetails });
      this.showSuccessConfirmation();
    } catch (err) {
      this.showErrorAlert(err);
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: false,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    try {
      await this.props.onPayWithSavedCard();
      this.showSuccessConfirmation();
    } catch (err) {
      this.showErrorAlert(err);
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: false,
      });
    }
  };

  handlePaypalAuthorization = async response => {
    console.log("Handling PayPal authorization");
    try {
      await this.props.onPayPalAuthorization(response);
      console.log("Done onPayPalAuthorization");
      this.showSuccessConfirmation();
    } catch (err) {
      console.log("Error handling Paypal auth", err);
      this.showErrorAlert(err);
    } finally {
      console.log("Done");
    }
  };

  render() {
    const { balance, topupOptions } = this.props;
    // eslint-disable-next-line
    let totalAmount = null;

    if (this.props.selectedTopupOption) {
      totalAmount =
        this.props.selectedTopupOption.get("cost") +
        this.props.selectedTopupOption.get("free_value");
    }

    const alert = (
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />
    );

    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    const paymentModeSelectionModal = (
      <Modal
        key="payment-mode-selection"
        isOpen={Boolean(this.props.selectedTopupOption)}
        onClose={this.props.onClearSelectedTopupOption}
        padded
      >
        {this.props.selectedTopupOption && (
          <div>
            <p>
              Credit your account with{" "}
              <Currency amount={this.props.selectedTopupOption.get("cost")} /> and receive{" "}
              <Currency amount={this.props.selectedTopupOption.get("free_value")} /> of free credit!
            </p>
            <p>Please choose a payment method:</p>
            <Button
              block
              label="Pay with Credit/Debit Card"
              onClick={() => this.openStripePaymentFormModal(this.props.selectedTopupOption)}
            />
            {this.props.canMakeStripePaymentRequest && (
              <StripePaymentRequestButton paymentRequest={this.props.paymentRequest} padded />
            )}
            {/*<PayPalButton
              amount={this.props.selectedTopupOption.get("cost").toString()}
              options={{
                debug: isSandbox,
                clientId: payPalClientID,
                currency: this.props.currency,
              }}
              style={{ color: "blue", label: "pay", tagline: false }}
              onSuccess={(details, data) => {
                this.handlePaypalAuthorization(details);
              }}
            />*/}
            <PayPalButton
              currency={this.props.currency}
              amount={this.props.selectedTopupOption.get("cost")}
              onAuthorize={this.handlePaypalAuthorization}
              disabled={this.props.isPayingForTopup}
              description={`${currencyFormatter.format(totalAmount, {
                code: this.props.currency,
              })} of PostSnap Prepay Credit`}
            />
          </div>
        )}
        <FullScreenLoader
          key="loader"
          message="Please wait while we process your payment"
          isVisible={this.props.isPayingForTopup}
        />
      </Modal>
    );

    const stripePaymentFormModal = (
      <Modal
        key="stripe-credit-card-payment-modal"
        padded
        isOpen={this.state.isStripePaymentFormVisible}
        onClose={this.closeStripePaymentFormModal}
        title="Card Payment"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={this.closeStripePaymentFormModal}
          />
        }
      >
        <StripePaymentForm
          onSubmit={this.handleStripePaymentFormSubmit}
          onPayWithSavedCard={this.handlePayWithSavedCard}
          isPaying={this.props.isPayingForTopup}
          isPayingWithSavedCard={this.props.isPayingForTopupWithSavedCard}
          currency={this.props.currency}
          amount={this.state.selectedTopupOption && this.state.selectedTopupOption.get("cost")}
        />
      </Modal>
    );

    const isNotInCorrectRegion = this.props.currency !== this.props.balance.currency;

    let mainContent;

    if (isNotInCorrectRegion) {
      mainContent = (
        <MainContent padded key="main-content">
          <Header
            title="Prepay"
            leftAction={
              <Button
                label="Back"
                link={routeCreators.account()}
                priority="tertiary"
                theme="muted"
              />
            }
          />
          <div className="text-center">
            <p>
              You can only purchase prepay credit in the region your account was created in (
              {this.props.balance.currency})
            </p>
            <Button
              block
              label={`Switch to ${this.props.balance.currency}`}
              onClick={() => this.props.onSwitchToCurrency(this.props.balance.currency)}
            />
          </div>
        </MainContent>
      );
    } else {
      mainContent = (
        <MainContent scrollable padded={false} key="main-content">
          <Header
            title="PrePay"
            rightAction={
              <Button
                label="History"
                link={routeCreators.topUpHistory()}
                priority="tertiary"
                theme="muted"
              />
            }
            leftAction={
              <Button
                label="Back"
                link={routeCreators.account()}
                priority="tertiary"
                theme="muted"
              />
            }
          />
          <div className="prepay-banner">
            <div className="prepay-banner__title">Your PrePay Balance is:</div>
            <div className="prepay-banner__balance">
              <strong>
                <Currency amount={balance.amount} />
              </strong>
            </div>
          </div>
          <ul style={{ marginLeft: "0" }}>
            {topupOptions.map(topup => (
              <Block
                key={topup.get("id")}
                hasRightArrow
                onClick={() => this.handleSelectTopupOption(topup)}
              >
                Prepay <Currency amount={topup.get("cost")} /> and get{" "}
                <Currency amount={topup.get("free_value")} /> FREE
              </Block>
            ))}
          </ul>
          <Footer floating padded>
            <p style={{ fontSize: "11px" }}>
              Buy PostSnap PrePay for cheaper cards and faster checkout - no card details required.
            </p>
          </Footer>
        </MainContent>
      );
    }

    return [alert, paymentModeSelectionModal, stripePaymentFormModal, mainContent];
  }
}

export default PrePay;
