import { types as basketTypes, actions as basketActions } from "../ducks/basket";
import { message } from 'antd';

message.config({
  duration: 3,
  maxCount: 1,
});

export default store => next => action => {
  const ACTIONS_TO_START_RENDER_FOR = [basketTypes.ADD_ITEM, basketTypes.UPDATE_ITEM];

  if (ACTIONS_TO_START_RENDER_FOR.includes(action.type)) {
    /**
     * A timeout is used to ensure that the action we're acting on has been dispatched and the store has been updated,
     * since the `renderItem()` action fetches the item data by itself
     */
    setTimeout(() => {
      const itemId = action.payload.itemId || action.payload.id;
      //console.log('Rendering item from ACTIONS_TO_START_RENDER_FOR');
      store.dispatch(basketActions.renderItem(itemId)).catch((err) => {
        message.error({
          content: 'There was a problem uploading your item, please check your connection and try again.',
        });
      });
    }, 0);
  }

  return next(action);
};
