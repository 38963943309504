import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//import { BounceLoader } from 'react-spinners';

import { routeCreators } from "../../lib/routes";

import { actions as basketActions } from "../../store/ducks/basket";

// import { PRIMARY_COLOR } from '../../constants/colors';

// import Block from '../../components/Block/Block';
// import Currency from '../../components/Formatters/Currency';
// import Date from '../../components/Formatters/Date';

import Button from "../../components/Button/Button";
import Form from "../../components/Form/Form";
import S3Image from "../../components/S3Image/S3Image";
import MainContent from "../../components/MainContent/MainContent";
import Header from "../../components/Header/Header";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

const SCHEMA = {
  showErrorList: false,
  schema: {
    type: "object",
    required: ["email", "name", "message"],
    properties: {
      name: { type: "string", title: "Name" },
      email: { type: "string", title: "Email" },
      phone: { type: "string", title: "Phone" },
      message: { type: "string", title: "Message" },
    },
  },
  uiSchema: {
    "ui:rootFieldId": "thanks",
    email: {
      "ui:widget": "email",
      "ui:placeholder": "Your email",
    },
    name: {
      "ui:placeholder": "Your name",
    },
    phone: {
      "ui:placeholder": "Your phone number",
    },
    message: {
      "ui:widget": "textarea",
      "ui:options": { rows: 5 },
      "ui:placeholder": "Your message",
      classNames: "textarea",
    },
  },
};

class OrderByReference extends Component {
  static propTypes = {
    reference: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    sentModalOpen: false,
    thanksData: {},
    alert: null,
    isLoadingRequest: false,
    messageSent: false,
  };

  handleThanksFormChange = form => {
    this.setState({
      thanksData: form.formData,
    });
  };

  handleThanksFormSubmit = async form => {
    let payload = { thank: { ...form.formData, type: "EmailCommunication" } };
    //console.log(payload);
    this.setState({
      isLoadingRequest: true,
    });
    try {
      const result = await this.props.sendThanksForOrder(payload);
      //console.log(result);
      if (!result.payload.data.success) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't send message",
            text: "There was a problem sending your message",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Message sent",
            text: "Your recipient will receive your message shortly",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
          messageSent: true,
        });
      }
    } catch (err) {
      console.warn("Error:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  renderDetail = props => {
    const order = props.orderByReference.get("data");
    const recipientCode = order.get("code");

    return [
      <FullScreenLoader
        key="thanks-loader"
        message="Sending message..."
        isVisible={this.state.isLoadingRequest}
      />,
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Header
        leftAction={<Button label="Scan again" priority="tertiary" link={routeCreators.scan()} />}
      />,
      <MainContent key="main" scrollable padded>
        <S3Image
          storageKey={order.getIn(["line_item", "preview_s3_key"])}
          className="item-preview shadowed animated fadeIn"
        />
        {this.state.messageSent ? (
          <div
            className="animated flipOutX"
            style={{
              backgroundColor: "#29bf9d",
              padding: "10px",
              margin: "10px",
              textAlign: "center",
              animationDelay: "5s",
            }}
          >
            <p style={{ color: "white" }}> Your message has been sent </p>
          </div>
        ) : (
          <div>
            <br />
            <p padded className="text-center">
              Send a message to {order.getIn(["line_item", "order", "customer", "first_name"])}
            </p>
            <Form
              {...SCHEMA}
              formData={{ ...this.state.thanksData, code: recipientCode }}
              onChange={this.handleThanksFormChange}
              onSubmit={this.handleThanksFormSubmit}
            >
              <Button
                block
                type="submit"
                label="Send Message"
                loadingLabel="Sending message"
                loading={this.state.isLoadingRequest}
              />
            </Form>
          </div>
        )}
      </MainContent>,
      <FullScreenLoader
        key="loader"
        message="Loading card"
        isVisible={this.props.orderByReference.get("loading")}
      />,
    ];
  };

  render() {
    if (this.props.reference) {
      return this.renderDetail(this.props);
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  sendThanksForOrder: thankData => dispatch(basketActions.sendThanksForOrder(thankData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderByReference);
