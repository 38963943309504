import "core-js/stable";
import "regenerator-runtime/runtime";
import "es6-shim";
import "es5-shim";

import React from "react";
import { hydrate, render, ReactDOM } from "react-dom";
import { loadComponents } from "loadable-components";
import { getState } from "loadable-components";
import { Provider } from "react-redux";
import WebFont from "webfontloader";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import moment from "moment";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import * as STORAGE_KEYS from "./constants/storage-keys";
import { unregister } from "./registerServiceWorker"; //registerServiceWorker
import createStore from "./store";

import * as Sentry from "@sentry/browser";
import App from "./components/App/App";

import ScreenshotRenderer from "./components/ScreenshotRenderer/ScreenshotRenderer";
import RendererDebug from "./pages/Debug/RendererDebug";

import "./styles/main.scss";

console.log("REACT_APP_SENTRY_RELEASE:", process.env.REACT_APP_SENTRY_RELEASE);

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  console.log("Initializing Sentry For Release:", process.env.REACT_APP_SENTRY_RELEASE);
  Sentry.init({
    dsn: "https://a25b4412f7fd45b0bb885be45aa0079d@sentry.io/1781955",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
  });
} else {
  console.log("Skipping Sentry");
}

window.snapSaveState = () => getState();

const isRenderer =
  typeof navigator !== "undefined" && navigator.userAgent
    ? navigator.userAgent.indexOf("PhantomJS") !== -1
    : false;

if (process.env.NODE_ENV !== "development" && !isRenderer) {
  //console.log("Initializing logrocket");
  LogRocket.init('srskdt/postsnap');
  setupLogRocketReact(LogRocket);
} else {
  // LogRocket.init('srskdt/postsnap');
  // setupLogRocketReact(LogRocket);
  //console.log("Skipping logrocket");
}

moment.locale("en-custom", {
  week: {
    dow: 1,
  },
});

WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700", "Hind:300,400,700", "Poppins:100,200,300,400,500"],
  },
});

export const history = createBrowserHistory({ basename: "/app/" });
export const store = createStore(history);

//console.log("> Pathname is", window.location.pathname);

switch (window.location.pathname) {
  case "/render": {
    //console.log(">> Pathname is", window.location.pathname);
    const queryParams = queryString.parse(window.location.search);
    document.documentElement.classList.add("renderer-mode");
    render(
      <ScreenshotRenderer
        itemDataUrl={queryParams.itemDataUrl}
        width={Number(queryParams.width)}
      />,
      document.getElementById("root")
    );
    break;
  }
  case "/debug/renderer": {
    render(<RendererDebug />, document.getElementById("root"));
    break;
  }
  default: {
    //console.log(">> Pathname is", window.location.pathname);
    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
      loadComponents().then(() => {
        hydrate(
          <Provider store={store}>
            <App />
          </Provider>,
          rootElement
        );
      });
    } else {
      render(
        <Provider store={store}>
          <App />
        </Provider>,
        rootElement
      );
    }
    // ReactDOM.render(
    //   <Provider store={store}>
    //     <App/>
    //   </Provider>,
    //   document.getElementById('root')
    // );
    break;
  }
}
if (module.hot) {
  module.hot.accept("./components/App/App", () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
  });
}

//registerServiceWorker();
unregister();
localStorage.setItem(STORAGE_KEYS.APP_VERSION, process.env.REACT_APP_VERSION);

window.store = store;
window.importBasketState = () =>
  localStorage.setItem("postsnap.basket", localStorage.getItem("postsnap.savedBasket"));
window.exportBasketState = () =>
  localStorage.setItem("postsnap.savedBasket", localStorage.getItem("postsnap.basket"));
window.copyEditorItemToClipboard = () =>
  window.copy(
    store
      .getState()
      .editor.get("item")
      .toJS()
  );
