import React from "react";
import { Link } from "react-router-dom";
import { routeCreators } from "../../lib/routes";

import MainContent from "../../components/MainContent/MainContent";
import Currency from "../../components/Formatters/Currency";

import './_ProductTypeLandingPage.scss';

const DIMENSIONS_INDICATOR_WIDTH = 16;

const ProductTypeLandingPage = ({ products, productType }) => {
  return (
    <MainContent padded scrollable className="product-type-landing-page">
      {products.map(product => {
        const dimensionsIndicatorStyling = {
          width: DIMENSIONS_INDICATOR_WIDTH,
          height: DIMENSIONS_INDICATOR_WIDTH / (product.get("width") / product.get("height")),
        };

        return (
          <Link
            to={routeCreators.productLandingPage(productType, product.get("url_slug"))}
            key={product.get("url_slug")}
            className="product-type-landing-page__product"
          >
            <div
              className="product-type-landing-page__product-dimensions-indicator"
              style={dimensionsIndicatorStyling}
            />
            <div className="product-type-landing-page__product-info">
              <div className="product-type-landing-page__product-name">{product.get("name")}</div>
              <div className="product-type-landing-page__product-pricing">
                From <Currency amount={product.getIn(["pricingScheme", "cost"])} />
              </div>
            </div>
          </Link>
        );
      })}
    </MainContent>
  );
};

export default ProductTypeLandingPage;
