import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";
import pullAt from "lodash/pullAt";
import throttle from "lodash/throttle";
import queryString from "query-string";

import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { pickImages } from "../../lib/file-uploader";
import * as designsData from "../../data/designs";
import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";

import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import Button from "../Button/Button";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import EditorCropperModal from "../Editor/EditorCropperModal";
import EditorAddressInputModal from "../Editor/EditorAddressInputModal";
import PhotoPrintsPreBag from "./PhotoPrintsPreBag";
import SweetAlert from "../SweetAlert/SweetAlert";
import { message, Icon as AntIcon, Modal as AntModal  } from 'antd';

const { confirm } = AntModal;

const DEBUG_UPLOADS = [
  {
    uuid: "171d3c8e-e89b-424b-9e7a-26c9baad5f23",
    name: "Screen Shot 2019-09-11 at 11.44.16.png",
    size: 1790676,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23/",
    cdnUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/171d3c8e-e89b-424b-9e7a-26c9baad5f23//-/autorotate/yes//-/resize/400x/",
  },
  {
    uuid: "c55e5bd2-8647-45df-96bf-0624a3bbbe90",
    name: "Screen Shot 2019-09-11 at 11.43.49.png",
    size: 1788153,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90/",
    cdnUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/c55e5bd2-8647-45df-96bf-0624a3bbbe90//-/autorotate/yes//-/resize/400x/",
  },
  {
    uuid: "59f08462-19f0-4717-bc43-80d17c599093",
    name: "Screen Shot 2019-09-11 at 11.39.33.png",
    size: 1850478,
    isStored: true,
    isImage: true,
    originalImageInfo: {
      color_mode: "RGBA",
      orientation: null,
      format: "PNG",
      sequence: false,
      height: 1080,
      width: 1920,
      geo_location: null,
      datetime_original: null,
      dpi: null,
    },
    mimeType: "image/png",
    originalUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093/",
    cdnUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093/",
    cdnUrlModifiers: null,
    sourceInfo: {
      source: "local",
      file: {},
    },
    highResUrl: "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093//-/autorotate/yes/",
    lowResUrl:
      "https://ucarecdn.com/59f08462-19f0-4717-bc43-80d17c599093//-/autorotate/yes//-/resize/400x/",
  },
];

const STEPS = {
  UPLOAD: "UPLOAD",
  CHOOSE_ADDRESS: "CHOOSE_ADDRESS",
};

const DEFAULT_PRODUCT_OPTION = "gloss";

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;

  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

class PhotoPrintsPreBagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      step: STEPS.UPLOAD,
      itemIndexForCropper: null,
      draftRotationState: null,
      isUploadModalVisible: false,
      isAddressInputModalVisible: false,
      isCancelConfirmationAlertVisible: false,
      shouldShowInfoMessage: false
    };

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag) {
      this.state.items = existingPrebag.data.items.map(item => fromJS(item));
    }
  }

  componentDidMount() {
    //this.debugUpload().then(() => {
    //  //this.startCropping(0);
    //});
    if (this.state.items.length === 0) {
      this.showUploadModal();
    }
  }

  componentWillUpdate() {
    if(this.state.shouldShowInfoMessage){
      message.info({
        content: 'Tap a print to crop, zoom and rotate it',
        duration: 5,
        icon: <AntIcon type="info-circle" style={{color:'#49bda1'}}/>,
      });
      this.setState({shouldShowInfoMessage: false});
    }
  }

  componentDidUpdate() {
    if (this.state.items.length > 0) {
      localStorage.setItem(
        STORAGE_KEYS.WIP_PREBAG,
        JSON.stringify({
          productTypeId: this.props.productTypeId,
          productId: this.props.productId,
          productSlug: this.props.productSlug,
          productTypeSlug: this.props.productTypeSlug,
          data: {
            items: this.state.items,
          },
        })
      );
    } else {
      const existingPrebag = getExistingPrebagForProduct(
        this.props.productTypeId,
        this.props.productId
      );

      if (existingPrebag) {
        localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
      }
    }
  }

  generateEditorItemsForImages = async images => {
    const { productTypeId, productId } = this.props;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    const parsedQueryString = queryString.parse(window.location.search);
    const isProductLandscape = parseFloat(product.get("width")) > parseFloat(product.get("height"));

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: parsedQueryString.finish || DEFAULT_PRODUCT_OPTION,
      postDate: moment(),
      duplicateAlertShown: true,
    });

    const indexOfPhotoLayer = itemData
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    let itemsWithImage = images.map(image => {
      const isImageLandscape = image.originalImageInfo.width > image.originalImageInfo.height;
      return itemData.withMutations(item => {
        item.set("isRotated", isProductLandscape && !isImageLandscape);
        item.setIn(
          ["layers", indexOfPhotoLayer, "config", "layout", 0, "image"],
          fromJS({
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            cropData: null,
          })
        );
        // Add border: 
        // item.setIn(
        //   ["layers", indexOfPhotoLayer, "config", "border"],
        //   fromJS({
        //     style: {
        //       type: "color",
        //       color: "rgb(255,255,255)",
        //     },
        //     thickness: 0.07
        //   })
        // );
      });
    });

    return itemsWithImage;
  };

  pickImageFromUploadcare = async source => {
    try{
      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };
  
      const images = await pickImages({
        amount: 100 - this.state.items.length,
        source,
        onTotalProgressUpdate: throttle(progressHandler, 500),
      });
  
      const generatedItems = await this.generateEditorItemsForImages(images);
  
      const isFirstSetOfItems = this.state.items.length === 0;
  
      this.setState({
        items: [...this.state.items, ...generatedItems],
        shouldShowInfoMessage : isFirstSetOfItems,
      });
      this.closeUploadModal();
    } catch (err) {
      console.log('Cancelled upload:', err);
    }
    
  };

  debugUpload = async () => {
    const generatedItems = await this.generateEditorItemsForImages(DEBUG_UPLOADS);
    this.setState({
      items: [...this.state.items, ...generatedItems],
    });
    this.closeUploadModal();
  };

  showUploadModal = () => {
    this.setState({ isUploadModalVisible: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalVisible: false });
  };

  showAddressInputModal = () => {
    if (this.props.existingPhotoPrintItems.size > 0) {
      const firstPhotoPrint = this.props.existingPhotoPrintItems.first();
      if (firstPhotoPrint.get("addressBookId")) {
        this.handleSelectAddressBookEntry(firstPhotoPrint.get("addressBookId"));
      } else {
        this.handleSaveAddress(firstPhotoPrint.get("address").toJS());
      }
    } else {
      this.setState({ isAddressInputModalVisible: true });
    }
  };

  closeAddressInputModal = () => {
    this.setState({ isAddressInputModalVisible: false });
  };

  showCancelConfirmationAlert = () => {
    if (this.state.items.length > 0) {
      confirm({
        title: 'Are you sure?',
        content: 'You have unsaved changes, are you sure you want to go back?',
        okText: 'Stay',
        cancelText: 'Exit & discard changes',
        onOk: () => {
          this.closeCancelConfirmationAlert();
        },
        onCancel: () => {
          this.navigateBack();
        },
      });
    } else {
      this.navigateBack();
    }
  };

  closeCancelConfirmationAlert = () => {
    this.setState({ isCancelConfirmationAlertVisible: false });
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  adjustItemQuantity = (itemIndex, amount) => {
    this.setState({
      items: this.state.items.map((item, index) => {
        if (index === itemIndex) {
          return item.update("quantity", qty => Math.max(1, qty + amount));
        }

        return item;
      }),
    });
  };

  handleChangeProductOption = option => {
    this.setState({
      items: this.state.items.map(item => item.set("product_options", option)),
    }, () => {
      message.success(`Print finish changed to ${option}`);
    });
  };

  deleteItem = index => {
    const updatedItems = [...this.state.items];
    pullAt(updatedItems, [index]);
    this.setState({
      items: updatedItems,
    });
  };

  startCropping = itemIndex => {
    this.setState({
      itemIndexForCropper: itemIndex,
      draftRotationState: this.state.items[itemIndex].get("isRotated"),
    });
  };

  closeCropper = () => {
    this.setState({
      itemIndexForCropper: null,
      draftRotationState: null,
    });
  };

  handleRotateCrop = () => {
    this.setState({
      draftRotationState: !this.state.draftRotationState,
    });
  };

  handleSaveCrop = crop => {
    const itemForCropper = this.state.items[this.state.itemIndexForCropper];
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    this.setState({
      items: this.state.items.map((item, index) => {
        if (index === this.state.itemIndexForCropper) {
          return item.withMutations(item => {
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "cropData"],
              fromJS(crop)
            );
            item.set("isRotated", Boolean(this.state.draftRotationState));
          });
        }

        return item;
      }),
    });
    this.closeCropper();
  };

  handleSaveAddress = address => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", null);
            item.set("address", fromJS(address));
          });
        }),
      },
      this.finalizePreBag
    );
    //this.closeAddressInputModal();
  };

  handleSelectAddressBookEntry = addressBookEntryId => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", addressBookEntryId);
            item.set("address", null);
          });
        }),
      },
      this.finalizePreBag
    );
    //this.closeAddressInputModal();
  };

  finalizePreBag = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    this.state.items.forEach(item => {
      this.props.addItemToBasket(item.toJS());
    });
    this.props.navigateToBasket();
  };

  render() {
    const { productId } = this.props;
    const product = productsByProductId.get(productId);

    const itemForCropper = this.state.items[this.state.itemIndexForCropper];
    const recentlyUploaded = JSON.parse(localStorage.getItem('recentlyUploadedFiles'));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;

    const uploadModal = (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title="Upload Photos"
      >
        <MainContent scrollable={false} padded centeredVertically>
          {process.env.NODE_ENV === "development" && (
            <Button
              theme="dark-blue"
              block
              label="DEBUG UPLOAD"
              icon="phone"
              onClick={this.debugUpload}
            />
          )}
          <Button
            block
            label="From Device"
            icon="phone"
            onClick={() => this.pickImageFromUploadcare("file")}
          />
          {anyRecentlyUploaded && (
            <Button
              block
              className="btn--recent"
              label="Recently Uploaded"
              icon="upload"
              onClick={() => this.pickImageFromUploadcare("favorites")}
            />
          )}
          <Button
            block
            icon="facebook"
            className="btn--facebook"
            label="Facebook"
            onClick={() => this.pickImageFromUploadcare("facebook")}
          />
          <Button
            block
            icon="instagram"
            className="btn--instagram"
            label="Instagram"
            onClick={() => this.pickImageFromUploadcare("instagram")}
          />
          <Button
            block
            icon="gdrive"
            className="btn--google"
            label="Google Drive"
            onClick={() => this.pickImageFromUploadcare("gdrive")}
          />
          <Button
            block
            icon="dropbox"
            className="btn--dropbox"
            label="Dropbox"
            onClick={() => this.pickImageFromUploadcare("dropbox")}
          />
          <Button
            block
            icon="flickr"
            className="btn--flickr"
            label="Flickr"
            onClick={() => this.pickImageFromUploadcare("flickr")}
          />
          {/* <Button
            block
            theme="muted"
            icon="questioncircle"
            priority="tertiary"
            className="btn--default"
            label="Are we missing a photo source?"
            onClick={() => window.location.href = `mailto:team@postsnap.com?subject=Missing Photo Source&body=Hi PostSnap,%0D%0APlease add the following photo source:`}
          /> */}
        </MainContent>
      </Modal>
    );

    const cropperModalProps = {};

    if (itemForCropper) {
      const indexOfPhotoLayer = itemForCropper
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      const regionToCrop = itemForCropper.getIn([
        "layers",
        indexOfPhotoLayer,
        "config",
        "layout",
        0,
      ]);
      const imageToCrop = regionToCrop.get("image");
      const productDimensions = itemForCropper.get("productDimensions").toJS();
      cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"]);
      cropperModalProps.cropData = imageToCrop.get("cropData")
        ? imageToCrop.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right) /
        (productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom);

      if (this.state.draftRotationState) {
        cropperModalProps.ratio =
          (productDimensions.height +
            productDimensions.bleed.top +
            productDimensions.bleed.bottom) /
          (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right);
      }
    }

    const cropperModal = (
      <EditorCropperModal
        isOpen={this.state.itemIndexForCropper !== null}
        {...cropperModalProps}
        onRotate={this.handleRotateCrop}
        onClose={this.closeCropper}
        onSave={this.handleSaveCrop}
      />
    );

    const addressInputModal = (
      <EditorAddressInputModal
        isDoubleDirect={false}
        isOpen={this.state.isAddressInputModalVisible}
        mode={"new"}
        initialFormData={
          this.state.items.length > 0 && this.state.items[0].get("address")
            ? this.state.items[0].get("address").toJS()
            : null
        }
        onCancel={this.closeAddressInputModal}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
      />
    );

    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`You have unsaved changes, are you sure you want to go back?`}
        cancelButtonText="Exit & discard changes"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    const totalQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);
    const pricingScheme = getPricingSchemeForProductAndQuantity({
      productId,
      quantity: totalQuantity,
      currency: this.props.currency,
    });

    return (
      <React.Fragment>
        {cropperModal}
        {uploadModal}
        {addressInputModal}
        {cancelConfirmationAlert}
        <PhotoPrintsPreBag
          currency={this.props.currency}
          product={product}
          items={this.state.items}
          totalQuantity={totalQuantity}
          costPerPrint={pricingScheme && pricingScheme.get("cost")}
          onStartUpload={this.showUploadModal}
          onIncreaseItemQuantity={itemIndex => this.adjustItemQuantity(itemIndex, 1)}
          onDecreaseItemQuantity={itemIndex => this.adjustItemQuantity(itemIndex, -1)}
          onChangeProductOption={this.handleChangeProductOption}
          onDeleteItem={this.deleteItem}
          onClickItem={this.startCropping}
          onClickNext={this.showAddressInputModal}
          onCancel={this.showCancelConfirmationAlert}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  existingPhotoPrintItems: basketSelectors
    .getItems(state)
    .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT),
});

const mapDispatchToProps = dispatch => ({
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(PhotoPrintsPreBagContainer);
