import stringToPatternOrFill from "../lib/string-to-pattern-or-fill";

export default [
  "rgb(255,255,255)",
  "rgb(145,145,145)",
  "rgb(70,70,70)",
  "rgb(0,0,0)",
  "rgb(148,179,200)",
  "rgb(178,219,209)",
  "rgb(149,209,206)",
  "rgb(132,202,196)",
  "rgb(75,186,189)",
  "rgb(61,179,194)",
  "rgb(64,209,211)",
  "rgb(148,247,201)",
  "rgb(69,158,153)",
  "rgb(10,119,148)",
  "rgb(15,101,114)",
  "rgb(112,158,168)",
  "rgb(185,219,237)",
  "rgb(152,204,228)",
  "rgb(134,183,203)",
  "rgb(77,146,195)",
  "rgb(63,127,208)",
  "rgb(24,175,247)",
  "rgb(105,179,233)",
  "rgb(57,80,150)",
  "rgb(89,145,194)",
  "rgb(19,139,174)",
  "rgb(61,111,158)",
  "rgb(45,81,141)",
  "rgb(19,51,93)",
  "rgb(61,59,97)",
  "rgb(193,182,215)",
  "rgb(161,139,189)",
  "rgb(188,121,173)",
  "rgb(163,69,147)",
  "rgb(149,17,174)",
  "rgb(185,82,230)",
  "rgb(131,51,134)",
  "rgb(115,81,159)",
  "rgb(97,44,141)",
  "rgb(134,36,113)",
  "rgb(97,32,99)",
  "rgb(246,229,229)",
  "rgb(237,195,211)",
  "rgb(239,197,198)",
  "rgb(234,174,189)",
  "rgb(229,175,203)",
  "rgb(232,139,166)",
  "rgb(238,128,177)",
  "rgb(254,92,193)",
  "rgb(223,3,169)",
  "rgb(255,0,120)",
  "rgb(246,48,160)",
  "rgb(246,63,117)",
  "rgb(217,92,155)",
  "rgb(230,51,144)",
  "rgb(189,24,117)",
  "rgb(230,18,102)",
  "rgb(239,35,86)",
  "rgb(212,10,88)",
  "rgb(219,90,110)",
  "rgb(230,102,100)",
  "rgb(198,28,95)",
  "rgb(148,29,90)",
  "rgb(151,28,77)",
  "rgb(190,28,57)",
  "rgb(247,35,56)",
  "rgb(246,0,0)",
  "rgb(204,75,85)",
  "rgb(237,69,68)",
  "rgb(189,29,36)",
  "rgb(151,30,47)",
  "rgb(132,13,30)",
  "rgb(167,84,32)",
  "rgb(217,120,35)",
  "rgb(221,104,48)",
  "rgb(236,128,23)",
  "rgb(255,106,0)",
  "rgb(241,103,14)",
  "rgb(252,71,29)",
  "rgb(245,81,53)",
  "rgb(246,132,85)",
  "rgb(247,159,69)",
  "rgb(240,159,39)",
  "rgb(247,192,124)",
  "rgb(247,202,20)",
  "rgb(243,199,82)",
  "rgb(247,231,51)",
  "rgb(254,239,0)",
  "rgb(184,227,47)",
  "rgb(95,189,0)",
  "rgb(107,216,88)",
  "rgb(2,203,50)",
  "rgb(237,254,25)",
  "rgb(231,211,119)",
  "rgb(237,232,85)",
  "rgb(246,235,181)",
  "rgb(243,237,204)",
  "rgb(211,221,77)",
  "rgb(176,206,56)",
  "rgb(191,201,138)",
  "rgb(152,199,152)",
  "rgb(161,197,105)",
  "rgb(126,171,106)",
  "rgb(158,173,53)",
  "rgb(99,173,63)",
  "rgb(23,134,67)",
  "rgb(2,87,22)",
  "rgb(118,126,69)",
  "rgb(93,103,51)",
  "rgb(214,201,193)",
  "rgb(212,178,149)",
  "rgb(159,137,104)",
  "rgb(127,94,57)",
  "rgb(135,90,58)",
  "rgb(91,58,24)",
  "rgb(84,68,41)",
  "rgb(50,32,18)",
  "https://eclipse-engine.s3.amazonaws.com/uploads/473329f1-b52e-4de1-bab7-55ecf69645b5/Easterchicks.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/3833f745-26b0-4ecd-9e85-b4d058a73b67/Eggs.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/14e3b746-e485-4b91-bf63-a106cc77305f/Daisy.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e47a4445-416e-4657-b485-b4a52984db6e/Daisy.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/120fe2c7-3aac-4116-badc-9ed4904df0d1/Stars.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/459e4835-ccbd-49c2-847e-b22b1a42e30f/StarsBlue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/8f798ec4-0683-4a2a-8d68-a2e2b9f59cd9/StarsPastel.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/4281d94d-8b38-49e7-aee0-d7f73c7bcd1c/starborder.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/63023732-b01e-43f1-a2ae-bb7c525fa8a7/heartborder200.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d359ce5d-ef00-4834-bae9-d60b0fcc4310/TransparentHearts.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/5c9d3075-540c-43aa-9751-1b708d1c5a2b/Polkadots.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/f4a59f37-17e0-45c1-b425-1876e6d7f453/CrayonsTransparent.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/17e51577-a5d2-4712-89cc-e14773550c9b/Triangles.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/44eb641e-d192-450f-8cd9-5d1e7ced7961/hexagonpattern.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/02e5c876-980b-46c7-86f2-8d12224ba599/RainbowStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2381d825-a587-47e0-bdc7-92518d5a2a1c/BlueStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/12034132-e008-482d-8fe8-994ee1754b26/PinkStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/fc3d58be-96e9-4dc4-bf95-c12e98aa98db/bluestripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2de5513c-5673-4862-b3e0-1b10a5fdc3d3/YellowStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e81f83a4-e562-4228-9eac-7a6cc1e902cc/BlueClouds.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d8dace84-d253-48bd-b611-ac1940305306/Clouds.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/81c4b72a-a02a-4b30-b3ff-50c8c5b2b8bd/raindrops.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/86af16b5-5df1-47cf-967e-a5df70910e5d/Pineapples.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/c9f1351e-4488-4448-ba2b-ddb31700a906/Watermelons.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/ae1ff1d9-6209-4c22-823e-e5d09179b805/Palm.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/a519d8da-c406-4a04-b07a-49664cf40a61/Flimingo.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/5d2e02f9-1205-42ff-b428-bf61cdf03f1e/Triangles.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/8fc20a9a-3a57-4494-9bc4-11d79ce3565b/AbstarctPaintStokes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/87c2d41b-de30-4ac4-9c8b-e73ab0766a4d/AnchorsWhite.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/c83ae3da-e447-4d9b-8360-5bfd9f2bf842/AnchorsBlue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/b8734580-05a0-426a-a4fc-32ebef0e411b/ElephantsGrey.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/7ef82833-e962-4614-a7a0-5054cfafcba3/ElephantBlue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/b401cedc-1a75-4aa3-8f51-2b3bca059c6f/ElephantPink.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e9140486-98ef-484e-8ad2-2ff5980bf1f9/GreenStipes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e0377cba-39aa-4013-9d69-592c177d57f6/BlueStipes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/0c2fa9b7-10f0-43f0-a771-fcf06f310de6/CandyflossStipes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/3a951f3f-179d-4c13-ba49-f1d9c5e58b86/orangestripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/0aca7d32-2f91-4166-97a3-c9f6b9660f91/Blue&orangestripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/f122a81d-15da-4387-8b12-50af61e64022/Pink&Purple.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/fec43bed-c04a-4c2c-b2ce-098af58dfba3/starsandstripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/22e7fdfa-9871-49e7-ae54-e0a50f1d83ba/woolyhats.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_pink.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_purple.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_blue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_aqua.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_silver.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/9671fdb0-f513-486a-9584-3d11ff7cbbe9/glitter_gold-250x250.jpg",
].map(stringToPatternOrFill);
