import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import $ from "jquery";

import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";

import './PhotoPrintPreview.scss';

const PhotoPrintPreview = ({ item }) => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const lastItemId = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const [imageStyles, setImageStyles] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  useEffect(() => {
    imageRef.current.onload = () => {
      setIsLoadingImage(false);
    };
  }, []);

  useEffect(() => {
    const parentElement = containerRef.current.parentElement;
    console.log(parentElement);
    const width = $(parentElement).width();
    const height = $(parentElement).height();

    let minHeightOrWidth = width >= height ? height : width;

    const productDimensions = item.get("productDimensions").toJS();
    const isRotated = item.get("isRotated");
    const ratio = productDimensions.width / productDimensions.height;
    const containerDimensions = isRotated
      ? {
          width: minHeightOrWidth / ratio,
          height: minHeightOrWidth,
        }
      : {
          width: minHeightOrWidth,
          height: minHeightOrWidth / ratio,
        };

    if (lastItemId.current !== item.get("id")) {
      setIsLoadingImage(true);
    }

    imageRef.current.src = generateS3AssetUrlFromKey(item.get("preview_s3_key"));

    setDimensions(containerDimensions);

    if (isRotated) {
      setImageStyles({
        transform: `
        scale(${containerDimensions.height / containerDimensions.width})
        rotate(-90deg) 
        translateX(-${(containerDimensions.height - containerDimensions.width) / 2}px)`,
      });
    } else {
      setImageStyles(null);
    }

    lastItemId.current = item.get("id");
  }, [item]);

  return (
    <div
      className={classNames("photo-print-preview", {
        "photo-print-preview--loading": isLoadingImage,
      })}
      ref={containerRef}
      style={{ ...(dimensions || {}) }}
    >
      {isLoadingImage && (
        <div className="photo-print-preview__loading-indicator">Loading image...</div>
      )}
      <img
        ref={imageRef}
        src={generateS3AssetUrlFromKey(item.get("preview_s3_key"))}
        style={{ ...(imageStyles || {}) }}
        alt=""
      />
    </div>
  );
};

export default PhotoPrintPreview;
