import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class HtmlRendererRect extends React.Component {
  getDimensions = () => {
    const $node = ReactDOM.findDOMNode(this);
    return {
      width: $node.offsetWidth,
      height: $node.offsetHeight,
    };
  };

  render() {
    const { rect, children, className, style, onClick } = this.props;

    const styles = {
      left: `${rect.x * 100}%`,
      top: `${Math.max(0, 1 - rect.y) * 100}%`,
      width: `${rect.width * 100}%`,
      height: `${Math.min(1, rect.height) * 100}%`,
      ...style,
    };

    const clickHandler = e => {
      e.stopPropagation();
      onClick();
    };

    return (
      <div
        className={`html-renderer-rect ${className}`}
        style={styles}
        onClick={clickHandler}
        onTouchStart={clickHandler}
      >
        {children}
      </div>
    );
  }
}

HtmlRendererRect.propTypes = {
  rect: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
};

HtmlRendererRect.defaultProps = {
  onClick: () => {},
  style: {},
};

export default HtmlRendererRect;
