import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

//import { PRODUCT_TYPE_IDS } from '../../data/products';
//import PAGES_BY_PRODUCT_TYPE_ID from '../../constants/pages-by-product-type';
//import * as LAYER_TYPES from '../../constants/layer-types';

import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";

class ScreenshotRenderer extends Component {
  static propTypes = {
    itemDataUrl: PropTypes.string.isRequired,
    width: PropTypes.number,
  };

  static defaultProps = {
    width: 900,
  };

  state = {
    itemData: null,
  };

  async componentWillMount() {
    const itemDataResponse = await axios.get(this.props.itemDataUrl);
    this.setState({
      itemData: itemDataResponse.data,
    });
  }

  render() {
    if (!this.state.itemData) {
      return null;
    }

    const item = this.state.itemData;
    const page = item.layers[0].page;
    let width = this.props.width;

    return <HtmlRenderer width={width} page={page} item={item} screenshotMode previewMode />;
  }
}

export default ScreenshotRenderer;
