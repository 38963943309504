import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { middleware as reduxPackMiddleware } from "redux-pack";
import LogRocket from "logrocket";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { rootReducer } from "./ducks";
import persistenceManager from "./middleware/persistence-manager";
import renderStarter from "./middleware/render-starter";
import renderPreloader from "./middleware/render-preloader";
import userDataSyncer from "./middleware/user-data-syncer";

export default function(history) {
  const middleware = routerMiddleware(history);
  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(
        thunk,
        middleware,
        reduxPackMiddleware,
        persistenceManager,
        renderStarter,
        renderPreloader,
        userDataSyncer,
        LogRocket.reduxMiddleware()
      )
    )
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./ducks", () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
