const FIELDS = [
  "county",
  "city",
  "first_name",
  "last_name",
  "postcode",
  "country",
  "line1",
  "line2",
];

export default function filterAddressFieldsFromObject(obj) {
  return FIELDS.reduce((map, field) => {
    if (obj[field]) {
      map[field] = obj[field];
    }

    return map;
  }, {});
}
