import { connect } from "react-redux";

import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";

import TopUpHistory from "./TopUpHistory";

const mapStateToProps = state => ({
  topUpHistory: authSelectors.getTopUpHistory(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTopUpHistory: () => dispatch(authActions.fetchTopUpHistory()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopUpHistory);
