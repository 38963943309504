export default {
  "12-x-8-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.059000000000000004,
        y: 0.924,
        width: 0.885,
        height: 0.845,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.059000000000000004,
        y: 0.924,
        width: 0.885,
        height: 0.845,
      },
    },
  },
  "16-x-12-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.05,
        y: 0.942,
        width: 0.905,
        height: 0.877,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.05,
        y: 0.942,
        width: 0.905,
        height: 0.877,
      },
    },
  },
  "12-x-12-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.06267806268,
        y: 0.9373219373,
        width: 0.8803418803,
        height: 0.8803418803,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.06267806268,
        y: 0.9373219373,
        width: 0.8803418803,
        height: 0.8803418803,
      },
    },
  },
  "24-x-16-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.036,
        y: 0.954,
        width: 0.932,
        height: 0.91,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.036,
        y: 0.954,
        width: 0.932,
        height: 0.91,
      },
    },
  },
};
