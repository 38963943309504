import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import Icon from "../Icon/Icon";

import './Block.scss';

const Block = ({ children, style, link, borderTop, hasRightArrow, onClick }) => {
  const classes = classNames("block", {
    "block--border-top": borderTop,
    "block--clickable": onClick || link,
  });

  return React.createElement(
    link ? Link : "div",
    {
      className: classes,
      style,
      onClick: () => (onClick ? onClick() : () => {}),
      to: link,
    },
    [
      <div className="block__main-content" key="main-content">
        {children}
      </div>,
      hasRightArrow && (
        <div className="block__arrow-container" key="right-arrow">
          <Icon size="small" name="arrow-right" />
        </div>
      ),
    ]
  );
};

Block.propTypes = {
  children: PropTypes.node.isRequired,
  hasRightArrow: PropTypes.bool,
  onClick: PropTypes.func,
};

Block.defaultProps = {
  hasRightArrow: false,
};

export default Block;
