import axios from "axios";

import { cachedDesigns } from "../../data/designs";
import { store } from "../../index";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors } from "../../store/ducks/basket";

const restClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Eclipse-Source": process.env.REACT_APP_ECLIPSE_SOURCE,
    "Eclipse-Medium": process.env.REACT_APP_ECLIPSE_MEDIUM,
  },
});

restClient.interceptors.request.use(config => {
  const state = store.getState();
  const token = authSelectors.getAuthToken(state);
  const currency = basketSelectors.getCurrency(state);

  config.params = {
    ...(config.params || {}),
    auth_token: token,
  };

  config.data = {
    ...(config.data || {}),
    auth_token: token,
  };

  config.headers = {
    ...config.headers,
    "Eclipse-Currency": currency,
  };

  return config;
});

restClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // router.replace({
          //   path: 'login',
          //   query: {redirect: router.currentRoute.fullPath}
          // });
          break;
        case 403:
          break;
        case 504:
          break;
        case 500:
          break;
        case 404:
          break;
        default:
          break;
      }
    }
    //console.log(JSON.stringify(error));
    return Promise.reject(error.response);
  }
);

export default {
  designs: {
    getDetails: designId => {
      const cachedDesign = cachedDesigns.get(designId);
      if (cachedDesign) {
        //console.log('Returning cached design for design', designId);
        return Promise.resolve({ data: { data: cachedDesign.toJS() } });
      }

      return restClient.get(`designs/${designId}`);
    },
  },
  auth: {
    signIn: ({ email, password, authToken }) =>
      restClient.post("customers/sign_in", {
        customer: {
          email,
          password,
          auth_token: authToken,
        },
      }),
    signUp: userData => restClient.post("customers", { customer: userData }),
    requestPasswordResetForEmail: email =>
      restClient.post("customers/password", {
        customer: { email: email },
      }),
    resetPassword: (newPassword, token) =>
      restClient.put("customers/password", {
        customer: {
          reset_password_token: token,
          password: newPassword,
          password_confirmation: newPassword,
        },
      }),
    confirmEmail: (confirmationToken, env) =>
      restClient.get(`customers/confirmation?confirmation_token=${confirmationToken}`),
    updateUser: userData =>
      restClient.put("customers", {
        customer: userData,
      }),
    signInWithAuthToken: authToken =>
      restClient.post("customers/sign_in", {
        customer: { auth_token: authToken },
      }),
    getOrderHistory: () =>
      restClient.post("orders/sync", {
        ids: {},
        order: {},
      }),
    getOrderByReference: reference => restClient.get(`recipients/${reference}`),
    getTopUpHistory: () => restClient.get("balance_transactions"),
    removeSavedCard: () => restClient.delete("delete_saved_card"),
  },
  topups: {
    createTopupPurchase: topupId =>
      restClient.post(`topups/${topupId}/purchase`, {
        topup_id: topupId,
        topup: {},
      }),
    chargeStripePaymentForTopupPurchase: ({ stripeToken, reference, saveCardDetails }) =>
      restClient.post("topups/charge_stripe_payment", {
        stripeToken,
        reference,
        saveCardDetails,
        topup: {},
      }),
    chargeStripeCustomerForTopupPurchase: reference =>
      restClient.post("topups/charge_stripe_customer", {
        reference,
        order: { reference },
      }),
    confirmPaypalPaymentForTopupPurchase: ({ token, reference }) =>
      restClient.post("topups/confirm_paypal_payment", {
        token,
        reference,
        version: 1,
        topup: {},
      }),
  },
  addressBook: {
    fetchEntries: () => restClient.post("address_book_entries/sync"),
    createEntry: address =>
      restClient.post("address_book_entries", { address_book_entry: address }),
    updateEntry: (id, address) =>
      restClient.put(`address_book_entries/${id}`, {
        address_book_entry: address,
        id: id.toString(),
      }),
    deleteEntry: id => restClient.delete(`address_book_entries/${id}`),
  },
  orders: {
    //createOrder: orderData => restClient.post("orders", orderData),
    createOrder: orderData => restClient.post("orders/create_v2", orderData),
    createGuestOrder: orderData => restClient.post("orders/as_guest", orderData),
    getPromoCodeDetails: promoCode => restClient.get(`promotions/${promoCode}`),
    chargeStripePayment: ({ stripeToken, reference, saveCardDetails = false }) =>
      restClient.post("orders/charge_stripe_payment", {
        stripeToken,
        reference,
        saveCardDetails,
        order: { reference },
      }),
    chargeStripeCustomerForOrder: reference =>
      restClient.post("orders/charge_stripe_customer", {
        reference,
        order: { reference },
      }),
    confirmPayPalPayment: ({ token, reference }) =>
      restClient.post("orders/confirm_paypal_payment", {
        token,
        reference,
        version: 1,
        order: { reference },
      }),
    processPrepayPayment: ({ amount, reference }) =>
      restClient.post("orders/process_prepay_payment", {
        amount,
        reference,
        order: { reference },
      }),
    sendThanks: thanksData => restClient.post("thank", thanksData),
  },
  renderService: {
    renderItem: itemData =>
      axios.post(process.env.REACT_APP_RENDER_SERVICE_URL, itemData, {
        headers: {
          "x-api-key": process.env.REACT_APP_RENDER_SERVICE_API_KEY,
        },
      }),
  },
};
