import React, { Component } from "react";
import classNames from "classnames";
import debounce from "lodash/debounce";

import * as googlePlacesApi from "../../lib/apis/google-places";
import * as addressSchemas from "../../form-schemas/address";
import { COUNTRIES, provincesByCountry, militaryStates } from "../../constants/geo";

import Form from "./Form";
import Button from "../Button/Button";

import { AutoComplete, Input, Icon } from 'antd';
const { Option } = AutoComplete;

const DEBOUNCE_DELAY_FOR_SUGGESTIONS_FETCHING = 250;

function getAddressComponentValueByType(addressComponents, type) {
  const component = addressComponents.find(c => (c.types || []).includes(type));

  if (component) {
    return component.long_name;
  }
}

function getAddressFieldsFromState(state) {
  return {
    county: state.county,
    city: state.city,
    first_name: state.first_name,
    postcode: state.postcode,
    country: state.country,
    line1: state.line1,
    line2: state.line2,
  };
}

const TextInput = ({ id, label, children, value, onChange, required }) => (
  <div
    className={classNames("form-group", "field", {
      "form-group--has-floating-icon": children,
    })}
  >
    <label htmlFor={id} className="control-label">
      {label}
      {required && "*"}
    </label>
    <div className="form-control-container">
      <input
        type="text"
        className="form-control"
        value={value}
        onChange={onChange}
        id={id}
        label={label}
        required={required}
      />
      {children}
    </div>
  </div>
);

const Dropdown = ({ id, label, value, options, onChange, required }) => (
  <div className="form-group field">
    <label htmlFor={id} className="control-label">
      {label}
      {required && "*"}
    </label>
    <select className="form-control" name={label} id={id} value={value} onChange={onChange}>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

class AddressComponentWithAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.formData,
      suggestions: [],
      isLine1Focused: false,
    };
  }
  onChange(name) {
    return event => {
      const updatedState = {
        [name]: event.target.value,
      };

      if (name === "country") {
        updatedState.suggestions = [];
      }

      this.setState(updatedState, () => this.props.onChange(getAddressFieldsFromState(this.state)));
    };
  }

  handleLine1Change = e => {
    const value = e.target.value;
    this.getSuggestionsForQuery(value);
    this.setState(
      {
        line1: value,
      },
      () => this.props.onChange(getAddressFieldsFromState(this.state))
    );
  };

  handleLine1Focus = () => {
    if (this.state.line1) {
      this.getSuggestionsForQuery(this.state.line1);
    }

    setTimeout(() => {
      this.setState({
        isLine1Focused: true,
      });
    });
  };

  handleLine1Blur = () => {
    setTimeout(() => {
      this.setState({
        isLine1Focused: false,
        suggestions: [],
      });
    });
  };

  handleLine1SuggestionSelect = async (value, item) => {
    try {
      const place = await googlePlacesApi.getPlaceDetails(value);
      let updatedFormData;

      switch (this.state.country) {
        case "United States": {
          updatedFormData = {
            city:
              getAddressComponentValueByType(place.address_components, "locality") ||
              getAddressComponentValueByType(place.address_components, "postal_town"),
            line1: item.structured_formatting.main_text,
            county: getAddressComponentValueByType(
              place.address_components,
              "administrative_area_level_1"
            ),
            postcode: getAddressComponentValueByType(place.address_components, "postal_code"),
          };
          break;
        }
        default: {
          updatedFormData = {
            city:
              getAddressComponentValueByType(place.address_components, "locality") ||
              getAddressComponentValueByType(place.address_components, "postal_town"),
            line1: item.structured_formatting.main_text,
            county: getAddressComponentValueByType(
              place.address_components,
              "administrative_area_level_2"
            ),
            postcode: getAddressComponentValueByType(place.address_components, "postal_code"),
          };
          break;
        }
      }
      this.setState(updatedFormData, () =>
        this.props.onChange(getAddressFieldsFromState(this.state))
      );
    } catch (err) {
      console.warn("Error while retrieving place:", err);
    }
  };

  getSuggestionsForQuery = debounce(async query => {
    try {
      const countryCode = COUNTRIES.find(c => c.name === this.state.country).code;
      const suggestions = await googlePlacesApi.getAutocompleteSuggestions(query, countryCode);
      this.setState({
        suggestions: suggestions,
      });
    } catch (err) {
      this.setState({
        suggestions: [],
      });
    }
  }, DEBOUNCE_DELAY_FOR_SUGGESTIONS_FETCHING);

  render() {

    const showCountryDropdown = this.props.formContext.showCountry;

    const { schema } = this.props;

    const countryPlaceholder = this.state.country === "United Kingdom" ? "a UK" : "an";

    const options = this.state.suggestions.map((suggestion, index) => (
      <Option
        key={suggestion.place_id}
        onTouchStart={() =>
          this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
        }
        onMouseDown={() =>
          this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
        }
        onKeyDown={() =>
          this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
        }
      >
        {suggestion.description}
      </Option>
    ));


    const autoCompleteStreet = (
      <div className="form-group field">
        <label className="control-label">Street Address*</label>
        <AutoComplete
          onSearch={this.handleLine1Change}
          onChange={this.handleLine1Change}
          dataSource={options}
          placeholder={`Start typing ${countryPlaceholder} address...`}
          style={{ width: '100%', borderRadius: '3px', height: '44px'}}
          size="large"
          value={this.state.line1}
        >
          {/* {this.state.suggestions.map((suggestion, index) => (
            <Option
              key={suggestion.place_id}
              onTouchStart={() =>
                this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
              }
              onMouseDown={() =>
                this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
              }
            >
              {suggestion.description}
            </Option>
          ))} */}
          <Input suffix={<Icon type="search" className="certain-category-icon" />} allowClear={true}/>
        </AutoComplete>
      </div>
    )

    const autocompleteStreetAddress = (
      <div className="form-group field">
        <label className="control-label">Street Address*</label>
        <input
          type="text"
          className="form-control"
          placeholder={`Start typing ${countryPlaceholder} address...`}
          value={this.state.line1}
          onChange={this.handleLine1Change}
          onFocus={this.handleLine1Focus}
          onBlur={this.handleLine1Blur}
          autoComplete={'nope'}
          required
        />
        {this.state.suggestions.length > 0 && this.state.isLine1Focused && (
          <ul className="autocomplete">
            {this.state.suggestions.map((suggestion, index) => (
              <li
                className="autocomplete__item"
                key={index}
                onTouchStart={() =>
                  this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
                }
                onMouseDown={() =>
                  this.handleLine1SuggestionSelect(suggestion.place_id, suggestion)
                }
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        )}
      </div>
    );

    let countyInput;

    switch (this.state.country) {
      case "United States": {
        countyInput = (
          <Dropdown
            id="county"
            label={schema.properties.county.title}
            value={this.state.county}
            options={provincesByCountry["US"].concat(militaryStates).map(c => c.name)}
            onChange={this.onChange("county")}
            required={schema.required.includes("county")}
          />
        );
        break;
      }
      default:
        countyInput = (
          <TextInput
            id="county"
            label={schema.properties.county.title}
            value={this.state.county}
            onChange={this.onChange("county")}
            required={schema.required.includes("county")}
          />
        );
        break;
    }

    return (
      <div>
        <TextInput
          id="first_name"
          label={schema.properties.first_name.title}
          value={this.state.first_name}
          onChange={this.onChange("first_name")}
          required={schema.required.includes("first_name")}
        />
        {showCountryDropdown && (
          <Dropdown
          id="country"
          label={schema.properties.country.title}
          value={this.state.country}
          options={schema.properties.country.enum}
          onChange={this.onChange("country")}
          required={schema.required.includes("country")}
        />
        )}
        {autocompleteStreetAddress}
        {/* {autoCompleteStreet} */}
        <TextInput
          id="line2"
          label={schema.properties.line2.title}
          value={this.state.line2}
          onChange={this.onChange("line2")}
          required={schema.required.includes("line2")}
        />
        <TextInput
          id="city"
          label={schema.properties.city.title}
          value={this.state.city}
          onChange={this.onChange("city")}
          required={schema.required.includes("city")}
        />
        {countyInput}
        <TextInput
          id="postcode"
          label={schema.properties.postcode.title}
          value={this.state.postcode}
          onChange={this.onChange("postcode")}
          required={schema.required.includes("postcode")}
        />
      </div>
    );
  }
}

class AddressForm extends Component {
  static propTypes = {};

  static defaultProps = {
    showCountry: true
  };

  submitForm = () => {
    this.submitButton.node.click();
  };

  render() {
    const formSchema =
      addressSchemas.schema[this.props.formData.country] || addressSchemas.schema.default;

    const uiSchema = {
      "ui:field": "address",
    };

    const fields = {
      address: AddressComponentWithAutocomplete,
    };

    return (
      <Form
        autoComplete={'off'}
        schema={formSchema}
        uiSchema={uiSchema}
        fields={fields}
        formContext={this.props}
        formData={this.props.formData}
        onChange={this.props.onChange}
        onSubmit={this.props.onSubmit}
        key={`${this.props.ref}-${this.props.formData.id}`}
      >
        <Button
          type="submit"
          label="Submit"
          buttonRef={el => (this.submitButton = el)}
          className="hidden"
        />
      </Form>
    );
  }
}

export default AddressForm;
