import React, { Component } from "react";
import PropTypes from "prop-types";
import Cropper from "cropperjs";
import 'cropperjs/dist/cropper.css'

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Footer from "../Footer/Footer";
import MainContent from "../MainContent/MainContent";
import PhotoMagazineCoverBand from "../PhotoMagazineCoverBand/PhotoMagazineCoverBand";
import { message } from 'antd';

import classNames from "classnames";

import './EditorCropperModal.scss';

const PREVIEW_CROPPER_NODE_WIDTH = 500;

class EditorCropperModal extends Component {
  static propTypes = {
    imgUrl: PropTypes.string,
    ratio: PropTypes.number,
    isOpen: PropTypes.bool,
    renderPhotoMagazineCoverBand: PropTypes.bool,
    photoMagazineCoverText: PropTypes.shape({
      firstLine: PropTypes.string,
      secondLine: PropTypes.string,
    }),
    onSave: PropTypes.func,
    onRotate: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    ratio: 1,
    onRotate: () => {},
  };

  state = {
    dragMode: "crop",
  };

  componentDidUpdate(prevProps) {
    const didOpen = !prevProps.isOpen && this.props.isOpen;
    const didClose = prevProps.isOpen && !this.props.isOpen;

    if (didOpen) {
      this.setState({
        cachedCropData: null,
      });
      setTimeout(this.initCropper, 300);
    }

    if (prevProps.ratio !== this.props.ratio && this.cropper) {
      this.setAspectRatio(this.props.ratio);
    }

    if (didClose) {
      this.cropper && this.cropper.destroy();
    }
  }

  componentWillUnmount() {
    this.cropper && this.cropper.destroy();
  }

  showCropperMessage = () => {
    message.config({
      top: 58,
      duration: 5,
      maxCount: 3,
      key: 'crop-warning',
    });
    message.info('Adjust the box to your preferred cropping', 4);
  };
  

  initCropper = () => {
    //let cropperData = { x: 0, y: 0 }; // top-left
    let cropperData = {}; //center

    if (this.props.cropData) {
      cropperData = this.props.cropData;
    }

    if (this.state.cachedCropData) {
      cropperData = this.state.cachedCropData;
    }

    const $cropperPreviewNode = document.getElementById("cropper-preview");

    this.cropper = new Cropper(this.cropperImgElement, {
      aspectRatio: this.props.ratio,
      data: cropperData,
      viewMode: 1,
      preview: $cropperPreviewNode,
      minCropBoxWidth: 50,
      background: false,
      strict: true,
      guides: true,
      autoCropArea: 1,
      ready: () => {
        if (cropperData && (cropperData.rotate === 90 || cropperData.rotate === 270)) {
          this.fitCroppedImageToCropper();
        }

        // We have to add a slight timeout before we position the photo band, otherwise the modal animation will mess
        // up the initial positioning.
        setTimeout(() => {
          this.fitPhotoMagazineCoverBandToCropperBox();
        }, 350);
        //this.fitPhotoMagazineCoverBandToCropperBox();

        //this.showCropperMessage();

      },
      cropmove: this.fitPhotoMagazineCoverBandToCropperBox,
    });

    this.setAspectRatio(this.props.ratio);
  };

  setAspectRatio = ratio => {
    //console.log("setAspectRatio:", ratio);
    const $cropperPreviewNode = document.getElementById("cropper-preview");
    $cropperPreviewNode.style.width = `${PREVIEW_CROPPER_NODE_WIDTH}px`;
    $cropperPreviewNode.style.height = `${PREVIEW_CROPPER_NODE_WIDTH * ratio}px`;
    this.cropper.setAspectRatio(ratio);
  };

  fitPhotoMagazineCoverBandToCropperBox = () => {
    if (!this.photoMagazineCoverBandContainer) {
      return;
    }

    const { left, top, width, height } = this.cropperContainerElement
      .querySelector(".cropper-crop-box")
      .getBoundingClientRect();

    Object.assign(this.photoMagazineCoverBandContainer.style, {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      opacity: "1",
    });

    this.photoMagazineCoverBandContainer.querySelector(
      ".photo-magazine-cover-band"
    ).style.fontSize = `${height * 0.03}px`;
  };

  fitCroppedImageToCropper = () => {
    console.log("Fitting image to cropper");
    // Most of this code is taken from http://stackoverflow.com/a/32938693
    let contData = this.cropper.getContainerData();
    this.cropper.setCropBoxData({
      width: 2,
      height: 2,
      top: contData.height / 2 - 1,
      left: contData.width / 2 - 1,
    });

    let canvData = this.cropper.getCanvasData();
    const newWidth = canvData.width * (contData.height / canvData.height);

    let newCanvData;

    if (newWidth >= contData.width) {
      const newHeight = canvData.height * (contData.width / canvData.width);
      newCanvData = {
        height: newHeight,
        width: contData.width,
        top: (contData.height - newHeight) / 2,
        left: 0,
      };
    } else {
      newCanvData = {
        height: contData.height,
        width: newWidth,
        top: 0,
        left: (contData.width - newWidth) / 2,
      };
    }

    this.cropper.setCanvasData(newCanvData);
    this.cropper.render();
    if (!this.props.cropData){
      this.cropper.setCropBoxData(newCanvData);
    } else{
      console.log("Adjusting crop using existing crop data");

      // let contData = this.cropper.getContainerData();
      let canvData = this.cropper.getCanvasData();
      let scale;
      //let newCanvData;

      if (canvData.height < contData.height){
        console.log("Portrait photo rotated to landscape...");

        scale = canvData.height / contData.height;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: (this.props.cropData.y * scale)+canvData.top,
          left: (this.props.cropData.x * scale)
        };
      } else{
        console.log("Landscape photo rotated to portrait...");        
        scale = contData.height / canvData.height;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: this.props.cropData.y * scale,
          left: (this.props.cropData.x * scale) +canvData.left
        };
      }
      setTimeout(()=> {
        this.cropper.setCropBoxData(newCanvData);
      },10)
    }
  };

  handleClickSave = () => {
    const $previewContainer = document.getElementById("cropper-preview");
    const $previewImg = $previewContainer.firstChild;
    const imgStyles = window.getComputedStyle($previewImg);
    let transformMatrix = null;

    if (imgStyles.transform !== "none") {
      transformMatrix = /matrix\((.*)\)/g
        .exec(imgStyles.transform)[1]
        .split(", ")
        .map(v => Number(v));
    }

    const transform = {
      containerWidth: $previewContainer.offsetWidth,
      containerHeight: $previewContainer.offsetHeight,
      width: parseFloat(imgStyles.width),
      height: parseFloat(imgStyles.height),
      translateX: transformMatrix ? transformMatrix[4] : 0,
      translateY: transformMatrix ? transformMatrix[5] : 0,
    };

    this.props.onSave({
      ...this.cropper.getData(),
      transform,
    });
  };

  rotateCroppedImage = rotation => {
    this.cropper.rotate(rotation);
    //this.cropper.render();
    this.fitCroppedImageToCropper();
    this.fitPhotoMagazineCoverBandToCropperBox();
    this.props.onRotate(this.cropper.getData().rotate);
  };

  zoomCroppedImage = zoomLevel => {
    this.cropper.zoom(zoomLevel);
  };

  setDragMode = dragMode => {
    console.log("Setting drag mode:", dragMode);
    this.cropper.setDragMode(dragMode);
    this.setState({
      dragMode,
    });
  };

  render() {

    const modalCropperInfoClasses = classNames("editor-modal__cropper-info", {
      "animated fadeIn": this.props.isOpen,
    });

    const modalCropperClasses = classNames("editor-modal__cropper", {
      "animated fadeIn": this.props.isOpen,
    });

    return (
      <Modal
        title="Crop, Zoom & Rotate"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        rightAction={<Button priority="tertiary" label="Save" onClick={this.handleClickSave} />}
      >
        <MainContent scrollable={false} padded={true}>
          <React.Fragment>
            {/* <div style={{ textAlign: "center", marginBottom: '10px', marginTop: '-15px' }}>
              {this.props.onHandleReplaceImage && (
                <Button
                  onClick={this.props.onHandleReplaceImage}
                  label={'Change Photo'}
                  size="small"
                  priority="secondary"
                  theme="muted"
                />
              )}
              
            </div> */}
            <div
              className={modalCropperClasses}
              style={{ height: Math.min(600, window.innerHeight * 0.6) }}
              ref={el => (this.cropperContainerElement = el)}
            >
              <img src={this.props.imgUrl} ref={el => (this.cropperImgElement = el)} alt="" />
              {this.props.renderPhotoMagazineCoverBand &&
                (this.props.photoMagazineCoverText.firstLine ||
                  this.props.photoMagazineCoverText.secondLine) && (
                  <div
                    style={{
                      position: "fixed",
                      pointerEvents: "none",
                      opacity: 0,
                      transition: "opacity 0.2s",
                    }}
                    ref={el => (this.photoMagazineCoverBandContainer = el)}
                  >
                    <PhotoMagazineCoverBand
                      firstLine={this.props.photoMagazineCoverText.firstLine}
                      secondLine={this.props.photoMagazineCoverText.secondLine}
                    />
                  </div>
                )}
            </div>
            <div className={modalCropperInfoClasses}>
              <p>Your photo will be cropped to the green box.<br/>Adjust the box to your preferred cropping</p>
            </div>
          </React.Fragment>
        </MainContent>
        <Footer>
          <React.Fragment>
            <div style={{ position: "absolute", left: '10px', bottom: '13px' }}>
              {this.props.onHandleReplaceImage && (
                <Button
                  onClick={this.props.onHandleReplaceImage}
                  label={'Change Photo'}
                  size="small"
                  priority="secondary"
                  theme="muted"
                />
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                icon="rotate-ccw"
                priority="tertiary"
                theme="muted"
                onClick={() => this.rotateCroppedImage(-90)}
              />
              <Button
                icon="rotate-cw"
                priority="tertiary"
                theme="muted"
                onClick={() => this.rotateCroppedImage(90)}
              />
            </div>
          </React.Fragment>
        </Footer>
      </Modal>
    );
  }
}

export default EditorCropperModal;
