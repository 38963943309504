import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererMask extends React.Component {
  static propTypes = {
    style: PropTypes.oneOf(["corners-and-dotted-lines"]),
    canvasDimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    addedPhoto: PropTypes.bool,
    productDimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      bleed: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  static defaultProps = {};

  render() {
    let maskElements = [];
    const productDimensions = this.props.productDimensions;
    const bleed = productDimensions.bleed;
    //TODO: top/left/right/bottom should also use the offsets to ensure the mask doesn't get overlaid on the flaps
    switch (this.props.style) {
      case "corners-and-dotted-lines": {
        maskElements = [
          <div
            key="top"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--horizontal", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              top: (bleed.top / productDimensions.height) * this.props.canvasDimensions.height,
            }}
          />,
          <div
            key="bottom"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--horizontal", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              bottom:
                (bleed.bottom / productDimensions.height) * this.props.canvasDimensions.height,
            }}
          />,
          <div
            key="left"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--vertical", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              left: (bleed.left / productDimensions.width) * this.props.canvasDimensions.width,
            }}
          />,
          <div
            key="right"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--vertical", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              right: (bleed.right / productDimensions.width) * this.props.canvasDimensions.width,
            }}
          />,
          <div
            key="tl"
            className="html-renderer-mask__corner"
            style={{
              height: (bleed.top / productDimensions.height) * this.props.canvasDimensions.height,
              width: (bleed.left / productDimensions.width) * this.props.canvasDimensions.width,
              top: 0,
              left: 0,
            }}
          />,
          <div
            key="tr"
            className="html-renderer-mask__corner"
            style={{
              height: (bleed.top / productDimensions.height) * this.props.canvasDimensions.height,
              width: (bleed.right / productDimensions.width) * this.props.canvasDimensions.width,
              top: 0,
              right: 0,
            }}
          />,
          <div
            key="bl"
            className="html-renderer-mask__corner"
            style={{
              height:
                (bleed.bottom / productDimensions.height) * this.props.canvasDimensions.height,
              width: (bleed.left / productDimensions.width) * this.props.canvasDimensions.width,
              bottom: 0,
              left: 0,
            }}
          />,
          <div
            key="br"
            className="html-renderer-mask__corner"
            style={{
              height:
                (bleed.bottom / productDimensions.height) * this.props.canvasDimensions.height,
              width: (bleed.right / productDimensions.width) * this.props.canvasDimensions.width,
              bottom: 0,
              right: 0,
            }}
          />,
        ];
        break;
      }
      case "bleed-bands": {
        maskElements = [
          <div
            key="top"
            className="html-renderer-mask__band html-renderer-mask__band--horizontal"
            style={{
              top: -2,
              height:
                (bleed.top / productDimensions.height) * this.props.canvasDimensions.height + 2,
            }}
          />,
          <div
            key="bottom"
            className="html-renderer-mask__band html-renderer-mask__band--horizontal"
            style={{
              bottom: -3,
              height:
                (bleed.bottom / productDimensions.height) * this.props.canvasDimensions.height + 3,
            }}
          />,
          <div
            key="left"
            className="html-renderer-mask__band html-renderer-mask__band--vertical"
            style={{
              left: -2,
              width: (bleed.left / productDimensions.width) * this.props.canvasDimensions.width + 2,
            }}
          />,
          <div
            key="right"
            className="html-renderer-mask__band html-renderer-mask__band--vertical"
            style={{
              right: -2,
              width:
                (bleed.right / productDimensions.width) * this.props.canvasDimensions.width + 2,
            }}
          />,
        ];
        break;
      }
      // no default
    }

    return (
      <HtmlRendererRect className="html-renderer-mask" rect={this.props.rect}>
        {maskElements}
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererMask;
