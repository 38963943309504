import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import isEqual from "lodash/isEqual";

import * as LAYER_TYPES from "../../constants/layer-types";
import getElementDimensions from "../../lib/get-element-dimensions";

import HtmlRendererPhotoLayer from "./HtmlRendererPhotoLayer";
import HtmlRendererPlaceholderLayer from "./HtmlRendererPlaceholderLayer";
import HtmlRendererTextLayer from "./HtmlRendererTextLayer";
import HtmlRendererGraphicLayer from "./HtmlRendererGraphicLayer";
import HtmlRendererIndiciaLayer from "./HtmlRendererIndiciaLayer";
import HtmlRendererAddressLayer from "./HtmlRendererAddressLayer";
import HtmlRendererSignatureLayer from "./HtmlRendererSignatureLayer";
import HtmlRendererRect from "./HtmlRendererRect";
import HtmlRendererMask from "./HtmlRendererMask";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import PhotoMagazineCoverBand from "../PhotoMagazineCoverBand/PhotoMagazineCoverBand";

import './HtmlRenderer.scss';

const MIN_RENDERER_WIDTH = 500;

const COLORS_PER_LAYER_TYPE = {
  [LAYER_TYPES.PHOTO]: "rgba(255,0,255,0.2)",
  [LAYER_TYPES.ADDRESS]: "rgba(255,0,0,0.2)",
  [LAYER_TYPES.TEXT]: "rgba(0,255,0,0.2)",
  TRANSFORMED_TEXT: "rgba(0,180,0,120.2)",
  [LAYER_TYPES.SIGNATURE]: "rgba(255,255,0,0.2)",
  [LAYER_TYPES.GRAPHIC]: "rgba(60,60,60,0.2)",
  [LAYER_TYPES.INDICIA]: "rgba(255,0,255,0.2)",
};

class HtmlRenderer extends React.Component {
  static propTypes = {
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    item: PropTypes.object.isRequired,
    width: PropTypes.number,
    isInteractive: PropTypes.bool,
    screenshotMode: PropTypes.bool,
    previewMode: PropTypes.bool,
    debug: PropTypes.bool,
    selectedLayerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedRegionIndex: PropTypes.number,
    onClick: PropTypes.func,
    onTextPositionChange: PropTypes.func,
    onTextContentChange: PropTypes.func,
    onSelectRegionInPhotoLayer: PropTypes.func,
    onMoveImageInRegionInPhotoLayer: PropTypes.func,
    onClearLayerSelection: PropTypes.func,
    onDeselectTextLayer: PropTypes.func,
    onSelectTextLayer: PropTypes.func,
    onClickTextLayer: PropTypes.func,
    onStartEditingTextLayer: PropTypes.func,
    onSelectTextPlaceholderLayer: PropTypes.func,
    onClickSignatureLayer: PropTypes.func,
    onClickAddressLayer: PropTypes.func,
    isPortraitProduct: PropTypes.bool,
  };

  static defaultProps = {
    isInteractive: true,
    onTextPositionChange: () => {},
    onClick: () => {},
  };

  state = {
    backgroundColor: "transparent",
  };

  layerRefs = {};

  componentDidMount() {
    this.setDimensions();
  }

  componentDidUpdate() {
    this.setDimensions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.photoMagazine){
      if (nextProps.showingPage){
        return nextProps.showingPage;
      } else{
        return false;
      }
    } else {
      return true
    }
  }

  setDimensions = () => {
    const dimensions = this.props.item.productDimensions;
    if (!dimensions.bleed) {
      dimensions.bleed = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
    }

    let ratio =
      (dimensions.width + dimensions.bleed.left + dimensions.bleed.right) /
      (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom);

    if (this.props.item.isRotated) {
      ratio =
        (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom) /
        (dimensions.width + dimensions.bleed.left + dimensions.bleed.right);
    }

    const elementDimensions = getElementDimensions(ReactDOM.findDOMNode(this).parentElement);
    const parentContainerWidth = this.props.containerWidth || elementDimensions.width;
    const parentContainerHeight =
      this.props.containerHeight ||
      elementDimensions.height ||
      getElementDimensions(ReactDOM.findDOMNode(this).parentElement.parentElement).height;
    let width;
    let height;

    if (this.props.width) {
      /**
       * If the given width is smaller than the minimum for us to render the product correctly (due to positioning
       * differences when the dimensions are small enough, related to rounding), we set the renderer to the min width
       * and then scale it down to the right dimensions in the render() method (by setting the given dimensions on the
       * container, and scaling down the renderer itself).
       */
      if (this.props.width < MIN_RENDERER_WIDTH) {
        width = MIN_RENDERER_WIDTH;
      } else {
        width = this.props.width;
      }
    } else {
      width = parentContainerWidth;
    }

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      height = width;
      width = Math.round(width * ratio);
    } else {
      height = Math.round(width / ratio);
    }

    if (this.props.page === "ENVELOPE" && this.props.isPortraitProduct) {
      height = Math.round(width * ratio);
    }

    if (height > parentContainerHeight && !this.props.width) {
      height = parentContainerHeight;
      width = Math.round(parentContainerHeight * ratio);
    }

    const currentState = {
      width: this.state.width,
      height: this.state.height,
      parentContainerHeight: this.state.parentContainerHeight,
      parentContainerWidth: this.state.parentContainerWidth,
      backgroundColor: this.props.screenshotMode ? "transparent" : "#fff",
    };

    const updatedState = {
      width: width,
      height: height,
      parentContainerHeight,
      parentContainerWidth,
      backgroundColor: this.props.screenshotMode ? "transparent" : "#fff",
    };

    if (!isEqual(currentState, updatedState)) {
      this.setState(updatedState);
    }
  };

  getDimensions = () => {
    return {
      width: this.state.width,
      height: this.state.height,
    };
  };

  clearSelection = () => {
    // TODO: IMPLEMENT?
  };

  handleClick = e => {
    if (e.target === this.$renderer && this.props.isInteractive) {
      this.props.onClearLayerSelection();
    } else {
      this.props.onClick();
    }
  };

  render() {
    const isCanvas = this.props.item.productTypeId === PRODUCT_TYPE_IDS.CANVAS;
    let scale = (this.props.width || this.state.width) / this.state.width;

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      scale = this.props.width / this.state.height;
    }

    const isCanvasPreviewMode = !this.props.screenshotMode && this.props.previewMode && isCanvas;

    const rendererStyles = {
      width: this.state.width,
      height: this.state.height,
      backgroundColor: this.state.backgroundColor,
      transform: isCanvasPreviewMode ? `translateX(-3%) scale(${scale * 1.2})` : `scale(${scale})`,
      transformOrigin: "left top",
    };

    const containerStyles = {
      width: this.props.width || this.state.width,
      height: (this.props.width || this.state.width) / (this.state.width / this.state.height) || "",
    };

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      containerStyles.height = this.props.width;
      containerStyles.width = this.props.width * (this.state.width / this.state.height);
    }

    if (this.props.item.isRotated && this.props.screenshotMode) {
      containerStyles.transform = containerStyles.WebkitTransform = `rotate(90deg) translateX(-${(this
        .state.height -
        this.state.width) /
        2}px)`;
    }

    const layersForCurrentPage = this.props.item.layers
      .filter(layer => layer.page === this.props.page)
      .map(layer => ({
        ...layer,
        config: {
          ...layer.config,
          max_length: parseInt(layer.config.max_length, 10),
          lock: Boolean(layer.config.lock),
        },
      }))
      .sort((a, b) => a.zindex - b.zindex);

    let layers = layersForCurrentPage.map(layer => {
      const genericLayerProps = {
        key: layer.id,
        id: layer.id,
        item: this.props.item,
        currentEditorStep: this.props.currentEditorStep,
        ref: e => (this.layerRefs[layer.id] = e),
        config: layer.config,
        debug: this.props.debug,
        isSelected: this.props.page === 0 && layer.id === this.props.selectedLayerId,
        isRendererContainerFlipped: this.props.isContainingElementFlipped,
        rendererDOMNode: this.$renderer,
        screenshotMode: this.props.screenshotMode,
        previewMode: this.props.previewMode,
        canvasDimensions: {
          width: this.state.width,
          height: this.state.height,
        },
      };

      switch (layer.type) {
        case LAYER_TYPES.PHOTO:
          return (
            <HtmlRendererPhotoLayer
              {...genericLayerProps}
              effect={isCanvas && !this.props.screenshotMode ? "canvas" : null}
              onClickRegion={regionIndex =>
                this.props.onSelectRegionInPhotoLayer(layer.id, regionIndex)
              }
            />
          );
        case LAYER_TYPES.TEXT:
          if (
            !layer.config.text &&
            layer.id !== "EXTRA_TEXT_LAYER" &&
            this.props.page !== 0 &&
            !this.props.screenshotMode &&
            this.props.isInteractive
          ) {
            return (
              <HtmlRendererPlaceholderLayer
                {...genericLayerProps}
                message="Add message"
                onClick={dimensions => this.props.onClickTextLayer(layer.id, dimensions)}
              />
            );
          } else {
            const shouldShowTextLayer =
              (layer.config.text || (layer.id === "EXTRA_TEXT_LAYER" && layer.config.text)) &&
              (this.props.screenshotMode
                ? layer.config.text !== layer.config.placeholderText
                : true) &&
              (!this.props.isInteractive
                ? layer.config.text !== layer.config.placeholderText
                : true);
            if (shouldShowTextLayer) {
              return (
                <HtmlRendererTextLayer
                  {...genericLayerProps}
                  lock={this.props.page !== 0}
                  onChangePosition={position => this.props.onTextPositionChange(layer.id, position)}
                  onClick={dimensions => this.props.onClickTextLayer(layer.id, dimensions)}
                  onClickDelete={() => this.props.onDeleteTextLayerContents(layer.id)}
                  onClickEdit={() => this.props.onStartEditingTextLayer(layer.id)}
                />
              );
            }
          }
          break;
        case LAYER_TYPES.ADDRESS:
          if (!(this.props.item.address || this.props.item.addressBookEntry)) {
            if (!this.props.screenshotMode && !this.props.previewMode && this.props.isInteractive) {
              return (
                <HtmlRendererPlaceholderLayer
                  {...genericLayerProps}
                  message="Add name & address"
                  onClick={this.props.onClickAddressLayer}
                />
              );
            }
          } else {
            return (
              <HtmlRendererAddressLayer
                {...genericLayerProps}
                productDimensions={this.props.item.productDimensions}
                address={this.props.item.address || this.props.item.addressBookEntry}
                onClick={this.props.onClickAddressLayer}
              />
            );
          }
          break;
        case LAYER_TYPES.SIGNATURE:
          if (layer.config.drawing || layer.config.avatar) {
            return (
              <HtmlRendererSignatureLayer
                {...genericLayerProps}
                onClick={() => this.props.onClickSignatureLayer(layer.id)}
              />
            );
          } else {
            if (!this.props.screenshotMode && !this.props.previewMode && this.props.isInteractive) {
              return (
                <HtmlRendererPlaceholderLayer
                  {...genericLayerProps}
                  message="Add signature"
                  onClick={() => this.props.onClickSignatureLayer(layer.id)}
                />
              );
            }
            break;
          }
        case LAYER_TYPES.GRAPHIC:
          //if (!this.props.screenshotMode && !this.props.previewMode) {
          return (
            <HtmlRendererGraphicLayer
              {...genericLayerProps}
              onClick={() => this.props.onDeselectTextLayer()}
            />
          );
        case LAYER_TYPES.INDICIA:
          return <HtmlRendererIndiciaLayer {...genericLayerProps} />;
        default:
          console.warn("Renderer has no layer component for type:", layer.type);
          return null;
      }

      return null;
    });

    if (this.props.debug) {
      layers = [
        ...layers,
        layersForCurrentPage.map(layer => {
          if (layer.type === LAYER_TYPES.TEXT) {
            return [
              layer.config.rect.transformedRect && (
                <HtmlRendererRect
                  className="html-renderer-debug-layer"
                  rect={layer.config.rect.transformedRect}
                  style={{
                    backgroundColor: COLORS_PER_LAYER_TYPE.TRANSFORMED_TEXT,
                  }}
                >
                  <div className="html-renderer-debug-layer__layer-id">{layer.id}-TRANSF.RECT</div>
                </HtmlRendererRect>
              ),
              <HtmlRendererRect
                className="html-renderer-debug-layer"
                rect={layer.config.rect}
                style={{ backgroundColor: COLORS_PER_LAYER_TYPE[layer.type] }}
              >
                <div className="html-renderer-debug-layer__layer-id">{layer.id}</div>
              </HtmlRendererRect>,
            ];
          } else {
            return (
              <HtmlRendererRect
                className="html-renderer-debug-layer"
                rect={layer.config.rect}
                style={{ backgroundColor: COLORS_PER_LAYER_TYPE[layer.type] }}
              >
                <div className="html-renderer-debug-layer__layer-id">{layer.id}</div>
              </HtmlRendererRect>
            );
          }
        }),
      ];
    }

    if (this.props.mask) {
      const canvasDimensions = {
        width: this.state.width,
        height: this.state.height,
      };

      const photosAdded = this.props.item.layers.filter(layer => {
        return layer.type === LAYER_TYPES.PHOTO && layer.config.layout[0].image !== undefined;
      });

      layers = [
        ...layers,
        <HtmlRendererMask
          addedPhoto={photosAdded.length > 0}
          key="mask"
          style={this.props.mask.style}
          rect={this.props.mask.rect}
          canvasDimensions={canvasDimensions}
          productDimensions={this.props.item.productDimensions}
        />,
      ];
    }

    const classes = classNames("html-renderer", {
      "html-renderer--non-interactive": !this.props.isInteractive,
    });

    const containerClasses = classNames("html-renderer-container", {
      "html-renderer-container--no-border": this.props.mask,
    });

    const isPhotoMagazine = this.props.item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE;

    const shouldShowCoverBand =
      isPhotoMagazine &&
      this.props.page === 0 &&
      (!this.props.screenshotMode ||
        (this.props.screenshotMode &&
          this.props.item.rendererReferenceLayerId === "FRONT_PREVIEW"));

    return (
      <div className={containerClasses} style={containerStyles}>
        <div
          className={classes}
          style={rendererStyles}
          ref={el => (this.$renderer = el)}
          onClick={this.handleClick}
        >
          {layers}
          {shouldShowCoverBand && (
            <PhotoMagazineCoverBand
              fontSize={this.state.height * 0.03}
              firstLine={this.props.item.product_options.split("\n")[0]}
              secondLine={this.props.item.product_options.split("\n")[1]}
            />
          )}
        </div>
      </div>
    );
  }
}

export default HtmlRenderer;
