import React from "react";
import PropTypes from "prop-types";

import Button from "../Button/Button";

import './BasketButton.scss';

class BasketButton extends React.Component {
  componentDidUpdate() {
    console.log("Setting basket cookie count to", this.props.totalItemQuantity);
    document.cookie = `basketCount=${
      this.props.totalItemQuantity
    }; domain=${window.location.hostname.replace(/^(app\.)/, "")}; path=/`;
  }

  render() {
    return (
      <div className="basket-button">
        <Button icon="cart" priority="tertiary" theme="muted" onClick={this.props.goToBasket} />
        {this.props.totalItemQuantity > 0 && (
          <div className="basket-button__quantity">{this.props.totalItemQuantity}</div>
        )}
      </div>
    );
  }
}

BasketButton.propTypes = {
  totalItemQuantity: PropTypes.number,
  goToBasket: PropTypes.func,
};

BasketButton.defaultProps = {};

export default BasketButton;
