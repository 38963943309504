import React, { Component } from "react";
import PropTypes from "prop-types";

import SweetAlert from "../SweetAlert/SweetAlert";
import Modal from "../Modal/Modal";
import Form from "../Form/Form";
import Button from "../Button/Button";
import Divider from "../Divider/Divider";
import Grid from "../Grid/Grid";
import { COUNTRIES } from "../../constants/geo";
import ModalPage from "../Modal/ModalPage";

import { countriesByCurrency } from "../../constants/geo";

import { connect } from "react-redux";
import { selectors as basketSelectors } from "../../store/ducks/basket";

const DEFAULT_COUNTRY = "United Kingdom";

const MODAL_PAGES = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

const SCHEMAS = {
  SIGN_IN: {
    showErrorList: false,
    schema: {
      type: "object",
      properties: {
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7 },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signIn",
      email: {
        "ui:widget": "email",
      },
      password: {
        "ui:widget": "password",
      },
    },
  },
  SIGN_UP: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["first_name", "email", "address", "password"],
      properties: {
        salutation: {
          type: "string",
          title: "Title",
          enum: ["Mr", "Mrs", "Ms", "Miss"],
        },
        first_name: { type: "string", title: "First Name" },
        last_name: { type: "string", title: "Last Name" },
        email: { type: "string", title: "Email" },
        mobile: { type: "string", title: "Mobile" },
        address: {
          type: "object",
          properties: {
            address_book_entry: {
              type: "object",
              required: ["line1", "city", "country", "postcode"],
              properties: {
                country: {
                  type: "string",
                  title: "Country",
                  enum: COUNTRIES.map(c => c.name),
                },
                postcode: { type: "string", title: "Postcode/Zip" },
                line1: { type: "string", title: "Line 1" },
                line2: { type: "string", title: "Line 2" },
                city: { type: "string", title: "Town/City" },
                county: { type: "string", title: "County/State" },
              },
            },
          },
        },
        password: { type: "string", title: "Password", minLength: 7 },
        marketing_opt_in: {
          type: "boolean",
          title: "Email preference",
        },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signUp",
      "ui:order": [
        "salutation",
        "first_name",
        "last_name",
        "email",
        "mobile",
        "password",
        "address",
        "marketing_opt_in",
      ],
      marketing_opt_in: {
        "ui:widget": props => {
          return (
            <Grid.Row>
              <Grid.Column size="0 auto">
                <div className="pretty p-svg" style={{ marginRight: 0 }}>
                  <input
                    id="sign-up-marketing-email"
                    type="checkbox"
                    checked={props.value}
                    onChange={() => props.onChange(!props.value)}
                  />
                  <div className="state">
                    <img
                      className="svg"
                      src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                      alt=""
                    />
                    <label htmlFor="">&nbsp;</label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="sign-up-marketing-email">
                  Send me emails with the latest news and offers
                </label>
              </Grid.Column>
            </Grid.Row>
          );
        },
      },
      email: {
        "ui:widget": "email",
      },
      password: {
        "ui:widget": "password",
        "ui:help": "(Must be 7 characters inc. at least one number.)",
      },
    },
  },
  REQUEST_PASSWORD_RESET: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Email" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "forgotPassword",
      email: {
        "ui:widget": "email",
      },
    },
  },
};

class AuthModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSignIn: PropTypes.func,
    onSignUp: PropTypes.func,
    onRequestPasswordReset: PropTypes.func,
  };

  static defaultProps = {};

  defaultState = {
    activePage: MODAL_PAGES.SIGN_IN,
    signInData: {},
    requestPasswordResetData: {},
    signUpData: {
      marketing_opt_in: true,
      address: {
        address_book_entry: {
          country: countriesByCurrency[this.props.currency] || DEFAULT_COUNTRY,
        },
      },
    },
    alert: null,
    isLoadingRequest: false,
  };

  state = { ...this.defaultState };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(this.defaultState);
    }
  }

  handleSignInFormChange = form => {
    this.setState({
      signInData: form.formData,
    });
  };

  handleSignInFormSubmit = async form => {
    const { email, password } = form.formData;
    this.setState({
      isLoadingRequest: true,
    });
    try {
      const result = await this.props.onSignIn({ email, password });
      if (result && result.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't sign you in",
            text: result.payload.data.error,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.props.onClose();
      }
    } catch (err) {
      console.warn("Error while signing in:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  handleRequestPasswordResetFormChange = form => {
    this.setState({
      requestPasswordResetData: form.formData,
    });
  };

  handleRequestPasswordResetFormSubmit = async form => {
    const { email } = form.formData;
    this.setState({
      isLoadingRequest: true,
    });

    try {
      const result = await this.props.onRequestPasswordReset(email);
      if (result.payload.data.success) {
        this.props.onClose();
        this.setState({
          alert: {
            type: "success",
            title: "Email sent",
            text: "Please check your email for instructions on how to reset your password.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        const errors = result.payload.data.errors;
        let title;
        let text;

        if (errors.email) {
          if (errors.email[0] === "not found") {
            title = "Email not found";
            text =
              "This email address was not found. Please check the email address for any mistakes and try again.";
          }
        }

        this.setState({
          alert: {
            type: "error",
            title,
            text,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      }
    } catch (err) {
      console.warn("Error while requesting password reset:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  handleSignUpFormChange = form => {
    this.setState({
      signUpData: form.formData,
    });
  };

  handleSignUpFormSubmit = async form => {
    const signUpData = { ...form.formData };
    signUpData.address.address_book_entry.first_name = signUpData.first_name;
    signUpData.address.address_book_entry.last_name = signUpData.last_name;
    signUpData.address.address_book_entry.salutation = signUpData.salutation;

    this.setState({
      isLoadingRequest: true,
    });

    try {
      const result = await this.props.onSignUp(signUpData);

      if (result.error) {
        const errors = result.payload.data.errors;
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't create your account",
            text: errors[Object.keys(errors)[0]][0],
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Account Created",
            text: "Your account has been created, and you have been signed in.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
        this.props.onClose();
      }
    } catch (err) {
      const errors = err.payload.data.errors;
      this.setState({
        alert: {
          type: "error",
          title: "Couldn't create your account",
          text: errors[Object.keys(errors)[0]][0],
          showCancelButton: false,
        },
      });
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  setActivePage = activePage => {
    this.setState({ activePage });
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    let headerTitle;
    let headerLeftAction = (
      <Button priority="tertiary" theme="muted" icon="clear" onClick={this.props.onClose} />
    );

    switch (this.state.activePage) {
      case MODAL_PAGES.SIGN_IN: {
        headerTitle = "Sign In";
        break;
      }
      case MODAL_PAGES.SIGN_UP: {
        headerTitle = "Sign Up";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_IN)}
          />
        );
        break;
      }
      case MODAL_PAGES.FORGOT_PASSWORD: {
        headerTitle = "Reset Password";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_IN)}
          />
        );
        break;
      }
      // no default
    }

    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Modal
        key="auth-modal"
        padded
        title={headerTitle}
        leftAction={headerLeftAction}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        className="auth-modal"
        paged
        activePage={this.state.activePage}
      >
        <ModalPage pageId={MODAL_PAGES.SIGN_IN} depth={1}>
          <div>
            <Form
              {...SCHEMAS.SIGN_IN}
              formData={this.state.signInData}
              onChange={this.handleSignInFormChange}
              onSubmit={this.handleSignInFormSubmit}
            >
              <Button
                block
                type="submit"
                label="Sign In"
                loadingLabel="Signing In"
                loading={this.state.isLoadingRequest}
              />
            </Form>
            <br />
            <Button
              block
              label="Forgotten Password?"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.FORGOT_PASSWORD)}
            />
          </div>
          <Divider label="or" />
          <Button
            block
            label="Sign Up"
            priority="secondary"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_UP)}
          />
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.SIGN_UP} depth={2}>
          <Form
            {...SCHEMAS.SIGN_UP}
            formData={this.state.signUpData}
            onChange={this.handleSignUpFormChange}
            onSubmit={this.handleSignUpFormSubmit}
          >
            <p>* Required</p>
            <Button
              block
              type="submit"
              label="Sign Up"
              loadingLabel="Creating Account"
              loading={this.state.isLoadingRequest}
            />
          </Form>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.FORGOT_PASSWORD} depth={2}>
          <Form
            {...SCHEMAS.REQUEST_PASSWORD_RESET}
            formData={this.state.requestPasswordResetData}
            onChange={this.handleRequestPasswordResetFormChange}
            onSubmit={this.handleRequestPasswordResetFormSubmit}
          >
            <Button
              block
              type="submit"
              label="Reset Password"
              loadingLabel="Requesting Reset"
              loading={this.state.isLoadingRequest}
            />
          </Form>
        </ModalPage>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

export default connect(mapStateToProps)(AuthModal);
