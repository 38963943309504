import React from "react";
import classNames from "classnames";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { ConnectedRouter, push } from "react-router-redux";
import moment from "moment";
import debounce from "lodash/debounce";
import cookie from "cookie";
import { Helmet } from "react-helmet";
import SlackFeedback, { themes } from 'react-slack-feedback';
import feedbackTheme from "../../constants/feedback-theme";
import Konami from 'react-konami-code';
import axios from 'axios';
import $ from 'jquery';
import Intercom from "../../lib/intercom";


import { history, store } from "../../index";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";
import { routeDefinitions, routeCreators, redirects, PostcardEditor } from "../../lib/routes";
import { getEarliestPostingDateForCurrentTime } from "../../lib/postage-date-calculator";
import { actions as uiActions, selectors as uiSelectors } from "../../store/ducks/ui";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as authActions, selectors as authSelectors } from "../../store/ducks/auth";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import { getAppValueByKey } from "../../data/app-values";
import * as designsData from "../../data/designs";

import Sidebar from "../Sidebar/Sidebar";
import SweetAlert from "../SweetAlert/SweetAlert";
import CookieWarning from "../CookieWarning/CookieWarning";
import AuthModal from "../AuthModal/AuthModal";
import EmailCapturePopup from "../EmailCapturePopup/EmailCapturePopup";
import OfflineChecker from "../OfflineChecker/OfflineChecker";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import GlobalProgressBarContainer from "../GlobalProgressBar/GlobalProgressBarContainer";

import BasketContainer from "../../pages/Basket/BasketContainer";
import PrePayContainer from "../../pages/PrePay/PrePayContainer";
import ScanContainer from "../../pages/Scan/ScanContainer";
import AppValue from "../../pages/AppValue/AppValue";
import OrderHistoryContainer from "../../pages/OrderHistory/OrderHistoryContainer";
import ReferenceContainer from "../../pages/ReferenceContainer/ReferenceContainer";
import TopUpHistoryContainer from "../../pages/TopUpHistory/TopUpHistoryContainer";
import AccountContainer from "../../pages/Account/AccountContainer";
import ResetPasswordContainer from "../../pages/ResetPassword/ResetPasswordContainer";
import ConfirmEmailContainer from "../../pages/ConfirmEmail/ConfirmEmailContainer";
import ProductTypeLandingPageContainer from "../../pages/ProductTypeLandingPage/ProductTypeLandingPageContainer";
import ProductLandingPageContainer from "../../pages/ProductLandingPage/ProductLandingPageContainer";
//import Toast from "../Toast/Toast";

import {notification, Icon, message, Button } from 'antd';

message.config({
  duration: 3,
  maxCount: 1,
});

function sendFeedbackToSlack(payload, success, error) {
  const feedbackType = payload.attachments[0].title;
  
  if (feedbackType === 'bug') {
    const description = payload.attachments[0].text;
    let token = 'f3984538de2fed532b28d6b8576971c741c4df98';
    axios.post('https://api.github.com/gists', {
      description,
      public: false,
      files: {
        'redux-state.json': {
          content: JSON.stringify(store.getState(), null, 2),
        },
      }},{
        headers: {
          "Authorization": `token ${token}`,
          "User-Agent": "postsnap-dev"
        }
      }).then((response) => {
      const gistUrl = response.data.html_url;
      payload.attachments[0].fields = [
        {
          title: 'Reproduction data',
          value: `<${gistUrl}|Gist> | <${response.data.files['redux-state.json'].raw_url}|Raw state JSON>`,
        }
      ];
      postMessageToSlack(payload, success, error);
    });
  } else {
    postMessageToSlack(payload, success, error);
  }

  function postMessageToSlack(payload, success, error) {
    return fetch(process.env.REACT_APP_SLACK_FEEDBACK_WEBHOOK_URL, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(res => {
      console.log(res);
      if (!res.ok) {
        error(res);
        throw res
      }
      return res
    })
    .then(success)
  }

}


class UserOnlyRoute extends React.Component {
  handleSignIn = ({ email, password }) => {
    return store.dispatch(authActions.signIn({ email, password }));
  };

  handleSignUp = userData => {
    return store.dispatch(authActions.signUp(userData));
  };

  handlePasswordResetRequest = email => {
    return store.dispatch(authActions.requestPasswordReset(email));
  };

  handleCloseAuthModal = () => {
    console.log("Closing auth");
    console.log("Should go to", document.referrer);
    //window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
    //return store.dispatch(push(routeCreators.basket())); // TODO maybe send to account or marketing site?
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          const user = authSelectors.getUser(store.getState());

          if (!user) {
            return (
              <AuthModal
                isOpen={true}
                onClose={this.handleCloseAuthModal}
                onSignIn={this.handleSignIn}
                onSignUp={this.handleSignUp}
                onRequestPasswordReset={this.handlePasswordResetRequest}
              />
            );
          }

          return <Component {...props} />;
        }}
      />
    );
  }
}

const isReactSnap = navigator.userAgent === "ReactSnap";

PostcardEditor.load();

class App extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    if (props.isSignedIn) {
      this.props.fetchAddressBookEntries();
      this.props.fetchUserDataWithAuthToken(this.props.authToken);
    }

    /**
     * Render all unrendered item IDs on app load, except on the basket route since that route renders the unrendered
     * items itself (otherwise we'd be duplicating the render requests)
     */
    if (window.location.pathname.replace("/app", "") !== routeDefinitions.basket) {
      console.log(routeDefinitions.basket);
      this.props.unrenderedItemIds.forEach(itemId => {
        console.log('Rendering item from App load');
        this.props.renderItem(itemId).catch((err) => {
          // message.error({
          //   content: 'There was a problem preparing the items in your bag, please check your connection and try again.',
          //   duration: 3
          // });
        });
      });
    }

    if (window.location.pathname.replace("/app", "") === routeDefinitions.basket) {
      this.props.unrenderedItemIds.forEach(itemId => {
        console.log('Rendering item from App load in basket');
        this.props.renderItem(itemId).catch((err) => {
          message.error({
            content: 'There was a problem preparing the items in your bag, please check your connection and try again.',
            duration: 3
          });
        });
      });
    }

    const greetingCardItems = this.props.basketItems.filter(
      item => item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD
    );

    if (greetingCardItems.size) {
      const earliestPostingDate = getEarliestPostingDateForCurrentTime();
      greetingCardItems.forEach(greetingCard => {
        const selectedDate = moment(greetingCard.get("postDate"));
        const isSelectedDateBeforeEarliestPostingDate =
          selectedDate.diff(earliestPostingDate, "days") < 0;

        if (isSelectedDateBeforeEarliestPostingDate) {
          this.props.setPostDateForItem(greetingCard.get("id"), earliestPostingDate.format());
        }
      });
    }

    // No longer used:
    // isCookieWarningVisible:
    //     !isReactSnap && !localStorage.getItem(STORAGE_KEYS.IS_COOKIE_WARNING_DISMISSED),
    //   isEmailCapturePopupVisible:
    //     !isReactSnap && !localStorage.getItem(STORAGE_KEYS.IS_EMAIL_CAPTURE_POPUP_DISMISSED),

    this.state = {
      alert: null,
      showExit: false,
      isCookieWarningVisible: false,
      isEmailCapturePopupVisible: false,
      isWipEditorItemNotificationVisible: false,
      isExistingPrebagNotificationVisible: false,
      slackFeedbackOpen: false,
      intercom: {
        isOpen: false,
        unread: 0,
      },
      wipEditorItem: localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM)
        ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM))
        : null,
      existingPrebag: localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
        ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
        : null,
    };
  }

  close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
  };
  
  closeAndRestoreItem = (key, continueCallback) => {
    notification.close(key);
    console.log("Restoring item");
    continueCallback();
  }

  closeAndDiscardItem = (key, discardCallback) => {
    notification.close(key);
    console.log("Discarding item");
    discardCallback();
  }

  openNotification = (title, content, continueCallback, closeCallback) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => this.closeAndRestoreItem(key, continueCallback)}>
        Continue
      </Button>
    );
    notification.open({
      message: title,
      description: content,
      duration: 0,
      btn,
      key,
      placement: 'bottom-right',
      icon: <Icon type="info-circle" style={{ color: '#F75E67' }} />,
      onClose: () => this.closeAndDiscardItem(key, closeCallback),
      onClick: () => this.closeAndRestoreItem(key, continueCallback),
    });
  };
  

  async componentDidMount() {
    console.log("RA Environment:", `${process.env.REACT_APP_ENVIRONMENT}`);
    console.log("Node Environment:", `${process.env.NODE_ENV}`);

    window.addEventListener(
      "resize",
      debounce(() => {
        if (window.innerWidth > 980 && this.props.isSidebarOpen) {
          this.props.toggleSidebar();
        }
        this.forceUpdate();
      }, 200)
    );
    document.addEventListener("touchend", this.handleExit);

    if (window.navigator.userAgent.indexOf("GSA") !== -1) {
      document.body.className += " gsa";
      document.body.style.height = "calc(100% - 130px)";
    }

    let cur = await cookie.parse(document.cookie).currency;
    // console.log("Cookie currency is ->", cur);
    // console.log("Props currency is ->", this.props.currency);

    if (cur && cur !== this.props.currency) {
      console.log("Setting local currency to marketing site cookie currency", cur);
      this.props.setCurrency(cur);
    }

    let basketCount = this.props.totalItemQuantity;
    document.cookie = `basketCount=${basketCount}; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;

    // Set signed in cookie
    document.cookie = `isSignedIn=${this.props.isSignedIn}; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;

    
    if (this.state.wipEditorItem && this.state.isWipEditorItemNotificationVisible === false){
      if(window.location.pathname.replace("/app", "") === this.state.wipEditorItem.source){
        //console.log("WIP item is the same as existing item");
      } else {
        const productName = productsByProductId.getIn([
          this.state.wipEditorItem.item.productId,
            "phone_title",
        ]);

        //const design = designsData.getDesignForDesignId(this.state.wipEditorItem.item.designId).get('description');

        this.openNotification(`Finish your ${productName}?`, `You didn't finish your ${productName} — would you like to continue with it?`, this.restoreWipEditorItem, this.discardWipEditorItem);
        this.setState({isWipEditorItemNotificationVisible: true});
      }
      
    }

    // If we do have an existing prebag item, we only show the toast if we're _not_ on the right prebag, because the
    // prebag will automatically restore the WIP item matches the prebag
    if (this.state.existingPrebag && this.state.isExistingPrebagNotificationVisible === false) {
      let shouldShowNotification = false;

      const prebagBasePath = `/create/${this.state.existingPrebag.productTypeSlug}/${this.state.existingPrebag.productSlug}`;
      const isAlreadyOnCorrectPrebag =
        window.location.pathname === prebagBasePath ||
        window.location.pathname === `/app${prebagBasePath}`;
        shouldShowNotification = !isAlreadyOnCorrectPrebag;

      if (shouldShowNotification){
        let productName = productsByProductId.getIn([
          this.state.existingPrebag.productId,
            "phone_title",
        ]);
        const pluralizedProduct = [PRODUCT_TYPE_IDS.PHOTO_PRINT];
        if (pluralizedProduct.includes(this.state.existingPrebag.productTypeId)){
          productName = `${productName}s`
        }

        this.openNotification(`Finish your ${productName}?`, `You didn't finish your ${productName}, would you like to continue with them?`, this.restoreWipPrebag, this.discardWipPrebag);
        this.setState({isExistingPrebagNotificationVisible: true});
      }
    }

  }

  openIntercom = isOpen => {
    this.setState({
      intercom: { ...this.state.intercom, isOpen },
    });
  };

  onIntercomUnreadCountChange = unread => {
    this.setState({
      intercom: { ...this.state.intercom, unread },
    });
  };

  closeIntercom = () => {
    this.setState({
      intercom: { ...this.state.intercom, isOpen: false },
    });
    window.Intercom("hide");
  };

  toggleIntercom = event => {
    if (event !== undefined) {
      event.preventDefault();
    }
    this.state.intercom.isOpen ? window.Intercom("hide") : window.Intercom("show");
  };

  handleExitClick = e => {
    this.setState({
      isEmailCapturePopupVisible: false,
    });
  };

  handleExit = e => {
    e = e ? e : window.event;
    // Get the current viewport width.
    var vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (e.clientX >= vpWidth - 50) return;

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (e.clientY >= 50) return;

    // Reliable, works on mouse exiting window and
    // user switching active program
    var from = e.relatedTarget || e.toElement;
    if (!from) {
      if (this.state.isEmailCapturePopupVisible === false) {
        this.setState({
          showExit: true,
        });
      }
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  goToShop = () => {
    // TODO: extract this to ENV var
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  handleSignOut = () => {
    this.setState({
      alert: {
        type: "warning",
        title: "Sign Out",
        text: "Are you sure you want to sign out?",
        confirmButtonText: "Sign Out",
        onConfirm: () => {
          this.goToShop();
          this.props.signOut();
          this.clearAlert();
        },
        cancelButtonText: "Cancel",
        onCancel: this.clearAlert,
      },
    });
  };

  dismissCookieWarning = () => {
    this.setState({
      isCookieWarningVisible: false,
    });
    localStorage.setItem(STORAGE_KEYS.IS_COOKIE_WARNING_DISMISSED, "true");
  };

  submitEmailCapturePopup = form => {
    console.log("Submitted email capture form:", form);
  };

  closeEmailCapturePopup = () => {
    localStorage.setItem(STORAGE_KEYS.IS_EMAIL_CAPTURE_POPUP_DISMISSED, "true");
    this.setState({
      isEmailCapturePopupVisible: false,
    });
  };

  restoreWipPrebag = () => {
    this.props.goToProduct(
      this.state.existingPrebag.productTypeSlug,
      this.state.existingPrebag.productSlug
    );
    this.setState({
      existingPrebag: null,
      isExistingPrebagNotificationVisible: false,
    });
  };

  discardWipPrebag = () => {
    console.log('Discarding Wip Prebag Item')
    this.setState({
      existingPrebag: null,
      isExistingPrebagNotificationVisible: false,
    });
  };

  restoreWipEditorItem = () => {
    console.log("Restoring", this.state.wipEditorItem.source);
    this.props.goToRoute(this.state.wipEditorItem.source);
    this.setState({
      wipEditorItem: null,
      isWipEditorItemNotificationVisible: false,
    }, () => {
      // Todo: we shouldn't need to do this if `goToRoute` worked correctly
      window.location.reload();
    });
  };

  discardWipEditorItem = () => {
    console.log('Discarding Wip Editor Item')
    this.setState({
      wipEditorItem: null,
      isWipEditorItemNotificationVisible: false,
    });
  };

  handleSlackSuccess = () => {
    console.log("Slack sent");
    this.setState({
      slackFeedbackOpen: false,
    }, () => {
      notification.open({
        message: 'Your feedback has been sent',
        description:
        `Thanks for helping us out, our technical team are looking into the issue`,
        icon: <Icon type="smile" style={{ color: '#49bda1' }} />,
        duration: 7,
        placement: 'bottomRight'
      });
    });
  }

  showSlackFeedbackForm = () => {
    this.setState({
      slackFeedbackOpen: true,
    })
  }

  render() {
    const appClasses = classNames("app", {
      "app--sidebar-open": this.props.isSidebarOpen,
    });

    //let intercomUser;

    if (this.props.isSignedIn) {
      // intercomUser = {
      //   user_id: this.props.user.get('id'),
      //   //user_hash: CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(this.props.user.get('id'), process.env.REACT_APP_INTERCOM_APP_ID )),
      //   email: this.props.user.get('email'),
      //   name: this.props.user.get('first_name'),
      //   hide_default_launcher: true,
      //   custom_launcher_selector: '#message-us'
      // };
    }

    if (this.state.isCookieWarningVisible) {
      //console.log("Cookie warning is visible");
    }

    // let isWipPrebagToastVisible = false;
    // let isWipEditorItemToastVisible = false;

    // If we do have an existing prebag item, we only show the toast if we're _not_ on the right prebag, because the
    // prebag will automatically restore the WIP item matches the prebag
    // if (this.state.existingPrebag) {
    //   const prebagBasePath = `/create/${this.state.existingPrebag.productTypeSlug}/${this.state.existingPrebag.productSlug}`;
    //   const isAlreadyOnCorrectPrebag =
    //     window.location.pathname === prebagBasePath ||
    //     window.location.pathname === `/app${prebagBasePath}`;
    //   isWipPrebagToastVisible = !isAlreadyOnCorrectPrebag;

    //   if (isWipPrebagToastVisible){
    //     notification.open({
    //       message: 'Continue where you left off?',
    //       description:
    //       `You have an unsaved product, click here to finish it.`,
    //       icon: <Icon type="smile" style={{ color: '#108ee9' }} />,
    //       onClick: () => this.restoreWipEditorItem,
    //       onHidden: () => this.discardWipEditorItem,
    //     });
  
    //   }
      
    // }

    

    // if (this.state.wipEditorItem && !isWipPrebagToastVisible) {
    //   if(window.location.pathname.replace("/app", "") === this.state.wipEditorItem.source){
    //     //console.log("WIP item is the same as existing item");
    //   } else{
    //     isWipEditorItemToastVisible = true;
    //   }
    // }

    // const TestError = ()=> {
    //   throw new Error('I crashed!');
    // }



    return (
      <ConnectedRouter history={history}>
        <ErrorBoundary user={this.props.isSignedIn ? this.props.user : null}>
          <div className={appClasses}>
            {process.env.REACT_APP_ENVIRONMENT === "staging" && (
              <div className="app-staging-banner">** STAGING **</div>
            )}
            <Konami action={this.showSlackFeedbackForm} code={[38,38,40,40,37,39,37,39]}/>
            {this.state.slackFeedbackOpen && (
              <SlackFeedback
                showChannel={false}
                disabled={false}
                open={true}
                channel="#aftersnap-feedback"
                errorTimeout={8 * 1000}
                icon={() => <Icon type="info-circle" style={{ color: '#ffffff', marginRight: '5px' }} />}
                onClose={() => {}}
                onOpen={() => {}}
                sentTimeout={5 * 1000}
                showIcon={true}
                theme={feedbackTheme}
                user={this.props.user && this.props.user.get('email')}
                onSubmit={(payload, success, error) => 
                  sendFeedbackToSlack(payload, this.handleSlackSuccess, error)
                }
              />
            )}
            
            {/* {isWipEditorItemToastVisible && (
              <Toast
                type="info"
                message={`You have an unsaved ${productsByProductId.getIn([
                  this.state.wipEditorItem.item.productId,
                  "phone_title",
                ])} product, click here to finish it.`}
                onClick={this.restoreWipEditorItem}
                onHidden={this.discardWipEditorItem}
              />
            )} */}
            {/* {isWipPrebagToastVisible && (
              <Toast
                type="info"
                message={`You have an unsaved ${productsByProductId.getIn([
                  this.state.existingPrebag.productId,
                  "phone_title",
                ])} product, click here to finish it.`}
                onClick={this.restoreWipPrebag}
                onHidden={this.discardWipPrebag}
              />
            )} */}
            <Helmet>
              <title>Postsnap - Send personalised photo postcards</title>
              <meta
                name="description"
                content="Make and send your own photo postcards online with our free postcard app. Use our postcard maker to turn photos from your phone, Facebook or Instagram into real personalized photo cards. We print & mail worldwide. Easy to use & fair prices."
              />
            </Helmet>
            <EmailCapturePopup isOpen={false} onClose={this.closeEmailCapturePopup} />
            <OfflineChecker />
            <SweetAlert isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />
            {this.props.isSignedIn && (
              <Intercom
                user={this.props.user}
                onHide={this.closeIntercom}
                onShow={this.openIntercom}
                onUnreadCountChange={this.onIntercomUnreadCountChange}
              />
            )}
            <div className="app__main" key="main-app">
              <a href="#maincontent" tabIndex="0" className="visuallyhidden">
                Skip to main content
              </a>
              <Route
                exact
                path={routeDefinitions.productTypeLandingPage}
                component={ProductTypeLandingPageContainer}
              />
              <Route
                exact
                path={routeDefinitions.productLandingPage}
                component={ProductLandingPageContainer}
              />

              <Route exact path={routeDefinitions.basket} component={BasketContainer} />
              <Route exact path={routeDefinitions.scan} component={ScanContainer} />
              <Route
                exact
                path={routeDefinitions.pricingInfo}
                render={() => (
                  <AppValue
                    htmlContent={getAppValueByKey("PRICES").get("value")}
                    title="Pricing info"
                  />
                )}
              />
              <UserOnlyRoute exact path={routeDefinitions.prepay} component={PrePayContainer} />
              <UserOnlyRoute
                exact
                path={routeDefinitions.topUpHistory}
                component={TopUpHistoryContainer}
              />
              <UserOnlyRoute exact path={routeDefinitions.account} component={AccountContainer} />
              <UserOnlyRoute
                exact
                path={routeDefinitions.orderHistory}
                component={OrderHistoryContainer}
              />
              <UserOnlyRoute
                exact
                path={routeDefinitions.orderWithReference}
                component={OrderHistoryContainer}
              />
              <Route exact path={routeDefinitions.reference} component={ReferenceContainer} />
              <Route
                exact
                path={routeDefinitions.resetPassword}
                component={ResetPasswordContainer}
              />
              <Route exact path={routeDefinitions.confirmEmail} component={ConfirmEmailContainer} />
              <Switch>{redirects}</Switch>
            </div>
            {this.state.isCookieWarningVisible && !isReactSnap && (
              <CookieWarning onDismiss={this.dismissCookieWarning} />
            )}
            {!isReactSnap && (
              <Sidebar
                currency={this.props.currency}
                onChangeCurrency={this.props.setCurrency}
                isSignedIn={this.props.isSignedIn}
                onClickSignIn={this.props.showAuthModal}
                onClickSignOut={this.handleSignOut}
                onToggleIntercom={this.toggleIntercom}
                unreadMessageCount={this.state.intercom.unread}
              />
            )}
            <GlobalProgressBarContainer />
            <AuthModal
              isOpen={this.props.isAuthModalOpen}
              onClose={this.props.onCloseAuthModal}
              onSignIn={this.props.onSignInAuthModal}
              onSignUp={this.props.onSignUpAuthModal}
              onRequestPasswordReset={this.props.onRequestPasswordReset}
            />
          </div>
          {/* <TestError/> */}
        </ErrorBoundary>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = state => ({
  isSidebarOpen: uiSelectors.getSidebarVisibilityStatus(state),
  isAuthModalOpen: uiSelectors.getAuthModalVisibilityStatus(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  authToken: authSelectors.getAuthToken(state),
  currency: basketSelectors.getCurrency(state),
  unrenderedItemIds: basketSelectors.getAllUnrenderedItemIds(state),
  basketItems: basketSelectors.getItems(state),
  totalItemQuantity: basketSelectors.getTotalQuantity(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  setPostDateForItem: (itemId, postDate) =>
    dispatch(basketActions.setPostDateForItem(itemId, postDate)),
  goToShop: () => dispatch(push(routeCreators.shop())),
  goToProduct: (productTypeId, productSlug) =>
    dispatch(push(routeCreators.productLandingPage(productTypeId, productSlug))),
  goToRoute: route => dispatch(push(route)),
  toggleSidebar: () => dispatch(uiActions.toggleSidebar()),
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
  onCloseAuthModal: () => dispatch(uiActions.hideAuthModal()),
  onSignInAuthModal: ({ email, password }) => dispatch(authActions.signIn({ email, password })),
  onSignUpAuthModal: userData => dispatch(authActions.signUp(userData)),
  onRequestPasswordReset: email => dispatch(authActions.requestPasswordReset(email)),
  signOut: () => dispatch(authActions.signOut()),
  fetchAddressBookEntries: () => dispatch(addressBookActions.fetchEntries()),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  fetchUserDataWithAuthToken: authToken =>
    dispatch(authActions.fetchUserDataWithAuthToken(authToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
