import { connect } from "react-redux";
import { push } from "react-router-redux";

import { routeCreators } from "../../lib/routes";
import AccountButton from "./AccountButton";

const mapDispatchToProps = dispatch => ({
  goToAccount: () => dispatch(push(routeCreators.account())),
});

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountButton);
