// Taken from: https://stackoverflow.com/a/38392812/1977688

export default function calculateLuminanceFromRGB(r, g, b) {
  const colorArray = [r, g, b];
  let colorFactor;

  let i;
  for (i = 0; i < colorArray.length; i++) {
    colorFactor = colorArray[i] / 255;
    if (colorFactor <= 0.03928) {
      colorFactor = colorFactor / 12.92;
    } else {
      colorFactor = Math.pow((colorFactor + 0.055) / 1.055, 2.4);
    }
    colorArray[i] = colorFactor;
  }

  return colorArray[0] * 0.2126 + colorArray[1] * 0.7152 + colorArray[2] * 0.0722 + 0.05;
}
