import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { reducer as auth } from "./auth";
import { reducer as editor } from "./editor";
import { reducer as basket } from "./basket";
import { reducer as ui } from "./ui";
import { reducer as addressBook } from "./address-book";

export const rootReducer = combineReducers({
  auth,
  editor,
  basket,
  ui,
  addressBook,
  router: routerReducer,
});
