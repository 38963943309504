/* global THREE */

import React from "react";
import isEqual from "lodash/isEqual";

//import Loader from 'react-loader';
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";

import './ThreeDimensionalViewer.scss';

class ThreeDimensionalViewer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.render3DModel(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.render3DModel(this.props);
    }
  }

  componentWillUnmount() {
    //window.model.geometry.dispose();
    //window.model.material.dispose();
    if (window.scene) {
      window.scene.remove(window.model);
    }
  }

  render3DModel(props) {
    //const $node = ReactDOM.findDOMNode(this);
    const $node = this.canvas;
    const width = $node.offsetWidth;
    const height = $node.offsetHeight;

    const scene = new THREE.Scene();

    //scene.background = new THREE.Color( 0x443333 );
    //scene.fog = new THREE.Fog( 0x443333, 2, 10 );

    const renderer = new THREE.WebGLRenderer({
      canvas: $node,
      antialias: true, // should be false if setting pixel ratio
      preserveDrawingBuffer: false,
      alpha: true,
    });
    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio || 1);
    renderer.setSize(width, height);
    //renderer.gammaOutput = true;

    let model;

    const camera = new THREE.PerspectiveCamera(22, width / height, 1, 1000);
    camera.position.set(16, 8, -20);

    // const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.25, 20 );
    // camera.position.set( -1.8, 0.9, 2.7 );

    const controls = new THREE.OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", render); // remove when using animation loop

    // enable animation loop when using damping or autorotation
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.autoRotateSpeed = 0.5;

    controls.autoRotate = true;

    controls.addEventListener("start", function() {
      controls.autoRotate = false;
    });

    // restart autorotate after the last interaction & an idle time has passed
    controls.addEventListener("end", function() {
      setTimeout(function() {
        controls.autoRotate = true;
      }, 3000);
    });

    // const light1 = new THREE.DirectionalLight(0xffffff, 0.5);
    // light1.position.set(1, 5, 7.5);
    // scene.add(light1);

    //const light2 = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
    //const light2 = new THREE.DirectionalLight(0xffffff, 0.5);

    // const light2 = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
    // light2.position.set(0.5, -5, -10);
    // scene.add(light2);

    const light1 = new THREE.HemisphereLight(0xffffff, 0x444444);
    light1.position.set(0, 2, 0);
    scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff);
    light2.position.set(0, 2, 1);
    scene.add(light1);

    //ground
    // let plane = new THREE.Mesh(
    //     new THREE.PlaneBufferGeometry( 20, 20 ),
    //       new THREE.MeshPhongMaterial( { color: 0x999999, specular: 0x101010 } )
    // );
    // plane.rotation.x = - Math.PI / 2;
    // // plane.position.y = -1;
    // plane.receiveShadow = true;
    // scene.add(plane);

    const loader = new THREE.ColladaLoader();
    loader.options.convertUpAxis = true;

    loader.load(
      props.model,
      collada => {
        model = collada.scene;

        model.traverse(child => {
          if (child.material && child.material.name === "") {
            child.material = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              roughness: 0.5,
              metalness: 0.5,
              emissive: 0x898989,
              side: THREE.DoubleSide,
            });
          }

          if (child.material && child.material.name === "CUSTOMISATION") {
            const loader = new THREE.TextureLoader();
            console.log("Loading", `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${props.image}`);
            loader.crossOrigin = "*";
            loader.load(
              `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${props.image}`,
              texture => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearMipmapLinearFilter;
                texture.offset.y = -0.02;
                texture.offset.x = -0.005;
                child.material = new THREE.MeshBasicMaterial({
                  map: texture,
                  overdraw: 0.5,
                });
                this.setState({ isLoading: false });
              },
              xhr => {
                console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
              },
              xhr => {
                console.log("An error happened", xhr);
              }
            );
          }

          //staples_00
          //hook_003
          //CANVAS

          if (child.material && child.material.name === "CANVAS") {
            const loader = new THREE.TextureLoader();
            loader.crossOrigin = "*";
            loader.load(
              `${process.env.PUBLIC_URL}/images/model-textures/canvas-texture.jpg`,
              function(texture) {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearMipmapLinearFilter;
                child.material = new THREE.MeshBasicMaterial({
                  map: texture,
                  overdraw: 0.5,
                });
              }
            );
          }

          if (child.material && child.material.name === "staples_003") {
            const loader = new THREE.TextureLoader();
            loader.crossOrigin = "*";
            loader.load(`${process.env.PUBLIC_URL}/images/model-textures/staple.png`, function(
              texture
            ) {
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

              texture.minFilter = THREE.LinearFilter;
              texture.magFilter = THREE.LinearMipmapLinearFilter;
              child.material = new THREE.MeshBasicMaterial({
                map: texture,
                overdraw: 0.5,
              });
            });
          }

          if (child.material && child.material.name === "hook_003") {
            const loader = new THREE.TextureLoader();
            loader.crossOrigin = "*";
            loader.load(`${process.env.PUBLIC_URL}/images/model-textures/brass.jpg`, function(
              texture
            ) {
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

              texture.minFilter = THREE.LinearFilter;
              texture.magFilter = THREE.LinearMipmapLinearFilter;
              child.material = new THREE.MeshBasicMaterial({
                map: texture,
                overdraw: 0.5,
              });
            });
          }

          if (child.material && child.material.name === "WOOD_FRAMES") {
            const loader = new THREE.TextureLoader();
            loader.crossOrigin = "*";
            loader.load(
              `${process.env.PUBLIC_URL}/images/model-textures/wood-texture.jpg`,
              function(texture) {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearMipmapLinearFilter;
                child.material = new THREE.MeshBasicMaterial({
                  map: texture,
                  overdraw: 0.5,
                });
              }
            );
          }
        });

        // TODO: Do this properly
        const MODEL_DIMENSIONS = 1400 / ((window.devicePixelRatio * 0.7) || 1);
        const proposedScale = Math.min(
          width / MODEL_DIMENSIONS,
          height / MODEL_DIMENSIONS,
        ) * 0.65;

        console.log("proposed scale", proposedScale);

        model.rotation.z = Math.PI;

        camera.lookAt(model.position);

        //let scale = 0.28;
        let scale = proposedScale;

        model.scale.set(scale, scale, scale);

        const yPosition = 1;
        model.position.set(0, yPosition, 0);

        scene.add(model);
        window.model = model;
      },
      xhr => {
        //props.setGlobalProgressBarPercentage(xhr.loaded / xhr.total * 100);
      }
    );

    function render() {
      renderer.render(scene, camera);
      // For Three.js Inspector - https://github.com/jeromeetienne/threejs-inspector/blob/master/README.md
      window.scene = scene;
      window.THREE = THREE;
    }

    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      render();
    }

    animate();
  }

  render() {
    return (
      <div className="threedimensionalviewer">
        {this.state.isLoading && (
          <div>
            <p
              className="text-center text-muted animated  threedimensionalviewer__waiting-text"
              style={{ padding: 10 }}
            >
              <small>Please wait while we generate your 3D preview</small>
            </p>
            <FullScreenLoader
              key="loader"
              message="Generating 3D preview"
              isVisible={this.state.isLoading}
            />
          </div>
        )}
        <canvas
          className={this.state.isLoading ? null : "shown animated fadeIn"}
          style={{ width: "100%", height: "100%", flex: 1 }}
          ref={node => (this.canvas = node)}
        />
      </div>
    );
  }
}

export default ThreeDimensionalViewer;
