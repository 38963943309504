import React, { Component } from "react";
import PropTypes from "prop-types";
import chunk from "lodash/chunk";

import Modal from "../Modal/Modal";
import Grid from "../Grid/Grid";

class LocalUploadModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.string),
    onSelectImage: PropTypes.func,
  };

  static defaultProps = {};

  render() {
    const imageChunks = chunk(this.props.images, 2);

    return (
      <Modal title="Debug upload" isOpen={this.props.isOpen} onClose={this.props.onClose}>
        {imageChunks.map((images, index) => (
          <Grid.Row key={index}>
            {images.map(imageUrl => (
              <Grid.Column key={imageUrl}>
                <div style={{ textAlign: "center" }}>
                  <img src={imageUrl} alt="" onClick={() => this.props.onSelectImage(imageUrl)} />
                  <p>
                    <small>{imageUrl}</small>
                  </p>
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Modal>
    );
  }
}

export default LocalUploadModal;
