import React from "react";
import PropTypes from "prop-types";

import Button from "../Button/Button";
//import SweetAlert from "../SweetAlert/SweetAlert";

import { Popconfirm, Icon, message } from 'antd';

import './QuantityPicker.scss';

class QuantityPicker extends React.Component {
  static propTypes = {
    quantity: PropTypes.number.isRequired,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    onIncrease: () => {},
    onDecrease: () => {},
    onDelete: () => {},
  };

  state = {
    isDeleteConfirmationAlertVisible: false,
  };

  handleDecrease = () => {
    if (this.props.quantity === 1) {
      this.showDeleteConfirmationAlert();
    } else {
      this.props.onDecrease();
    }
  };

  handleDelete = () => {
    this.props.onDelete();
    message.success('Print deleted');
    this.hideDeleteConfirmationAlert();
  };

  showDeleteConfirmationAlert = () => {
    this.setState({
      isDeleteConfirmationAlertVisible: true,
    });
  };

  hideDeleteConfirmationAlert = () => {
    this.setState({
      isDeleteConfirmationAlertVisible: false,
    });
  };

  render() {
    const { onIncrease, quantity } = this.props;

    return (
      <div className="quantity-picker">
        <div className="quantity-picker__main">
          <Button
            icon="subtract"
            kind="primary-outline"
            size="small"
            onClick={this.handleDecrease}
            disabled={this.props.quantity === 1}
          />
          <div className="quantity-picker__value">{quantity}</div>
          <Button icon="add" kind="primary-outline" size="small" onClick={onIncrease} />
        </div>
        <Popconfirm
          style={{marginRight: '10px'}}
          visible={this.state.isDeleteConfirmationAlertVisible}
          title="Delete this print?"
          onConfirm={this.handleDelete}
          onCancel={this.hideDeleteConfirmationAlert}
          okText="Yes"
          cancelText="No"
          icon={<Icon type="exclamation-circle-o" style={{ color: '#F75E67' }}/>}
          placement="left"
        >
          <Button
            className="quantity-picker__delete-button"
            label="Delete"
            theme="muted"
            priority="tertiary"
            size="small"
            onClick={this.showDeleteConfirmationAlert}
          />
        </Popconfirm>
      </div>
    );
  }
}

export default QuantityPicker;
