import { connect } from "react-redux";
import { push } from "react-router-redux";

import { routeCreators } from "../../lib/routes";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import BasketButton from "./BasketButton";

const mapStateToProps = state => ({
  totalItemQuantity: basketSelectors.getTotalQuantity(state),
});

const mapDispatchToProps = dispatch => ({
  goToBasket: () => dispatch(push(routeCreators.basket())),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketButton);
