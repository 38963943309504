import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";

class AccountButton extends React.Component {
  componentDidUpdate() {}

  render() {
    return (
      <div className="account-button" style={{ marginLeft: "8px" }}>
        <Button icon="account" priority="tertiary" theme="muted" onClick={this.props.goToAccount} />
      </div>
    );
  }
}

AccountButton.propTypes = {
  goToAccount: PropTypes.func,
};

AccountButton.defaultProps = {};

export default AccountButton;
