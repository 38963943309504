import { fromJS, Map } from "immutable";
import { handle } from "redux-pack";

import postsnapApi from "../../lib/apis/postsnap";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

export const types = {
  FETCH_ENTRIES: "POSTSNAP/ADDRESS_BOOK/FETCH_ENTRIES",
  ADD_NEW_ENTRY: "POSTSNAP/ADDRESS_BOOK/ADD_NEW_ENTRY",
  UPDATE_ENTRY: "POSTSNAP/ADDRESS_BOOK/UPDATE_ENTRY",
  DELETE_ENTRY: "POSTSNAP/ADDRESS_BOOK/DELETE_ENTRY",
};

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("addressBook", {
    entriesById: {},
  })
);

export function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_ENTRIES:
      return handle(state, action, {
        success: prevState => {
          const entries = payload.data.data.created;
          return prevState.set("entriesById", new Map(entries.map(v => [v.id, fromJS(v)])));
        },
      });
    case types.ADD_NEW_ENTRY:
      return handle(state, action, {
        success: prevState => {
          return prevState.setIn(["entriesById", payload.data.data.id], fromJS(payload.data.data));
        },
      });
    case types.UPDATE_ENTRY:
      let cachedEntry = state.getIn(["entriesById", action.meta.id]);
      return handle(state, action, {
        start: prevState => {
          return prevState.updateIn(["entriesById", action.meta.id], entry =>
            entry.merge(fromJS(action.meta.address))
          );
        },
        failure: prevState => {
          return prevState.updateIn(["entriesById", payload.data.data.id], entry =>
            entry.merge(cachedEntry)
          );
        },
      });
    case types.DELETE_ENTRY:
      // This is an optimistic update, we remove the entry by default and re-add it if the deletion fails
      let entry = state.getIn(["entriesById", action.meta.id]);
      return handle(state, action, {
        start: prevState => {
          return prevState.update("entriesById", entriesById => entriesById.delete(action.meta.id));
        },
        failure: prevState => {
          return prevState.setIn(["entriesById", action.meta.id], entry);
        },
      });
    default:
      return state;
  }
}

export const actions = {
  fetchEntries: () => ({
    type: types.FETCH_ENTRIES,
    promise: postsnapApi.addressBook.fetchEntries(),
  }),
  addNewEntry: address => {
    return {
      type: types.ADD_NEW_ENTRY,
      promise: postsnapApi.addressBook.createEntry(address),
    };
  },
  updateEntry: ({ id, address }) => {
    return {
      type: types.UPDATE_ENTRY,
      promise: postsnapApi.addressBook.updateEntry(id, address),
      meta: { address, id },
    };
  },
  deleteEntry: id => {
    return {
      type: types.DELETE_ENTRY,
      promise: postsnapApi.addressBook.deleteEntry(id),
      meta: { id },
    };
  },
};

export const selectors = {
  getAllEntries: state => state.addressBook.get("entriesById").toList(),
  getEntry: (state, id) => state.addressBook.getIn(["entriesById", id]),
};
