import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Scan from "./Scan";

class ScanContainer extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <Scan {...this.props} />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanContainer);
