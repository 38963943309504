import { fromJS } from "immutable";

const allPostageSchemes = fromJS(require("../api-data/postage-schemes"));
const postageSchemesMap = allPostageSchemes
  .groupBy(s => s.get("currency"))
  .map(schemesForCurrency => {
    return schemesForCurrency
      .groupBy(s => s.get("destination"))
      .map(schemesForCountry => {
        return schemesForCountry.groupBy(s => s.get("product_type_id"));
      });
  });

const EU_COUNTRIES = [
  "Andorra",
  "Austria",
  "Belgium",
  "Bosnia and Herzegovina",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Gibraltar",
  "Greece",
  "Holy See (Vatican City State)",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Monaco",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "San Marino",
  "Serbia and Montenegro",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

function getInternalDestinationFromRealCountryName(countryName) {
  if (EU_COUNTRIES.includes(countryName)) {
    return "eu";
  }

  switch (countryName) {
    case "United Kingdom":
      return "uk";
    case "United States":
      return "usa";
    case "Canada":
      return "canada";
    case "Australia":
      return "australia";
    default:
      return "international";
  }
}

export function getPostageSchemeForProductTypeAndDestination({
  productTypeId,
  destinationCountry,
  currency = "GBP",
  weight,
}) {
  const internalDestinationCode = getInternalDestinationFromRealCountryName(destinationCountry);
  const applicablePostageSchemes = postageSchemesMap.getIn([
    currency,
    internalDestinationCode,
    productTypeId,
  ]);

  return applicablePostageSchemes.find(scheme => {
    const maxWeight = scheme.get("max_weight") === null ? Infinity : scheme.get("max_weight");
    return weight >= scheme.get("min_weight") && weight < maxWeight;
  });
}
