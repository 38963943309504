import { connect } from "react-redux";

import { selectors as uiSelectors } from "../../store/ducks/ui";
import GlobalProgressBar from "./GlobalProgressBar";

const mapStateToProps = state => ({
  percentage: uiSelectors.getGlobalProgressBarPercentage(state),
});

export default connect(mapStateToProps)(GlobalProgressBar);
