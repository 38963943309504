import Moment from "moment";
import { extendMoment } from "moment-range";

import { COUNTRIES } from "../constants/geo";

const moment = extendMoment(Moment);
const SAME_DAY_POSTING_TIME_CUTOFF_HOUR = 11;

function getMinimumTransitDurationForDestination(destination) {
  const country = COUNTRIES.find(c => c.name === destination);
  const isEuropeanDestination = country && country.isEU;

  if (isEuropeanDestination) {
    return 2;
  }

  switch (destination) {
    case "United Kingdom":
      return 1;
    case "United States":
      return 3;
    default:
      return 7;
  }
}

function getTransitDeviationForDestination(destination) {
  const country = COUNTRIES.find(c => c.name === destination);
  const isEuropeanDestination = country && country.isEU;

  if (isEuropeanDestination) {
    return 2;
  }

  switch (destination) {
    case "United States":
      return 3;
    default:
      return 2;
  }
}

export function getEarliestPostingDateForCurrentTime() {
  let earliestPostingDate = moment().utc();
  const cutOffMomentForSameDayPosting = moment()
    .utc()
    .set({
      hour: SAME_DAY_POSTING_TIME_CUTOFF_HOUR,
      minute: 0,
      second: 0,
    });

  const isCurrentTimeAfterSameDayPostingCutOff = earliestPostingDate.isAfter(
    cutOffMomentForSameDayPosting
  );

  // If the current time is after noon, add one day
  if (isCurrentTimeAfterSameDayPostingCutOff) {
    earliestPostingDate.add(1, "days");
  }

  // If posting date is on a Saturday or Sunday, move it to the next Monday
  if (earliestPostingDate.isoWeekday() === 6 || earliestPostingDate.isoWeekday() === 7) {
    earliestPostingDate.add(7 - earliestPostingDate.isoWeekday() + 1, "days");
  }

  return earliestPostingDate;
}

export function getArrivalDatesForDestination(postDate, destination) {
  const minimumTransitDuration = getMinimumTransitDurationForDestination(destination);
  const earliestArrivalDate = moment(postDate).add(minimumTransitDuration, "days");
  const transitDeviation = getTransitDeviationForDestination(destination);

  // If date is on a Sunday, move it to the next Monday
  if (earliestArrivalDate.isoWeekday() === 7) {
    earliestArrivalDate.add(1, "days");
  }

  const latestArrivalDate = earliestArrivalDate.clone().add(transitDeviation, "days");

  // If date is on a Sunday, move it to the next Monday
  if (latestArrivalDate.isoWeekday() === 7) {
    latestArrivalDate.add(1, "days");
  }

  /**
   * Count the amount of sundays in the delivery window and add those to the latest arrival since we don't deliver on
   * Sundays.
   */
  const rangeArray = Array.from(moment.range(earliestArrivalDate, latestArrivalDate).by("days"));
  const amountOfSundaysInRange = rangeArray.filter(m => m.isoWeekday() === 7).length;

  latestArrivalDate.add(amountOfSundaysInRange, "days");

  return {
    earliestArrivalDate,
    latestArrivalDate,
  };
}
