import React, { Component } from "react";
import PropTypes from "prop-types";

import QrReader from "react-qr-reader";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

//import Lottie from 'react-lottie';
//import * as animationData from './scan-qr-code.json'
//import SampleQRC from './sampleQRCode.jpg'

class Scan extends Component {
  static propTypes = {
    onScan: PropTypes.func,
    onError: PropTypes.func,
  };

  static defaultProps = {
    onError: () => ({}),
  };

  state = {
    isStopped: false,
    isPaused: false,
    animationShown: false,
    alert: null,
    delay: 300,
    loading: true,
    error: false,
    facingMode: "user",
    result: "No card found",
    legacyMode: false,
  };

  handleScan = data => {
    this.setState({ result: data });
    if (data && data !== null) {
      let potentialCode = data.split("/")[data.split("/").length - 1];
      if (/^([0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4})$/.test(potentialCode) === true) {
        this.props.history.push(`/${potentialCode}`, { code: potentialCode });
      } else {
        this.setState({
          alert: {
            type: "error",
            title: "Unrecognised code",
            text: "This doesn't look like one of our QR codes",
            showCancelButton: false,
            confirmButtonText: "Try another code",
            onConfirm: this.clearAlert,
          },
          legacyMode: true,
        });
      }
    }
  };

  handleLoad = () => {
    this.setState({ loading: false });
  };

  handleFacingModeChange = e => {
    this.setState({ facingMode: e.target.value });
  };

  handleSubmit() {
    this.reader.openImageDialog();
  }
  clearAlert = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.reader.openImageDialog();
      }
    );
  };

  handleError = error => {
    console.log(error);
    this.setState({
      alert: {
        type: "error",
        title: "Webcam not supported",
        text: "We can't access your camera, please upload a photo of the QR code instead",
        showCancelButton: false,
        confirmButtonText: "OK",
        onConfirm: this.clearAlert,
      },
      legacyMode: true,
    });
  };

  handleAnimationShown = () => {
    this.setState({
      animationShown: true,
    });
  };

  render() {
    const centerContainerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 100,
    };
    const hozContainerStyle = {
      margin: "10px",
      display: "flex",
      justifyContent: "space-between",
    };

    const previewStyle = {
      width: "100%",
    };

    if (this.state.error) {
      return (
        <div style={centerContainerStyle}>
          <h3>Error</h3>
        </div>
      );
    } else {
      return [
        <SweetAlert
          isOpen={Boolean(this.state.alert)}
          {...(this.state.alert || {})}
          key="basket-alert"
        />,
        <MainContent scrollable padded>
          <div>
            {!this.state.animationShown && (
              <div className="text-center">
                <p>Scan the QR found on the back of one of our cards to message the sender</p>
                <br />
              </div>
            )}
            {this.state.animationShown && (
              <div>
                {this.state.loading && (
                  <div style={centerContainerStyle}>
                    <h3>Loading</h3>
                  </div>
                )}
                <div style={{ display: this.state.loading ? "none" : "block" }}>
                  {!this.state.legacyMode ? (
                    <div style={hozContainerStyle}>
                      <select
                        value={this.state.facingMode}
                        onChange={this.handleFacingModeChange}
                        style={{ display: "none" }}
                      >
                        <option value="user">User Camera</option>
                        <option value="environment">Environment Camera</option>
                      </select>
                    </div>
                  ) : (
                    <div style={hozContainerStyle}>
                      <Button block label="Upload a photo" onClick={() => this.handleSubmit()} />
                    </div>
                  )}
                  <div className="text-center">
                    {!this.state.legacyMode ? (
                      <p>Scan the QR found on the back of one of our cards to message the sender</p>
                    ) : (
                      <p>
                        Upload a high-quality image of the QR code and we'll try to match it up with
                        one of our cards
                      </p>
                    )}
                    <div
                      className="animated fadeIn"
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                        margin: "0 auto",
                      }}
                    >
                      <QrReader
                        delay={this.state.delay}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        onLoad={this.handleLoad}
                        facingMode={this.state.facingMode}
                        legacyMode={this.state.legacyMode}
                        ref={el => (this.reader = el)}
                        style={previewStyle}
                        handleImageNotRecognized={this.handleError}
                        maxImageSize={750}
                      />
                      <p>{this.state.result}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </MainContent>,
      ];
    }
  }
}

export default Scan;
