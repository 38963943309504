import React from "react";
import { Redirect } from "react-router";
import loadable from "loadable-components";
import { BounceLoader } from "react-spinners";

const Loading = ({ message }) => {
  return (
    <div className="fullscreen-loader fullscreen-loader--is-visible">
      <div className="fullscreen-loader__spinner">
        <BounceLoader color={"#ff5a5f"} />
      </div>
      <div className="fullscreen-loader__message">Loading</div>
    </div>
  );
};
export const PostcardEditor = loadable(
  () => import("../pages/PostcardEditor/PostcardEditorContainer"),
  {
    LoadingComponent: Loading,
  }
);

export const GreetingCardEditor = loadable(
  () => import("../pages/GreetingCardEditor/GreetingCardEditorContainer"),
  {
    LoadingComponent: Loading,
  }
);

export const AnnouncementCardEditor = loadable(
  () => import("../pages/AnnouncementCardEditor/AnnouncementCardEditorContainer"),
  {
    LoadingComponent: Loading,
  }
);

export const CanvasEditor = loadable(() => import("../pages/CanvasEditor/CanvasEditorContainer"), {
  LoadingComponent: Loading,
});

export const routeDefinitions = {
  shop: "/",
  basket: "/basket",
  pricingInfo: "/pricing",
  prepay: "/prepay",
  scan: "/scan",
  topUpHistory: "/topup-history",
  account: "/account",
  orderHistory: "/account/orders",
  orderWithReference: "/account/orders/:reference",
  reference: "/:reference([0-9a-fA-f]{4}-[0-9a-fA-f]{4}-[0-9a-fA-f]{4})",
  resetPassword: "/reset-password",
  referralSignup: "/r/:referralCode",
  confirmEmail: "/confirmations",
  magazineEditor: "/create/magazine",
  productTypeLandingPage: "/create/:productType",
  productLandingPage: "/create/:productType/:productSlug",
};

export const routeCreators = {
  shop: () => routeDefinitions.shop,
  canvasEditor: () => routeDefinitions.canvasEditor,
  magazineEditor: () => routeDefinitions.magazineEditor,
  basket: () => routeDefinitions.basket,
  pricingInfo: () => routeDefinitions.pricingInfo,
  prepay: () => routeDefinitions.prepay,
  scan: () => routeDefinitions.scan,
  orderHistory: () => routeDefinitions.orderHistory,
  topUpHistory: () => routeDefinitions.topUpHistory,
  account: () => routeDefinitions.account,
  orderWithReference: reference =>
    routeDefinitions.orderWithReference.replace(":reference", reference),
  productLandingPage: (productType, productSlug) =>
    routeDefinitions.productLandingPage
      .replace(":productType", productType)
      .replace(":productSlug", productSlug),
  reference: reference => routeDefinitions.reference.replace(":reference", reference),
  resetPassword: () => routeDefinitions.resetPassword,
  confirmEmail: () => routeDefinitions.confirmEmail,
  referralSignup: referralCode =>
    routeDefinitions.referralSignup.replace(":referralCode", referralCode),
};

const OLD_LANDING_PAGES = [
  "hj",
  "austravel",
  "bloggers",
  "postcard-app",
  "app-review-team",
  "press-kit",
  "press-kit-uk",
  "postcard-app/newzealand",
  "for-business",
  "postcard-app/canada",
  "postcards-australia",
  "touchnote-vs-postsnap-postcards",
  "business-postcards",
  "cards/mothers-day",
  "personalized-photo-postcards",
  "business-postcards/usa",
  "business-postcards/aus",
  "cards/invitations",
  "vacation/postcards",
  "postcards/thank-you",
  "postcard-app/uk",
  "invitation-cards/usa",
  "greetings-cards",
  "baby-announcements",
  "baby-shower-invitations",
  "baby-shower-invitations/usa",
  "baby-announcements/usa",
  "cards/christmas",
  "create/postcard",
  //"greeting-cards",
  "announcement-cards",
  "photo-cards/greeting-cards/birthday-cards",
  "photo-cards/greeting-cards/christmas-cards",
  "photo-cards/greeting-cards/thank-you-cards",
  "faq",
  "photo-cards",
  "prices-and-postage",
];

const redirectsMap = OLD_LANDING_PAGES.map(oldPage => {
  return { from: `/${oldPage}`, to: "/" };
});

export const redirects = redirectsMap.map(({ from, to }, i) => {
  return <Redirect key={Math.random() + "REDIRECT_"} from={from} to={to} />;
});
