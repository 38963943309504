import {
  types as authTypes,
  selectors as authSelectors,
  actions as authActions,
} from "../ducks/auth";
import { types as addressBookTypes, actions as addressBookActions } from "../ducks/address-book";
import { types as basketTypes } from "../ducks/basket";

export default store => next => action => {
  const { type, payload, meta } = action;

  function synchronize() {
    const authToken = authSelectors.getAuthToken(store.getState());
    if (authToken) {
      console.log("Synchronizing user data...");
      store.dispatch(authActions.fetchUserDataWithAuthToken(authToken));
    }
  }

  switch (type) {
    case authTypes.CHARGE_STRIPE_PAYMENT_FOR_TOPUP_PURCHASE:
    case authTypes.CHARGE_STRIPE_CUSTOMER_FOR_TOPUP_PURCHASE:
    case authTypes.CONFIRM_PAYPAL_PAYMENT_FOR_TOPUP_PURCHASE:
    case authTypes.UPDATE_USER:
    case basketTypes.PROCESS_PREPAY_PAYMENT:
      if (payload && payload.data && payload.data.success) {
        synchronize();
      }
      break;
    case basketTypes.CHARGE_STRIPE_PAYMENT:
      if (payload && payload.data && payload.data.success && meta.saveCardDetails) {
        synchronize();
      }
      break;
    case addressBookTypes.UPDATE_ENTRY:
      const user = authSelectors.getUser(store.getState());
      if (
        payload &&
        payload.data &&
        payload.data.success &&
        payload.data.data.id === user.getIn(["address", "id"])
      ) {
        synchronize();
      }
      break;
    case addressBookTypes.ADD_NEW_ENTRY:
      if (payload && payload.data && payload.data.success && payload.data.data.main) {
        synchronize();
      }
      break;
    case authTypes.SIGN_IN:
    case authTypes.SIGN_UP:
      if (payload && payload.data && payload.data.success) {
        store.dispatch(addressBookActions.fetchEntries());
      }
      break;
    // no default
  }

  return next(action);
};
