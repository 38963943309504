import React from "react";

import { connect } from "react-redux";

import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

import OrderByReference from "./OrderByReference";

class ReferenceContainer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    alert: null,
    isLookingUpReference: true,
    foundOrder: false,
  };

  componentDidMount = async e => {
    const response = await this.props.fetchOrderByReference(this.props.match.params.reference);
    if (response.error) {
      console.log(response);
      this.setState({
        alert: {
          type: "error",
          title: "Invalid Code",
          text: "It looks like this isn't a valid recipient code",
          showCancelButton: false,
          confirmButtonText: "OK",
          onConfirm: this.clearAlert,
        },
        isLookingUpReference: false,
        foundOrder: false,
      });
    } else {
      this.setState({
        isLookingUpReference: false,
        foundOrder: true,
      });
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    return [
      <SweetAlert isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} key="alert" />,
      !this.state.isLookingUpReference && this.state.foundOrder && (
        <OrderByReference
          reference={this.props.match.params.reference}
          orderByReference={this.props.orderByReference}
        />
      ),
    ];
  }
}

const mapStateToProps = state => ({
  orderByReference: authSelectors.getOrderByReference(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrderByReference: reference => dispatch(authActions.fetchOrderByReference(reference)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceContainer);
