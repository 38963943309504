export default function loadCrossOriginImage(src) {
  return new Promise(function(resolve, reject) {
    const crossOriginImageObj = new Image();
    crossOriginImageObj.crossOrigin = "anonymous";
    crossOriginImageObj.crossorigin = "anonymous";
    crossOriginImageObj.src = src;
    crossOriginImageObj.onerror = function() {
      reject("cross-origin image load error");
    };
    crossOriginImageObj.onload = () => {
      resolve(crossOriginImageObj);
    };
  });
}
