import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PRODUCT_TYPE_IDS,
  PRODUCT_TYPE_URL_SLUGS,
  productsByProductTypeId,
} from "../../data/products";
import PhotoPrintsPreBagContainer from "../../components/PhotoPrintsPreBag/PhotoPrintsPreBagContainer";
import PhotoMagazinePreBagContainer from "../../components/PhotoMagazinePreBag/PhotoMagazinePreBagContainer";
import PostcardEditorContainer from "../PostcardEditor/PostcardEditorContainer";
import AnnouncementCardEditorContainer from "../AnnouncementCardEditor/AnnouncementCardEditorContainer";
import GreetingCardEditorContainer from "../GreetingCardEditor/GreetingCardEditorContainer";
import CanvasEditorContainer from "../CanvasEditor/CanvasEditorContainer";

function getProductByProductTypeIdAndProductSlug(productTypeId, productSlug) {
  return productsByProductTypeId.get(+productTypeId).find(p => {
    return p.get("url_slug") === productSlug;
  });
}

class ProductLandingPageContainer extends Component {
  state = {};

  render() {
    const { productType, productSlug } = this.props.match.params;

    switch (productType) {
      case PRODUCT_TYPE_URL_SLUGS.POSTCARD:
        return (
          <PostcardEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.POSTCARD}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.POSTCARD, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.ANNOUNCEMENT:
        return (
          <AnnouncementCardEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.ANNOUNCEMENT}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.ANNOUNCEMENT,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.GREETING_CARD:
        return <GreetingCardEditorContainer designSlug={productSlug} />;
      case PRODUCT_TYPE_URL_SLUGS.CANVAS:
        return (
          <CanvasEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.CANVAS}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.CANVAS, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.PHOTO_PRINT:
        return (
          <PhotoPrintsPreBagContainer
            productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.PHOTO_PRINT,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.PHOTO_MAGAZINE:
        return (
          <PhotoMagazinePreBagContainer
            productTypeId={+PRODUCT_TYPE_IDS.PHOTO_MAGAZINE}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductLandingPageContainer);
