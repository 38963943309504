import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ModalPage = ({ children, pageId, depth, style, animated }) => {
  const classes = classNames("modal__page", {
    "modal__page--animated": animated,
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

ModalPage.propTypes = {
  children: PropTypes.node.isRequired,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  depth: PropTypes.number.isRequired,
};

ModalPage.defaultProps = {
  depth: 1,
};

export default ModalPage;
