import React from "react";
import toastr from "toastr";
import PropTypes from "prop-types";

class Toast extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(["info", "warning", "success", "error"]).isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string,
    closeButton: PropTypes.bool,
    positionClass: PropTypes.oneOf(["toast-bottom-center", "toast-top-center"]),
    onClick: PropTypes.func,
    onHidden: PropTypes.func,
    onCloseClick: PropTypes.func,
    timeOut: PropTypes.number,
  };

  static defaultProps = {
    type: "info",
    closeButton: false,
    positionClass: "toast-bottom-center",
    onClick: () => {},
    onHidden: () => {},
    onCloseClick: () => {},
    timeOut: 6000,
    extendedTimeOut: 0,
  };

  componentDidMount() {
    toastr[this.props.type](this.props.message, this.props.title, {
      ...this.props,
      onclick: this.props.onClick,
    });
  }

  render() {
    return null;
  }
}

export default Toast;
