import React, { Component } from "react";
import PropTypes from "prop-types";
import { routeCreators } from "../../lib/routes";
import Button from "../../components/Button/Button";
import Block from "../../components/Block/Block";
import Currency from "../../components/Formatters/Currency";
import Date from "../../components/Formatters/Date";

import S3Image from "../../components/S3Image/S3Image";
import MainContent from "../../components/MainContent/MainContent";
import Header from "../../components/Header/Header";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";

class OrderHistory extends Component {
  static propTypes = {
    reference: PropTypes.string,
  };

  static defaultProps = {};

  renderList = props => {
    const orderEntries = props.orderHistory.get("data").map(order => (
      <Block
        hasRightArrow
        key={order.get("reference")}
        link={routeCreators.orderWithReference(order.get("reference"))}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <Date date={order.get("created_at")} />
              </td>
              <td>#{order.get("reference")}</td>
              <td>
                <Currency amount={order.get("total")} />
              </td>
            </tr>
          </tbody>
        </table>
      </Block>
    ));

    return (
      <MainContent scrollable padded={false}>
        <Header
          title="Order History"
          leftAction={
            <Button label="Back" link={routeCreators.account()} priority="tertiary" theme="muted" />
          }
        />
        {orderEntries}
      </MainContent>
    );
  };

  renderDetail = props => {
    const order = props.orderHistory
      .get("data")
      .find(entry => entry.get("reference") === props.reference);

    return [
      <Header
        leftAction={<Button label="Back" priority="tertiary" link={routeCreators.orderHistory()} />}
      />,
      <MainContent key="main" scrollable padded>
        <table>
          <tbody>
            <tr>
              <td>Order Number:</td>
              <td className="text-right">#{order.get("reference")}</td>
            </tr>
            <tr>
              <td>Ordered On:</td>
              <td className="text-right">
                <Date date={order.get("created_at")} format="DD/MM/YYYY, HH:MM" />
              </td>
            </tr>
            <tr>
              <td>Order Status:</td>
              <td className="text-right">{order.get('customer_status')}</td>
            </tr>
            <tr>
              <td>Payment Method:</td>
              <td className="text-right">{order.get("public_payment_method")}</td>
            </tr>
            <tr>
              <td>Order Total:</td>
              <td className="text-right">
                <Currency amount={order.get("total")} />
              </td>
            </tr>
            <tr>
              <td>Items:</td>
              <td className="text-right">{order.get("line_items").size}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className="order-detail table__spaced">
          <tbody>
            {order.get("line_items").map(item => (
              <tr
                key={item.get("id")}
                style={{ marginBottom: "5px", borderBottom: "1px solid #ccc" }}
              >
                <td
                  style={{
                    width: "40%",
                    whiteSpace: "nowrap",
                    padding: "10px",
                  }}
                >
                  <S3Image storageKey={item.get("preview_s3_key")} className="item-preview" />
                </td>
                <td className="text-right text-muted">
                  <strong>{item.get("item_description")}</strong>
                  <br />
                  <p style={{ fontSize: "12px" }}>
                    {item
                      .get("recipients")
                      .first()
                      .get("first_name")}
                    &nbsp;
                    {item
                      .get("recipients")
                      .first()
                      .get("last_name")}
                    <br />
                    {item
                      .get("recipients")
                      .first()
                      .get("line1")}
                    <br />
                    {item
                      .get("recipients")
                      .first()
                      .get("postcode")}
                    <br />
                    {item
                      .get("recipients")
                      .first()
                      .get("country")}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainContent>,
    ];
  };

  render() {
    if (this.props.orderHistory.get("loading") && this.props.orderHistory.get("data").size === 0) {
      return (
        <FullScreenLoader
          key="loader"
          isVisible={true}
          message={
            this.props.reference
              ? "Fetching your order details"
              : "Please wait while we fetch your orders"
          }
        />
      );
    }

    if (this.props.reference && this.props.orderHistory.get("data").size !== 0) {
      return this.renderDetail(this.props);
    }

    return this.renderList(this.props);
  }
}

export default OrderHistory;
