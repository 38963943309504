import { fromJS } from "immutable";

import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

export const types = {
  TOGGLE_SIDEBAR: "POSTSNAP/UI/TOGGLE_SIDEBAR",
  SHOW_AUTH_MODAL: "POSTSNAP/UI/SHOW_AUTH_MODAL",
  HIDE_AUTH_MODAL: "POSTSNAP/UI/HIDE_AUTH_MODAL",
  SET_GLOBAL_PROGRESS_BAR_PERCENTAGE: "POSTSNAP/UI/SET_GLOBAL_PROGRESS_BAR_PERCENTAGE",
};

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("ui", {
    isSidebarOpen: false,
    isAuthModalVisible: false,
    globalProgressBarPercentage: 0,
  })
);

export function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case "@@router/LOCATION_CHANGE":
      return state.withMutations(state => {
        state.set("isSidebarOpen", false);
      });
    case types.TOGGLE_SIDEBAR:
      return state.set("isSidebarOpen", !state.get("isSidebarOpen"));
    case types.SHOW_AUTH_MODAL:
      return state.set("isAuthModalVisible", true);
    case types.HIDE_AUTH_MODAL:
      return state.set("isAuthModalVisible", false);
    case types.SET_GLOBAL_PROGRESS_BAR_PERCENTAGE:
      return state.set("globalProgressBarPercentage", payload);
    default:
      return state;
  }
}

export const actions = {
  toggleSidebar: () => ({ type: types.TOGGLE_SIDEBAR }),
  showAuthModal: () => ({ type: types.SHOW_AUTH_MODAL }),
  hideAuthModal: () => ({ type: types.HIDE_AUTH_MODAL }),
  setGlobalProgressBarPercentage: percentage => ({
    type: types.SET_GLOBAL_PROGRESS_BAR_PERCENTAGE,
    payload: percentage,
  }),
};

export const selectors = {
  getSidebarVisibilityStatus: state => state.ui.get("isSidebarOpen"),
  getAuthModalVisibilityStatus: state => state.ui.get("isAuthModalVisible"),
  getGlobalProgressBarPercentage: state => state.ui.get("globalProgressBarPercentage"),
};
