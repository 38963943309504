import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import $ from "jquery";

import Button from "../Button/Button";
import Grid from "../Grid/Grid";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";

import './PhotoMagazinePreview.scss';

require("../../vendor/turn");


const Page = React.memo(({ item, children, pageNumber, debug, pageObject }) => {
  let showingPage = false;
  if (pageObject){
    const oddPage = Math.abs(pageObject.page % 2) == 1;
    if (oddPage && pageNumber === pageObject.page){
      showingPage = true;
    }
    if (oddPage){
      if (pageNumber === pageObject.page-1){
        showingPage = true;
      }
    }
  } else{
    if(pageNumber === 0){
      showingPage = true;
    }
  }

  return (
    <div className="page">
      {debug && <div className="page__number">{pageNumber}</div>}
      <HtmlRenderer photoMagazine={true} showingPage={showingPage} page={pageNumber} item={item.toJS()} isInteractive={false} previewMode={true} />
    </div>
  );
});

const BlankPage = ({ label }) => {
  return (
    <div className="page page--blank">
      <div className="page__blank-label">{label}</div>
    </div>
  );
};

const PhotoMagazinePreview = forwardRef(({ item, onChangePage }, ref) => {
  const containerRef = useRef(null);
  // eslint-disable-next-line
  const [fontSizeForCoverBand, setFontSizeForCoverBand] = useState(3);
  const [pageObject, setPageObject] = useState(null);

  useEffect(() => {
    const { width } = containerRef.current.getBoundingClientRect();
    $(containerRef.current).turn({
      width: width,
      height: Math.round(width / 2),
      elevation: 50,
      disabled: true,
      duration: 1200,
      acceleration: true,
    });

    setFontSizeForCoverBand((width / 2) * 0.03);

    $(containerRef.current).bind("start", (event, pageObject, turned) => {
      onChangePage(pageObject);
      setPageObject(pageObject);
    });
  }, [onChangePage, setFontSizeForCoverBand]);

  useEffect(() => {
    $(containerRef.current).turn("page", 1);
  }, [item]);

  const goToPreviousPage = () => {
    $(containerRef.current).turn("previous");
  };

  const goToNextPage = () => {
    $(containerRef.current).turn("next");
  };

  useImperativeHandle(ref, () => ({
    goToPreviousPage,
    goToNextPage,
  }));

  return (
    <div className="photo-magazine-preview">
      <div className="photo-magazine-preview__render">
        <div ref={containerRef}>
          <Page item={item} pageNumber={0} debug={true} pageObject={pageObject}/>
          <BlankPage label="Blank inside front cover" />
          {new Array(22).fill("").map((_, index) => (
            <Page key={index} item={item} debug={true} pageNumber={index + 1} pageObject={pageObject} />
          ))}
          <BlankPage label="Blank inside back cover" />
          <div className="shadow"></div>
        </div>
      </div>
      <br />
      <p className="help-text text-center">
        {!pageObject || pageObject.next === 1
          ? `Cover`
          : pageObject.next === 2 || pageObject.next === 24
          ? `Page ${pageObject.next - 1}`
          : `Page ${pageObject.next - 2} - ${pageObject.next - 1} of 24`}
      </p>
      <div className="photo-magazine-preview__navigation">
        <Grid.Row>
          <Grid.Column>
            <div style={{ paddingRight: "0.25rem" }}>
              <Button
                block
                size="small"
                disabled={!pageObject || pageObject.next === 1}
                label="Previous"
                theme="dark-blue"
                onClick={goToPreviousPage}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.25rem" }}>
              <Button
                disabled={pageObject && pageObject.page === 23}
                block
                size="small"
                label="Next"
                theme="dark-blue"
                onClick={goToNextPage}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
});

export default PhotoMagazinePreview;
