// eslint-disable-next-line
import $ from "jquery";
// eslint-disable-next-line
/* global $ */

export default function getElementDimensions(element) {
  //const styles = window.getComputedStyle(element);
  //const paddingHorizontal = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
  //const paddingVertical = parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);

  return {
    width: $(element).width(),
    height: $(element).height(),
  };
}
