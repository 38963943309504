import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './EditorFillOption.scss';

const EditorFillOption = ({ type, color, src, active, firstAfterFixed, onClick }) => {
  const styles = {};

  switch (type) {
    case "color":
      styles.backgroundColor = color;
      break;
    case "image":
      styles.backgroundImage = `url(${src})`;
      break;
    // no default
  }

  const classes = classNames(
    "editor__fill-option",
    { "editor__fill-option--active": active },
    { "editor__fill-option--first-after-fixed": firstAfterFixed }
  );

  return (
    <div className={classes} style={styles} onClick={onClick}>
      <img
        src={`${process.env.PUBLIC_URL}/images/check-shadow.png`}
        alt="Selected"
        className="editor__fill-option__checkmark"
      />
    </div>
  );
};

EditorFillOption.propTypes = {
  type: PropTypes.oneOf(["color", "image"]).isRequired,
  color: PropTypes.string,
  active: PropTypes.bool,
  firstAfterFixed: PropTypes.bool,
  src: PropTypes.string,
  onClick: PropTypes.func,
};

EditorFillOption.defaultProps = {
  active: false,
};

export default EditorFillOption;
