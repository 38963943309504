import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { actions as authActions } from "../../store/ducks/auth";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";

class ConfirmEmailContainer extends React.Component {
  state = {
    alert: null,
    confirming: false,
    confirmingFinished: false,
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  clearAlertAndRedirect = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        window.location.href = window.location.origin;
        //this.props.goToShop();
      }
    );
  };

  confirmEmail = () => {
    this.setState({
      confirming: true,
    });
    console.log(`Confirming email ${queryString.parse(window.location.search).confirmation_token}`);
    this.props
      .confirmEmail({
        confirmationToken: queryString.parse(window.location.search).confirmation_token,
        env: queryString.parse(window.location.search).env,
      })
      .then(result => {
        if (result.payload.data.success) {
          this.setState({
            alert: {
              confirming: false,
              confirmingFinished: true,
              type: "success",
              title: "Email confirmed",
              text: "Your email has been confirmed and you can now log in.",
              showCancelButton: false,
              onConfirm: this.clearAlertAndRedirect, //window.location.href = window.location.origin;
              callback: () => {
                window.location.href = window.location.origin;
              },
            },
          });
        } else {
          const errors = result.payload.data.errors;
          let title;
          let text;
          if (errors.confirmation_token) {
            title = "Invalid token";
            text = "This confirmation token is not valid. Please contact us for support.";
          }
          this.setState({
            confirming: false,
            confirmingFinished: true,
            alert: {
              type: "error",
              title: title,
              text: text,
              showCancelButton: false,
              onConfirm: this.clearAlert,
            },
          });
        }
      });
  };

  componentDidMount = () => {
    this.confirmEmail();
  };

  render() {
    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <FullScreenLoader
        key="loader"
        message="Confirming your email address..."
        isVisible={this.state.confirming && !this.state.confirmingFinished}
      />,
    ];
  }
}

const mapDispatchToProps = dispatch => ({
  confirmEmail: ({ confirmationToken, env }) =>
    dispatch(authActions.confirmEmail({ confirmationToken, env })),
});

export default connect(
  null,
  mapDispatchToProps
)(ConfirmEmailContainer);
