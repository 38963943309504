import React, { Component } from "react";
import PropTypes from "prop-types";

import FILL_OPTIONS from "../../constants/fill-options";

// SignaturePad needs an explicit import because of https://github.com/szimek/signature_pad/issues/257
import SignaturePad from "signature_pad/dist/signature_pad";

import { pickImages } from "../../lib/file-uploader";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Grid from "../Grid/Grid";
import ModalPage from "../Modal/ModalPage";
// eslint-disable-next-line
import LocalUploadGrid from "../Debug/LocalUploadGrid";
import MainContent from "../MainContent/MainContent";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import EditorSignatureColorOption from "./EditorSignatureColorOption";
import SplitButton from "../Button/SplitButton";

// eslint-disable-next-line
const PLACEHOLDER_AVATAR_IMAGES = [
  "/images/avatars/001-boy-1.png",
  "/images/avatars/002-girl-1.png",
  "/images/avatars/003-boy.png",
  "/images/avatars/004-man-4.png",
  "/images/avatars/005-man-3.png",
  "/images/avatars/006-man-2.png",
  "/images/avatars/007-girl.png",
  "/images/avatars/008-man-1.png",
  "/images/avatars/009-man.png",
];

const MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  CHANGE_AVATAR: "CHANGE_AVATAR",
  CHANGE_DRAWING: "CHANGE_DRAWING",
};

const COLOR_OPTIONS = FILL_OPTIONS.slice(3, -1);

COLOR_OPTIONS.splice(1, 0, FILL_OPTIONS[1]);

class EditorSignatureInputModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    ratio: PropTypes.number,
    onSave: PropTypes.func.isRequired,
    signatureData: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ratio: 1,
    signatureData: [],
  };

  state = {
    isDrawingEditorModalVisible: false,
    isAvatarUploadModalVisible: false,
    activePage: MODAL_PAGES.OVERVIEW,
    drawing: null,
    avatar: null,
    activeColor: "rgb(0,0,0)",
  };

  componentDidMount() {
    /**
     * We need to use a `setTimeout` here because the canvasElement ref isn't actually ready when the component mounts
     * because the DOM is transferred by react-modal to another location.
     */
    if (this.props.isOpen) {
      this.setUpSignaturePad();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        drawing: this.props.config.get("drawing") && this.props.config.get("drawing"),
        avatar: this.props.config.get("avatar") && this.props.config.get("avatar"),
      });
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.signaturePad = null;
    }

    if (this.props.isOpen && !this.signaturePad) {
      /**
       * We need to use a `setTimeout` here because the canvasElement ref isn't actually ready when the component mounts
       * because the DOM is transferred by react-modal to another location.
       */
      setTimeout(() => {
        this.setUpSignaturePad();
      });
    }
  }

  setUpSignaturePad = () => {
    /**
     * We need to take the pixel density into account, otherwise the drawing won't work correctly.
     * See https://github.com/szimek/signature_pad#handling-high-dpi-screens for more info
     */

    this.signaturePad = new SignaturePad(this.canvasElement);
    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas);

    if (this.state.drawing && this.state.drawing.data) {
      this.signaturePad.fromData(this.state.drawing.data);
    } else{
      console.log("No signature pad data");
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
  }

  setActivePage = pageId => {
    this.setState({
      activePage: pageId,
    });

    if (pageId === MODAL_PAGES.CHANGE_DRAWING && this.state.drawing && this.state.drawing.data) {
      this.signaturePad.fromData(this.state.drawing.data);
    }
  };

  resizeCanvas = () => {
    if (!this.canvasElement) {
      return;
    }

    this.canvasElement.style.height = `${this.canvasElement.offsetWidth / this.props.ratio}px`;
    const devicePixelRatio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvasElement.width = this.canvasElement.offsetWidth * devicePixelRatio;
    this.canvasElement.height = this.canvasElement.offsetHeight * devicePixelRatio;
    this.canvasElement.getContext("2d").scale(devicePixelRatio, devicePixelRatio);

    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  };

  saveDrawing = () => {
    const dataUrl = this.signaturePad.toDataURL("image/png");
    const data = this.signaturePad.toData();
    this.setState({
      drawing: {
        data,
        image: dataUrl,
      },
      activePage: MODAL_PAGES.OVERVIEW,
    });
  };

  handleClickUndo = () => {
    let data = this.signaturePad.toData();
    if (data && this.signaturePad) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  };

  onChangeColor = color => {
    if (this.signaturePad) {
      this.signaturePad.penColor = color;
      this.setState({ activeColor: color });
    }
  };

  handleClickClear = () => {
    this.signaturePad && this.signaturePad.clear();
  };

  handleClickSave = () => {
    const data = {
      drawing: this.state.drawing,
      avatar: this.state.avatar,
    };
    this.props.onSave(data);
  };

  startAvatarUpload = async source => {
    try {
      const uploadedFile = await pickImages({ amount: 1, source });
      const urlWithCropParam = `${uploadedFile.lowResUrl}/-/scale_crop/500x500/center/`;
      this.changeAvatarUrl(urlWithCropParam);
    } catch (err) {
      console.warn("Error while uploading avatar:", err);
    }
  };

  changeAvatarUrl = imageUrl => {
    this.setState({
      avatar: {
        url: imageUrl,
      },
      activePage: MODAL_PAGES.OVERVIEW,
    });
  };

  removeAvatar = () => {
    this.setState({
      avatar: null,
    });
  };

  render() {
    const hasDrawing = Boolean(this.state.drawing);
    const hasAvatar = Boolean(this.state.avatar);
    const signatureImageSrc = hasDrawing
      ? this.state.drawing.image
      : `${process.env.PUBLIC_URL}/images/signature-drawing-placeholder.svg`;
    const avatarImageSrc = hasAvatar
      ? this.state.avatar.url
      : `${process.env.PUBLIC_URL}/images/signature-avatar-placeholder.svg`;

    let headerProps;

    switch (this.state.activePage) {
      case MODAL_PAGES.OVERVIEW:
        headerProps = {
          title: "Add signature",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={this.props.onCancel}
            />
          ),
          rightAction: (
            <Button
              label="Save"
              priority="tertiary"
              theme="default"
              onClick={this.handleClickSave}
            />
          ),
        };
        break;
      case MODAL_PAGES.CHANGE_DRAWING:
        headerProps = {
          title: hasDrawing ? "Change signature" : "Add signature",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            <Button label="Save" priority="tertiary" theme="default" onClick={this.saveDrawing} />
          ),
          className: "editor__signature-input-modal",
        };
        break;
      case MODAL_PAGES.CHANGE_AVATAR:
        headerProps = {
          title: hasAvatar ? "Change avatar" : "Add avatar",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      // no default
    }

    return (
      <Modal
        padded
        isOpen={this.props.isOpen}
        onClose={this.props.onCancel}
        paged
        activePage={this.state.activePage}
        {...headerProps}
      >
        <ModalPage pageId={MODAL_PAGES.OVERVIEW} depth={1}>
          <Grid.Row>
            <Grid.Column size="0 auto" className="pr-default">
              <div>
                <img
                  style={{
                    height: 85,
                    border: "1px solid rgb(215, 215, 215)",
                    display: "block",
                    borderRadius: "50%",
                    boxShadow: "2px 2px 6px 0px #d9d9d9",
                  }}
                  src={avatarImageSrc}
                  alt="Avatar"
                  onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_AVATAR)}
                />
              </div>
              <br />
              <Button
                block
                label={hasAvatar ? "Change" : "Add"}
                priority="secondary"
                onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_AVATAR)}
              />
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_DRAWING)}
                style={{
                  border: "1px solid rgb(215, 215, 215)",
                  height: 85,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "2px 2px 6px 0px #d9d9d9",
                }}
              >
                <img
                  style={{ maxWidth: "90%", maxHeight: "90%" }}
                  src={signatureImageSrc}
                  alt="Signature"
                  onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_DRAWING)}
                />
              </div>
              <br />
              <Button
                block
                label={hasDrawing ? "Change" : "Add"}
                priority="secondary"
                onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_DRAWING)}
              />
            </Grid.Column>
          </Grid.Row>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.CHANGE_AVATAR} depth={2}>
          <MainContent scrollable padded centeredVertically>
            {hasAvatar && (
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img
                  style={{
                    border: "1px solid rgb(215, 215, 215)",
                    width: "35%",
                    margin: "21px auto",
                    display: "block",
                    borderRadius: "50%",
                    boxShadow: "2px 2px 6px 0px #d9d9d9",
                  }}
                  src={avatarImageSrc}
                  alt="Avatar"
                  onClick={() => this.setActivePage(MODAL_PAGES.CHANGE_AVATAR)}
                />
                <div style={{}}>
                  <Button label="Remove" priority="secondary" onClick={this.removeAvatar} />
                </div>
              </div>
            )}
            <Button
              block
              label="From Device"
              icon="phone"
              onClick={() => this.startAvatarUpload("file")}
            />
            <Button
              block
              icon="facebook"
              label="Facebook"
              className="btn--facebook"
              onClick={() => this.startAvatarUpload("facebook")}
            />
            <Button
              block
              icon="instagram"
              label="Instagram"
              className="btn--instagram"
              onClick={() => this.startAvatarUpload("instagram")}
            />
            <Button
              block
              icon="gdrive"
              className="btn--google"
              label="Google Drive"
              onClick={() => this.startAvatarUpload("gdrive")}
            />
            <Button
              block
              icon="dropbox"
              className="btn--dropbox"
              label="Dropbox"
              onClick={() => this.startAvatarUpload("dropbox")}
            />
            <Button
              block
              icon="flickr"
              className="btn--flickr"
              label="Flickr"
              onClick={() => this.startAvatarUpload("flickr")}
            />
          </MainContent>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.CHANGE_DRAWING} depth={2}>
          <canvas
            className="editor__signature-input-modal-canvas"
            ref={e => (this.canvasElement = e)}
          />
          <div className="editor__signature-color-options" key="signature-color">
            <HorizontalScroller>
              {COLOR_OPTIONS.filter(o => o.type === "color").map((option, index) => (
                <EditorSignatureColorOption
                  key={index}
                  color={option.color}
                  active={this.state.activeColor === option.color}
                  onClick={() => this.onChangeColor(option.color)}
                />
              ))}
            </HorizontalScroller>
          </div>
          <div className="text-center">
            <SplitButton>
              <Button
                label="Undo"
                priority="tertiary"
                theme="muted"
                onClick={this.handleClickUndo}
              />
              <Button
                label="Clear"
                priority="tertiary"
                theme="muted"
                onClick={this.handleClickClear}
              />
            </SplitButton>
          </div>
        </ModalPage>
      </Modal>
    );
  }
}

export default EditorSignatureInputModal;
