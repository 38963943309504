import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PrePay from "./PrePay";
import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";
import { selectors as basketSelectors, actions as basketActions } from "../../store/ducks/basket";
import { topups } from "../../data/topups";

class PrePayContainer extends Component {
  static propTypes = {
    balance: PropTypes.object,
    currency: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    canMakeStripePaymentRequest: false,
    selectedTopupOption: null,
    paymentRequest: null,
    isPayingForTopup: false,
    isPayingForTopupWithSavedCard: false,
  };

  constructor(props) {
    super(props);

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: props.currency.toLowerCase(),
      total: {
        label: "Order total",
        amount: 0,
      },
    });

    paymentRequest.on("token", ({ complete, token, ...data }) => {
      complete("success");
      this.handleStripePaymentRequestSuccess(token, data);
    });

    paymentRequest.canMakePayment().then(result => {
      this.setState({
        canMakeStripePaymentRequest: Boolean(result),
        paymentRequest,
      });
    });
  }

  handleSelectTopupOption = topup => {
    this.setState({
      selectedTopupOption: topup,
    });

    if (this.state.paymentRequest) {
      //console.log('Updating payment request');
      this.state.paymentRequest.update({
        currency: this.props.currency.toLowerCase(),
        total: {
          label: "Order total",
          amount: Math.round(topup.get("cost") * 100),
        },
      });
    }
  };

  handleStripePaymentRequestSuccess = async (token, data) => {
    this.handleStripePaymentFormSubmit({ result: { token } });
  };

  handlePaypalAuthorization = async response => {
    this.setState({
      isPayingForTopup: true,
    });

    try {
      console.log("Creating topup purchase for", this.state.selectedTopupOption.get("id"))
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      console.log("Topup reference is", topupPurchaseResponse.payload.data.data.reference)
      const reference = topupPurchaseResponse.payload.data.data.reference;
      const payPalResponse = await this.props.confirmPaypalPaymentForTopupPurchase({
        token: response.id,
        reference,
      });
      console.log("payPalResponse", payPalResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Paypal:", err);
    } finally {
      this.setState({
        isPayingForTopup: false,
        selectedTopupOption: null,
      });
    }
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    this.setState({
      isPayingForTopup: true,
    });

    try {
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      
      const reference = topupPurchaseResponse.payload.data.data.reference;
      await this.props.chargeStripePaymentForTopupPurchase({
        stripeToken: result.token.id,
        saveCardDetails,
        reference,
      });
      //console.log(topupPaymentResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Stripe:", err);
    } finally {
      this.setState({
        isPayingForTopup: false,
        selectedTopupOption: null,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    this.setState({
      isPayingForTopupWithSavedCard: true,
    });

    try {
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      const reference = topupPurchaseResponse.payload.data.data.reference;
      await this.props.chargeStripeCustomerForTopupPurchase(reference);
      //console.log(topupPaymentResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Stripe:", err);
    } finally {
      this.setState({
        isPayingForTopupWithSavedCard: false,
        selectedTopupOption: null,
      });
    }
  };

  clearSelectedTopupOption = () => {
    this.setState({
      selectedTopupOption: null,
    });
  };

  render() {
    const availableTopups = topups.filter(topup => topup.get("currency") === this.props.currency);
    return (
      <PrePay
        {...this.props}
        currency={this.props.currency}
        topupOptions={availableTopups}
        selectedTopupOption={this.state.selectedTopupOption}
        onSelectTopupOption={this.handleSelectTopupOption}
        onClearSelectedTopupOption={this.clearSelectedTopupOption}
        onStripePaymentFormSubmit={this.handleStripePaymentFormSubmit}
        onPayWithSavedCard={this.handlePayWithSavedCard}
        onPayPalAuthorization={this.handlePaypalAuthorization}
        onSwitchToCurrency={this.props.switchToCurrency}
        canMakeStripePaymentRequest={this.state.canMakeStripePaymentRequest}
        paymentRequest={this.state.paymentRequest}
        isPayingForTopup={this.state.isPayingForTopup}
        isPayingForTopupWithSavedCard={this.state.isPayingForTopupWithSavedCard}
      />
    );
  }
}

const mapStateToProps = state => ({
  balance: authSelectors.getPrePayBalance(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  switchToCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  createTopupPurchase: topupId => dispatch(authActions.createTopupPurchase(topupId)),
  chargeStripePaymentForTopupPurchase: ({ stripeToken, reference, saveCardDetails }) => {
    return dispatch(
      authActions.chargeStripePaymentForTopupPurchase({
        stripeToken,
        reference,
        saveCardDetails,
      })
    );
  },
  chargeStripeCustomerForTopupPurchase: reference => {
    return dispatch(authActions.chargeStripeCustomerForTopupPurchase(reference));
  },
  confirmPaypalPaymentForTopupPurchase: ({ token, reference }) => {
    return dispatch(authActions.confirmPaypalPaymentForTopupPurchase({ token, reference }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrePayContainer);
