import React, { Component } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import debounce from "lodash/debounce";

import ReactPixel from 'react-facebook-pixel';

import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as basketActions } from "../../store/ducks/basket";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { actions as uiActions } from "../../store/ducks/ui";
import { routeCreators, routeDefinitions } from "../../lib/routes";
import getUnrenderedItems from "../../lib/get-unrendered-items";

import Basket from "./Basket";
import Modal from "../../components/Modal/Modal";
import EditorContainer from "../../components/Editor/EditorContainer";
import Button from "../../components/Button/Button";
import MainContent from "../../components/MainContent/MainContent";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import WithWipEditorItemRestore from "../../hoc-components/WithWipEditorItemRestore";
import EditorCropperModal from "../../components/Editor/EditorCropperModal";
import * as LAYER_TYPES from "../../constants/layer-types";
import { fromJS } from "immutable";
import PhotoMagazineEditorContainer from "../../components/PhotoMagazineEditor/PhotoMagazineEditorContainer";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import Header from "../../components/Header/Header";
import EditorAddressInputModal from "../../components/Editor/EditorAddressInputModal";
import Footer from "../../components/Footer/Footer";
import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
import MODELS from "../../constants/models";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";

import { message } from 'antd';

function getAllAutoApprovedItems(items) {
  return items.filter(
    item =>
      ![
        PRODUCT_TYPE_IDS.CANVAS,
        PRODUCT_TYPE_IDS.PHOTO_PRINT,
        PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
      ].includes(item.get("productTypeId"))
  );
}

function createAndAppendScriptWithSourceCode(id, sourceCode) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.id = id;
  s.innerHTML = sourceCode;
  document.body.appendChild(s);
  return id;
}

function createAndAppendScriptWithSrc(id, src) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.id = id;
  s.src = src;
  document.body.appendChild(s);
  return id;
}

function removeElementFromBodyWithId(id) {
  const el = document.querySelector(`#${id}`);
  if (el) {
    document.body.removeChild(el);
  }
}

class BasketContainer extends Component {
  static propTypes = {
    items: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    currency: PropTypes.string,
    isSignedIn: PropTypes.bool,
    updateItem: PropTypes.func,
    deleteItem: PropTypes.func,
    createOrderFromItems: PropTypes.func,
    createGuestOrderFromItems: PropTypes.func,
    chargeStripePayment: PropTypes.func,
    confirmPayPalPayment: PropTypes.func,
  };

  static defaultProps = {};

  state = {
    itemToEdit: null,
    itemToEditAddress: null,
    isEditorModalVisible: false,
    isCropperModalVisible: false,
    draftRotationState: null,
    orderData: null,
    isCreatingOrder: false,
    isCreatingGuestOrder: false,
    isPayingForOrder: false,
    isPayingForOrderWithSavedCard: false,
    isOrderCompletionVisible: false,
    canMakeStripePayment: false,
    paymentRequest: null,
    alert: null,
    isCanvasApprovalModalVisible: false,
    renderItemLoading: false
  };

  constructor(props) {
    super(props);

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: props.currency.toLowerCase(),
      total: {
        label: "Order total",
        amount: Math.round(props.orderSummary.get("total") * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.on("token", async ({ complete, token, ...data }) => {
      console.log('Received Stripe token: ', token);
      console.log('Received customer information: ', data);
      try {
        await complete('success');
        this.handleStripePaymentRequestSuccess(token, data);
      } catch (err) {
        console.log(err)
      }
    });

    paymentRequest.canMakePayment().then(result => {
      this.setState({
        canMakeStripePayment: Boolean(result),
        paymentRequest,
      });
    });

    const allAutoApprovedItems = getAllAutoApprovedItems(this.props.unapprovedItems);
    this.props.approveItems(allAutoApprovedItems.map(i => i.get("id")).toJS());
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkForUnrenderedItems();

    const nonCanvasItems = getAllAutoApprovedItems(this.props.unapprovedItems);
    this.props.approveItems(nonCanvasItems.map(i => i.get("id")).toJS());

    if (this.state.paymentRequest) {
      if (prevProps.currency !== this.props.currency || prevProps.orderSummary.get("total") !== this.props.orderSummary.get("total")){
        console.log("Updating payment request");
        this.state.paymentRequest.update({
          currency: this.props.currency.toLowerCase(),
          total: {
            label: "Order total",
            amount: Math.round(this.props.orderSummary.get("total") * 100),
          },
        });
      } else{
        //console.log("Not updating payment request");
      }
      
    }

    if (this.state.isOrderCompletionVisible) {
      this.loadConversionScripts();
    }

  }

  componentWillUnmount() {
    this.removeConversionScripts();
  }

  loadConversionScripts = () => {
    if (this.sentConversion){
      console.log("Already sent conversion");
      return true;
    }

    const total = parseFloat(this.state.orderData.total);
    const currency = this.props.currency;

    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: true, // enable logs
    };
    ReactPixel.init('1507037732935328', advancedMatching, options);
    //ReactPixel.pageView();
    
    const data = {
      value: total,
      currency: currency,
      transaction_id: this.state.orderData.reference
    }

    ReactPixel.track('Purchase', data);

    this.sentConversion = true;

  }

  removeConversionScripts = () => {
    [
      this.facebookEventsScriptId
    ].forEach(removeElementFromBodyWithId);
  };


  trackAdwordsConversion = () => {
    console.log("Tracking AdWords/E-commerce conversion");
    try {
      if (window.gtag) {
        let adConversion = {
          'send_to': 'AW-974777519/24rBCPujrrcBEK_Z59AD',
          'value': parseFloat(this.state.orderData.total),
          'currency': this.props.currency,
          'transaction_id': this.state.orderData.reference
        };
        console.log("Tracking ad conversion", adConversion);
        window.gtag('event', 'conversion', adConversion);

        //console.log("Order data", this.state.orderData);
        // E-commerce purchase
        let itemsList = this.state.orderData.line_items.map(function (line_item, index) {
          return {
            brand: "PostSnap",
            category: line_item.item_description, // product type
            id: line_item.product.app_key, // SKU
            name: line_item.item_description, // desciption
            price: line_item.total, // '3.0'
            quantity: line_item.quantity, // 1
            list_positon: index + 1
          };
        });
        
        let shippingTotal = 0
        let totalShipping = this.state.orderData.line_items.reduce(
          (sum, line_item) => sum + parseFloat(line_item.delivery_cost)
          , shippingTotal
        )

        let promotionCode = "None";
        if (this.state.orderData.promotions.length > 0){
          promotionCode = this.state.orderData.promotions[0].code;
        }
    
        let transaction = {
          "transaction_id": this.state.orderData.reference,
          "affiliation": "PostSnap Website",
          "value": parseFloat(this.state.orderData.total),
          "currency": this.props.currency,
          "tax": 0.0, // TODO
          "shipping": totalShipping, //parseFloat(totalShipping), // TODO
          "items": itemsList,
          "coupon": promotionCode || "None"
        }

        console.log("Tracking e-commerce purchase", transaction);
        window.gtag('event', 'purchase', transaction);
        return false;
      }
    } catch (err) {
      console.log("Failed to track Adwords conversion",err);
    }
  }

  checkForUnrenderedItems = debounce(() => {
    const unrenderedItems = getUnrenderedItems(this.props.orderSummary.get("items"));
    unrenderedItems.forEach(item => {
      console.log('Rendering item from unchecked');
      this.props.renderItem(item.get("id"));
    });
  }, 200);

  retryRenders = () =>{
    const unrenderedItems = getUnrenderedItems(this.props.orderSummary.get("items"), true);
    unrenderedItems.forEach(item => {
      console.log('Rendering item retry');
      this.props.renderItem(item.get("id")).catch((err) => {
        message.error({
          content: 'There was still a problem. Please contact us if the issue persists.',
          duration: 3
        });
      });;
    });
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  closeEditor = (closeBehavior = {}) => {
    if (closeBehavior.closedWithEscKey || closeBehavior.closedByClickingOverlay) {
      return;
    }

    this.setState({
      itemToEdit: null,
      isEditorModalVisible: false,
    });
  };

  handleEditorSave = item => {
    this.props.updateItem(item.get("id"), item.toJS());
    // TODO: Re-show 3d approval modal here
    this.closeEditor();
  };

  handleClickPreview = item => {
    switch (item.get("productTypeId")) {
      case PRODUCT_TYPE_IDS.PHOTO_PRINT:
        this.openCropperModal(item);
        break;
      case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
        this.openPhotoMagazineEditor(item);
        return;
      default:
        this.openEditor(item);
    }
  };

  handleClickItemAddress = item => {
    this.setState({
      itemToEditAddress: item,
    });
  };

  handleSelectAddressBookEntry = addressBookEntryId => {
    this.props.updateItemAddress(this.state.itemToEditAddress.get("id"), {
      addressBookId: addressBookEntryId,
      address: null,
    });
    this.closeAddressInputModal();
  };

  handleSaveAddress = address => {
    console.log("Saving address:", address);
    this.props.updateItemAddress(this.state.itemToEditAddress.get("id"), {
      addressBookId: null,
      address: address,
    });
    this.closeAddressInputModal();
  };

  closeAddressInputModal = () => {
    this.setState({ itemToEditAddress: null });
  };

  openCropperModal = item => {
    this.setState({
      itemToEdit: item,
      draftRotationState: item.get("isRotated"),
      isCropperModalVisible: true,
    });
  };

  handleRotateCrop = () => {
    this.setState({
      draftRotationState: !this.state.draftRotationState,
    });
  };

  closeCropper = () => {
    this.setState({
      itemToEdit: null,
      draftRotationState: null,
      isCropperModalVisible: false,
    });
  };

  handleSaveCrop = crop => {
    const itemForCropper = this.state.itemToEdit;
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    const updatedItem = itemForCropper.withMutations(item => {
      item.setIn(
        ["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "cropData"],
        fromJS(crop)
      );
      item.set("isRotated", Boolean(this.state.draftRotationState));
    });

    this.props.updateItem(updatedItem.get("id"), updatedItem.toJS());
    this.closeCropper();
  };

  openPhotoMagazineEditor = item => {
    this.setState({
      itemToEdit: item,
      isPhotoMagazineEditorVisible: true,
    });
  };

  closePhotoMagazineEditor = () => {
    this.setState({
      itemToEdit: null,
      isPhotoMagazineEditorVisible: false,
    });
  };

  handleSavePhotoMagazine = item => {
    if (this.state.isDuplicate) {
      this.props.addBasketItem(item.toJS());
      this.setState({
        isDuplicate: false,
      });
    } else {
      this.props.updateItem(item.get("id"), item.toJS());
    }
    this.closePhotoMagazineEditor();
  };

  openEditor = item => {
    this.setState({
      itemToEdit: item,
      isEditorModalVisible: true,
      isDuplicate: false,
    });
  };

  openEditorForDuplicate = item => {
    switch (item.get("productTypeId")) {
      case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
        this.setState({
          itemToEdit: item,
          isPhotoMagazineEditorVisible: true,
          isDuplicate: true,
        });
        break;
      default:
        this.setState({
          itemToEdit: item,
          isEditorModalVisible: true,
          isDuplicate: true,
        });
        break;
    }
  };

  handleCreateOrder = async () => {
    this.setState({
      isCreatingOrder: true,
    });

    try {
      const orderResponse = await this.props.createOrderFromItems();
      this.setState({
        orderData: orderResponse.data.data,
        isCreatingOrder: false,
      });
      return orderResponse;
    } catch (err) {
      console.warn("Error while creating order:", err);
      if(err.data){
        console.log(JSON.stringify(err.data.errors));
      }
      this.setState({
        isCreatingOrder: false,
      });
      return err;
    }
  };

  handleStripePaymentRequestSuccess = async (token, data) => {
    const key = 'paymentMessage';
    const makingPaymentMessage = message.loading({ content: 'Making payment, please wait...', key, duration: 0 });
    if (this.props.isSignedIn) {
      try {
        const orderResponse = await this.props.createOrderFromItems();
        this.setState({
          orderData: orderResponse.data.data,
        });

        const response = await this.props.chargeStripePayment({
          reference: this.state.orderData.reference,
          stripeToken: token.id,
        });
        console.log(response);
        if (response.error) {
          console.warn("Error while creating paymentRequest with Stripe payment:", response.payload.data.errors);
          const errorMessage = `${response.payload.data.errors.base[0].message} Please try again or use another card.` || "Something went wrong with your payment, Please try again later.";
          message.error({ content: 'There was an error making your payment', key, duration: 2 });
          this.setState({
            alert: {
              type: "error",
              title: "Payment Error",
              text: errorMessage,
              showCancelButton: false,
              confirmButtonText: "OK",
              onConfirm: this.clearAlert,
            },
          });
        } else{
          message.success({ content: 'Payment completed', key, duration: 2 });

          this.trackAdwordsConversion();
          this.setState({
            isOrderCompletionVisible: true,
          });
        }
      } catch (err) {
        console.warn("Error while creating order:", err);
        return err;
      } finally {

      }
    } else {
      this.setState({
        isCreatingGuestOrder: true,
      });

      try {
        await this.props.createGuestOrderFromItems(token.id, data.payerEmail);
        message.success({ content: 'Payment completed', key, duration: 2 });
      } catch (err) {
        console.warn("Error while creating guest order with Stripe payment:", err);

        let errorMessage;

        if (err.data.errors && err.data.errors.base){
          errorMessage = err.data.errors.base[0].message || err.data.errors.base[0];
        } else{
          errorMessage = "There was a problem creating your order"
        }

        console.log(errorMessage);
        message.error({ content: 'There was an error making your payment', key, duration: 2 });
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: errorMessage || "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
        return err;
      } finally {
        
        this.setState({
          isCreatingGuestOrder: false,
        });
      }
    }
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    this.setState({
      isPayingForOrder: true,
    });

    try {
      const response = await this.props.chargeStripePayment({
        reference: this.state.orderData.reference,
        stripeToken: result.token.id,
        saveCardDetails,
      });

      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while paying for order with Stripe:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    this.setState({
      isPayingForOrderWithSavedCard: true,
    });

    try {
      const response = await this.props.chargeStripeCustomerForOrder(
        this.state.orderData.reference
      );
      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while paying for order with Stripe:", err);
      this.setState({
        isPayingForOrderWithSavedCard: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrderWithSavedCard: false,
      });
    }
  };

  handlePayPalAuthorization = async response => {
    this.setState({
      isPayingForOrder: true,
    });
    try {
      console.log("Confirming with token", response.id);
      const payPalResponse = await this.props.confirmPayPalPayment({
        reference: this.state.orderData.reference,
        token: response.id,
      });
      if (payPalResponse.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while confirming PayPal payment:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handlePayForOrderWithPrepayBalance = async () => {
    console.log("Paying for order with prepay balance");
    this.setState({
      isPayingForOrder: true,
    });

    try {
      const response = await this.props.processPrepayPayment({
        amount: this.props.orderSummary.get("total"),
        reference: this.state.orderData.reference,
      });
      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while processing prepay payment:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handleAddToBasket = async item => {
    if (item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS) {
      this.setState({
        renderItemLoading: true,
      });

      const bagItem = await this.props.addBasketItemAsync(item.toJS());
      console.log('Rendering item from handleAddToBasket');
      this.props.renderItem(bagItem.payload.id).then(async res => {
        const item = await this.props.getItem(bagItem.payload.id);
        const s3Key = item.payload.itemData.preview_s3_key;
        const productSlug = productsByProductId.get(item.payload.itemData.productId).get("url_slug");
        
        this.setState({
          canvasApprovalModalItemId: bagItem.payload.id,
          canvasApprovalModalItem: bagItem.payload,
          canvasApprovalModalImage: s3Key,
          threeDModel: MODELS[productSlug],
        });

        if (this.state.isDuplicate) {
          this.setState({
            isDuplicate: false,
          });
        }
        this.closeEditor();
        this.setState({
          renderItemLoading: false,
        });
        setTimeout(() => {
          this.openCanvasApprovalModal();
        }, 200);
      });
    } else {
      this.props.addBasketItem(item.toJS());
      this.closeEditor();
    }
  };

  openCanvasApprovalModal = () => {
    this.setState({
      isCanvasApprovalModalVisible: true,
    });
  };

  closeCanvasApprovalModal = itemId => {
    this.setState({
      isCanvasApprovalModalVisible: false,
    });
  };

  closeCanvasApprovalModalAndDeleteItem = itemId => {
    //this.props.deleteItem(itemId);
    this.closeCanvasApprovalModal();
  };

  handleApproveCanvasItem = item => {
    this.props.approveItem(item);
    this.closeCanvasApprovalModal();
  };

  handleEditCanvasItem = item => {
    this.props.deleteItem(item.id);
    this.closeCanvasApprovalModal();

    if (this.state.isDuplicate) {
      this.setState({
        isDuplicate: false,
      });
      this.openEditorForDuplicate(fromJS(item));
    } else{
      this.openEditor(fromJS(item));
    }
    
    // const editorInstance = this.editorContainer.getWrappedInstance();
    // editorInstance.goToFrontPage();
  };

  goToShop = () => {
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  render() {
    const cropperModalProps = {};
    const { itemToEdit } = this.state;

    if (itemToEdit) {
      const indexOfPhotoLayer = itemToEdit
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      const regionToCrop = itemToEdit.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0]);
      const imageToCrop = regionToCrop.get("image");
      const productDimensions = itemToEdit.get("productDimensions").toJS();
      cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"]);
      cropperModalProps.cropData = imageToCrop.get("cropData")
        ? imageToCrop.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right) /
        (productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom);

      if (this.state.draftRotationState) {
        cropperModalProps.ratio =
          (productDimensions.height +
            productDimensions.bleed.top +
            productDimensions.bleed.bottom) /
          (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right);
      }
    }

    const addressInputModal = (
      <EditorAddressInputModal
        key="address-input-modal"
        isDoubleDirect={
          Boolean(this.state.itemToEditAddress &&
            ![
              PRODUCT_TYPE_IDS.POSTCARD,
              PRODUCT_TYPE_IDS.CANVAS,
              PRODUCT_TYPE_IDS.PHOTO_PRINT,
              PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
            ].includes(this.state.itemToEditAddress.get("productTypeId"))
          )
        }
        isOpen={Boolean(this.state.itemToEditAddress)}
        mode={"new"}
        initialFormData={
          this.state.itemToEditAddress && this.state.itemToEditAddress.get("address")
            ? this.state.itemToEditAddress.get("address").toJS()
            : null
        }
        onCancel={this.closeAddressInputModal}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
      />
    );

    const cropperModal = (
      <EditorCropperModal
        key="cropper-modal"
        isOpen={this.state.isCropperModalVisible}
        {...cropperModalProps}
        onClose={this.closeCropper}
        onRotate={this.handleRotateCrop}
        onSave={this.handleSaveCrop}
      />
    );

    const photoMagazineEditorModal = (
      <Modal
        key="photo-magazine-editor-modal"
        isOpen={this.state.isPhotoMagazineEditorVisible}
        onClose={this.closePhotoMagazineEditor}
        hasHeader={false}
      >
        <PhotoMagazineEditorContainer
          key="basket-editor-container"
          item={this.state.itemToEdit}
          onCancel={this.closePhotoMagazineEditor}
          onSave={this.handleSavePhotoMagazine}
          saveButtonText="Save"
        />
      </Modal>
    );

    const canvasApprovalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isCanvasApprovalModalVisible}
        onClose={this.closeCanvasApprovalModal}
        title="Approve Your Canvas"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={() => this.closeCanvasApprovalModalAndDeleteItem(this.state.canvasApprovalModalItemId)}
          />
        }
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Approve"
            onClick={() => this.handleApproveCanvasItem(this.state.canvasApprovalModalItemId)}
          />
        }
      >
        <MainContent scrollable={false} centeredVertically padded key="main-preview">
          <ThreeDimensionalViewer model={this.state.threeDModel} image={this.state.canvasApprovalModalImage} />
          <p className="help-text text-center" style={{ zIndex: 1 }}>
            This preview is for illustrative purposes only. The actual product may differ slightly
            from what is shown here.
          </p>
        </MainContent>
        <Footer padded key="footer">
          <div className="footer__split-buttons">
            <Button
              label="Edit"
              priority="secondary"
              onClick={() => this.handleEditCanvasItem(this.state.canvasApprovalModalItem)}
            />
            <Button
              label="Approve"
              onClick={() => this.handleApproveCanvasItem(this.state.canvasApprovalModalItemId)}
            />
          </div>
        </Footer>
      </Modal>
    );

    const content = [
      cropperModal,
      photoMagazineEditorModal,
      canvasApprovalModal,
      addressInputModal,
      this.state.isOrderCompletionVisible && (
        <React.Fragment>
          <Header
            theme="grey"
            image="/images/logo.svg"
            title="PostSnap Postcard App"
            rightAction={<BasketButtonContainer />}
            leftAction={<AccountButtonContainer />}
            onClickTitleOrImage={this.goToShop}
          />
          <MainContent scrollable={true} padded={true} centeredVertically>
            {this.state.orderData && (
              <div
                key="order-completion"
                className="animated fadeIn"
                dangerouslySetInnerHTML={{
                  __html: this.state.orderData.completion_html,
                }}
              />
            )}
            <br />
            <Button
              block
              label="Shop for another product"
              link={process.env.REACT_APP_BASE_URL || "https://www.postsnap.com"}
              key="go-to-shop"
            />
          </MainContent>
        </React.Fragment>
      ),
      !this.state.isOrderCompletionVisible && (
        <Basket
          key="basket"
          items={this.props.items}
          orderSummary={this.props.orderSummary}
          user={this.props.user}
          currency={this.props.currency}
          unapprovedItems={this.props.unapprovedItems}
          showDuplicateAlertForItem={this.props.showDuplicateAlertForItem}
          prepayBalance={this.props.prepayBalance}
          canMakeStripePayment={this.state.canMakeStripePayment}
          paymentRequest={this.state.paymentRequest}
          isSignedIn={this.props.isSignedIn}
          isCreatingOrder={this.state.isCreatingOrder}
          isPayingForOrder={this.state.isPayingForOrder}
          isPayingForOrderWithSavedCard={this.state.isPayingForOrderWithSavedCard}
          isCreatingGuestOrder={this.state.isCreatingGuestOrder}
          onClickPreview={this.handleClickPreview}
          onClickDuplicate={this.openEditorForDuplicate}
          onDeleteItem={this.props.deleteItem}
          onDeleteAllItems={this.props.deleteAllItems}
          onClickItemAddress={this.handleClickItemAddress}
          onCreateOrder={this.handleCreateOrder}
          onStripePaymentFormSubmit={this.handleStripePaymentFormSubmit}
          onPayPalAuthorization={this.handlePayPalAuthorization}
          onPayForOrderWithPrepayBalance={this.handlePayForOrderWithPrepayBalance}
          onPayWithSavedCard={this.handlePayWithSavedCard}
          onShowAuthModal={this.props.showAuthModal}
          onDismissDuplicateAlertItem={this.props.setDuplicateAlertShown}
          onApproveItem={this.props.approveItem}
          onApproveAllItems={this.props.approveAllItems}
          onApplyPromoCode={this.props.applyPromoCode}
          onRemovePromoCode={this.props.removePromoCode}
          onRetryRenders={this.retryRenders}
          goToRoute={this.props.goToRoute}
          hasSeenCrossSellModal={this.props.hasSeenCrossSellModal}
          onSetSeenCrossSellModal={this.props.setSeenCrossSellModal}
          onDecreaseQuantityForItem={this.props.decreaseQuantityForItem}
          onIncreaseQuantityForItem={this.props.increaseQuantityForItem}
        />
      ),
      <Modal
        key="basket-editor-modal"
        isOpen={this.state.isEditorModalVisible}
        onClose={this.closeEditor}
        hasHeader={false}
      >
        <EditorContainer
          key="basket-editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemToEdit}
          isDuplicate={this.state.isDuplicate}
          onClose={this.closeEditor}
          onSave={this.state.isDuplicate ? this.handleAddToBasket : this.handleEditorSave}
          saveButtonLabel={this.state.isDuplicate ? "Add to Basket" : "Save"}
        />
      </Modal>,
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="basket-alert"
      />,
      <FullScreenLoader
        key="3d-loader"
        loader="bar"
        message="Please wait while we prepare a preview of your canvas ..."
        isVisible={this.state.renderItemLoading}
      />
    ];

    return (
      <WithWipEditorItemRestore
        key="basket-wip"
        source={routeDefinitions.basket}
        onRestore={this.openEditor}
      >
        {content}
      </WithWipEditorItemRestore>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  hasSeenCrossSellModal: basketSelectors.getHasSeenCrossSellModal(state),
  unapprovedItems: basketSelectors.getAllUnapprovedItems(state),
  showDuplicateAlertForItem: basketSelectors.getDuplicateAlertItem(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  prepayBalance: authSelectors.getPrePayBalance(state),
});

const mapDispatchToProps = dispatch => ({
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  getItem: (itemId) => dispatch(basketActions.getItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  goToRoute: (route) => dispatch(push(route)),
  setSeenCrossSellModal: seenCrossSellModal => dispatch(basketActions.setSeenCrossSellModal(seenCrossSellModal)),
  updateItem: (itemId, itemData) => dispatch(basketActions.updateItem(itemId, itemData)),
  updateItemAddress: (itemId, addressData) =>
    dispatch(basketActions.updateItemAddress(itemId, addressData)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
  deleteAllItems: () => dispatch(basketActions.deleteAllItems()),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  setDuplicateAlertShown: itemId => dispatch(basketActions.setDuplicateAlertShown(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  approveAllItems: itemId => dispatch(basketActions.approveAllItems()),
  approveItems: itemIds => {
    if (itemIds.length) {
      dispatch(basketActions.approveItems(itemIds));
    }
  },
  createOrderFromItems: () => dispatch(basketActions.createOrderFromItems()),
  createGuestOrderFromItems: (stripeToken, customerEmail) =>
    dispatch(basketActions.createGuestOrderFromItems(stripeToken, customerEmail)),
  chargeStripePayment: ({ reference, stripeToken, saveCardDetails }) =>
    dispatch(
      basketActions.chargeStripePayment({
        reference,
        stripeToken,
        saveCardDetails,
      })
    ),
  chargeStripeCustomerForOrder: reference =>
    dispatch(basketActions.chargeStripeCustomerForOrder(reference)),
  confirmPayPalPayment: ({ reference, token }) =>
    dispatch(basketActions.confirmPayPalPayment({ token, reference })),
  processPrepayPayment: ({ amount, reference }) =>
    dispatch(basketActions.processPrepayPayment({ amount, reference })),
  applyPromoCode: code => dispatch(basketActions.applyPromoCode(code)),
  removePromoCode: code => dispatch(basketActions.removePromoCode(code)),
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
  decreaseQuantityForItem: (itemId) => dispatch(basketActions.decreaseQuantityForItem(itemId)),
  increaseQuantityForItem: (itemId) => dispatch(basketActions.increaseQuantityForItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketContainer);
