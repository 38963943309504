import { PRODUCT_TYPE_IDS } from "../data/products";

export default {
  [PRODUCT_TYPE_IDS.POSTCARD]: {
    front: 0,
    rear: 1,
  },
  [PRODUCT_TYPE_IDS.ANNOUNCEMENT]: {
    front: 0,
    rear: 1,
    envelope: "ENVELOPE",
  },
  [PRODUCT_TYPE_IDS.INVITATION]: {
    front: 0,
    rear: 1,
    envelope: "ENVELOPE",
  },
  [PRODUCT_TYPE_IDS.GREETING_CARD]: {
    front: 0,
    rear: 2,
    envelope: "ENVELOPE",
  },
  [PRODUCT_TYPE_IDS.CANVAS]: {
    front: 0,
    envelope: "ENVELOPE",
  },
  [PRODUCT_TYPE_IDS.PHOTO_PRINT]: {
    front: 0,
  },
  [PRODUCT_TYPE_IDS.PHOTO_MAGAZINE]: new Array(23).fill("").reduce((map, _, index) => {
    map[index === 0 ? "front" : index] = index;
    return map;
  }, {}),
};
