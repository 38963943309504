import { fromJS } from "immutable";

import { PRODUCT_TYPE_IDS } from "../data/products";
import * as LAYER_TYPES from "../constants/layer-types";
import {
  MIN_FONT_SIZE,
  DEFAULT_FONT,
  FONT_FAMILIES,
  DEFAULT_FONT_COLOR,
  POSTCARD_DEFAULT_FONT_SIZE,
  GREETING_CARD_DEFAULT_FONT_SIZE,
} from "../constants/text-style-options";
import LAYOUTS from "../constants/layouts";
import PAGES_BY_PRODUCT_TYPE from "../constants/pages-by-product-type";

export default function transformDesignLayers(layers, productTypeId) {
  layers = layers.push(
    fromJS({
      config: {
        size: 24,
        font: DEFAULT_FONT,
        style: "Normal",
        alignment: "center",
        max_length: "750",
        color: "rgb(255,255,255)",
        colors: ["255,255,255"],
        rect: {
          x: 0.15,
          y: 0.6,
          width: 0.7,
          height: 0.1,
        },
        text: "",
        lock: "false",
      },
      design_id: 2,
      id: "EXTRA_TEXT_LAYER",
      page: 0,
      zindex: null,
      type: "Layer::Text",
    })
  );

  return layers
    .filter(layer => {
      switch (productTypeId) {
        case PRODUCT_TYPE_IDS.GREETING_CARD:
          const isTextLayer = layer.type === LAYER_TYPES.TEXT;
          const isOnFrontOfGreetingCard = layer.page === PAGES_BY_PRODUCT_TYPE[productTypeId].front;
          return !(isTextLayer && isOnFrontOfGreetingCard);
        default:
          return true;
      }
    })
    .map(layer => {
      switch (layer.get("type")) {
        case LAYER_TYPES.PHOTO:
          return layer.update("config", layerConfig =>
            layerConfig.withMutations(config => {
              config.set(
                "border",
                fromJS({
                  style: null,
                  thickness: 0.1,
                })
              );

              config.set("layout", fromJS(LAYOUTS[0]));
            })
          );
        case LAYER_TYPES.ADDRESS:
          return layer.update("config", layerConfig =>
            layerConfig.withMutations(config => {
              if (config.get("font") === "Helvetica") {
                config.set("font", "Anaheim Gothic");
              }
            })
          );
        case LAYER_TYPES.TEXT:
          return layer.update("config", layerConfig =>
            layerConfig.withMutations(config => {
              const isFontFamilyPresent = FONT_FAMILIES.map(font => font.name).includes(
                config.get("font")
              );
              /**
               * The API adds the weight variant to the font name, so `Antonio-Light` is actually the `Antonio` font,
               * but since we can't find that literal `Antonio-Light` string in our font families, we check the font
               * families again for a partial match. This way we can use the default font family for that variant as a
               * fallback font.
               */
              const fallbackFontFamily = FONT_FAMILIES.find(font =>
                config.get("font").includes(font.name)
              );
              //console.log(isFontFamilyPresent, fallbackFontFamily);
              config.set(
                "font",
                isFontFamilyPresent
                  ? config.get("font")
                  : (fallbackFontFamily && fallbackFontFamily.name) || DEFAULT_FONT
              );
              config.set(
                "variantColor",
                config.get("colors").size
                  ? `rgb(${config.getIn(["colors", 0])})`
                  : DEFAULT_FONT_COLOR
              );
              config.set("colors", config.get("colors").map(color => `rgb(${color})`));
              const isOnFrontOfGreetingCard =
                productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD &&
                layer.get("page") === PAGES_BY_PRODUCT_TYPE[productTypeId].front;
              config.set("originalText", isOnFrontOfGreetingCard ? "" : config.get("text"));
              config.set("placeholderText", config.get("text"));

              if (config.get("size") < MIN_FONT_SIZE) {
                config.set("size", POSTCARD_DEFAULT_FONT_SIZE);
              }

              if (
                productTypeId === PRODUCT_TYPE_IDS.POSTCARD &&
                layer.get("id") !== "EXTRA_TEXT_LAYER"
              ) {
                config.set("size", POSTCARD_DEFAULT_FONT_SIZE);
              }

              if (
                productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD &&
                layer.get("id") !== "EXTRA_TEXT_LAYER" &&
                layer.get("page") !== 0
              ) {
                config.set("size", GREETING_CARD_DEFAULT_FONT_SIZE);
              }
            })
          );
        case LAYER_TYPES.GRAPHIC:
          if (productTypeId === PRODUCT_TYPE_IDS.POSTCARD) {
            return layer.update("config", layerConfig =>
              layerConfig.withMutations(config => {
                config.setIn(
                  ["s3_keys", 0],
                  `${process.env.PUBLIC_URL}/images/postcard-rear-graphic.png`
                );
                config.setIn(
                  ["thumbnails", 0],
                  `${process.env.PUBLIC_URL}/images/postcard-rear-graphic.png`
                );
              })
            );
          } else {
            return layer;
          }
        default:
          return layer;
      }
    });
}
