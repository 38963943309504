import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getAppValueByKeyAndLocale } from "../../data/app-values";
import { Icon as AntIcon, Button as AntButton, Row, Col } from "antd";
import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import QuantityPicker from "../QuantityPicker/QuantityPicker";
import Footer from "../Footer/Footer";
import Grid from "../Grid/Grid";
//import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Currency from "../Formatters/Currency";
import Modal from "../Modal/Modal";
import Icon from "../Icon/Icon";
import AppValue from "../../pages/AppValue/AppValue";
import QueueAnim from 'rc-queue-anim';
import './PhotoPrintsPreBag.scss';

const PhotoPrintsPreBag = ({
  product,
  items,
  totalQuantity,
  costPerPrint,
  showTooltip,
  onCancel,
  onStartUpload,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  onDeleteItem,
  onClickNext,
  onClickItem,
  onChangeProductOption,
  currency
}) => {
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const [isPricingBandVisible, setIsPricingBandVisible] = useState(true);

  const handleChangeProductOption = (el, option) => {
    document.activeElement.blur();
    onChangeProductOption(option);
  }

  const currencyToLocaleMappings = {
    "GBP" : "en_GB",
    "USD" : "en_US",
    "CAD" : "en_CA",
    "AUD" : "en_AU",
  }
  
  const pricingModal = (
    <Modal isOpen={isPricingModalVisible} onClose={() => setIsPricingModalVisible(false)}>
      <AppValue
        htmlContent={getAppValueByKeyAndLocale("PRINTS_PRICING", currencyToLocaleMappings[currency]).get("value")}
        title="Pricing info"
      />
    </Modal>
  );
  
  const glossyOrMatt = true;
  const isGlossy = items.length > 0 ? items[0].get("product_options") === "gloss" : true;
  const classes = classNames(
    'photo-prints-prebag__item-preview',
    {
      'item-preview--is-glossy-or-matt': glossyOrMatt,
      'item-preview--is-glossy': isGlossy,
    }
  );

  return (
    <React.Fragment>
      {pricingModal}
      <Header
        title={product.get("name")}
        leftAction={<Button theme="muted" priority="tertiary" label="Cancel" onClick={onCancel} />}
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Next"
            disabled={items.length === 0}
            onClick={onClickNext}
          />
        }
      />
      <MainContent padded scrollable className="photo-prints-prebag">
        <p className="help-text text-center" style={{marginBottom: '5px', marginTop: '-15px'}}>
          Tap a print to crop, zoom and rotate.
          <span style={{position: 'relative', top: '9px', left: '0px'}}>
            <Icon name="crop-and-rotate" size="large"/>
          </span>
        </p>
        <Row type="flex" justify="center" style={{marginBottom: '15px', marginTop: '10px'}}>
          <Col>
            <AntButton.Group>
              <AntButton
                size="default"
                type={items.length === 0
                  ? "primary"
                  : items.length > 0 && items[0].get("product_options") === "gloss"
                  ? "primary"
                  : ""}
                onClick={(e) => handleChangeProductOption(e, "gloss")}
              >
                Gloss
              </AntButton>
              <AntButton
                size="default"
                onClick={(e) => handleChangeProductOption(e, "matt")}
                type={items.length === 0
                  ? ""
                  : items.length > 0 && items[0].get("product_options") === "matt"
                  ? "primary"
                  : ""}
              >
                Matt
              </AntButton>
            </AntButton.Group>
          </Col>
        </Row>
        <QueueAnim delay={100} duration={500} interval={150} className="queue-simple" type={['right', 'left']} leaveReverse>
          {items.map((item, index) => (
              <div key={index} className="photo-prints-prebag__item">
                <div className={classes}>
                  <HtmlRenderer
                    width={130}
                    isInteractive={false}
                    previewMode={true}
                    item={item.toJS()}
                    page={item.getIn(["pages", "front"])}
                    onClick={() => onClickItem(index)}
                  />
                  {glossyOrMatt && (
                    <div className="glossy-or-matt" />
                  )}
                </div>
                <QuantityPicker
                  quantity={item.get("quantity")}
                  onIncrease={() => onIncreaseItemQuantity(index)}
                  onDecrease={() => onDecreaseItemQuantity(index)}
                  onDelete={() => onDeleteItem(index)}
                />
              </div>
          ))}
        </QueueAnim>        
      </MainContent>
      <Footer padded>
        {totalQuantity > 0 &&
          (isPricingBandVisible ? (
            <div className="photo-prints-prebag__pricing-indicator">
              <p>
                You've added {totalQuantity} prints which cost <Currency amount={costPerPrint} />{" "}
                per print. The more you print, the less you pay.
              </p>
              <Button
                size="small"
                label="See prices"
                priority="primary"
                theme="muted"
                onClick={() => setIsPricingModalVisible(true)}
              />
              <AntButton type="link" ghost onClick={() => setIsPricingBandVisible(false)}>
                <AntIcon type="down" style={{ fontSize: "20px", color: "#fff" }} />
              </AntButton>
            </div>
          ) : (
            <Row>
              <Col span={3} offset={20}>
                <AntButton type="link" ghost onClick={() => setIsPricingBandVisible(true)}>
                  <AntIcon type="up" style={{ fontSize: "20px", color: "#043253" }} />
                </AntButton>
              </Col>
            </Row>
          ))}
        <Grid.Row>
          <Grid.Column>
            <div style={{ paddingRight: "0.5rem" }}>
              <Button
                block
                size="small"
                theme="success"
                label={items.length > 0 ? "Get more photos" : "Add photos" }
                onClick={onStartUpload}
                disabled={items.length >= 100}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.5rem" }}>
              <Button
                block
                size="small"
                label="Next"
                onClick={onClickNext}
                disabled={items.length === 0}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Footer>
    </React.Fragment>
  );
};

PhotoPrintsPreBag.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default PhotoPrintsPreBag;
