import PropTypes from "prop-types";
import moment from "moment";

const Date = ({ date, format }) => {
  return moment(date).format(format);
};

Date.propTypes = {
  format: PropTypes.string,
};

Date.defaultProps = {
  format: "DD/MM/YYYY",
};

export default Date;
