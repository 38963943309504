import { fromJS, List } from "immutable";
import shortid from "shortid";
import moment from "moment";
import { handle } from "redux-pack";
import uniq from "lodash/uniq";

import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as CURRENCIES from "../../constants/currencies";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  getNextDecreasedQuantityForProductType,
  getNextIncreasedQuantityForProductType
} from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import { getPostageSchemeForProductTypeAndDestination } from "../../data/postage-schemes";
import { selectors as addressBookSelectors } from "./address-book";
import { types as userTypes } from "./auth";
import postsnapApi from "../../lib/apis/postsnap";
import { isCurrentMajorVersionOutdated } from "../../lib/version-checker";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

function roundToTwoDecimals(number,down=false) {
  //console.log(parseFloat(RoundHalfDown(number).toFixed(2)));
  return parseFloat(RoundHalfDown(number, down).toFixed(2));
}

function RoundHalfDown(num, down=false) {
  //return -Math.round(-num * 100) / 100;
  if (down){
    return Math.floor(num * 100) / 100;
  } else {
    return -Math.round(-num * 100) / 100;
  }
  
}

const LAYER_TYPES_REQUIRING_RENDER = [LAYER_TYPES.TEXT, LAYER_TYPES.PHOTO, LAYER_TYPES.SIGNATURE];

export const types = {
  SET_CURRENCY: "POSTSNAP/BASKET/SET_CURRENCY",
  SET_SEEN_CROSSSELL_MODAL: "POSTSNAP/BASKET/SET_SEEN_CROSSSELL_MODAL",
  ADD_ITEM: "POSTSNAP/BASKET/ADD_ITEM",
  UPDATE_ITEM: "POSTSNAP/BASKET/UPDATE_ITEM",
  UPDATE_ITEM_ADDRESS: "POSTSNAP/BASKET/UPDATE_ITEM_ADDRESS",
  RENDER_ITEM_START: "POSTSNAP/BASKET/RENDER_ITEM_START",
  RENDER_ITEM_END: "POSTSNAP/BASKET/RENDER_ITEM_END",
  RENDER_ITEM_FAILED: "POSTSNAP/BASKET/RENDER_ITEM_FAILED",
  RENDER_LAYER: "POSTSNAP/BASKET/RENDER_LAYER",
  DELETE_ITEM: "POSTSNAP/BASKET/DELETE_ITEM",
  DELETE_ALL_ITEMS: "POSTSNAP/BASKET/DELETE_ALL_ITEMS",
  SET_POST_DATE_FOR_ITEM: "POSTSNAP/BASKET/SET_POST_DATE_FOR_ITEM",
  DUPLICATE_ALERT_DIMISSED: "POSTSNAP/BASKET/DUPLICATE_ALERT_DIMISSED",
  APPROVE_ITEM: "POSTSNAP/BASKET/APPROVE_ITEM",
  APPROVE_ITEMS: "POSTSNAP/BASKET/APPROVE_ITEMS",
  APPROVE_ALL_ITEMS: "POSTSNAP/BASKET/APPROVE_ALL_ITEMS",
  APPLY_PROMOTION_CODE: "POSTSNAP/BASKET/APPLY_PROMOTION_CODE",
  REMOVE_PROMOTION_CODE: "POSTSNAP/BASKET/REMOVE_PROMOTION_CODE",
  CREATE_ORDER: "POSTSNAP/BASKET/CREATE_ORDER",
  CREATE_GUEST_ORDER: "POSTSNAP/BASKET/CREATE_GUEST_ORDER",
  SEND_THANKS_FOR_ORDER: "POSTSNAP/BASKET/SEND_THANKS_FOR_ORDER",
  CHARGE_STRIPE_PAYMENT: "POSTSNAP/BASKET/CHARGE_STRIPE_PAYMENT",
  CHARGE_STRIPE_CUSTOMER: "POSTSNAP/BASKET/CHARGE_STRIPE_CUSTOMER",
  CONFIRM_PAYPAL_PAYMENT: "POSTSNAP/BASKET/CONFIRM_PAYPAL_PAYMENT",
  PROCESS_PREPAY_PAYMENT: "POSTSNAP/BASKET/PROCESS_PREPAY_PAYMENT",
  DECREASE_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/DECREASE_QUANTITY_FOR_ITEM",
  INCREASE_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/INCREASE_QUANTITY_FOR_ITEM",
};

let storedBasketState;

if (isCurrentMajorVersionOutdated()) {
  localStorage.removeItem(STORAGE_KEYS.BASKET);
} else {
  try {
    storedBasketState = JSON.parse(localStorage.getItem(STORAGE_KEYS.BASKET));
    storedBasketState.items = storedBasketState.items.map(item => {
      item.isRendering = false;
      item.renderFailed = false;
      return item;
    });
  } catch (err) {
    console.warn("Error retrieving or parsing basket items from localStorage:", err.message);
  }
}

let defaultCurrency = CURRENCIES.USD;

switch (window.navigator.languages && window.navigator.languages[0]) {
  case "en-GB":
    defaultCurrency = CURRENCIES.GBP;
    break;
  case "en-AU":
    defaultCurrency = CURRENCIES.AUD;
    break;
  case "en-CA":
  case "fr-CA":
    defaultCurrency = CURRENCIES.CAD;
    break;
  default:
    defaultCurrency = CURRENCIES.USD;
    break;
}

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("basket", {
    currency: defaultCurrency,
    items: [],
    promotionCode: null,
    order: null,
    seenCrossSellModal: false
  })
);

export function reducer(
  state = (storedBasketState && fromJS(storedBasketState)) || DEFAULT_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case userTypes.SIGN_OUT:
      return fromJS(DEFAULT_STATE);
    case types.SET_CURRENCY:
      return state.set("currency", payload.currency);
    case types.SET_SEEN_CROSSSELL_MODAL:
      console.log("Set seen",payload.seenCrossSellModal);
      return state.set("seenCrossSellModal", payload.seenCrossSellModal);
    case types.ADD_ITEM:
      let newItem = fromJS(payload);

      if (!newItem.get("quantity")) {
        newItem = newItem.set("quantity", 1);
      }

      return state.update("items", items => items.push(newItem));
    case types.UPDATE_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.merge(fromJS({ ...payload.itemData, isApproved: false }));
          } else {
            return item;
          }
        })
      );
    case types.DECREASE_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const decresedQty = getNextDecreasedQuantityForProductType(
                item.get("productTypeId"),
                item.get("quantity"));
              item.set("quantity", decresedQty);
            });
          } else {
            return item;
          }
        })
      );
    case types.INCREASE_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const increasedQty = getNextIncreasedQuantityForProductType(
                item.get("productTypeId"),
                item.get("quantity"));
              item.set("quantity", increasedQty);
            });
          } else {
            return item;
          }
        })
      );
    case types.UPDATE_ITEM_ADDRESS: {
      const itemToUpdate = state.get("items").find(item => item.get("id") === payload.itemId);
      const shouldUpdateAllPhotoPrintAddresses =
        itemToUpdate.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT;
      return state.update("items", items =>
        items.map(item => {
          if (
            item.get("id") === payload.itemId ||
            (shouldUpdateAllPhotoPrintAddresses &&
              item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
          ) {
            return item.merge(fromJS({ ...payload.addressData }));
          } else {
            return item;
          }
        })
      );
    }
    case types.RENDER_ITEM_START:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", true);
              item.set("renderFailed", false);
              item.update("layers", layers => layers.map(l => l.set("render", null)));
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_ITEM_END:
      console.log("Render item end");
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            //return item.set("isRendering", false);
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", false);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_ITEM_FAILED:
      console.log("Render item failed");
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", true);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_LAYER:
      return handle(state, action, {
        success: prevState => {
          return prevState.update("items", items =>
            items.map(item => {
              if (item.get("id") === action.meta.itemId) {
                return item.withMutations(mutatedItem => {
                  const isFrontPreviewRender = action.meta.layerId === "FRONT_PREVIEW";

                  if (isFrontPreviewRender) {
                    console.log("Saving Preview S3 key", payload.data.s3_key);
                    mutatedItem.set("preview_s3_key", payload.data.s3_key);
                  } else {
                    mutatedItem.update("layers", layers =>
                      layers.map(layer => {
                        if (layer.get("id") === payload.data.layerId) {
                          return layer.set("render", payload.data.s3_key);
                        } else {
                          return layer;
                        }
                      })
                    );
                  }
                });
              } else {
                return item;
              }
            })
          );
        },
        failure: prevState => {
          //console.log("Render failure:", action);
          //dispatch({type: ADD_ERROR, error: err});
          return prevState;
        },
      });
    case types.DELETE_ITEM:
      return state.update("items", items =>
        items.filter(item => item.get("id") !== payload.itemId)
      );
    case types.DELETE_ALL_ITEMS:
      return fromJS(DEFAULT_STATE);
    case types.SET_POST_DATE_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("postDate", payload.postDate);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ITEMS:
      return state.update("items", items =>
        items.map(item => {
          if (payload.itemIds.includes(item.get("id"))) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ALL_ITEMS:
      return state.update("items", items =>
        items.map(item => {
          return item.set("isApproved", true);
        })
      );
    case types.DUPLICATE_ALERT_DIMISSED:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("duplicateAlertShown", true);
          } else {
            return item;
          }
        })
      );
    case types.APPLY_PROMOTION_CODE:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            if (payload.data.success) {
              return state.set("promotionCode", fromJS(payload.data.data));
            }
          });
        },
      });
    case types.REMOVE_PROMOTION_CODE:
      return state.set("promotionCode", null);
    case types.CREATE_ORDER:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            state.setIn(["order"], fromJS(payload.data.data));
          });
        },
      });
    case types.CHARGE_STRIPE_PAYMENT:
    case types.CHARGE_STRIPE_CUSTOMER:
    case types.CONFIRM_PAYPAL_PAYMENT:
    case types.PROCESS_PREPAY_PAYMENT:
    case types.CREATE_GUEST_ORDER:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            state.update("items", items => items.clear());
            state.set("promotionCode", null);
            state.set("order", null);
          });
        },
      });
    default:
      return state;
  }
}

export const actions = {
  setCurrency: currency => async (dispatch, getState) => {
    dispatch({
      type: types.SET_CURRENCY,
      payload: { currency },
    });
    document.cookie = `currency=${currency}; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;
  },
  setSeenCrossSellModal: seenCrossSellModal => async (dispatch, getState) => {
    console.log(seenCrossSellModal);
    dispatch({
      type: types.SET_SEEN_CROSSSELL_MODAL,
      payload: { seenCrossSellModal },
    });
  },
  addItem: item => {
    const id = shortid.generate();
    const itemData = {
      ...item,
      id,
      isApproved: false,
      duplicateAlertShown: item.duplicateAlertShown || false,
    };
    return {
      type: types.ADD_ITEM,
      payload: itemData,
    };
  },
  addItemAsync: item => {
    const id = shortid.generate();
    const itemData = {
      ...item,
      id,
      isApproved: false,
      duplicateAlertShown: false,
    };
    return {
      type: types.ADD_ITEM,
      payload: itemData,
    };
  },
  updateItem: (itemId, itemData) => ({
    type: types.UPDATE_ITEM,
    payload: { itemId, itemData },
  }),
  updateItemAddress: (itemId, addressData) => {
    return {
      type: types.UPDATE_ITEM_ADDRESS,
      payload: { itemId, addressData },
    };
  },

  getItem: itemId => async (dispatch, getState) => {
    const itemData = selectors.getItem(getState(), itemId).toJS();
    return {
      type: types.GET_ITEM,
      payload: { itemData },
    };
  },

  renderItem: itemId => async (dispatch, getState) => {
    const item = selectors.getItem(getState(), itemId).toJS();

    console.log("Rendering item...");

    dispatch({
      type: types.RENDER_ITEM_START,
      payload: { itemId },
    });

    const layerRenders = item.layers
      .filter(
        layer =>
          LAYER_TYPES_REQUIRING_RENDER.includes(layer.type) && layer.id !== "EXTRA_TEXT_LAYER"
      )
      .reduce((renders, layer) => {
        const itemData = {
          ...item,
          layers: [layer],
        };

        const postcardOrAnnouncement =
          item.productTypeId === PRODUCT_TYPE_IDS.POSTCARD ||
          item.productTypeId === PRODUCT_TYPE_IDS.ANNOUNCEMENT;
        // Announcements also have the EXTRA_TEXT_LAYER
        if (postcardOrAnnouncement && layer.page === 0 && layer.type === LAYER_TYPES.PHOTO) {
          itemData.layers = [
            ...itemData.layers,
            item.layers.find(layer => layer.id === "EXTRA_TEXT_LAYER"),
          ];
        }

        itemData.rendererReferenceLayerId = itemData.layers[0].id;

        return [
          ...renders,
          {
            layerId: layer.id,
            promise: postsnapApi.renderService.renderItem(itemData),
          },
        ];
      }, []);
    const itemForPreviewRender = {
      ...item,
      layers: item.layers.filter(layer => layer.page === item.pages.front),
      rendererReferenceLayerId: "FRONT_PREVIEW",
      allLayers: item.layers,
    };
    const previewRender = {
      layerId: "FRONT_PREVIEW",
      promise: postsnapApi.renderService.renderItem(itemForPreviewRender),
    };
    layerRenders.push(previewRender);

    layerRenders.forEach(layerRender => {
      dispatch({
        type: types.RENDER_LAYER,
        promise: layerRender.promise,
        meta: {
          itemId,
          layerId:layerRender.layerId,
          onFailure: (payload, getState) => {
            console.log("Render Failure Result", payload);
            dispatch({
              type: types.RENDER_ITEM_FAILED,
              payload: { itemId },
            });
            //console.log("Render Failure State", getState());
          }
        },
      });
    });

    await Promise.all(layerRenders.map(r => r.promise));
    dispatch({
      type: types.RENDER_ITEM_END,
      payload: { itemId },
    });
  },
  decreaseQuantityForItem: itemId => ({
    type: types.DECREASE_QUANTITY_FOR_ITEM,
    payload: { itemId },
  }),
  increaseQuantityForItem: itemId => ({
    type: types.INCREASE_QUANTITY_FOR_ITEM,
    payload: { itemId },
  }),
  deleteItem: itemId => ({
    type: types.DELETE_ITEM,
    payload: { itemId },
  }),
  deleteAllItems: () => ({
    type: types.DELETE_ALL_ITEMS,
  }),
  setPostDateForItem: (itemId, postDate) => ({
    type: types.SET_POST_DATE_FOR_ITEM,
    payload: { itemId, postDate },
  }),
  setDuplicateAlertShown: itemId => ({
    type: types.DUPLICATE_ALERT_DIMISSED,
    payload: { itemId },
  }),
  approveItem: itemId => ({
    type: types.APPROVE_ITEM,
    payload: { itemId },
  }),
  approveItems: itemIds => ({
    type: types.APPROVE_ITEMS,
    payload: {
      itemIds,
    },
  }),
  approveAllItems: itemIds => ({
    type: types.APPROVE_ALL_ITEMS,
  }),
  applyPromoCode: code => ({
    type: types.APPLY_PROMOTION_CODE,
    promise: postsnapApi.orders.getPromoCodeDetails(code),
  }),
  removePromoCode: () => ({
    type: types.REMOVE_PROMOTION_CODE,
  }),
  createOrderFromItems: () => (dispatch, getState) => {
    const state = getState();
    const orderSummary = selectors.getOrderSummary(state);
    const lineItems = selectors.getLineItemsForOrderPayload(state);
    const payload = {
      order: {
        line_items: lineItems.toJS(),
        discount: orderSummary.get("discount"),
        total: lineItems.reduce((total, item) => total + item.get("total"), 0),
        sub_total: lineItems.reduce((subTotal, item) => subTotal + item.get("sub_total"), 0),
        previous_order_reference: selectors.getOrderReference(state),
      },
      previous_order_reference: selectors.getOrderReference(state),
    };

    const promotionCode = selectors.getPromotionCode(state);

    if (promotionCode) {
      payload.order.promotion_codes = [promotionCode.get("code")];
    }

    const promise = postsnapApi.orders.createOrder(payload);

    dispatch({
      type: types.CREATE_ORDER,
      promise,
    });

    return promise;
  },
  createGuestOrderFromItems: (stripeToken, customerEmail) => (dispatch, getState) => {
    const state = getState();
    const lineItems = selectors.getLineItemsForOrderPayload(getState());
    const payload = {
      order: {
        line_items: lineItems.toJS(),
        discount: 0, // TODO
        total: lineItems.reduce((total, item) => total + item.get("total"), 0),
        sub_total: lineItems.reduce((subTotal, item) => subTotal + item.get("sub_total"), 0),
      },
      payment: {
        method: "stripe",
        stripeToken,
      },
      customer: {
        email: customerEmail,
      },
    };

    const promotionCode = selectors.getPromotionCode(state);

    if (promotionCode) {
      payload.order.promotion_codes = [promotionCode.get("code")];
    }

    const promise = postsnapApi.orders.createGuestOrder(payload);

    dispatch({
      type: types.CREATE_GUEST_ORDER,
      promise,
    });

    return promise;
  },
  sendThanksForOrder: thankData => ({
    type: types.SEND_THANKS_FOR_ORDER,
    promise: postsnapApi.orders.sendThanks(thankData),
  }),
  chargeStripePayment: ({ reference, stripeToken, saveCardDetails = false }) => ({
    type: types.CHARGE_STRIPE_PAYMENT,
    promise: postsnapApi.orders.chargeStripePayment({
      reference,
      stripeToken,
      saveCardDetails,
    }),
    meta: { saveCardDetails },
  }),
  chargeStripeCustomerForOrder: reference => ({
    type: types.CHARGE_STRIPE_CUSTOMER,
    promise: postsnapApi.orders.chargeStripeCustomerForOrder(reference),
  }),
  confirmPayPalPayment: ({ reference, token }) => ({
    type: types.CONFIRM_PAYPAL_PAYMENT,
    promise: postsnapApi.orders.confirmPayPalPayment({ reference, token }),
  }),
  processPrepayPayment: ({ amount, reference }) => ({
    type: types.PROCESS_PREPAY_PAYMENT,
    promise: postsnapApi.orders.processPrepayPayment({ amount, reference }),
  }),
};

export const selectors = {
  getCurrency: state => state.basket.get("currency"),
  getHasSeenCrossSellModal: state => state.basket.get("seenCrossSellModal"),
  getPromotionCode: state => state.basket.get("promotionCode"),
  getItem: (state, id) => state.basket.get("items").find(i => i.get("id") === id),
  getItems: state =>
    state.basket
      .get("items")
      .map(item => {
        return item.withMutations(item => {

          let quantity = item.get("quantity");
          // For photo prints, we need the quantity of _all_ photo prints of that size (product ID)
          if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
            let printProductId = item.get("productId");
            quantity = state.basket.get("items").reduce((totalPrintQty, basketItem) => {
              if (basketItem.get("productId") === printProductId) {
                return totalPrintQty + basketItem.get("quantity");
              }
              return totalPrintQty;
            }, 0);
          }
          
          const pricingScheme = getPricingSchemeForProductAndQuantity({
            productId: item.get("productId"),
            quantity: quantity,
            currency: selectors.getCurrency(state),
          });
          item.set("pricingScheme", pricingScheme);

          item.set(
            "description",
            productsByProductId.getIn([item.get("productId"), "basket_description"]).replace("Portrait", "") ||
              productsByProductId.getIn([item.get("productId"), "name"]).replace("Portrait", "")
          );

          if (item.get("address") || item.get("addressBookId")) {
            let country;

            if (item.get("address")) {
              country = item.getIn(["address", "country"]);
            }

            if (item.get("addressBookId")) {
              const addressBookEntry = addressBookSelectors.getEntry(
                state,
                item.get("addressBookId")
              );
              country = addressBookEntry && addressBookEntry.get("country");
            }

            if (country) {
              let quantity = item.get("quantity");

              // For photo prints, we need the quantity of _all_ photo prints, since we only apply the postage scheme
              // to one product
              if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
                quantity = state.basket.get("items").reduce((totalPrintQty, basketItem) => {
                  if (basketItem.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
                    return totalPrintQty + basketItem.get("quantity");
                  }

                  return totalPrintQty;
                }, 0);
              }

              const totalWeight =
                quantity * (item.get("weight") === undefined ? 1 : item.get("weight"));
              const postageScheme = getPostageSchemeForProductTypeAndDestination({
                productTypeId: item.get("productTypeId"),
                destinationCountry: country,
                currency: selectors.getCurrency(state),
                weight: totalWeight,
              });
              item.set("postageScheme", postageScheme);
            }
          }

          if (item.get("addressBookId")) {
            item.set(
              "addressBookEntry",
              addressBookSelectors.getEntry(state, item.get("addressBookId"))
            );
          }
        });
      })
      .map((item, index, items) => {
        if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
          const photoPrintItems = items.filter(
            item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT
          );

          const isLastPhotoPrintItem = item.get("id") === photoPrintItems.last().get("id");

          /**
           * If we're not on the last photo print item, we set the shipping cost to 0 since we only charge for it once
           */
          if (!isLastPhotoPrintItem) {
            return item.setIn(["postageScheme", "cost"], 0);
          }
        }

        return item;
      })
      .sort((a, b) => a.get("productTypeId") - b.get("productTypeId")),
  getOrderReference: state =>
    state.basket.get("order") && state.basket.getIn(["order", "reference"]),
  getOrderSummary: state => {
    let items = selectors.getItems(state);
    const promotion = selectors.getPromotionCode(state);
    let promotionInfo = {
      data: selectors.getPromotionCode(state),
    };

    let subTotal = items.reduce((total, item) => {
      const itemCost = item.get("quantity") * item.getIn(["pricingScheme", "cost"]);
      return total + itemCost;
    }, 0);
    let totalShippingCost = items.reduce((total, item) => {
      const shippingCost = item.get("postageScheme") && item.getIn(["postageScheme", "cost"]);
      return total + (shippingCost || 0);
    }, 0);
    let total = subTotal + totalShippingCost;
    let globalDiscount = 0;

    if (promotion) {
      switch (promotion.get("promo_type")) {
        case "order": {
          globalDiscount =
            parseFloat(promotion.get("amount")) ||
            parseFloat(total) * (parseFloat(promotion.get("percent")) / 100);

          promotionInfo.totalDiscountForOrder = globalDiscount;
          total = total - globalDiscount;
          break;
        }
        case "delivery": {
          globalDiscount =
            +promotion.get("amount") ||
            parseFloat(totalShippingCost) * (parseFloat(promotion.get("percent")) / 100);

          promotionInfo.totalDiscountForOrder = globalDiscount;
          total = total - globalDiscount;
          break;
        }
        case "product": {
          const cheapestItem = items.reduce((cheapestSoFar, item) => {
            const cheapestCost = parseFloat(
              cheapestSoFar.get("quantity") *
                parseFloat(cheapestSoFar.getIn(["pricingScheme", "cost"]))
            );
            const lineItemCost = parseFloat(
              item.get("quantity") * parseFloat(item.getIn(["pricingScheme", "cost"]))
            );
            return lineItemCost < cheapestCost ? item : cheapestSoFar;
          }, items.first());
          const costForCheapestItem = parseFloat(
            cheapestItem.get("quantity") * parseFloat(cheapestItem.getIn(["pricingScheme", "cost"]))
          );
          const discountForCheapestItem = roundToTwoDecimals(
            +promotion.get("amount") ||
              parseFloat(costForCheapestItem) * (parseFloat(promotion.get("percent")) / 100),
            true
          );

          items = items.map(item => {
            if (item === cheapestItem) {
              return item.set("discount", discountForCheapestItem);
            } else {
              return item;
            }
          });

          promotionInfo.totalDiscountForOrder = discountForCheapestItem;

          total = total - discountForCheapestItem;
          break;
        }
        case "multi_product": {
          const allApplicableItems = items.filter(item =>
            promotion.get("product_ids").includes(item.get("productId"))
          );
          let discountAcrossAllItems = 0;

          items = items.map(item => {
            if (allApplicableItems.includes(item)) {
              const itemCost = parseFloat(
                item.get("quantity") * parseFloat(item.getIn(["pricingScheme", "cost"]))
              );
              const discountForItem = roundToTwoDecimals(
                +promotion.get("amount") ||
                  parseFloat(itemCost) * (parseFloat(promotion.get("percent")) / 100),
                true
              );
              discountAcrossAllItems += discountForItem;
              return item.set("discount", discountForItem);
            } else {
              return item;
            }
          });

          promotionInfo.totalDiscountForOrder = discountAcrossAllItems;

          total = total - discountAcrossAllItems;
          break;
        }
        // no default
      }
    }

    return fromJS({
      items,
      subTotal: roundToTwoDecimals(subTotal),
      totalShippingCost: roundToTwoDecimals(totalShippingCost),
      total: roundToTwoDecimals(total),
      discount: roundToTwoDecimals(globalDiscount),
      promotionInfo,
    });
  },
  getAllUnrenderedItemIds: state =>
    selectors
      .getItems(state)
      .filter(item => {
        const pageNumbers = Object.values(PAGES_BY_PRODUCT_TYPE[item.get("productTypeId")]);
        const isItemMissingRendersForOneOrMoreLayers = item
          .get("layers")
          .filter(layer => layer.get("id") !== "EXTRA_TEXT_LAYER")
          .filter(layer => pageNumbers.includes(layer.get("page")))
          .filter(layer =>
            [LAYER_TYPES.SIGNATURE, LAYER_TYPES.PHOTO, LAYER_TYPES.TEXT].includes(layer.get("type"))
          )
          .some(layer => {
            const isRenderableLayerType = [
              LAYER_TYPES.SIGNATURE,
              LAYER_TYPES.PHOTO,
              LAYER_TYPES.TEXT,
            ].includes(layer.get("type"));
            return isRenderableLayerType && !layer.get("render");
          });

        return !item.get("isRendering") && isItemMissingRendersForOneOrMoreLayers;
      })
      .map(item => item.get("id")),
  getAllUnapprovedItems: state => selectors.getItems(state).filter(i => !i.get("isApproved")),
  getDuplicateAlertItem: state =>
    selectors.getItems(state).find(i => !i.get("duplicateAlertShown")),
  getLineItemsForOrderPayload: state => {
    const orderSummary = selectors.getOrderSummary(state);
    const items = orderSummary.get("items");
    return items.map(item => {
      const customisations = item
        .get("layers")
        .filter(layer => layer.get("id") !== "EXTRA_TEXT_LAYER")
        .filter(layer =>
          [
            LAYER_TYPES.PHOTO,
            LAYER_TYPES.TEXT,
            LAYER_TYPES.SIGNATURE,
            LAYER_TYPES.GRAPHIC,
          ].includes(layer.get("type"))
        )
        .map(layer => {
          const customisation = {
            klass: `${layer.get("type")}::Config`,
            layer_id: layer.get("id"),
          };

          if (layer.get("type") === LAYER_TYPES.TEXT) {
            const rect = layer.getIn(["config", "rect"]).toJS();
            if (rect.transformedRect) {
              customisation.stringRect = `{{${rect.transformedRect.x}, ${rect.transformedRect.y}}, {${rect.transformedRect.width}, ${rect.transformedRect.height}}}`;
            } else {
              customisation.stringRect = `{{${rect.x}, ${rect.y}}, {${rect.width}, ${rect.height}}}`;
            }
            customisation.text = layer.getIn(["config", "text"]);
          }

          if (layer.get("type") === LAYER_TYPES.SIGNATURE) {
            const drawing = layer.getIn(["config", "drawing"]);
            const avatar = layer.getIn(["config", "avatar"]);
            customisation.base64_image = drawing && drawing.get("image");
            customisation.avatar_url = avatar && avatar.get("url");
          } else {
            customisation.s3_key = layer.get("render");
          }

          if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
            customisation.s3_key = layer.getIn(["config", "s3_key"]);
          }

          if (!customisation.s3_key) {
            console.warn(
              "Created a line item with a customisation that has no render:",
              customisation,
              layer
            );
          }

          return customisation;
        });

      const lineItem = {
        quantity: item.get("quantity"),
        item_cost: 0, // TODO
        discount: item.get("discount") || 0,
        preview_s3_key: item.get("preview_s3_key"),
        product_id: item.get("productId"),
        product_options: item.get("product_options"),
        design_id: item.get("designId"),
        item_description: item.get("description"),
        uploadcare_uuids: uniq(
          item
            .get("layers")
            .reduce((allUuids, layer) => {
              if (layer.get("type") === LAYER_TYPES.PHOTO) {
                const uuids = layer
                  .getIn(["config", "layout"])
                  .reduce((layerUuids, region) => {
                    if (region.get("image")) {
                      return layerUuids.concat(region.getIn(["image", "src", "uploadcareUuid"]));
                    }

                    return layerUuids;
                  }, new List())
                  .toJS();

                return allUuids.concat(uuids);
              }

              return allUuids;
            }, new List())
            .flatten(1)
            .toJS()
        ),
        recipients: [
          {
            quantity: item.get("quantity"),
            customisations,
          },
        ],
        sub_total: item.get("quantity") * item.getIn(["pricingScheme", "cost"]),
        delivery_cost: item.getIn(["postageScheme", "cost"]),
        total:
          item.get("quantity") * item.getIn(["pricingScheme", "cost"]) +
          item.getIn(["postageScheme", "cost"]),
      };

      if (item.get("addressBookId")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          address_book_id: item.get("addressBookId"),
        }));
      }

      if (item.get("address")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          address_book_entry: item.get("address"),
        }));
      }

      if (item.get("postDate")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          posting_date: moment(item.get("postDate")).format("YYYY-MM-DD"),
        }));
      }

      return fromJS(lineItem);
    });
  },
  getTotalQuantity: state => {
    const totalQuantityWithoutPrints = selectors.getItems(state).reduce((totalQty, item) => {
      if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
        return totalQty;
      }

      return totalQty + item.get("quantity");
    }, 0);

    const bagHasOneOrMorePrints =
      selectors
        .getItems(state)
        .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT).size > 0;

    return totalQuantityWithoutPrints + (bagHasOneOrMorePrints ? 1 : 0);
  },
};
