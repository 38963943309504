import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import './Icon.scss';

const manifest = require("../../icons/manifest.json");

const Icon = ({ name, size }) => {
  const classes = classNames("icon", `icon--${name}`, {
    [`icon--${size}`]: size,
  });

  return <span className={classes} dangerouslySetInnerHTML={{ __html: manifest[name] }} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(manifest)).isRequired,
  size: PropTypes.oneOf(["small", "large", "full-width"]),
};

export default Icon;
